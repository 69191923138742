import { Navigate, Route, Routes } from "react-router-dom";
import React from "react";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import SignInForm from "./components/SignInForm";
import ForbiddenFeedbackPage from "./components/ForbiddenFeedbackPage";
import BasePageScreen from "./components/common/BasePageScreen";
import HomePageActions from "./components/HomePageActions";
import AdminEmailSignUp from "./components/signup/AdminEmailSignUp";
import SelectPlanSignUp from "./components/signup/SelectPlanSignUp";
import WorkflowList from "./components/workflow_history/WorkflowList";
import WorkflowRunDetailCard from "./components/workflow_history/WorkflowRunDetailCard";
import PersonnelDetailCard from "./components/personnel/PersonnelDetailCard";
import EditPersonnelForm from "./components/personnel/EditPersonnelForm";
import AddPersonnelForm from "./components/personnel/AddPersonnelForm";
import AddRoleForm from "./components/personnel/AddRoleForm";
import RoleDetailCard from "./components/personnel/RoleDetailCard";
import EditRoleForm from "./components/personnel/EditRoleForm";
import PersonnelOrRoleList from "./components/personnel/PersonnelOrRoleList";
import AddSampleForm from "./components/samples/AddSampleForm";
import BulkAddSampleForm from "./components/samples/BulkAddSampleForm";
import SampleDetailsCard from "./components/samples/SampleDetailsCard";
import EditSampleForm from "./components/samples/EditSampleForm";
import AddWorkflowTemplateForm from "./components/workflow_templates/AddWorkflowTemplateForm";
import ReagentDetailsCard from "./components/reagents/ReagentDetailsCard";
import EditReagentForm from "./components/reagents/EditReagentForm";
import AddReagentForm from "./components/reagents/AddReagentForm";
import EquipmentDetailsCard from "./components/equipment/EquipmentDetailsCard";
import EditEquipmentForm from "./components/equipment/EditEquipmentForm";
import AddEquipmentForm from "./components/equipment/AddEquipmentForm";
import SOPCategoryList from "./components/sops/SOPCategoryList";
import AnnualProcedureManualReviewForm from "./components/sops/AnnualProcedureManualReviewForm";
import SOPList from "./components/sops/SOPList";
import SOPViewer from "./components/sops/SOPViewer";
import DraftViewer from "./components/sops/DraftViewer";
import AddSOPCategoryForm from "./components/sops/AddSOPCategoryForm";
import SOPCategoryDetailCard from "./components/sops/SOPCategoryDetailCard";
import EditSOPCategoryForm from "./components/sops/EditSOPCategoryForm";
import AddSOPForm from "./components/sops/AddSOPForm";
import UpdateSOPForm from "./components/sops/UpdateSOPForm";
import UpdateDraftForm from "./components/sops/UpdateDraftForm";
import FormsList from "./components/forms/FormsList";
import AddFormForm from "./components/forms/AddFormForm";
import FormViewer from "./components/forms/FormViewer";
import RecordsList from "./components/records/RecordsList";
import RecordDetailCard from "./components/records/RecordDetailCard";
import AddWorkflowRunForm from "./components/workflow_history/AddWorkflowRunForm";
import WorkflowRunUpdateStatusForm from "./components/workflow_history/WorkflowRunUpdateStatusForm";
import TaskDetailCard from "./components/tasks/TaskDetailCard";
import SettingsPage from "./components/settings/SettingsPage";
import NotFoundFeedbackPage from "./components/NotFoundFeedbackPage";
import EquipmentOrTypeList from "./components/equipment/EquipmentOrTypeList";
import EquipmentTypeDetailsCard from "./components/equipment/EquipmentTypeDetailsCard";
import EditEquipmentTypeForm from "./components/equipment/EditEquipmentTypeForm";
import ReagentOrTypeList from "./components/reagents/ReagentOrTypeList";
import AddEquipmentTypeForm from "./components/equipment/AddEquipmentTypeForm";
import ReagentTypesDetailsCard from "./components/reagents/ReagentTypesDetailsCard";
import EditReagentTypeForm from "./components/reagents/EditReagentTypeForm";
import AddReagentTypeForm from "./components/reagents/AddReagentTypeForm";
import WorkflowTemplateDetailsCard from "./components/workflow_templates/WorkflowTemplateDetailsCard";
import EditWorkflowTemplateForm from "./components/workflow_templates/EditWorkflowTemplateForm";
import WorkflowTemplatesList from "./components/workflow_templates/WorkflowTemplatesList";
import EditFormForm from "./components/forms/EditFormForm";
import TasksList from "./components/tasks/TasksList";
import CompetencyTypeList from "./components/competency/CompetencyTypeList";
import CompetencyTypeDetailCard from "./components/competency/CompetencyTypeDetailCard";
import AddCompetencyTypeForm from "./components/competency/AddCompetencyTypeForm";
import EditCompetencyTypeForm from "./components/competency/EditCompetencyTypeForm";
import CompetencyList from "./components/competency/CompetencyList";
import AddCompetencyForm from "./components/competency/AddCompetencyForm";
import CompetencyDetailCard from "./components/competency/CompetencyDetailCard";
import EditCompetencyForm from "./components/competency/EditCompetencyForm";
import SampleOrTypeList from "./components/samples/SampleOrTypeList";
import SampleTypeDetailsCard from "./components/samples/SampleTypeDetailsCard";
import AddSampleTypeForm from "./components/samples/AddSampleTypeForm";
import EditSampleTypeForm from "./components/samples/EditSampleTypeForm";
import ReviewAndCheckoutSignUp from "./components/signup/ReviewAndCheckoutSignUp";
import PaymentConfirmationSignUp from "./components/signup/PaymentConfirmationSignUp";
import StripeElementWrapper from "./components/common/StripeElementWrapper";
import UserDetailCard from "./components/settings/UserDetailCard";
import EditUserForm from "./components/settings/EditUserForm";
import AddUserForm from "./components/settings/AddUserForm";
import ActivateUserForm from "./components/ActivateUserForm";
import ResetUserPasswordForm from "./components/ResetUserPasswordForm";
import ChecklistManager from "./components/checklist_management/ChecklistManager";

export default function App(): JSX.Element {
  return (
    <Routes>
      <Route path="*" element={<NotFoundFeedbackPage />} />
      <Route path="/">
        <Route
          index
          element={<Navigate to="regulator/login" replace={true} />}
        />

        <Route path="signup">
          <Route index element={<Navigate to="admin" replace={true} />} />
          <Route path="admin" element={<AdminEmailSignUp />} />
          <Route path="plan" element={<SelectPlanSignUp />} />
          <Route path="review" element={<ReviewAndCheckoutSignUp />} />
          <Route
            path="confirmation"
            element={
              <StripeElementWrapper component={<PaymentConfirmationSignUp />} />
            }
          />
        </Route>

        <Route path="forbidden" element={<ForbiddenFeedbackPage />} />
        <Route path="invite" element={<ActivateUserForm />}></Route>
        <Route path="resetPassword" element={<ResetUserPasswordForm />}></Route>

        <Route path="regulator/login" element={<SignInForm />}></Route>
        <Route path="regulator" element={<BasePageScreen />}>
          <Route
            index
            element={<Navigate to="/regulator/home" replace={true} />}
          />
          <Route path="home" element={<HomePageActions />} />

          <Route path="workflows/templates">
            <Route index element={<WorkflowTemplatesList />} />
            <Route path="add" element={<AddWorkflowTemplateForm />} />
            <Route path=":id">
              <Route index element={<WorkflowTemplateDetailsCard />} />
              <Route path="edit" element={<EditWorkflowTemplateForm />} />
            </Route>
            <Route path="archived">
              <Route index element={<WorkflowTemplatesList />} />
              <Route path=":id">
                <Route index element={<WorkflowTemplateDetailsCard />} />
              </Route>
            </Route>
          </Route>

          <Route path="workflows/history">
            <Route index element={<WorkflowList />} />
            <Route path=":id">
              <Route index element={<WorkflowRunDetailCard />} />
              <Route path="edit" element={<WorkflowRunUpdateStatusForm />} />
            </Route>
            <Route path="add" element={<AddWorkflowRunForm />} />
          </Route>

          <Route path="samples">
            <Route index element={<SampleOrTypeList />} />
            <Route path="add" element={<AddSampleForm />} />
            <Route path="bulk-add" element={<BulkAddSampleForm />} />
            <Route path=":id">
              <Route index element={<SampleDetailsCard />} />
              <Route path="edit" element={<EditSampleForm />} />
            </Route>
            <Route path="types">
              <Route index element={<SampleOrTypeList />} />
              <Route path="add" element={<AddSampleTypeForm />} />
              <Route path=":id">
                <Route index element={<SampleTypeDetailsCard />} />
                <Route path="edit" element={<EditSampleTypeForm />} />
              </Route>
            </Route>
            <Route path="reported">
              <Route index element={<SampleOrTypeList />} />
            </Route>
          </Route>

          <Route path="reagents">
            <Route index element={<ReagentOrTypeList />} />
            <Route path="add" element={<AddReagentForm />} />
            <Route path=":id">
              <Route index element={<ReagentDetailsCard />} />
              <Route path="edit" element={<EditReagentForm />} />
            </Route>
            <Route path="types">
              <Route index element={<ReagentOrTypeList />} />
              <Route path="add" element={<AddReagentTypeForm />} />
              <Route path=":id">
                <Route index element={<ReagentTypesDetailsCard />} />
                <Route path="edit" element={<EditReagentTypeForm />} />
              </Route>
            </Route>
            <Route path="archived">
              <Route index element={<ReagentOrTypeList />} />
              <Route path="add" element={<AddReagentForm />} />
              <Route path=":id">
                <Route index element={<ReagentDetailsCard />} />
                <Route path="edit" element={<EditReagentForm />} />
              </Route>
            </Route>
          </Route>

          <Route path="equipment">
            <Route index element={<EquipmentOrTypeList />} />
            <Route path="add" element={<AddEquipmentForm />} />
            <Route path=":id">
              <Route index element={<EquipmentDetailsCard />} />
              <Route path="edit" element={<EditEquipmentForm />} />
            </Route>
            <Route path="types">
              <Route index element={<EquipmentOrTypeList />} />
              <Route path="add" element={<AddEquipmentTypeForm />} />
              <Route path=":id">
                <Route index element={<EquipmentTypeDetailsCard />} />
                <Route path="edit" element={<EditEquipmentTypeForm />} />
              </Route>
            </Route>
            <Route path="archived">
              <Route index element={<EquipmentOrTypeList />} />
              <Route path="add" element={<AddEquipmentForm />} />
              <Route path=":id">
                <Route index element={<EquipmentDetailsCard />} />
                <Route path="edit" element={<EditEquipmentForm />} />
              </Route>
            </Route>
          </Route>

          <Route path="personnel">
            <Route index element={<PersonnelOrRoleList />} />
            <Route path="add" element={<AddPersonnelForm />} />
            <Route path=":id">
              <Route index element={<PersonnelDetailCard />} />
              <Route path="edit" element={<EditPersonnelForm />} />
            </Route>
            <Route path="roles">
              <Route index element={<PersonnelOrRoleList />} />
              <Route path=":id">
                <Route index element={<RoleDetailCard />} />
                <Route path="edit" element={<EditRoleForm />} />
              </Route>
              <Route path="add" element={<AddRoleForm />} />
            </Route>
          </Route>

          <Route path="competency">
            <Route index element={<CompetencyTypeList />} />
            <Route path="type">
              <Route path=":id">
                <Route index element={<CompetencyTypeDetailCard />} />
                <Route path="edit" element={<EditCompetencyTypeForm />} />
              </Route>

              <Route path="add">
                <Route index element={<AddCompetencyTypeForm />} />
              </Route>
            </Route>
            <Route path="active" element={<CompetencyList />} />
            <Route path="pending" element={<CompetencyList />} />
            <Route path="inactive" element={<CompetencyList />} />
            <Route path="add" element={<AddCompetencyForm />} />
            <Route path=":id">
              <Route index element={<CompetencyDetailCard />} />
              <Route path="edit" element={<EditCompetencyForm />} />
            </Route>
          </Route>

          <Route path="sops">
            <Route
              index
              element={<Navigate to="categories" replace={true} />}
            />
            <Route path="categories">
              <Route index element={<SOPCategoryList />} />
              <Route path=":id">
                <Route index element={<SOPCategoryDetailCard />} />
                <Route path="edit" element={<EditSOPCategoryForm />} />
              </Route>
              <Route path="add" element={<AddSOPCategoryForm />} />
            </Route>

            <Route path="active" element={<SOPList />} />
            <Route path="draft" element={<SOPList />} />
            <Route path="archived" element={<SOPList />} />
            <Route path="add" element={<AddSOPForm />} />

            <Route path=":id">
              <Route index element={<SOPViewer />} />
              <Route path="edit" element={<UpdateSOPForm />} />
              <Route path="draft-edit" element={<UpdateDraftForm />} />
              <Route path="draft-review" element={<DraftViewer />} />
              <Route
                path="procedure-manual-review"
                element={<AnnualProcedureManualReviewForm />}
              />
            </Route>
          </Route>

          <Route path="forms">
            <Route index element={<FormsList />} />
            <Route path=":id">
              <Route index element={<FormViewer />} />
              <Route path="edit" element={<EditFormForm />} />
            </Route>
            <Route path="add" element={<AddFormForm />} />
            <Route path="archived">
              <Route index element={<FormsList />} />
            </Route>
          </Route>

          <Route path="records" element={<RecordsList />} />
          <Route path="/regulator/records/:id" element={<RecordDetailCard />} />

          <Route path="/regulator/tasks/">
            <Route index element={<TasksList />} />
            <Route path=":id" element={<TaskDetailCard />} />
            <Route path="closed">
              <Route index element={<TasksList />} />
              <Route path=":id" element={<TaskDetailCard />} />
            </Route>
          </Route>

          <Route path="settings">
            <Route index element={<SettingsPage />} />
            <Route path="user/:id">
              <Route index element={<UserDetailCard />} />
              <Route path="edit" element={<EditUserForm />} />
            </Route>
            <Route path="user/add">
              <Route index element={<AddUserForm />} />
            </Route>
          </Route>
          <Route
            path="checklist-manager"
            element={<ChecklistManager />}
          ></Route>
        </Route>
      </Route>
    </Routes>
  );
}
