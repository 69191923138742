import CustomSelectMenu from "../common/CustomSelectMenu";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { updateWorkflowRun, useWorkflowRun } from "../common/DataFetchers";
import { useUserContext } from "../common/BasePageScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import { Status } from "../common/Entities.d";
import FileUploadComponent from "../common/FileUploadComponent";
import { formatErrorMessage } from "../common/Utils";
import ErrorBanner from "../common/ErrorBanner";

const options = [
  { id: "1", value: "Queued", display: "Queued" },
  { id: "2", value: "Pass", display: "Pass" },
  { id: "3", value: "Fail", display: "Fail" },
];

export default function WorkflowRunUpdateStatusForm() {
  const navigate = useNavigate();
  const { workflowHistoryRead, workflowHistoryWrite } = useUserContext();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const [newAttachments] = useState<File[]>([]);

  const params = useParams<{ id: string }>();
  const runId = params.id as string;

  const {
    workflowRun,
    workflowRunIsLoading,
    workflowRunIsError,
    mutateWorkflowRun,
  } = useWorkflowRun(runId, workflowHistoryWrite);

  const [status, setStatus] = useState<{
    id: string;
    value: any;
    display: string;
  }>(options[0]);

  useEffect(() => {
    setStatus(
      options[
        options.findIndex((option) => option.value === workflowRun?.status)
      ]
    );
  }, [workflowRun]);

  if (workflowRunIsLoading) {
    return <LoadingComponent />;
  }

  if (workflowRunIsError || !workflowRun) {
    return <ErrorComponent />;
  }

  if (!workflowHistoryWrite || !workflowHistoryRead) {
    navigate("/forbidden");
    return <></>;
  }

  return (
    <form
      className="py-3 px-3 space-y-8 divide-y divide-gray-200"
      onSubmit={(e: React.SyntheticEvent) => {
        e.preventDefault();

        setShowErrorBanner(false);

        const newStatus = status.value as Status;

        if (workflowHistoryWrite) {
          updateWorkflowRun(runId, newStatus, newAttachments)
            .then((run) => {
              mutateWorkflowRun(run, false);
              navigate(`/regulator/workflows/history/${run?.id}`);
            })
            .catch((error) => {
              setErrorBannerText(formatErrorMessage(error));
              setShowErrorBanner(true);
            });
        }
      }}
    >
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <ErrorBanner
            showError={showErrorBanner}
            setShowError={setShowErrorBanner}
            errorText={errorBannerText}
          />
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Update Workflow Run Status
            </h3>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <CustomSelectMenu
                label={"Status"}
                options={options}
                selected={status}
                setSelected={setStatus}
              />
            </div>
            <FileUploadComponent
              label={"New Attachments"}
              files={newAttachments}
              fileTypes={"PDF, PNG, JPG"}
            />
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <Link to={`/regulator/workflows/history/${runId}`}>
              <button
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Cancel
              </button>
            </Link>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
