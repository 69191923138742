import { FormElementSimpleSelect } from "../../common/Entities.d";
import { useEffect, useState } from "react";
import CustomSelectMenu from "../../common/CustomSelectMenu";

export default function SelectMenu(props: {
  selectMenuInput: FormElementSimpleSelect;
  recordPointer: { recordValueArray: string; recordValueIndex: number };
  textValues: Array<string[]>;
  setTextValues: (textValues: Array<string[]>) => void;
}) {
  const options = props.selectMenuInput.options.map((option, optionIdx) => {
    return {
      id: optionIdx.toString(),
      value: option.value,
      display: option.value,
    };
  });
  const label = props.selectMenuInput.label;
  const [selected, setSelected] = useState(options[0]);

  useEffect(() => {
    props.textValues[props.recordPointer.recordValueIndex] = [selected.value];
    props.setTextValues(props.textValues);
  }, [selected]);

  return (
    <div>
      <CustomSelectMenu
        label={label}
        options={options}
        selected={selected}
        setSelected={setSelected}
      />
    </div>
  );
}
