import { LockClosedIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { addRegistration, login, useCurrentUser } from "./common/DataFetchers";
import Footer from "./common/Footer";
import { formatErrorMessage } from "./common/Utils";
import ErrorBanner from "./common/ErrorBanner";

export default function SignInForm() {
  const navigate = useNavigate();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const { isAuthenticated } = useCurrentUser();
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [userFound, setUserFound] = useState(true);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/regulator/home", { replace: true });
    }
  }, [isAuthenticated]);

  return (
    <div>
      <ErrorBanner
        showError={showErrorBanner}
        setShowError={setShowErrorBanner}
        errorText={errorBannerText}
      />
      <div className="relative bg-white pt-16 px-4 pb-56 sm:px-6 lg:pt-24 lg:px-8">
        <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full space-y-8">
            <div>
              <img
                className="mx-auto -mt-36 -mb-48"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/dl_regulator_logo-full_color-black.svg"
                }
                alt="Regulator"
              />
              <h2 className="mt-12 sm:mt-6 text-center text-3xl font-extrabold text-gray-900">
                Log in to Regulator
              </h2>
              <p className="mt-2 text-center text-sm text-gray-600">
                Or{" "}
                <button
                  type="button"
                  onClick={() => {
                    addRegistration()
                      .then(() => navigate("/signup"))
                      .catch((error) => {
                        setErrorBannerText(formatErrorMessage(error));
                        setShowErrorBanner(true);
                      });
                  }}
                  className="font-medium text-blue-600 hover:text-blue-500"
                >
                  sign up with a 30 day money back guarantee
                </button>
              </p>
            </div>
            <form
              className="mt-8 space-y-6"
              onSubmit={(e: React.SyntheticEvent) => {
                e.preventDefault();
                const target = e.target as typeof e.target & {
                  email: { value: string };
                  password: { value: string };
                };

                const email = target.email.value;
                const password = target.password.value;

                setEmailError(false);
                setPasswordError(false);
                setUserFound(true);

                if (email.trim() === "" || password.trim() === "") {
                  setEmailError(true);
                  setPasswordError(true);
                  return;
                }

                login(email, password)
                  .then(() => navigate("/regulator/home"))
                  .catch((error) => {
                    console.log(error);
                    setUserFound(false);
                  });
              }}
            >
              <input type="hidden" name="remember" defaultValue="true" />
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className={
                      emailError
                        ? "appearance-none rounded-none relative block w-full px-3 py-2 border border-red-300 placeholder-red-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm"
                        : "appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                    }
                    placeholder="Email address"
                  />
                </div>
                <div>
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    className={
                      passwordError
                        ? "appearance-none rounded-none relative block w-full px-3 py-2 border border-red-300 placeholder-red-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm"
                        : "appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                    }
                    placeholder="Password"
                  />
                </div>
              </div>
              <p
                className="mt-2 text-sm text-red-500"
                hidden={!emailError && !passwordError}
              >
                * Email and password are required
              </p>
              <p className="mt-2 text-sm text-red-500" hidden={userFound}>
                * Email and password do not match records
              </p>
              <div className="flex items-center justify-between">
                {/*<div className="flex items-center">*/}
                {/*    <input*/}
                {/*        id="remember-me"*/}
                {/*        name="remember-me"*/}
                {/*        type="checkbox"*/}
                {/*        onChange={event => setRemember(event.target.checked)}*/}
                {/*        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"*/}
                {/*    />*/}
                {/*    <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">*/}
                {/*        Remember me*/}
                {/*    </label>*/}
                {/*</div>*/}
              </div>

              <div>
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <LockClosedIcon
                      className="h-5 w-5 text-blue-500 group-hover:text-blue-400"
                      aria-hidden="true"
                    />
                  </span>
                  Log in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 w-full">
        <Footer />
      </div>
    </div>
  );
}
