import { ClockIcon } from "@heroicons/react/outline";

export default function LinkNotFoundComponent() {
  return (
    <div className="py-16 bg-white rounded-md">
      <div className="mx-auto flex items-center justify-center h-20 w-20 rounded-full bg-red-100">
        <ClockIcon className="h-10 w-10 text-red-500" aria-hidden="true" />
      </div>
      <div className="m-auto text-center justify-center">
        <span className="font-semibold text-2xl text-red-500">
          Sorry, it appears this link has expired or is broken.
        </span>
      </div>
      <div className="m-auto text-center">
        Please contact your Regulator admin to get another invitation link.
      </div>
    </div>
  );
}
