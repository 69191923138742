import { PlusIcon, XIcon } from "@heroicons/react/solid";
import React from "react";
import {
  FormCategoryPointer,
  FormElementSimpleInput,
  FormElementSimpleSelect,
} from "../../common/Entities.d";
import { Switch } from "@headlessui/react";
import { classNames } from "../../common/Utils";

export default function SimpleSelectFormBuilderComponent(props: {
  orderComponent: { error: boolean; pointer: FormCategoryPointer };
  orderComponentIndex: number;
  allowDrop: (e: React.DragEvent<HTMLDivElement>) => void;
  dropHandler: (
    e: React.DragEvent<HTMLDivElement>,
    orderComponent: { error: boolean; pointer: FormCategoryPointer },
    orderComponentIndex: number
  ) => void;
  dragStartHandler: (
    e: React.DragEvent<HTMLDivElement>,
    orderComponent: { error: boolean; pointer: FormCategoryPointer },
    orderComponentIndex: number
  ) => void;
  removeComponent: (e: React.MouseEvent<HTMLButtonElement>) => void;
  element: FormElementSimpleSelect;
  toggleSimpleSelectRequired: (
    e: boolean,
    element: FormElementSimpleSelect
  ) => void;
  addSimpleSelectOption: (
    e: React.MouseEvent<HTMLButtonElement>,
    element: FormElementSimpleSelect
  ) => void;
  removeSimpleSelectOption: (
    e: React.MouseEvent<HTMLButtonElement>,
    element: FormElementSimpleSelect,
    idx: number
  ) => void;
}) {
  return (
    <div
      className={
        props.orderComponent.error
          ? "p-3 mt-3 bg-blue-50 border rounded-md shadow-md"
          : "p-3 mt-3 bg-blue-50 border-red-300 rounded-md shadow-md"
      }
      id={props.orderComponent.pointer.category}
      onDragOver={props.allowDrop}
      onDrop={(e) =>
        props.dropHandler(e, props.orderComponent, props.orderComponentIndex)
      }
      onDragStart={(e) =>
        props.dragStartHandler(
          e,
          props.orderComponent,
          props.orderComponentIndex
        )
      }
      draggable
    >
      <div className="text-right">
        <button
          id={props.orderComponentIndex.toString()}
          type="button"
          onClick={props.removeComponent}
          className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          <XIcon className="h-4 w-4" aria-hidden="true" />
        </button>
      </div>
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        {props.element.elementType}
      </h3>
      <div className="py-1">
        <label
          htmlFor={`label-${props.orderComponentIndex.toString()}`}
          className="block text-sm font-medium text-gray-700"
        >
          Label
        </label>
        <div className="mt-1">
          <input
            type="text"
            name={`label-${props.orderComponentIndex.toString()}`}
            id={`label-${props.orderComponentIndex.toString()}`}
            className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Enter label text here"
            key={props.element.label}
            defaultValue={props.element.label}
            onChange={(e) => (props.element.label = e.currentTarget.value)}
          />
        </div>
      </div>
      <div className="py-1">
        <Switch.Group as="div" className="flex items-center justify-between">
          <span className="flex-grow flex flex-col">
            <Switch.Label
              as="span"
              className="text-sm font-medium text-gray-900"
              passive
            >
              Required
            </Switch.Label>
            <Switch.Description as="span" className="text-sm text-gray-500">
              Set this form field to make this form field required.
            </Switch.Description>
          </span>
          <Switch
            checked={props.element.required}
            onChange={(e) => props.toggleSimpleSelectRequired(e, props.element)}
            className={classNames(
              props.element.required ? "bg-blue-600" : "bg-gray-200",
              "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                props.element.required ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
              )}
            />
          </Switch>
        </Switch.Group>
      </div>
      <div className="py-3">
        <button
          type="button"
          onClick={(e) => props.addSimpleSelectOption(e, props.element)}
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <PlusIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
          Add Option
        </button>
      </div>
      {props.element.options.map((option, optionIdx) => (
        <div>
          <div className="text-right">
            <button
              type="button"
              onClick={(e) =>
                props.removeSimpleSelectOption(e, props.element, optionIdx)
              }
              className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              <XIcon className="h-4 w-4" aria-hidden="true" />
            </button>
          </div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Option {optionIdx + 1}
          </h3>
          <div className="py-1">
            <label
              htmlFor={`simple-select-option-${props.orderComponentIndex.toString()}-label-${optionIdx.toString()}`}
            >
              Value
            </label>
            <div className="mt-1">
              <input
                type="text"
                name={`simple-select-option-${props.orderComponentIndex.toString()}-label-${optionIdx.toString()}`}
                id={`simple-select-option-${props.orderComponentIndex.toString()}-label-${optionIdx.toString()}`}
                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="Enter value text here"
                key={option.value}
                defaultValue={option.value}
                onChange={(e) => (option.value = e.currentTarget.value)}
              />
            </div>
          </div>
          <div className="py-1">
            <label
              htmlFor={`simple-select-option-${props.orderComponentIndex.toString()}-description-${optionIdx.toString()}`}
              className="block text-sm font-medium text-gray-700"
            >
              Description (Optional)
            </label>
            <div className="mt-1">
              <input
                type="text"
                name={`simple-select-option-${props.orderComponentIndex.toString()}-description-${optionIdx.toString()}`}
                id={`simple-select-option-${props.orderComponentIndex.toString()}-description-${optionIdx.toString()}`}
                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="Enter description text here"
                key={option.description}
                defaultValue={option.description}
                onChange={(e) => (option.description = e.currentTarget.value)}
              />
            </div>
          </div>
        </div>
      ))}
      <p
        className="mt-2 text-sm text-red-500"
        hidden={!props.orderComponent.error}
      >
        * Label and at least one option is required
      </p>
    </div>
  );
}
