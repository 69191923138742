import { PersonnelSummary } from "../common/Entities.d";
import React from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleIcon } from "@heroicons/react/outline";

interface ApproverSectionProps {
  approvalError: boolean;
  labDirectorApprovalError: boolean;
  approvers: Map<string, ApproverPersonnel>;
  additionalApprovers: Map<string, ApproverPersonnel>;
  selectedApproverIDs: Set<string>;
  setSelectedApproverIDs: (value: Set<string>) => void;
}

export interface ApproverPersonnel {
  roleName: string;
  personnel: PersonnelSummary[];
}

export default function ApproverSection(props: ApproverSectionProps) {
  const createApproverSection = (
    approverBucket: Map<string, ApproverPersonnel>
  ) => {
    const section: JSX.Element[] = [];
    approverBucket.forEach((approver) => {
      section.push(
        <div>
          <label className="block text-sm italic font-medium text-gray-500 sm:mt-px sm:pt-2 mb-2">
            {approver.roleName}
          </label>
          {approver.personnel.map((personnel) => {
            return (
              <div className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id={personnel.id}
                    name={personnel.id}
                    type="checkbox"
                    checked={props.selectedApproverIDs.has(personnel.id)}
                    onChange={(e) => {
                      e.target.checked
                        ? props.selectedApproverIDs.add(e.target.id)
                        : props.selectedApproverIDs.delete(e.target.id);
                      props.setSelectedApproverIDs(
                        new Set(props.selectedApproverIDs)
                      );
                    }}
                    className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor={personnel.id}
                    className="font-medium text-gray-700"
                  >
                    {`${personnel.firstName} ${personnel.lastName}`.trim()}
                  </label>
                </div>
              </div>
            );
          })}
        </div>
      );
    });

    return section;
  };

  return (
    <>
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <p className="text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Approvers
          <span hidden={props.approvers.size !== 0}>
            <ExclamationCircleIcon className="ml-1 text-red-500 inline-flex h-5 w-5" />
          </span>
        </p>
        {props.approvers.size === 0 ? (
          <p className="text-sm text-gray-500">
            Please{" "}
            <Link
              to="/regulator/personnel/add"
              className="font-medium text-blue-500"
              target="_blank"
              rel="noreferrer"
            >
              create
            </Link>{" "}
            personnel records for the approvers for this document. All documents
            must have Laboratory Director approval at minimum.
          </p>
        ) : (
          createApproverSection(props.approvers)
        )}
        <p
          className="text-sm text-red-500 sm:col-span-3 sm:col-start-2"
          hidden={!props.approvalError}
        >
          * At least one (1) approver must be selected.
        </p>
        <p
          className="text-sm text-red-500 sm:col-span-3 sm:col-start-2"
          hidden={!props.labDirectorApprovalError}
        >
          * Laboratory Director approval is required for all SOPs.
        </p>
      </div>
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <p className="text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Additional Approvers
        </p>
        <div>
          {props.additionalApprovers.size === 0 ? (
            <p className="text-sm text-gray-500">None</p>
          ) : (
            createApproverSection(props.additionalApprovers)
          )}
        </div>
      </div>
    </>
  );
}
