import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";
import { classNames, formatErrorMessage } from "../common/Utils";
import {
  updatePersonnelRole,
  usePersonnelRole,
  usePersonnelRoleSummaries,
} from "../common/DataFetchers";
import SOPSelectMenu from "../common/SOPSelectMenu";
import { useUserContext } from "../common/BasePageScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import { SOPSummary } from "../common/Entities.d";
import ErrorBanner from "../common/ErrorBanner";

export default function EditRoleForm(): JSX.Element {
  const { personnelRead, personnelWrite } = useUserContext();
  const navigate = useNavigate();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const { roleSummaries } = usePersonnelRoleSummaries(personnelRead);

  const [roleError, setRoleError] = useState(false);

  const params = useParams<{ id: string }>();
  const roleID = params.id as string;

  const { role, mutateRole, roleIsLoading, roleIsError } = usePersonnelRole(
    roleID,
    personnelRead
  );

  const sopOptions: {
    id: string;
    value: SOPSummary | null;
    display: string;
  }[] = [];
  sopOptions.push({ id: "None", value: null, display: "None" });

  const [jobDescription, setJobDescription] = useState(sopOptions[0]);
  const [delegation, setDelegation] = useState(sopOptions[0]);

  if (!personnelWrite || !personnelRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (roleIsLoading) {
    return <LoadingComponent />;
  }

  if (roleIsError || !role) {
    return <ErrorComponent />;
  }

  const reportsTo = new Set<string>(role.reportsTo.map((role) => role.id));
  const reportsToCheckboxes = roleSummaries
    .filter((role) => role.id !== roleID)
    .map((role) => (
      <div className="relative flex items-start">
        <div className="flex items-center h-5">
          <input
            id={role.id}
            aria-describedby="comments-description"
            name={role.name}
            type="checkbox"
            className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.target.checked
                ? reportsTo.add(e.target.id)
                : reportsTo.delete(e.target.id);
            }}
            defaultChecked={reportsTo.has(role.id)}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor={role.id} className="font-medium text-gray-700">
            {role.name}
          </label>
        </div>
      </div>
    ));

  const reports = new Set<string>(role.reports.map((role) => role.id));
  const reportsCheckboxes = roleSummaries
    .filter((role) => role.name !== "Laboratory Director" && role.id !== roleID)
    .map((role) => (
      <div className="relative flex items-start">
        <div className="flex items-center h-5">
          <input
            id={role.id}
            aria-describedby="comments-description"
            name={role.name}
            type="checkbox"
            className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.target.checked
                ? reports.add(e.target.id)
                : reports.delete(e.target.id);
            }}
            defaultChecked={reports.has(role.id)}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor={role.id} className="font-medium text-gray-700">
            {role.name}
          </label>
        </div>
      </div>
    ));

  return (
    <form
      className="py-3 px-3 space-y-8 divide-y divide-gray-200"
      onSubmit={(e: React.SyntheticEvent) => {
        e.preventDefault();

        setShowErrorBanner(false);

        const target = e.target as typeof e.target & {
          roleName: { value: string };
        };

        setRoleError(false);
        target.roleName.value = target.roleName.value.trim();
        if (target.roleName.value === "") {
          setRoleError(true);
          return;
        }

        if (personnelWrite) {
          updatePersonnelRole(roleID, {
            name: target.roleName.value,
            jobDescriptionDisplayID: jobDescription.value
              ? jobDescription.value.displayID
              : null,
            letterOfDelegationDisplayID: delegation.value
              ? delegation.value.displayID
              : null,
            reportsTo: Array.from(reportsTo),
            reports: Array.from(reports),
          })
            .then((role) => {
              mutateRole(role, false);
              navigate(`/regulator/personnel/roles/${role.id}`);
            })
            .catch((error) => {
              setErrorBannerText(formatErrorMessage(error));
              setShowErrorBanner(true);
            });
        }

        navigate(`/regulator/personnel/roles/${roleID}`);
      }}
    >
      <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
        <div>
          <ErrorBanner
            showError={showErrorBanner}
            setShowError={setShowErrorBanner}
            errorText={errorBannerText}
          />
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Role Information
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Fill in required details to create a new role.
          </p>
        </div>
        <div className="space-y-6 sm:space-y-5">
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Role Name
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                type="text"
                name="roleName"
                id="roleName"
                defaultValue={role.name}
                autoComplete="given-name"
                className={classNames(
                  "max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md",
                  roleError
                    ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                    : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                )}
              />
              <p className="mt-2 text-sm text-red-500" hidden={!roleError}>
                * Role Name is required
              </p>
            </div>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <SOPSelectMenu
              label="Job Description"
              selected={jobDescription}
              setSelected={setJobDescription}
            />
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <SOPSelectMenu
              label="Letter of Delegation"
              selected={delegation}
              setSelected={setDelegation}
            />
          </div>
          {role.name === "Laboratory Director" ? null : (
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Reports To
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                {reportsToCheckboxes}
              </div>
            </div>
          )}
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="last-name"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Reports
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              {reportsCheckboxes}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <Link to="/regulator/personnel/roles">
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
