import { Switch } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import {
  capitalizeEachWord,
  classNames,
  formatErrorMessage,
  getDate,
  getFormattedAmount,
} from "../common/Utils";
import {
  addBillingPortalSession,
  updateSubscription,
  updateSubscriptionPreview,
  useBilling,
} from "../common/DataFetchers";
import { useUserContext } from "../common/BasePageScreen";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import { priceInfo } from "../common/Constants.d";
import { BillingPreviewDetails } from "../common/Entities.d";
import ErrorBanner from "../common/ErrorBanner";

function getPriceID(plan: string, annualBilling: boolean) {
  const frequency = annualBilling ? "annually" : "monthly";
  const priceInfoIdx = priceInfo.findIndex(
    (info) => info.frequency === frequency && info.plan === plan
  );
  return priceInfo[priceInfoIdx].priceID;
}

function getPlan(priceID: string | undefined) {
  if (!priceID) {
    return "";
  }
  const priceInfoIdx = priceInfo.findIndex((info) => info.priceID === priceID);
  return priceInfo[priceInfoIdx].plan;
}

function getProductName(priceID: string | undefined) {
  if (!priceID) {
    return;
  }
  const plan = getPlan(priceID);
  return plan === "starter"
    ? "Regulator Starter Plan"
    : plan === "growth"
    ? "Regulator Growth Plan"
    : "Regulator Full Plan";
}

export default function BillingCard() {
  const navigate = useNavigate();
  const { user } = useUserContext();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  useEffect(() => {
    if (!user) {
      return;
    }
    if (!user.isAdmin) {
      navigate("/forbidden");
    }
  }, [user]);

  const [priceID, setPriceID] = useState<string>();
  const [annualBilling, setAnnualBilling] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const [quantity, setQuantity] = useState<number | undefined>(0);

  const [showChangePricingPlan, setShowChangePricingPlan] = useState(false);
  const [updated, setUpdated] = useState(false);

  const { billing, billingIsLoading, billingIsError } = useBilling(
    user.isAdmin
  );

  const [subscriptionPreview, setSubscriptionPreview] =
    useState<BillingPreviewDetails>();

  useEffect(() => {
    if (!billing) {
      return;
    }
    const currentPriceID = billing.priceID;
    const priceInfoIdx = priceInfo.findIndex(
      (info) => info.priceID === currentPriceID
    );
    const billingFrequency = priceInfo[priceInfoIdx].frequency;
    setAnnualBilling(billingFrequency === "annually");
    setPriceID(currentPriceID);
    setQuantity(billing.quantity);
  }, [billing]);

  if (billingIsLoading) {
    return <LoadingComponent />;
  }

  if (billingIsError || !billing) {
    return <ErrorComponent />;
  }

  function resetBillingCard() {
    setPriceID(billing?.priceID);
    const priceInfoIdx = priceInfo.findIndex(
      (info) => info.priceID === billing?.priceID
    );
    const billingFrequency = priceInfo[priceInfoIdx].frequency;
    setAnnualBilling(billingFrequency === "annually");
    setQuantity(billing?.quantity);
    setSubscriptionPreview(undefined);
    setUpdated(false);
    setShowPreview(false);
    setShowChangePricingPlan(false);
    setShowErrorBanner(false);
  }

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      <ErrorBanner
        showError={showErrorBanner}
        setShowError={setShowErrorBanner}
        errorText={errorBannerText}
      />
      <section aria-labelledby="payment-details-heading">
        <div id="subscription-settings">
          <div className="flex flex-wrap justify-center mt-4">
            <div className="md:w-3/5 w-full inline-block rounded-md p-4">
              <div>
                For the following changes, please contact{" "}
                <span className="font-medium text-blue-600">
                  support@decotolabs.com
                </span>
                <ul className="list-disc list-inside">
                  <li className="list-item">
                    Decrease the number of seats on your subscription
                  </li>
                  <li className="list-item">Requesting refunds</li>
                </ul>
              </div>
              <div className="mt-4 border rounded p-4">
                <div className="font-bold text-xl mb-2">Account</div>
                <div className="text-xl text-gray-600">
                  Subscription Summary
                </div>
                <div className="flex justify-between text-gray-600">
                  <div id="subscription-details">
                    You are subscribed to <b>{billing.quantity}</b> seat(s) on
                    the <b>{billing.productName}</b> plan.
                  </div>
                </div>
                <div>
                  <div className="text-gray-600" id="last-payment-summary">
                    Your last payment was{" "}
                    <b>{getFormattedAmount(billing.latestInvoiceAmountPaid)}</b>
                    .
                  </div>
                </div>
                <div>
                  {billing.cancelAt ? (
                    <div id="next-payment-summary" className="text-gray-600">
                      Your plan has been <b>cancelled</b>. Your access will end{" "}
                      <b>{getDate(billing.cancelAt)}</b>.
                    </div>
                  ) : (
                    <div id="next-payment-summary" className="text-gray-600">
                      Your next payment of{" "}
                      <b>
                        {getFormattedAmount(billing.upcomingInvoiceAmountDue)}
                      </b>{" "}
                      will be due{" "}
                      <b>
                        {billing.upcomingInvoiceNextPaymentAttempt
                          ? getDate(billing.upcomingInvoiceNextPaymentAttempt)
                          : "Never"}
                      </b>
                    </div>
                  )}
                </div>
                <div className="flex justify-between">
                  <div className="text-xl text-gray-600">Credit Card</div>
                  <span
                    id="credit-card-last-four"
                    className="font-bold text-xl text-gray-600"
                  >
                    {capitalizeEachWord(billing.cardBrand)}{" "}
                    &#xB7;&#xB7;&#xB7;&#xB7; {billing.cardLastDigits}
                  </span>
                </div>
                <div
                  className={classNames(
                    billing.cancelAt === null ? "" : "hidden",
                    "flex justify-between mt-2 mb-2 text-gray-900 font-bold text-xl cursor-pointer"
                  )}
                  onClick={() =>
                    setShowChangePricingPlan(!showChangePricingPlan)
                  }
                >
                  <span>
                    Add Seats <span>→</span>
                  </span>
                </div>
                <div
                  className={classNames(
                    billing.cancelAt === null ? "" : "hidden",
                    "flex justify-between mt-2 mb-2 text-gray-900 font-bold text-xl cursor-pointer"
                  )}
                  onClick={() =>
                    addBillingPortalSession()
                      .then((session) => window.location.assign(session.url))
                      .catch((error) => {
                        setErrorBannerText(formatErrorMessage(error));
                        setShowErrorBanner(true);
                      })
                  }
                >
                  <span>
                    Update Billing Information/Cancel Plan <span>→</span>
                  </span>
                </div>
              </div>

              <div
                id="prices-form"
                className="w-full md:mb-8"
                hidden={!showChangePricingPlan}
              >
                <div className="text-center text-pasha font-bold text-2xl mt-4 mb-6">
                  Add Seats
                  <Switch.Group as="div" className="items-center">
                    <Switch
                      checked={annualBilling}
                      onChange={(e) => {
                        setAnnualBilling(e);
                        setPriceID(getPriceID("full", e));
                      }}
                      className={classNames(
                        annualBilling ? "bg-blue-600" : "bg-gray-200",
                        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          annualBilling ? "translate-x-5" : "translate-x-0",
                          "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                        )}
                      />
                    </Switch>
                    <Switch.Label as="span" className="ml-3">
                      <span className="text-sm font-medium text-gray-900">
                        Annual billing{" "}
                      </span>
                      <span className="text-sm text-gray-500">
                        (Get 2 months free)
                      </span>
                    </Switch.Label>
                  </Switch.Group>
                </div>

                <div className="flex justify-between mb-8">
                  <div
                    id="full"
                    className="border-2 border-blue-600 mx-auto w-full rounded overflow-hidden rounded-md p-2"
                  >
                    <div className="px-2 py-2 text-center">
                      <div className="text-gray-500 text-xl mb-2 font-medium">
                        Regulator Full Plan
                      </div>
                      <p className="text-pasha text-2xl font-extrabold">
                        $
                        {annualBilling
                          ? priceInfo[1].amount
                          : priceInfo[0].amount}
                        .00
                      </p>
                      <div className="flex-wrap">
                        <div className="leading-5 text-gray-500 text-xs font-medium mt-1">
                          Billed {annualBilling ? " annually" : " monthly"}
                        </div>
                        <div className="leading-5 text-gray-500 text-xs font-medium mt-1">
                          $
                          {annualBilling
                            ? priceInfo[1].amount
                            : priceInfo[0].amount}{" "}
                          per seat
                        </div>
                        <div className="mb-2"></div>
                        <div className="flex justify-center mt-12">
                          <button
                            type="button"
                            onClick={() => {
                              setQuantity(
                                Math.max(quantity! - 1, billing?.quantity)
                              );
                            }}
                            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            -
                          </button>
                          <input
                            type="number"
                            className="shadow-sm mx-2 focus:ring-blue-500 focus:border-blue-500 block w-16 sm:text-sm border-gray-300 rounded-md"
                            id="quantity-input-starter"
                            min={billing.quantity}
                            value={quantity}
                            onChange={(e) => {
                              setQuantity(
                                Math.max(parseInt(e.currentTarget.value), 1)
                              );
                            }}
                          />
                          <button
                            type="button"
                            onClick={() => {
                              setQuantity(quantity! + 1);
                            }}
                            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            +
                          </button>
                        </div>
                      </div>

                      <div className="flex justify-center mt-6">
                        <button
                          id="select-starter"
                          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          type="button"
                          onClick={() => {
                            setPriceID(getPriceID("full", annualBilling));
                            setUpdated(true);
                          }}
                        >
                          <div
                            id="select-starter-button-text"
                            className="w-auto -mx-2 md:mx-0"
                          >
                            Update
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center" hidden={!updated}>
                  <button
                    onClick={() => {
                      updateSubscriptionPreview({
                        priceID: priceID!,
                        quantity: quantity!,
                      })
                        .then((preview) => setSubscriptionPreview(preview))
                        .catch((error) => {
                          setErrorBannerText(formatErrorMessage(error));
                          setShowErrorBanner(true);
                        })
                        .finally(() => setShowPreview(true));
                    }}
                    className="inline-flex items-center px-2.5 py-1.5 mb-4 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Preview Subscription Change
                  </button>
                </div>
                <div
                  className={classNames(
                    showPreview && !subscriptionPreview ? "" : "hidden"
                  )}
                >
                  <div className="m-auto text-center animate-pulse">
                    <span className="font-semibold text-2xl text-blue-500">
                      Loa
                    </span>
                    <span className="font-semibold text-2xl text-blue-600">
                      din
                    </span>
                    <span className="font-semibold text-2xl text-blue-700">
                      g...
                    </span>
                  </div>
                </div>
                <div
                  id="price-change-form"
                  className={classNames(
                    !showPreview || !subscriptionPreview ? "hidden" : "",
                    "flex justify-center"
                  )}
                >
                  <div className="w-full rounded overflow-hidden border rounded-md p-4 mb-4">
                    <div className="flex justify-between text-gray-600 text-m">
                      <div id="change-summary">
                        <span id="quantity-change">
                          You added{" "}
                          <b>
                            {subscriptionPreview
                              ? subscriptionPreview.quantity - billing.quantity
                              : ""}
                          </b>{" "}
                          seats. Your subscription is now for{" "}
                          <b>{subscriptionPreview?.quantity}</b> seats on{" "}
                          <b>{getProductName(subscriptionPreview?.priceID)}.</b>
                        </span>
                        <br />
                        <span id="immediate-total">
                          You will be charged{" "}
                          <b>
                            {subscriptionPreview
                              ? getFormattedAmount(
                                  subscriptionPreview.immediateTotal
                                )
                              : ""}
                          </b>{" "}
                          for the remaining pay period.
                        </span>
                        <br />
                        <span id="next-payment">
                          Your next payment will be{" "}
                          <b>
                            {subscriptionPreview
                              ? getFormattedAmount(
                                  subscriptionPreview.upcomingInvoiceAmountDue
                                )
                              : ""}
                          </b>{" "}
                          due{" "}
                          <b>
                            {subscriptionPreview
                              ? getDate(
                                  subscriptionPreview.upcomingInvoiceNextPaymentAttempt
                                )
                              : ""}
                            .
                          </b>
                        </span>
                      </div>
                    </div>
                    <div>
                      <button
                        id="confirm-price-change-submit"
                        onClick={() =>
                          updateSubscription({
                            priceID: priceID!,
                            quantity: quantity!,
                          })
                            .then((billing) => {
                              setPriceID(billing.priceID);
                              const priceInfoIdx = priceInfo.findIndex(
                                (info) => info.priceID === billing.priceID
                              );
                              const billingFrequency =
                                priceInfo[priceInfoIdx].frequency;
                              setAnnualBilling(billingFrequency === "annually");
                              setQuantity(billing.quantity);
                              setSubscriptionPreview(undefined);
                              setShowPreview(false);
                              setUpdated(false);
                              setShowChangePricingPlan(false);
                            })
                            .catch((error) => {
                              setErrorBannerText(formatErrorMessage(error));
                              setShowErrorBanner(true);
                            })
                        }
                        className="bg-blue-600 hover:bg-white hover:shadow-outline hover:text-blue-600 hover:border hover:border-blue-600 focus:shadow-outline text-white focus:bg-white focus:text-pasha font-light py-2 px-4 rounded-lg"
                        type="submit"
                      >
                        <div className="w-auto -mx-2 md:mx-0">
                          <div id="loading" className=""></div>
                          <span id="button-text">Confirm change</span>
                        </div>
                      </button>
                      <button
                        id="confirm-price-change-cancel"
                        onClick={() => {
                          resetBillingCard();
                        }}
                        className="ml-1 bg-blue-600 hover:bg-white hover:shadow-outline hover:text-blue-600 hover:border hover:border-blue-600 focus:shadow-outline text-white focus:bg-white focus:text-pasha font-light py-2 px-4 rounded-lg"
                        type="submit"
                      >
                        <div className="w-auto -mx-2 md:mx-0">
                          <div id="loading" className=""></div>
                          <span id="button-text">Cancel</span>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
