import { UserAddIcon } from "@heroicons/react/solid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Tabs from "../common/Tabs";
import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import * as React from "react";
import { useUserContext } from "../common/BasePageScreen";
import PersonnelList from "./PersonnelList";
import RoleList from "./RoleList";

const personnelPages = [{ name: "Personnel", path: "/regulator/personnel" }];
const rolePages = [
  { name: "Personnel", path: "/regulator/personnel" },
  { name: "Roles", path: "/regulator/personnel/roles" },
];

const personnelTabs = [
  { name: "Personnel", href: "/regulator/personnel" },
  { name: "Roles", href: "/regulator/personnel/roles" },
];

export default function PersonnelOrRoleList(): JSX.Element {
  const { personnelRead, personnelWrite } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();

  if (!personnelRead) {
    navigate("/forbidden");
    return <></>;
  }

  const isPersonnelPath = location.pathname === "/regulator/personnel";

  const breadcrumbBar = isPersonnelPath ? (
    <FullWidthBreadcrumbBar pages={personnelPages} />
  ) : (
    <FullWidthBreadcrumbBar pages={rolePages} />
  );

  const addButton = isPersonnelPath
    ? { text: "Add Personnel", href: "/regulator/personnel/add" }
    : { text: "Add Role", href: "/regulator/personnel/roles/add" };

  const component = isPersonnelPath ? <PersonnelList /> : <RoleList />;

  return (
    <div className="flex flex-col">
      {breadcrumbBar}
      <div className="py-3 text-right">
        <Link to={addButton.href} hidden={!personnelWrite}>
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <UserAddIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
            {addButton.text}
          </button>
        </Link>
      </div>
      <Tabs tabs={personnelTabs} />
      {component}
    </div>
  );
}
