import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";
import CustomSelectMenu from "../common/CustomSelectMenu";
import {
  classNames,
  formatDateInputValue,
  formatErrorMessage,
  getDate,
  getDefaultValueFromDateString,
} from "../common/Utils";
import { updateSample, useSample } from "../common/DataFetchers";
import { Status } from "../common/Entities.d";
import { useUserContext } from "../common/BasePageScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import ErrorBanner from "../common/ErrorBanner";

const statusOptions = [
  { id: "1", value: "Queued", display: "Queued" },
  { id: "2", value: "Pass", display: "Pass" },
  { id: "3", value: "Fail", display: "Fail" },
];

export default function EditSampleForm(): JSX.Element {
  const { samplesRead, samplesWrite } = useUserContext();
  const navigate = useNavigate();

  const params = useParams<{ id: string }>();
  const sampleID = params.id as string;

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const { sample, sampleIsLoading, sampleIsError, mutateSample } = useSample(
    sampleID,
    samplesWrite
  );

  const initialStatusIndex = sample
    ? statusOptions.findIndex((status) => status.value === sample.status)
    : 0;
  const [status, setStatus] = useState(statusOptions[initialStatusIndex]);

  if (!samplesWrite || !samplesRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (sampleIsLoading) {
    return <LoadingComponent />;
  }

  if (sampleIsError || !sample) {
    return <ErrorComponent />;
  }

  return (
    <form
      className="py-3 px-3 space-y-8 divide-y divide-gray-200"
      onSubmit={(e: React.SyntheticEvent) => {
        e.preventDefault();

        setShowErrorBanner(false);

        const target = e.target as typeof e.target & {
          reportIssued: { value: string };
        };

        const newReportIssued = target.reportIssued.value;

        if (samplesWrite) {
          updateSample(
            sampleID,
            status.value as Status,
            newReportIssued
              ? new Date(formatDateInputValue(newReportIssued))
              : undefined
          )
            .then((sample) => {
              mutateSample(sample, false);
              navigate(`/regulator/samples/${sampleID}`);
            })
            .catch((error) => {
              setErrorBannerText(formatErrorMessage(error));
              setShowErrorBanner(true);
            });
        }
      }}
    >
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <ErrorBanner
            showError={showErrorBanner}
            setShowError={setShowErrorBanner}
            errorText={errorBannerText}
          />
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Edit Sample
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Fill in required details to edit existing sample.
            </p>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Sample ID
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <p className="mt-2 font-medium text-sm text-gray-900">
                  {sample.displayID}
                </p>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Sample Type
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <p className="mt-2 font-medium text-sm text-gray-900">
                  {sample.type.name}
                </p>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Preservation Method
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <p className="mt-2 font-medium text-sm text-gray-900">
                  {sample.preservationMethod}
                </p>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <CustomSelectMenu
                label="Status"
                options={statusOptions}
                selected={status}
                setSelected={setStatus}
              />
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Date Received
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <p className="mt-2 font-medium text-sm text-gray-900">
                  {getDate(sample.received)}
                </p>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Report Issued
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="date"
                  name="reportIssued"
                  id="reportIssued"
                  className={classNames(
                    "max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md",
                    "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                  )}
                  defaultValue={
                    sample.reportIssued
                      ? getDefaultValueFromDateString(sample.reportIssued)
                      : undefined
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <Link to={`/regulator/samples/${sample.id}`}>
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
