import FileUploadComponent from "../common/FileUploadComponent";
import React, { useState } from "react";
import { addSamples, useSampleTypeSummaries } from "../common/DataFetchers";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { useUserContext } from "../common/BasePageScreen";
import { SampleTypeSummary } from "../common/Entities.d";
import { DownloadIcon } from "@heroicons/react/solid";
import ErrorBanner from "../common/ErrorBanner";
import { formatErrorMessage } from "../common/Utils";

function getSampleTypeIDFromName(
  sampleTypes: SampleTypeSummary[],
  name: string
) {
  const typeIndex = sampleTypes.findIndex((type) => type.name === name);
  return sampleTypes[typeIndex].id;
}

export default function BulkAddSampleForm(): JSX.Element {
  const { samplesRead, samplesWrite } = useUserContext();
  const { sampleTypeSummaries } = useSampleTypeSummaries(samplesWrite);

  const navigate = useNavigate();
  const [files] = useState([] as File[]);
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const [uploadError, setUploadError] = useState(false);

  if (!samplesWrite || !samplesRead) {
    navigate("/forbidden");
    return <></>;
  }

  return (
    <div>
      <ErrorBanner
        showError={showError}
        setShowError={setShowError}
        errorText={errorText}
      />
      <form
        className="py-3 px-3 space-y-8 divide-y divide-gray-200"
        onSubmit={(e: React.SyntheticEvent) => {
          e.preventDefault();
          if (files.length === 0 || files.length > 1) {
            setUploadError(true);
          } else {
            if (samplesWrite) {
              addSamplesFromFiles(
                files[0],
                sampleTypeSummaries,
                navigate,
                setShowError,
                setErrorText
              );
            }
            //navigate("/samples");
            return;
          }
        }}
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Sample Information
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Fill in required details to create a new sample.
              </p>
              <p className="mt-3 max-w-2xl text-sm font-medium text-gray-900">
                Note: There must be a valid{" "}
                <Link to="/samples/types">
                  <span className="text-blue-600">sample type </span>
                </Link>
                with the name provided in the spreadsheet to be successful.
              </p>
            </div>
            <div>
              <ul className="bg-white border border-gray-200 rounded-md divide-y divide-gray-200">
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div className="w-0 flex-1 flex items-center">
                    <DownloadIcon
                      className="flex-shrink-0 h-5 w-5 text-blue-400"
                      aria-hidden="true"
                    />
                    <span className="ml-2 flex-1 w-0 truncate">
                      Sample Bulk Upload Worksheet
                    </span>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <a
                      download={"SampleBulkUploadWorksheet.xlsx"}
                      href={`${process.env.PUBLIC_URL}/SampleBulkUploadWorksheet.xlsx`}
                      className="font-medium text-blue-600 hover:text-blue-500"
                    >
                      Download
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div className="space-y-6 sm:space-y-5">
              <FileUploadComponent
                label={"Bulk Upload Samples"}
                files={files}
                fileTypes={"XLSX, TSV, CSV"}
              />
              <p
                className="-ml-24 text-center text-sm text-red-500"
                hidden={!uploadError}
              >
                * Bulk sample upload must be exactly one file.
              </p>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <Link to="/regulator/samples">
              <button
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Cancel
              </button>
            </Link>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

function addSamplesFromFiles(
  file: File,
  sampleTypeSummaries: SampleTypeSummary[],
  navigate: NavigateFunction,
  setShowError: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorText: React.Dispatch<React.SetStateAction<string>>
) {
  const samples = [] as {
    typeID: string;
    displayID: string;
    received: Date;
    preservationMethod: string;
  }[];

  const reader = new FileReader();

  reader.onload = (e) => {
    if (!e.target) {
      return;
    }

    const workbook = XLSX.read(e.target.result, {
      type: "buffer",
    });

    workbook.SheetNames.forEach((sheetName) => {
      const sheetJSONObjects = XLSX.utils.sheet_to_json(
        workbook.Sheets[sheetName],
        { raw: false }
      );
      sheetJSONObjects.forEach((jsonObject) => {
        const inputSample = jsonObject as {
          type: string;
          sampleID: string;
          preservationMethod: string;
          dateReceived: string;
        };

        inputSample.sampleID = inputSample.sampleID.trim();
        if (inputSample.sampleID === "") {
          setErrorText("Error: sample ID cannot be empty");
          setShowError(true);
          return;
        }

        inputSample.preservationMethod = inputSample.preservationMethod.trim();
        if (inputSample.preservationMethod === "") {
          setErrorText("Error: preservation method cannot be empty");
          setShowError(true);
          return;
        }

        try {
          const formattedSample = {
            typeID: getSampleTypeIDFromName(
              sampleTypeSummaries,
              inputSample.type
            ),
            displayID: inputSample.sampleID,
            received: new Date(inputSample.dateReceived),
            preservationMethod: inputSample.preservationMethod,
          };

          samples.push(formattedSample);
        } catch (e) {
          const newErrorText = `Error: ${e}`;
          setErrorText(newErrorText);
          setShowError(true);
        }
      });
    });

    if (samples.length === 0) {
      return;
    }

    addSamples(samples)
      .then(() => navigate("/regulator/samples"))
      .catch((error) => {
        const newErrorText = formatErrorMessage(error);
        setErrorText(newErrorText);
        setShowError(true);
      });
  };

  reader.onerror = (error) => console.log(error);
  reader.readAsArrayBuffer(file);
}
