import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { classNames } from "./Utils";
import { Link } from "react-router-dom";
import Pagination from "./Pagination";

interface TableWithCheckboxProps {
  header: string;
  options: { id: string; display: string }[];
  linkPrefix: string;
  selected: { id: string; display: string }[];
  setSelected: (selected: { id: string; display: string }[]) => void;
}

export default function TableWithCheckbox(props: TableWithCheckboxProps) {
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [items, setItems] = useState(props.options);
  useEffect(() => {
    if (!props.options) {
      return;
    }
    setItems(props.options);
  }, [props.options]);

  useLayoutEffect(() => {
    const isIndeterminate =
      props.selected.length > 0 &&
      props.selected.length < props.options.length &&
      props.options.length !== 0;
    setChecked(
      props.selected.length === props.options.length &&
        props.selected.length !== 0 &&
        props.options.length !== 0
    );
    setIndeterminate(isIndeterminate);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    checkbox.current.indeterminate = isIndeterminate;
  }, [props.selected, props.options]);

  function toggleAll() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    props.setSelected(checked || indeterminate ? [] : props.options);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  const pageEndIndex = currentPage * itemsPerPage;
  const pageStartIndex = pageEndIndex - itemsPerPage;
  const options = items.slice(pageStartIndex, pageEndIndex);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <div>
      <div className="flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full table-fixed divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="relative w-12 px-6 sm:w-16 sm:px-8"
                    >
                      <input
                        type="checkbox"
                        className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 sm:left-6"
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        ref={checkbox}
                        checked={checked}
                        onChange={toggleAll}
                      />
                    </th>
                    <th
                      scope="col"
                      className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                    >
                      {props.header}
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">View</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {options.map((option) => (
                    <tr
                      key={option.id}
                      className={
                        props.selected.includes(option)
                          ? "bg-gray-50"
                          : undefined
                      }
                    >
                      <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                        {props.selected.includes(option) && (
                          <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />
                        )}
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 sm:left-6"
                          value={option.id}
                          checked={
                            props.selected.findIndex(
                              (select) => select.id === option.id
                            ) >= 0
                          }
                          onChange={(e) =>
                            props.setSelected(
                              e.target.checked
                                ? [...props.selected, option]
                                : props.selected.filter((p) => p !== option)
                            )
                          }
                        />
                      </td>
                      <td
                        className={classNames(
                          "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                          props.selected.includes(option)
                            ? "text-blue-600"
                            : "text-gray-900"
                        )}
                      >
                        {option.display}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <Link
                          to={`${props.linkPrefix}/${option.id}`}
                          target="_blank"
                          rel="noreferrer"
                          className="text-blue-600 hover:text-blue-900"
                        >
                          View
                          <span className="sr-only">, {option.display}</span>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={items ? items.length : props.options.length}
                currentPage={currentPage}
                paginate={paginate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
