import { ClipboardCheckIcon, DocumentTextIcon } from "@heroicons/react/outline";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PencilAltIcon } from "@heroicons/react/solid";
import { classNames, getDate } from "../common/Utils";
import { sampleAuditPath, useSample } from "../common/DataFetchers";
import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import * as React from "react";
import { useUserContext } from "../common/BasePageScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import AuditTrailCard from "../common/AuditTrailCard";

const fieldDisplayMap = new Map([
  ["status", "Status"],
  ["reportIssued", "Report Issued"],
]);

export default function SampleDetailsCard(): JSX.Element {
  const { samplesRead, samplesWrite } = useUserContext();
  const navigate = useNavigate();

  const params = useParams<{ id: string }>();
  const sampleID = params.id as string;

  const { sample, sampleIsLoading, sampleIsError } = useSample(
    sampleID,
    samplesRead
  );

  if (!samplesRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (sampleIsLoading) {
    return <LoadingComponent />;
  }

  if (sampleIsError || !sample) {
    return <ErrorComponent />;
  }

  const pages = [
    { name: "Samples", path: "/regulator/samples" },
    { name: sample.displayID, path: `/regulator/samples/${sample.id}` },
  ];

  return (
    <div>
      <FullWidthBreadcrumbBar pages={pages} />
      <div className="py-3 pt-5">
        <div className="flex justify-end">
          <Link
            to={`/regulator/samples/${sample.id}/edit`}
            hidden={!samplesWrite}
          >
            <button
              type="button"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <PencilAltIcon
                className="-ml-1 mr-3 h-5 w-5"
                aria-hidden="true"
              />
              Edit
            </button>
          </Link>
        </div>
      </div>
      <div className="bg-white mt-3 shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Sample Details
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Details regarding sample and associated runs
          </p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Sample ID</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {sample.displayID}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Sample Type</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <Link
                  to={`/samples/types/${sample.type.id}`}
                  className="font-medium text-blue-600"
                >
                  {sample.type.name}
                </Link>
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Preservation Method
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {sample.preservationMethod}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Status</dt>
              <dd
                className={
                  sample.status === "Pass"
                    ? "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 w-min"
                    : sample.status === "Fail"
                    ? "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 w-min"
                    : "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800 w-min"
                }
              >
                {sample.status}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Date Received
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {getDate(sample.received)}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Report Issued
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {sample.reportIssued ? getDate(sample.reportIssued) : "N/A"}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Associated Runs
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {sample.runs.length === 0 ? (
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm italic">
                      None
                    </li>
                  </ul>
                ) : (
                  <div>
                    {sample.runs.map((run) => (
                      <ul
                        key={run.id}
                        className="border border-gray-200 rounded-md divide-y divide-gray-200"
                      >
                        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                          <div className="w-0 flex-1 flex items-center">
                            <ClipboardCheckIcon
                              className="flex-shrink-0 h-5 w-5 text-blue-400"
                              aria-hidden="true"
                            />
                            <span className="ml-2 flex-1 w-0 truncate">
                              {run.displayID}
                            </span>
                            <span
                              className={classNames(
                                run.status === "Queued"
                                  ? "bg-gray-100 text-gray-800"
                                  : run.status === "Fail"
                                  ? "bg-red-100 text-red-800"
                                  : "bg-green-100 text-green-800",
                                "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium"
                              )}
                            >
                              {run.status}
                            </span>
                          </div>
                          <div className="ml-4 flex-shrink-0">
                            <Link
                              to={`/regulator/workflows/history/${run.id}`}
                              className="font-medium text-blue-600 hover:text-blue-500"
                            >
                              View
                            </Link>
                          </div>
                        </li>
                      </ul>
                    ))}
                  </div>
                )}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Records</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {sample.records.length === 0 ? (
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm italic">
                      None
                    </li>
                  </ul>
                ) : (
                  <div>
                    {sample.records.map((record) => (
                      <ul
                        key={record.id}
                        className="border border-gray-200 rounded-md divide-y divide-gray-200"
                      >
                        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                          <div className="w-0 flex-1 flex items-center">
                            <DocumentTextIcon
                              className="flex-shrink-0 h-5 w-5 text-blue-400"
                              aria-hidden="true"
                            />
                            <span className="ml-2 flex-1 w-0 truncate">
                              {`${record.form.title} - ${getDate(
                                record.completed
                              )}`}
                            </span>
                          </div>
                          <div className="ml-4 flex-shrink-0">
                            <Link
                              to={`/regulator/records/${record.id}`}
                              className="font-medium text-blue-600 hover:text-blue-500"
                            >
                              View
                            </Link>
                          </div>
                        </li>
                      </ul>
                    ))}
                  </div>
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <AuditTrailCard
        fieldDisplayMap={fieldDisplayMap}
        type={`${sample.displayID}`}
        fetchPath={sampleAuditPath(sampleID)}
      />
    </div>
  );
}
