import { PencilAltIcon } from "@heroicons/react/solid";
import { Link, useNavigate, useParams } from "react-router-dom";
import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import { reagentTypeAuditPath, useReagentType } from "../common/DataFetchers";
import React from "react";
import { useUserContext } from "../common/BasePageScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import AuditTrailCard from "../common/AuditTrailCard";

const fieldDisplayMap = new Map([
  ["name", "Reagent Type Name"],
  ["manufacturer", "Manufacturer"],
  ["partNumber", "Part Number"],
]);

export default function ReagentTypesDetailsCard(): JSX.Element {
  const { reagentsRead, reagentsWrite } = useUserContext();
  const navigate = useNavigate();

  const params = useParams<{ id: string }>();
  const reagentTypeId = params.id as string;

  const { reagentType, reagentTypeIsLoading, reagentTypeIsError } =
    useReagentType(reagentTypeId, reagentsRead);

  if (!reagentsRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (reagentTypeIsLoading) {
    return <LoadingComponent />;
  }

  if (reagentTypeIsError || !reagentType) {
    return <ErrorComponent />;
  }

  const pages = [
    { name: "Reagent Types", path: "/regulator/reagents/types" },
    {
      name: reagentType.name,
      path: `/regulator/reagents/types/${reagentType.id}`,
    },
  ];

  return (
    <div>
      <FullWidthBreadcrumbBar pages={pages} />
      <div className="py-3 pt-5">
        <div className="flex justify-end">
          <Link
            to={`/regulator/reagents/types/${reagentType.id}/edit`}
            hidden={!reagentsWrite}
          >
            <button
              type="button"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <PencilAltIcon
                className="-ml-1 mr-3 h-5 w-5"
                aria-hidden="true"
              />
              Edit
            </button>
          </Link>
        </div>
      </div>
      <div className="bg-white mt-3 shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Reagent Type Details
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Details regarding reagent type
          </p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Reagent Type
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {reagentType.name}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Manufacturer
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {reagentType.manufacturer}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Part Number</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {reagentType.partNumber}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <AuditTrailCard
        fieldDisplayMap={fieldDisplayMap}
        type={"reagent type"}
        fetchPath={reagentTypeAuditPath(reagentTypeId)}
      />
    </div>
  );
}
