import { Link, useNavigate } from "react-router-dom";
import FileUploadComponent from "../common/FileUploadComponent";
import React, { useState } from "react";
import CustomSelectMenu from "../common/CustomSelectMenu";
import {
  classNames,
  formatDateInputValue,
  formatErrorMessage,
} from "../common/Utils";
import { addReagent, useReagentTypeSummaries } from "../common/DataFetchers";
import { ReagentStatus } from "../common/Entities.d";
import { useUserContext } from "../common/BasePageScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import ErrorBanner from "../common/ErrorBanner";

const statusOptions = [
  { id: "1", value: "Quarantined", display: "Quarantined" },
  { id: "2", value: "In Use", display: "In Use" },
  { id: "3", value: "Archived", display: "Archived" },
];

export default function AddReagentForm(): JSX.Element {
  const { reagentsRead, reagentsWrite } = useUserContext();
  const navigate = useNavigate();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const {
    reagentTypeSummaries,
    reagentTypeSummariesIsLoading,
    reagentTypeSummariesIsError,
  } = useReagentTypeSummaries(reagentsRead);

  const [status, setStatus] = useState(statusOptions[0]);
  const [displayIDError, setDisplayIDError] = useState(false);
  const [reagentTypeError, setReagentTypeError] = useState(false);
  const [dateReceivedError, setDateReceivedError] = useState(false);
  const [expDateError, setExpDateError] = useState(false);
  const [lotError, setLotError] = useState(false);

  const typeOptions: { id: string; value: any; display: string }[] = [];
  typeOptions.push({ id: "0", value: "Select", display: "Select" });
  const [reagentType, setReagentType] = useState(typeOptions[0]);
  reagentTypeSummaries.forEach((reagentType) =>
    typeOptions.push({
      id: reagentType.id,
      value: reagentType,
      display: reagentType.name,
    })
  );

  const [attachments] = useState<File[]>([]);

  if (!reagentsWrite || !reagentsRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (reagentTypeSummariesIsLoading) {
    return <LoadingComponent />;
  }

  if (reagentTypeSummariesIsError) {
    return <ErrorComponent />;
  }

  return (
    <form
      className="py-3 px-3 space-y-8 divide-y divide-gray-200"
      onSubmit={(e: React.SyntheticEvent) => {
        e.preventDefault();

        setShowErrorBanner(false);

        const target = e.target as typeof e.target & {
          reagentDisplayID: { value: string };
          dateReceived: { value: string };
          expirationDate: { value: string };
          lot: { value: string };
          aliquot: { value: string };
        };

        let isValid = true;

        setDisplayIDError(false);
        target.reagentDisplayID.value = target.reagentDisplayID.value.trim();
        if (target.reagentDisplayID.value === "") {
          setDisplayIDError(true);
          isValid = false;
        }

        setReagentTypeError(false);
        if (reagentType.value === "Select") {
          setReagentTypeError(true);
          isValid = false;
        }

        setDateReceivedError(false);
        target.dateReceived.value = target.dateReceived.value.trim();
        if (target.dateReceived.value === "") {
          setDateReceivedError(true);
          isValid = false;
        }

        setExpDateError(false);
        target.expirationDate.value = target.expirationDate.value.trim();
        if (target.expirationDate.value === "") {
          setExpDateError(true);
          isValid = false;
        }

        setLotError(false);
        target.lot.value = target.lot.value.trim();
        if (target.lot.value === "") {
          setLotError(true);
          isValid = false;
        }

        target.aliquot.value = target.aliquot.value.trim();

        if (!isValid) {
          return;
        }

        if (reagentsWrite) {
          addReagent(
            reagentType.id,
            target.reagentDisplayID.value,
            status.value as ReagentStatus,
            target.lot.value,
            target.aliquot.value,
            new Date(formatDateInputValue(target.dateReceived.value)),
            new Date(formatDateInputValue(target.expirationDate.value)),
            attachments
          )
            .then((reagent) => navigate(`/regulator/reagents/${reagent.id}`))
            .catch((error) => {
              setErrorBannerText(formatErrorMessage(error));
              setShowErrorBanner(true);
            });
        }
      }}
    >
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <ErrorBanner
            showError={showErrorBanner}
            setShowError={setShowErrorBanner}
            errorText={errorBannerText}
          />
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Reagent Information
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Fill in required details to create a new reagent.
            </p>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="reagentDisplayID"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Reagent ID
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="reagentDisplayID"
                  id="reagentDisplayID"
                  className={classNames(
                    "max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md",
                    displayIDError
                      ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                      : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                  )}
                />
                <p
                  className="mt-2 text-sm text-red-500"
                  hidden={!displayIDError}
                >
                  * Reagent ID is required
                </p>
              </div>
            </div>
            {reagentTypeSummaries.length === 0 ? (
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="equipmentDisplayID"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Reagent Type
                  <span hidden={reagentTypeSummaries.length !== 0}>
                    <ExclamationCircleIcon className="ml-1 text-red-500 inline-flex h-5 w-5" />
                  </span>
                </label>
                <p className="mt-2 text-sm text-gray-500">
                  Please{" "}
                  <Link
                    to="/regulator/reagents/types/add"
                    className="font-medium text-blue-500"
                    target="_blank"
                    rel="noreferrer"
                  >
                    create
                  </Link>{" "}
                  reagent types before creating reagents.
                </p>
              </div>
            ) : (
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <CustomSelectMenu
                  label={"Reagent Type"}
                  options={typeOptions}
                  selected={reagentType}
                  setSelected={setReagentType}
                />
                <p
                  className="-mt-2 text-sm text-red-500 col-start-2"
                  hidden={!reagentTypeError}
                >
                  * Reagent Type is required
                </p>
              </div>
            )}
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="dateReceived"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Date Received
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="date"
                  name="dateReceived"
                  id="dateReceived"
                  className={classNames(
                    "max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md",
                    dateReceivedError
                      ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                      : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                  )}
                />
                <p
                  className="mt-2 text-sm text-red-500"
                  hidden={!dateReceivedError}
                >
                  * Date Received is required
                </p>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="expirationDate"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Expiration Date
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="date"
                  name="expirationDate"
                  id="expirationDate"
                  className={classNames(
                    "max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md",
                    expDateError
                      ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                      : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                  )}
                />
                <p className="mt-2 text-sm text-red-500" hidden={!expDateError}>
                  * Expiration Date is required
                </p>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="lot"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Lot
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="lot"
                  id="lot"
                  className={classNames(
                    "max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md",
                    lotError
                      ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                      : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                  )}
                />
                <p className="mt-2 text-sm text-red-500" hidden={!lotError}>
                  * Lot is required
                </p>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="aliquot"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Aliquot Number
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="aliquot"
                  id="aliquot"
                  className={classNames(
                    "max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                  )}
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <CustomSelectMenu
                label="Status"
                options={statusOptions}
                selected={status}
                setSelected={setStatus}
              />
            </div>
            <FileUploadComponent
              label={"Attachments"}
              files={attachments}
              fileTypes={"PDF, PNG, JPG"}
            />
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <Link to="/regulator/reagents">
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
