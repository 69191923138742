import { TemplateIcon } from "@heroicons/react/solid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Tabs from "../common/Tabs";
import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import * as React from "react";
import { useUserContext } from "../common/BasePageScreen";
import ActiveWorkflowTemplatesList from "./ActiveWorkflowTemplatesList";
import ArchivedWorkflowTemplatesList from "./ArchivedWorkflowTemplatesList";

const activePages = [
  { name: "Workflow Templates", path: "/regulator/workflows/templates" },
];
const archivedPages = [
  { name: "Workflow Templates", path: "/regulator/workflows/templates" },
  { name: "Archived", path: "/regulator/workflows/templates/archived" },
];

const workflowTemplateTabs = [
  { name: "Active", href: "/regulator/workflows/templates" },
  { name: "Archived", href: "/regulator/workflows/templates/archived" },
];

export default function WorkflowTemplatesList(): JSX.Element {
  const { workflowTemplatesRead, workflowTemplatesWrite } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();

  if (!workflowTemplatesRead) {
    navigate("/forbidden");
    return <></>;
  }

  const isActivePath = location.pathname === "/regulator/workflows/templates";

  const breadcrumbBar = isActivePath ? (
    <FullWidthBreadcrumbBar pages={activePages} />
  ) : (
    <FullWidthBreadcrumbBar pages={archivedPages} />
  );

  const component = isActivePath ? (
    <ActiveWorkflowTemplatesList />
  ) : (
    <ArchivedWorkflowTemplatesList />
  );

  return (
    <div className="flex flex-col">
      {breadcrumbBar}
      <div className="py-3 text-right">
        <Link
          to={"/regulator/workflows/templates/add"}
          hidden={!workflowTemplatesWrite}
        >
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <TemplateIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
            Add WorkflowTemplate
          </button>
        </Link>
      </div>
      <Tabs tabs={workflowTemplateTabs} />
      {component}
    </div>
  );
}
