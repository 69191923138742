import { FormElementText } from "../../common/Entities.d";

export default function Paragraph(props: { formText: FormElementText }) {
  return (
    <div className="pb-1">
      <p className="-mt-4 max-w-4xl text-sm text-gray-500">
        {props.formText.text}
      </p>
    </div>
  );
}
