import {
  ClipboardCheckIcon,
  DocumentTextIcon,
  PaperClipIcon,
} from "@heroicons/react/outline";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ArchiveIcon, PencilAltIcon } from "@heroicons/react/solid";
import { classNames, getDate } from "../common/Utils";
import {
  formatURL,
  reagentAuditPath,
  useReagent,
} from "../common/DataFetchers";
import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import React from "react";
import { useUserContext } from "../common/BasePageScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import AuditTrailCard from "../common/AuditTrailCard";

const fieldDisplayMap = new Map([
  ["status", "Status"],
  ["qcStatus", "QC Status"],
]);

export default function ReagentDetailsCard(): JSX.Element {
  const { reagentsRead, reagentsWrite } = useUserContext();
  const navigate = useNavigate();

  const params = useParams<{ id: string }>();
  const reagentID = params.id as string;

  const { reagent, reagentIsLoading, reagentIsError } = useReagent(
    reagentID,
    reagentsRead
  );

  if (!reagentsRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (reagentIsLoading) {
    return <LoadingComponent />;
  }

  if (reagentIsError || !reagent) {
    return <ErrorComponent />;
  }

  const pages = [
    { name: "Reagents", path: "/regulator/reagents" },
    { name: reagent.displayID, path: `/regulator/reagents/${reagent.id}` },
  ];

  return (
    <div>
      <FullWidthBreadcrumbBar pages={pages} />
      <div className="py-3 px-5 pt-5">
        <div className="flex justify-end">
          <Link
            to={`/regulator/reagents/${reagentID}/edit`}
            hidden={!reagentsWrite}
          >
            <button
              type="button"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <PencilAltIcon
                className="-ml-1 mr-3 h-5 w-5"
                aria-hidden="true"
              />
              Edit
            </button>
          </Link>
        </div>
      </div>
      <div className="bg-white mt-3 shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Reagent Details
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Details regarding reagent and associated runs
          </p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Reagent ID</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {reagent.displayID}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Reagent Type
              </dt>
              <Link to={`/regulator/reagents/types/${reagent.type.id}`}>
                <dd className="mt-1 text-sm font-medium text-blue-600 sm:mt-0 sm:col-span-2">
                  {reagent.type.name}
                </dd>
              </Link>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Status</dt>
              <dd
                className={
                  reagent.status === "In Use"
                    ? "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 w-14"
                    : reagent.status === "Quarantined"
                    ? "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800 w-min"
                    : "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800 w-min"
                }
              >
                {reagent.status}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Lot</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {reagent.lot}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Aliquot Number
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {reagent.aliquot.trim() === "" ? "None" : `${reagent.aliquot}`}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Date Received
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {getDate(reagent.received)}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Expiration Date
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {getDate(reagent.expiration)}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">QC status</dt>
              <dd
                className={
                  reagent.qcStatus === "Pass"
                    ? "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 w-min"
                    : reagent.qcStatus === "Fail"
                    ? "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 w-min"
                    : "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800 w-min"
                }
              >
                {reagent.qcStatus}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Attachments</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <p className="ml-1 text-sm">Active</p>
                {reagent.attachments.filter((a) => !a.archived).length === 0 ? (
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm italic">
                      None
                    </li>
                  </ul>
                ) : (
                  <div>
                    {reagent.attachments
                      .filter((a) => !a.archived)
                      .map((attachment) => (
                        <ul
                          key={attachment.id}
                          className="border border-gray-200 rounded-md divide-y divide-gray-200"
                        >
                          <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                            <div className="w-0 flex-1 flex items-center">
                              <PaperClipIcon
                                className="flex-shrink-0 h-5 w-5 text-blue-400"
                                aria-hidden="true"
                              />
                              <span className="ml-2 flex-1 w-0 truncate">
                                {attachment.fileName}
                              </span>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                              <a
                                download={attachment.fileName}
                                href={formatURL(
                                  `/reagents/attachments/${attachment.id}/download`
                                )}
                                className="font-medium text-blue-600 hover:text-blue-500"
                              >
                                Download
                              </a>
                            </div>
                          </li>
                        </ul>
                      ))}
                  </div>
                )}
              </dd>
              <dd className="mt-1 text-sm text-gray-900 mt-0 col-span-2 col-end-4">
                <p className="ml-1 text-sm">Archived</p>
                {reagent.attachments.filter((a) => a.archived).length === 0 ? (
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm italic">
                      None
                    </li>
                  </ul>
                ) : (
                  <div>
                    {reagent.attachments
                      .filter((a) => a.archived)
                      .map((attachment) => (
                        <ul
                          key={attachment.id}
                          className="border border-gray-200 rounded-md divide-y divide-gray-200"
                        >
                          <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                            <div className="w-0 flex-1 flex items-center">
                              <ArchiveIcon
                                className="flex-shrink-0 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                              <span className="ml-2 flex-1 w-0 truncate text-gray-500 italic">
                                {attachment.fileName}
                              </span>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                              <a
                                download={attachment.fileName}
                                href={formatURL(
                                  `/reagents/attachments/${attachment.id}/download`
                                )}
                                className="font-medium text-blue-600 hover:text-blue-500"
                              >
                                Download
                              </a>
                            </div>
                          </li>
                        </ul>
                      ))}
                  </div>
                )}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">QC Runs</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {reagent.qcRuns.length === 0 ? (
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm italic">
                      None
                    </li>
                  </ul>
                ) : (
                  <div>
                    {reagent.qcRuns.map((run) => (
                      <ul
                        key={run.id}
                        className="border border-gray-200 rounded-md divide-y divide-gray-200"
                      >
                        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                          <div className="w-0 flex-1 flex items-center">
                            <ClipboardCheckIcon
                              className="flex-shrink-0 h-5 w-5 text-blue-400"
                              aria-hidden="true"
                            />
                            <span className="ml-2 flex-1 w-0 truncate">
                              {run.displayID}
                            </span>
                            <span
                              className={classNames(
                                run.status === "Queued"
                                  ? "bg-gray-100 text-gray-800"
                                  : run.status === "Fail"
                                  ? "bg-red-100 text-red-800"
                                  : "bg-green-100 text-green-800",
                                "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium"
                              )}
                            >
                              {run.status}
                            </span>
                          </div>
                          <div className="ml-4 flex-shrink-0">
                            <Link
                              to={`/regulator/workflows/history/${run.id}`}
                              className="font-medium text-blue-600 hover:text-blue-500"
                            >
                              View
                            </Link>
                          </div>
                        </li>
                      </ul>
                    ))}
                  </div>
                )}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Production Runs
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {reagent.productionRuns.length === 0 ? (
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm italic">
                      None
                    </li>
                  </ul>
                ) : (
                  <div>
                    {reagent.productionRuns.map((run) => (
                      <ul
                        key={run.id}
                        className="border border-gray-200 rounded-md divide-y divide-gray-200"
                      >
                        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                          <div className="w-0 flex-1 flex items-center">
                            <ClipboardCheckIcon
                              className="flex-shrink-0 h-5 w-5 text-blue-400"
                              aria-hidden="true"
                            />
                            <span className="ml-2 flex-1 w-0 truncate">
                              {run.displayID}
                            </span>
                            <span
                              className={classNames(
                                run.status === "Queued"
                                  ? "bg-gray-100 text-gray-800"
                                  : run.status === "Fail"
                                  ? "bg-red-100 text-red-800"
                                  : "bg-green-100 text-green-800",
                                "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium"
                              )}
                            >
                              {run.status}
                            </span>
                          </div>
                          <div className="ml-4 flex-shrink-0">
                            <Link
                              to={`/regulator/workflows/history/${run.id}`}
                              className="font-medium text-blue-600 hover:text-blue-500"
                            >
                              View
                            </Link>
                          </div>
                        </li>
                      </ul>
                    ))}
                  </div>
                )}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Records</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {reagent.records.length === 0 ? (
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm italic">
                      None
                    </li>
                  </ul>
                ) : (
                  <div>
                    {reagent.records.map((record) => (
                      <ul
                        key={record.id}
                        className="border border-gray-200 rounded-md divide-y divide-gray-200"
                      >
                        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                          <div className="w-0 flex-1 flex items-center">
                            <DocumentTextIcon
                              className="flex-shrink-0 h-5 w-5 text-blue-400"
                              aria-hidden="true"
                            />
                            <span className="ml-2 flex-1 w-0 truncate">
                              {`${record.form.title} - ${getDate(
                                record.completed
                              )}`}
                            </span>
                          </div>
                          <div className="ml-4 flex-shrink-0">
                            <Link
                              to={`/regulator/records/${record.id}`}
                              className="font-medium text-blue-600 hover:text-blue-500"
                            >
                              View
                            </Link>
                          </div>
                        </li>
                      </ul>
                    ))}
                  </div>
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <AuditTrailCard
        fieldDisplayMap={fieldDisplayMap}
        type={`${reagent.displayID} - ${reagent.type.name}`}
        fetchPath={reagentAuditPath(reagentID)}
      />
    </div>
  );
}
