import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Pagination from "../common/Pagination";
import EmptyStateScreen from "../common/EmptyStateScreen";
import { formatDateInputValue, getDate } from "../common/Utils";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import CustomSelectMenu from "../common/CustomSelectMenu";
import { usePersonnelSummaries } from "../common/DataFetchers";
import { PersonnelSummary } from "../common/Entities.d";
import { useUserContext } from "../common/BasePageScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";

const statusOptions = [
  { id: "0", value: "Select", display: "Select" },
  { id: "1", value: "Active", display: "Active" },
  { id: "2", value: "Inactive", display: "Inactive" },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function PersonnelList(): JSX.Element {
  const { personnelRead, personnelWrite } = useUserContext();

  const {
    personnelSummaries,
    personnelSummariesIsLoading,
    personnelSummariesIsError,
  } = usePersonnelSummaries(personnelRead);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [items, setItems] = useState<PersonnelSummary[]>([]);

  const [showFilterBox, setShowFilterBox] = useState(false);

  const [roleOptions, setRoleOptions] = useState<
    { id: string; value: any; display: string }[]
  >([{ id: "0", value: "Select", display: "Select" }]);

  useEffect(() => {
    if (personnelSummaries.length === 0) {
      return;
    }

    setItems(personnelSummaries);

    const roleSet = new Set<string>();
    personnelSummaries.forEach((personnel) => roleSet.add(personnel.role.name));
    const updatedRoleOptions: { id: string; value: any; display: string }[] = [
      { id: "0", value: "Select", display: "Select" },
    ];
    roleSet.forEach((role) =>
      updatedRoleOptions.push({
        id: role,
        value: role,
        display: role,
      })
    );

    setRoleOptions(updatedRoleOptions);
  }, [personnelSummaries]);

  const [filterRole, setFilterRole] = useState(roleOptions[0]);

  const [filterStatus, setFilterStatus] = useState(statusOptions[0]);

  if (personnelSummariesIsLoading) {
    return <LoadingComponent />;
  }

  if (personnelSummariesIsError) {
    return <ErrorComponent />;
  }

  const pageEndIndex = currentPage * itemsPerPage;
  const pageStartIndex = pageEndIndex - itemsPerPage;
  const pagePersonnelSummaries = items
    ? items.slice(pageStartIndex, pageEndIndex)
    : personnelSummaries.slice(pageStartIndex, pageEndIndex);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const tablePersonnelRows = pagePersonnelSummaries.map((personnel, index) => (
    <tr
      key={personnel.id}
      className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
    >
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {personnel.firstName}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {personnel.lastName}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {personnel.role.name}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {getDate(personnel.hired)}
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <span
          className={classNames(
            personnel.status === "Active"
              ? "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
              : "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
          )}
        >
          {personnel.status}
        </span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <Link
          to={`/regulator/personnel/${personnel.id}`}
          className="text-blue-600 hover:text-blue-900"
        >
          View
        </Link>
      </td>
    </tr>
  ));

  function clearFilter() {
    (document.getElementById("firstNameFilter") as HTMLInputElement).value = "";
    (document.getElementById("lastNameFilter") as HTMLInputElement).value = "";
    (document.getElementById("startDateFilter") as HTMLInputElement).value = "";
    (document.getElementById("endDateFilter") as HTMLInputElement).value = "";
    setFilterRole(roleOptions[0]);
    setFilterStatus(statusOptions[0]);
    setItems(Array.from(personnelSummaries));
  }

  function handleFilter(e: React.SyntheticEvent) {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      firstNameFilter: { value: string };
      lastNameFilter: { value: string };
      startDateFilter: { value: string };
      endDateFilter: { value: string };
    };

    let newItems = personnelSummaries;

    const firstNameFilter = target.firstNameFilter.value.trim();
    if (firstNameFilter !== "") {
      newItems = newItems.filter((i) => i.firstName === firstNameFilter);
    }
    const lastNameFilter = target.lastNameFilter.value.trim();
    if (lastNameFilter !== "") {
      newItems = newItems.filter((i) => i.lastName === lastNameFilter);
    }
    const roleFilter = filterRole.value;
    if (roleFilter !== "Select") {
      newItems = newItems.filter((i) => i.role.name === roleFilter);
    }
    const startDateFilter = target.startDateFilter.value.trim();
    if (startDateFilter !== "") {
      newItems = newItems.filter(
        (i) =>
          new Date(i.hired) >= new Date(formatDateInputValue(startDateFilter))
      );
    }
    const endDateFilter = target.endDateFilter.value.trim();
    if (endDateFilter !== "") {
      newItems = newItems.filter(
        (i) =>
          new Date(i.hired) <= new Date(formatDateInputValue(endDateFilter))
      );
    }
    const statusFilter = filterStatus.value;
    if (statusFilter !== "Select") {
      newItems = newItems.filter((i) => i.status === statusFilter);
    }

    setItems(Array.from(newItems));
  }

  return (
    <div>
      <div className="bg-white p-3 border border-gray-200 rounded-lg shadow-sm">
        <h3 className="ml-1 text-md leading-6 font-medium text-gray-900">
          Filter
        </h3>
        <div className="-mt-6 text-right">
          <button onClick={() => setShowFilterBox(!showFilterBox)}>
            {showFilterBox ? (
              <ChevronUpIcon
                className="h-6 w-6 text-gray-500"
                aria-hidden="true"
              />
            ) : (
              <ChevronDownIcon
                className="h-6 w-6 text-gray-500"
                aria-hidden="true"
              />
            )}
          </button>
        </div>
        <div hidden={!showFilterBox}>
          <form
            className="grid grid-cols-5 gap-4"
            onSubmit={(e) => handleFilter(e)}
          >
            <div className="ml-1">
              <label
                htmlFor="firstNameFilter"
                className="mt-2.5 block text-sm font-medium text-gray-700"
              >
                First Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="firstNameFilter"
                  id="firstNameFilter"
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="ml-1">
              <label
                htmlFor="lastNameFilter"
                className="mt-2.5 block text-sm font-medium text-gray-700"
              >
                Last Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="lastNameFilter"
                  id="lastNameFilter"
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="ml-1">
              <CustomSelectMenu
                label={"Role"}
                options={roleOptions}
                selected={filterRole}
                setSelected={setFilterRole}
              />
            </div>
            <div className="ml-1">
              <CustomSelectMenu
                label={"Status"}
                options={statusOptions}
                selected={filterStatus}
                setSelected={setFilterStatus}
              />
            </div>
            <div className="ml-1">
              <label
                htmlFor="startDateFilter"
                className="mt-2.5 block text-sm font-medium text-gray-700 mb-1"
              >
                Hire Date
              </label>
              <input
                type="date"
                name="startDateFilter"
                id="startDateFilter"
                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
              <p className="text-center">to</p>
              <input
                type="date"
                name="endDateFilter"
                id="endDateFilter"
                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div className="text-right col-end-6 col-span-1">
              <button
                type="button"
                onClick={clearFilter}
                className="m-1 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Clear
              </button>
              <button
                type="submit"
                className="m-1 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Apply
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            {personnelSummaries.length === 0 ? (
              <EmptyStateScreen
                item={"personnel"}
                createLink={"/regulator/personnel/add"}
                writeAccess={personnelWrite}
              />
            ) : (
              <div>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        First Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Last Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Role
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Hire Date
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Status
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">View</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{tablePersonnelRows}</tbody>
                </table>
                <Pagination
                  itemsPerPage={itemsPerPage}
                  totalItems={items ? items.length : personnelSummaries.length}
                  currentPage={currentPage}
                  paginate={paginate}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
