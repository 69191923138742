import { Link, useParams } from "react-router-dom";
import { CheckIcon, MailIcon, MailOpenIcon } from "@heroicons/react/outline";
import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import {
  updateTaskRead,
  updateTaskUnread,
  useTask,
} from "../common/DataFetchers";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import { formatErrorMessage, getDate, getFullName } from "../common/Utils";
import React, { useState } from "react";
import TaskCloseModal from "./TaskCloseModal";
import { TaskType } from "../common/Entities.d";
import { REGULATOR_BOT, taskTypeToDisplayMap } from "../common/Constants.d";
import ErrorBanner from "../common/ErrorBanner";

const taskTypeToLinkMap = new Map<string, string>([
  ["SOPDraftReview", "/regulator/sops"],
  ["SOPAnnualReview", "/regulator/sops"],
  ["EquipmentCalibration", "/regulator/equipment"],
  ["ReagentExpiration", "/regulator/reagents"],
  ["CompetencyExpiration", "/regulator/competency"],
  ["CompetencyReview", "/regulator/competency"],
]);

export default function TaskDetailCard() {
  const params = useParams<{ id: string }>();
  const taskId = params.id as string;

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const [taskCloseModalOpen, setTaskCloseModalOpen] = useState(false);

  const { task, taskIsLoading, taskIsError, mutateTask } = useTask(taskId);

  if (taskIsLoading) {
    return <LoadingComponent />;
  }

  if (taskIsError || !task) {
    return <ErrorComponent />;
  }

  function getLinkEntity(type: TaskType) {
    if (!task) {
      return;
    }

    if (type === "SOPDraftReview" || type === "ProcedureManualReview") {
      if (task.sop) {
        return task.sop;
      }
    }

    if (type === "EquipmentCalibration") {
      if (task.equipment) {
        return task.equipment;
      }
    }

    if (type === "ReagentExpiration") {
      if (task.reagent) {
        return task.reagent;
      }
    }

    if (type === "CompetencyExpiration" || type === "CompetencyReview") {
      if (task.competency) {
        return task.competency;
      }
    }
  }

  function getLinkDisplay(type: TaskType) {
    if (!task) {
      return;
    }

    if (type === "SOPDraftReview" || type === "ProcedureManualReview") {
      if (task.sop) {
        return task.sop.displayID;
      }
    }

    if (type === "EquipmentCalibration") {
      if (task.equipment) {
        return task.equipment.displayID;
      }
    }

    if (type === "ReagentExpiration") {
      if (task.reagent) {
        return task.reagent.displayID;
      }
    }

    if (type === "CompetencyExpiration" || type === "CompetencyReview") {
      if (task.competency) {
        return `${task.competency.typeName} - ${getFullName(
          task.competency.personnel.firstName,
          task.competency.personnel.lastName
        )}`;
      }
    }
  }

  function markNotificationAsReadOrUnread() {
    if (!task) {
      return;
    }
    if (task.read === null) {
      updateTaskRead(task.id)
        .then((task) => mutateTask(task, false))
        .catch((error) => {
          setErrorBannerText(formatErrorMessage(error));
          setShowErrorBanner(true);
        });
    } else {
      updateTaskUnread(task.id)
        .then((task) => mutateTask(task, false))
        .catch((error) => {
          setErrorBannerText(formatErrorMessage(error));
          setShowErrorBanner(true);
        });
    }
  }

  const pages = [
    { name: "Tasks", path: "/regulator/tasks" },
    {
      name: `${taskTypeToDisplayMap.get(task.type)} - ${getLinkDisplay(
        task.type
      )}`,
      path: `/regulator/tasks/${task.id}`,
    },
  ];

  return (
    <div>
      <FullWidthBreadcrumbBar pages={pages} />
      <div className="mt-2">
        <ErrorBanner
          showError={showErrorBanner}
          setShowError={setShowErrorBanner}
          errorText={errorBannerText}
        />
      </div>
      <div className="mt-3 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Task Details
          </h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Due Date</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {getDate(task.due)}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Requester</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {task.assigner
                  ? `${task.assigner.firstName} ${task.assigner.lastName}`
                  : REGULATOR_BOT}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Type</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {taskTypeToDisplayMap.get(task.type)}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Task Item</dt>
              <Link
                to={`${taskTypeToLinkMap.get(task.type)}/${
                  getLinkEntity(task.type)?.id
                }`}
              >
                <dd className="mt-1 text-sm font-medium text-blue-600">
                  {getLinkDisplay(task.type)}
                </dd>
              </Link>
            </div>
            <div className="sm:col-span-1" hidden={task.closed === null}>
              <dt className="text-sm font-medium text-gray-500">Date Closed</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {task.closed ? getDate(task.closed) : ""}
              </dd>
            </div>
            <div className="sm:col-span-1" hidden={task.closed === null}>
              <dt className="text-sm font-medium text-gray-500">Closed By</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {task.closer
                  ? getFullName(task.closer.firstName, task.closer.lastName)
                  : ""}
              </dd>
            </div>
            <div className="sm:col-span-2" hidden={task.closed === null}>
              <dt className="text-sm font-medium text-gray-500">Reason</dt>
              <dd className="mt-1 text-sm text-gray-900">{task.reason}</dd>
            </div>
          </dl>
          <div className="py-3 text-right" hidden={task.closed !== null}>
            <button
              type="button"
              className="mr-2 inline-flex items-center px-4 py-2 border border-blue-600 shadow-sm text-base font-medium rounded-md text-blue-600 bg-white hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={() => markNotificationAsReadOrUnread()}
            >
              {task.read ? (
                <MailIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              ) : (
                <MailOpenIcon
                  className="-ml-1 mr-3 h-5 w-5"
                  aria-hidden="true"
                />
              )}
              {task.read ? "Mark as Unread" : "Mark as Read"}
            </button>
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={() => setTaskCloseModalOpen(true)}
            >
              <CheckIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              Mark as Closed
            </button>
            <TaskCloseModal
              open={taskCloseModalOpen}
              setOpen={setTaskCloseModalOpen}
              mutateTask={mutateTask}
              taskId={task.id}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
