import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

interface PaginationProps {
  itemsPerPage: number;
  totalItems: number;
  currentPage: number;
  paginate: (pageNumber: number) => void;
}

export default function Pagination(props: PaginationProps) {
  const pageNumbers: number[] = [];

  if (props.totalItems <= props.itemsPerPage) {
    pageNumbers.push(1);
  } else {
    for (
      let i = 1;
      i <= Math.ceil(props.totalItems / props.itemsPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }
  }

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between rounded-b-lg border-t border-gray-200 sm:px-6">
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          {props.totalItems <= props.itemsPerPage ? (
            <p className="text-sm text-gray-700">
              Showing{" "}
              <span className="font-medium">
                {props.totalItems === 0 ? "0" : "1"}
              </span>{" "}
              to <span className="font-medium">{props.totalItems}</span> of{" "}
              <span className="font-medium">{props.totalItems}</span> results
            </p>
          ) : (
            <p className="text-sm text-gray-700">
              Showing{" "}
              <span className="font-medium">
                {props.currentPage * props.itemsPerPage -
                  (props.itemsPerPage - 1)}
              </span>{" "}
              to{" "}
              <span className="font-medium">
                {Math.min(
                  props.currentPage * props.itemsPerPage,
                  props.totalItems
                )}
              </span>{" "}
              of <span className="font-medium">{props.totalItems}</span> results
            </p>
          )}
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <button
              type="button"
              key="Previous"
              disabled={props.currentPage - 1 < 1}
              onClick={() => props.paginate(props.currentPage - 1)}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {pageNumbers.map((number) => (
              <button
                type="button"
                key={number.toString()}
                onClick={() => props.paginate(number)}
                className={
                  props.currentPage === number
                    ? "z-10 bg-blue-50 border-blue-500 text-blue-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                    : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                }
              >
                {number}
              </button>
            ))}
            <button
              type="button"
              key="Next"
              disabled={
                props.currentPage + 1 > pageNumbers[pageNumbers.length - 1]
              }
              onClick={() => props.paginate(props.currentPage + 1)}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}
