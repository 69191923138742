import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  FormCategoryPointer,
  FormElementAttachment,
  FormElementEntitySelect,
  FormElementSimpleInput,
  FormElementSimpleSelect,
  FormElementText,
} from "../common/Entities.d";
import Header from "./form_elements/Header";
import Paragraph from "./form_elements/Paragraph";
import TextInput from "./form_elements/TextInput";
import TextArea from "./form_elements/TextArea";
import NumberInput from "./form_elements/NumberInput";
import DateInput from "./form_elements/DateInput";
import LinkInput from "./form_elements/LinkInput";
import Checkbox from "./form_elements/Checkbox";
import SelectMenu from "./form_elements/SelectMenu";
import RadioGroup from "./form_elements/RadioGroup";
import InternalEntityInput from "./form_elements/InternalEntityInput";
import Attachment from "./form_elements/Attachment";
import { XIcon } from "@heroicons/react/solid";
import { v4 as uuid } from "uuid";

export default function PreviewModal(props: {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  order: { pointer: FormCategoryPointer; error: boolean }[];
  text: FormElementText[];
  simpleInput: FormElementSimpleInput[];
  simpleSelect: FormElementSimpleSelect[];
  entitySelect: FormElementEntitySelect[];
  attachment: FormElementAttachment[];
}) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-gray-100 rounded-md text-gray-500 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  onClick={() => props.setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <h3 className="ml-1">Form Preview </h3>
              <div className="mt-5 p-3 bg-gray-100 rounded-md border border-dashed border-gray-600">
                <h1 className="text-2xl leading-6 font-medium text-gray-900">
                  {props.title}
                </h1>
                <div className="mt-6 space-y-6 sm:space-y-5">
                  {props.order.map(function (orderComponent) {
                    const index = orderComponent.pointer.categoryIndex;
                    const type = orderComponent.pointer.category;
                    if (type === "Text") {
                      const textElement = props.text[index];
                      if (textElement.elementType === "SectionHeader") {
                        return (
                          <div key={uuid()}>
                            <Header formText={textElement} />
                          </div>
                        );
                      }
                      if (textElement.elementType === "Paragraph") {
                        return (
                          <div key={uuid()}>
                            <Paragraph formText={textElement} />
                          </div>
                        );
                      }
                    }
                    if (type === "SimpleInput") {
                      const simpleInputElement = props.simpleInput[index];
                      if (simpleInputElement.elementType === "TextInput") {
                        return (
                          <div key={uuid()}>
                            <TextInput
                              simpleFormInput={simpleInputElement}
                              error={false}
                              recordPointer={{
                                recordValueArray: "",
                                recordValueIndex: 0,
                              }}
                              textValues={[]}
                              setTextValues={(textValues: Array<string[]>) => {
                                return;
                              }}
                            />
                          </div>
                        );
                      }
                      if (simpleInputElement.elementType === "TextArea") {
                        return (
                          <div key={uuid()}>
                            <TextArea
                              simpleFormInput={simpleInputElement}
                              error={false}
                              recordPointer={{
                                recordValueArray: "",
                                recordValueIndex: 0,
                              }}
                              textValues={[]}
                              setTextValues={(textValues: Array<string[]>) => {
                                return;
                              }}
                            />
                          </div>
                        );
                      }
                      if (simpleInputElement.elementType === "Number") {
                        return (
                          <div key={uuid()}>
                            <NumberInput
                              simpleFormInput={simpleInputElement}
                              error={false}
                              recordPointer={{
                                recordValueArray: "",
                                recordValueIndex: 0,
                              }}
                              textValues={[]}
                              setTextValues={(textValues: Array<string[]>) => {
                                return;
                              }}
                            />
                          </div>
                        );
                      }
                      if (simpleInputElement.elementType === "Date") {
                        return (
                          <div key={uuid()}>
                            <DateInput
                              simpleFormInput={simpleInputElement}
                              error={false}
                              recordPointer={{
                                recordValueArray: "",
                                recordValueIndex: 0,
                              }}
                              textValues={[[""]]}
                              setTextValues={(textValues: Array<string[]>) => {
                                return;
                              }}
                            />
                          </div>
                        );
                      }
                      if (simpleInputElement.elementType === "Link") {
                        return (
                          <div key={uuid()}>
                            <LinkInput
                              simpleFormInput={simpleInputElement}
                              error={false}
                              recordPointer={{
                                recordValueArray: "",
                                recordValueIndex: 0,
                              }}
                              textValues={[]}
                              setTextValues={(textValues: Array<string[]>) => {
                                return;
                              }}
                            />
                          </div>
                        );
                      }
                    }
                    if (type === "SimpleSelect") {
                      const simpleSelectElement = props.simpleSelect[index];
                      if (simpleSelectElement.elementType === "Checkbox") {
                        return (
                          <div key={uuid()}>
                            <Checkbox
                              checkboxInput={simpleSelectElement}
                              error={false}
                              recordPointer={{
                                recordValueArray: "",
                                recordValueIndex: 0,
                              }}
                              textValues={[]}
                              setTextValues={(textValues: Array<string[]>) => {
                                return;
                              }}
                            />
                          </div>
                        );
                      }
                      if (simpleSelectElement.elementType === "SelectMenu") {
                        return (
                          <div key={uuid()}>
                            <SelectMenu
                              selectMenuInput={simpleSelectElement}
                              recordPointer={{
                                recordValueArray: "",
                                recordValueIndex: 0,
                              }}
                              textValues={[]}
                              setTextValues={(textValues: Array<string[]>) => {
                                return;
                              }}
                            />
                          </div>
                        );
                      }
                      if (simpleSelectElement.elementType === "RadioGroup") {
                        return (
                          <div key={uuid()}>
                            <RadioGroup
                              radioGroupInput={simpleSelectElement}
                              recordPointer={{
                                recordValueArray: "",
                                recordValueIndex: 0,
                              }}
                              textValues={[]}
                              setTextValues={(textValues: Array<string[]>) => {
                                return;
                              }}
                            />
                          </div>
                        );
                      }
                    }
                    if (type === "EntitySelect") {
                      const internalEntityInput = props.entitySelect[index];
                      return (
                        <div key={uuid()}>
                          <InternalEntityInput
                            internalEntityInput={internalEntityInput}
                            error={false}
                            recordPointer={{
                              recordValueArray: "",
                              recordValueIndex: 0,
                            }}
                            textValues={[]}
                            setTextValues={(textValues: Array<string[]>) => {
                              return;
                            }}
                          />
                        </div>
                      );
                    }
                    if (type === "Attachment") {
                      const attachmentInput = props.attachment[index];
                      return (
                        <div key={uuid()}>
                          <Attachment
                            attachmentInput={attachmentInput}
                            error={false}
                            recordPointer={{
                              recordValueArray: "",
                              recordValueIndex: 0,
                            }}
                            attachments={[]}
                            setAttachments={(attachments: Array<File[]>) => {
                              return;
                            }}
                          />
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
