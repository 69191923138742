import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import { Link, useParams } from "react-router-dom";
import { PencilAltIcon, UserIcon } from "@heroicons/react/solid";
import { getFullName } from "../common/Utils";
import AuditTrailCard from "../common/AuditTrailCard";
import {
  competencyTypeAuditPath,
  useCompetencyType,
  usePersonnelSummaries,
} from "../common/DataFetchers";
import * as React from "react";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";

const fieldDisplayMap = new Map([["name", "Name"]]);

export default function CompetencyTypeDetailCard() {
  const params = useParams<{ id: string }>();
  const competencyTypeID = params.id as string;

  const { competencyType, competencyTypeIsLoading, competencyTypeIsError } =
    useCompetencyType(competencyTypeID, true);

  if (competencyTypeIsLoading) {
    return <LoadingComponent />;
  }

  if (competencyTypeIsError || !competencyType) {
    return <ErrorComponent />;
  }

  const pages = [
    { name: "Training & Competency", path: "/regulator/competency" },
    {
      name: competencyType.name,
      path: `/regulator/competency/type/${competencyType.id}`,
    },
  ];

  return (
    <div>
      <FullWidthBreadcrumbBar pages={pages} />
      <div className="py-3 pt-5">
        <div className="flex justify-end">
          <Link to={`/regulator/competency/type/${competencyType.id}/edit`}>
            <button
              type="button"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <PencilAltIcon
                className="-ml-1 mr-3 h-5 w-5"
                aria-hidden="true"
              />
              Edit
            </button>
          </Link>
        </div>
      </div>
      <div className="bg-white mt-3 shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Competency Type Details
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Details regarding competency type
          </p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {competencyType.name}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <AuditTrailCard
        fieldDisplayMap={fieldDisplayMap}
        type={"Competency Type"}
        fetchPath={competencyTypeAuditPath(competencyTypeID)}
      />
    </div>
  );
}
