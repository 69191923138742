import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { classNames, formatErrorMessage } from "../common/Utils";
import {
  updateUser,
  useAdminUserSummaries,
  useCurrentLab,
  useUser,
} from "../common/DataFetchers";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import CustomSelectMenu from "../common/CustomSelectMenu";
import { Switch } from "@headlessui/react";
import ErrorBanner from "../common/ErrorBanner";

const statusOptions = [
  { id: "1", value: "Active", display: "Active" },
  { id: "2", value: "Inactive", display: "Inactive" },
];

const permissionTypes = [
  { display: "None", id: "none", key: "n" },
  { display: "Read", id: "read", key: "r" },
  { display: "Read/Write", id: "read-write", key: "rw" },
];
export default function EditUserForm(): JSX.Element {
  const navigate = useNavigate();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const params = useParams<{ id: string }>();
  const userID = params.id as string;

  const [isAdmin, setIsAdmin] = useState(false);
  const [status, setStatus] = useState(statusOptions[0]);

  const { user, userIsLoading, userIsError } = useUser(userID);
  const { currentLab } = useCurrentLab();
  const { adminUserSummaries } = useAdminUserSummaries("");

  function disableIsAdminToggle() {
    if (!currentLab || !adminUserSummaries) {
      return true;
    }
    if (currentLab?.maxAdminUsers === 1) {
      return true;
    }
    return (
      currentLab?.maxAdminUsers ===
      adminUserSummaries.filter((admin) => admin.status !== "Inactive").length
    );
  }

  useEffect(() => {
    if (!user) {
      return;
    }
    const statusOptionIndex = statusOptions.findIndex(
      (option) => option.value === user.status
    );
    setStatus(statusOptions[statusOptionIndex]);
    setIsAdmin(user.isAdmin);
  }, [user]);

  if (userIsLoading) {
    return <LoadingComponent />;
  }

  if (userIsError || !user) {
    return <ErrorComponent />;
  }

  return (
    <form
      className="py-3 px-3 space-y-8 divide-y divide-gray-200"
      onSubmit={(e: React.SyntheticEvent) => {
        e.preventDefault();

        setShowErrorBanner(false);

        const target = e.target as typeof e.target & {
          "workflow-templates-none": { checked: boolean };
          "workflow-templates-read": { checked: boolean };
          "workflow-templates-read-write": { checked: boolean };

          "workflow-history-none": { checked: boolean };
          "workflow-history-read": { checked: boolean };
          "workflow-history-read-write": { checked: boolean };

          "samples-none": { checked: boolean };
          "samples-read": { checked: boolean };
          "samples-read-write": { checked: boolean };

          "reagents-none": { checked: boolean };
          "reagents-read": { checked: boolean };
          "reagents-read-write": { checked: boolean };

          "equipment-none": { checked: boolean };
          "equipment-read": { checked: boolean };
          "equipment-read-write": { checked: boolean };

          "personnel-none": { checked: boolean };
          "personnel-read": { checked: boolean };
          "personnel-read-write": { checked: boolean };

          "competencies-none": { checked: boolean };
          "competencies-read": { checked: boolean };
          "competencies-read-write": { checked: boolean };

          "sops-none": { checked: boolean };
          "sops-read": { checked: boolean };
          "sops-read-write": { checked: boolean };

          "forms-none": { checked: boolean };
          "forms-read": { checked: boolean };
          "forms-read-write": { checked: boolean };

          "records-none": { checked: boolean };
          "records-read": { checked: boolean };
          "records-read-write": { checked: boolean };
        };

        let workflowTemplatesPermission = user?.workflowTemplates;
        if (target["workflow-templates-none"].checked) {
          workflowTemplatesPermission = "n";
        } else if (target["workflow-templates-read"].checked) {
          workflowTemplatesPermission = "r";
        } else if (target["workflow-templates-read-write"].checked) {
          workflowTemplatesPermission = "rw";
        }

        let workflowHistoryPermission = user?.workflowHistory;
        if (target["workflow-history-none"].checked) {
          workflowHistoryPermission = "n";
        } else if (target["workflow-history-read"].checked) {
          workflowHistoryPermission = "r";
        } else if (target["workflow-history-read-write"].checked) {
          workflowHistoryPermission = "rw";
        }

        let samplesPermission = user?.samples;
        if (target["samples-none"].checked) {
          samplesPermission = "n";
        } else if (target["samples-read"].checked) {
          samplesPermission = "r";
        } else if (target["samples-read-write"].checked) {
          samplesPermission = "rw";
        }

        let reagentsPermission = user?.reagents;
        if (target["reagents-none"].checked) {
          reagentsPermission = "n";
        } else if (target["reagents-read"].checked) {
          reagentsPermission = "r";
        } else if (target["reagents-read-write"].checked) {
          reagentsPermission = "rw";
        }

        let equipmentPermission = user?.equipment;
        if (target["equipment-none"].checked) {
          equipmentPermission = "n";
        } else if (target["equipment-read"].checked) {
          equipmentPermission = "r";
        } else if (target["equipment-read-write"].checked) {
          equipmentPermission = "rw";
        }

        let personnelPermission = user?.personnel;
        if (target["personnel-none"].checked) {
          personnelPermission = "n";
        } else if (target["personnel-read"].checked) {
          personnelPermission = "r";
        } else if (target["personnel-read-write"].checked) {
          personnelPermission = "rw";
        }

        let competencyPermission = user?.competencies;
        if (target["competencies-none"].checked) {
          competencyPermission = "n";
        } else if (target["competencies-read"].checked) {
          competencyPermission = "r";
        } else if (target["competencies-read-write"].checked) {
          competencyPermission = "rw";
        }

        let sopPermission = user?.sops;
        if (target["sops-none"].checked) {
          sopPermission = "n";
        } else if (target["sops-read"].checked) {
          sopPermission = "r";
        } else if (target["sops-read-write"].checked) {
          sopPermission = "rw";
        }

        let formsPermission = user?.forms;
        if (target["forms-none"].checked) {
          formsPermission = "n";
        } else if (target["forms-read"].checked) {
          formsPermission = "r";
        } else if (target["forms-read-write"].checked) {
          formsPermission = "rw";
        }

        let recordsPermission = user?.records;
        if (target["records-none"].checked) {
          recordsPermission = "n";
        } else if (target["records-read"].checked) {
          recordsPermission = "r";
        } else if (target["records-read-write"].checked) {
          recordsPermission = "rw";
        }

        updateUser(user.id, {
          status: status.value === "Active" ? "Active" : "Inactive",
          isAdmin: isAdmin,
          workflowTemplates: isAdmin ? "rw" : workflowTemplatesPermission,
          workflowHistory: isAdmin ? "rw" : workflowHistoryPermission,
          samples: isAdmin ? "rw" : samplesPermission,
          reagents: isAdmin ? "rw" : reagentsPermission,
          equipment: isAdmin ? "rw" : equipmentPermission,
          personnel: isAdmin ? "rw" : personnelPermission,
          competencies: isAdmin ? "rw" : competencyPermission,
          sops: isAdmin ? "rw" : sopPermission,
          forms: isAdmin ? "rw" : formsPermission,
          records: isAdmin ? "rw" : recordsPermission,
        })
          .then(() => navigate(`/regulator/settings/user/${user.id}`))
          .catch((error) => {
            setErrorBannerText(formatErrorMessage(error));
            setShowErrorBanner(true);
          });
      }}
    >
      <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
        <ErrorBanner
          showError={showErrorBanner}
          setShowError={setShowErrorBanner}
          errorText={errorBannerText}
        />
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Edit User
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Fill in required details to edit the user.
          </p>
        </div>
        <div className="space-y-6 sm:space-y-5">
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="admin"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Admin User?
            </label>
            <div className="mt-1">
              <Switch
                disabled={disableIsAdminToggle()}
                checked={isAdmin}
                onChange={setIsAdmin}
                className={classNames(
                  isAdmin ? "bg-blue-600" : "bg-gray-200",
                  "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    isAdmin ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                  )}
                />
              </Switch>
            </div>
          </div>
          <div hidden={disableIsAdminToggle() && user.isAdmin}>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <CustomSelectMenu
                label={"Status"}
                options={statusOptions}
                selected={status}
                setSelected={setStatus}
              />
            </div>
          </div>
          <div hidden={isAdmin}>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Permissions
              </label>
              <div className="mt-1 sm:col-span-2">
                <label className="text-base font-medium text-gray-900">
                  Workflow Templates
                </label>
                <fieldset className="mt-4">
                  <legend className="sr-only">Permission</legend>
                  <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                    {permissionTypes.map((permissionType) => (
                      <div
                        key={permissionType.id}
                        className="flex items-center"
                      >
                        <input
                          id={`workflow-templates-${permissionType.id}`}
                          name="workflow-templates-permission"
                          type="radio"
                          defaultChecked={
                            user?.workflowTemplates === permissionType.key
                          }
                          className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                        />
                        <label
                          htmlFor={`workflow-templates-${permissionType.id}`}
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          {permissionType.display}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>
              <div className="mt-1 sm:col-start-2 sm:col-span-2">
                <label className="text-base font-medium text-gray-900">
                  Workflow History
                </label>
                <fieldset className="mt-4">
                  <legend className="sr-only">Permission</legend>
                  <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                    {permissionTypes.map((permissionType) => (
                      <div
                        key={permissionType.id}
                        className="flex items-center"
                      >
                        <input
                          id={`workflow-history-${permissionType.id}`}
                          name="workflow-history-permission"
                          type="radio"
                          defaultChecked={
                            user?.workflowHistory === permissionType.key
                          }
                          className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                        />
                        <label
                          htmlFor={`workflow-history-${permissionType.id}`}
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          {permissionType.display}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>
              <div className="mt-1 sm:col-start-2 sm:col-span-2">
                <label className="text-base font-medium text-gray-900">
                  Samples
                </label>
                <fieldset className="mt-4">
                  <legend className="sr-only">Permission</legend>
                  <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                    {permissionTypes.map((permissionType) => (
                      <div
                        key={permissionType.id}
                        className="flex items-center"
                      >
                        <input
                          id={`samples-${permissionType.id}`}
                          name="samples-permission"
                          type="radio"
                          defaultChecked={user?.samples === permissionType.key}
                          className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                        />
                        <label
                          htmlFor={`samples-${permissionType.id}`}
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          {permissionType.display}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>
              <div className="mt-1 sm:col-start-2 sm:col-span-2">
                <label className="text-base font-medium text-gray-900">
                  Reagents
                </label>
                <fieldset className="mt-4">
                  <legend className="sr-only">Permission</legend>
                  <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                    {permissionTypes.map((permissionType) => (
                      <div
                        key={permissionType.id}
                        className="flex items-center"
                      >
                        <input
                          id={`reagents-${permissionType.id}`}
                          name="reagents-permission"
                          type="radio"
                          defaultChecked={user?.reagents === permissionType.key}
                          className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                        />
                        <label
                          htmlFor={`reagents-${permissionType.id}`}
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          {permissionType.display}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>
              <div className="mt-1 sm:col-start-2 sm:col-span-2">
                <label className="text-base font-medium text-gray-900">
                  Equipment
                </label>
                <fieldset className="mt-4">
                  <legend className="sr-only">Permission</legend>
                  <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                    {permissionTypes.map((permissionType) => (
                      <div
                        key={permissionType.id}
                        className="flex items-center"
                      >
                        <input
                          id={`equipment-${permissionType.id}`}
                          name="equipment-permission"
                          type="radio"
                          defaultChecked={
                            user?.equipment === permissionType.key
                          }
                          className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                        />
                        <label
                          htmlFor={`equipment-${permissionType.id}`}
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          {permissionType.display}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>
              <div className="mt-1 sm:col-start-2 sm:col-span-2">
                <label className="text-base font-medium text-gray-900">
                  Personnel
                </label>
                <fieldset className="mt-4">
                  <legend className="sr-only">Permission</legend>
                  <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                    {permissionTypes.map((permissionType) => (
                      <div
                        key={permissionType.id}
                        className="flex items-center"
                      >
                        <input
                          id={`personnel-${permissionType.id}`}
                          name="personnel-permission"
                          type="radio"
                          defaultChecked={
                            user?.personnel === permissionType.key
                          }
                          className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                        />
                        <label
                          htmlFor={`personnel-${permissionType.id}`}
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          {permissionType.display}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>
              <div className="mt-1 sm:col-start-2 sm:col-span-2">
                <label className="text-base font-medium text-gray-900">
                  Training & Competency
                </label>
                <fieldset className="mt-4">
                  <legend className="sr-only">Permission</legend>
                  <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                    {permissionTypes.map((permissionType) => (
                      <div
                        key={permissionType.id}
                        className="flex items-center"
                      >
                        <input
                          id={`competencies-${permissionType.id}`}
                          name="competencies-permission"
                          type="radio"
                          defaultChecked={
                            user?.competencies === permissionType.key
                          }
                          className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                        />
                        <label
                          htmlFor={`competencies-${permissionType.id}`}
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          {permissionType.display}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>
              <div className="mt-1 sm:col-start-2 sm:col-span-2">
                <label className="text-base font-medium text-gray-900">
                  SOPs
                </label>
                <fieldset className="mt-4">
                  <legend className="sr-only">Permission</legend>
                  <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                    {permissionTypes.map((permissionType) => (
                      <div
                        key={permissionType.id}
                        className="flex items-center"
                      >
                        <input
                          id={`sops-${permissionType.id}`}
                          name="sops-permission"
                          type="radio"
                          defaultChecked={user?.sops === permissionType.key}
                          className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                        />
                        <label
                          htmlFor={`sops-${permissionType.id}`}
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          {permissionType.display}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>
              <div className="mt-1 sm:col-start-2 sm:col-span-2">
                <label className="text-base font-medium text-gray-900">
                  Forms
                </label>
                <fieldset className="mt-4">
                  <legend className="sr-only">Permission</legend>
                  <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                    {permissionTypes.map((permissionType) => (
                      <div
                        key={permissionType.id}
                        className="flex items-center"
                      >
                        <input
                          id={`forms-${permissionType.id}`}
                          name="forms-permission"
                          type="radio"
                          defaultChecked={user?.forms === permissionType.key}
                          className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                        />
                        <label
                          htmlFor={`forms-${permissionType.id}`}
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          {permissionType.display}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>
              <div className="mt-1 sm:col-start-2 sm:col-span-2">
                <label className="text-base font-medium text-gray-900">
                  Records
                </label>
                <fieldset className="mt-4">
                  <legend className="sr-only">Permission</legend>
                  <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                    {permissionTypes.map((permissionType) => (
                      <div
                        key={permissionType.id}
                        className="flex items-center"
                      >
                        <input
                          id={`records-${permissionType.id}`}
                          name="records-permission"
                          type="radio"
                          defaultChecked={user?.records === permissionType.key}
                          className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
                        />
                        <label
                          htmlFor={`records-${permissionType.id}`}
                          className="ml-3 block text-sm font-medium text-gray-700"
                        >
                          {permissionType.display}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <Link to={`/regulator/settings/user/${userID}`}>
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
