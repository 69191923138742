import { Link, useLocation, useNavigate } from "react-router-dom";
import Tabs from "../common/Tabs";
import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import * as React from "react";
import { useUserContext } from "../common/BasePageScreen";
import ActiveFormsList from "./ActiveFormsList";
import ArchivedFormsList from "./ArchivedFormsList";
import { ViewGridAddIcon } from "@heroicons/react/outline";

const activePages = [{ name: "Forms", path: "/regulator/forms" }];
const archivedPages = [
  { name: "Forms", path: "/regulator/forms" },
  { name: "Archived", path: "/regulator/forms/archived" },
];

const workflowTemplateTabs = [
  { name: "Active", href: "/regulator/forms" },
  { name: "Archived", href: "/regulator/forms/archived" },
];

export default function WorkflowTemplatesList(): JSX.Element {
  const { formsRead, formsWrite } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();

  if (!formsRead) {
    navigate("/forbidden");
    return <></>;
  }

  const isActivePath = location.pathname === "/regulator/forms";

  const breadcrumbBar = isActivePath ? (
    <FullWidthBreadcrumbBar pages={activePages} />
  ) : (
    <FullWidthBreadcrumbBar pages={archivedPages} />
  );

  const component = isActivePath ? <ActiveFormsList /> : <ArchivedFormsList />;

  return (
    <div className="flex flex-col">
      {breadcrumbBar}
      <div className="py-3 text-right">
        <Link to="/regulator/forms/add" hidden={!formsWrite}>
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <ViewGridAddIcon
              className="-ml-1 mr-3 h-5 w-5"
              aria-hidden="true"
            />
            Add Form
          </button>
        </Link>
      </div>
      <Tabs tabs={workflowTemplateTabs} />
      {component}
    </div>
  );
}
