import { Link, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Pagination from "../common/Pagination";
import EmptyStateScreen from "../common/EmptyStateScreen";
import { formatDateInputValue, getDate } from "../common/Utils";
import { SOPSummary } from "../common/Entities.d";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import CustomSelectMenu from "../common/CustomSelectMenu";
import { useSOPSummaries } from "../common/DataFetchers";
import { useUserContext } from "../common/BasePageScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";

const statusOptions = [
  { id: "0", value: "Select", display: "Select" },
  { id: "1", value: "Drafting", display: "Drafting" },
  { id: "2", value: "In Review", display: "In Review" },
  { id: "3", value: "Rejected", display: "Rejected" },
  { id: "4", value: "Approved", display: "Approved" },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function DraftList(): JSX.Element {
  const { sopsWrite, sopsRead } = useUserContext();

  const [searchParams] = useSearchParams();
  const categoryID = searchParams.get("categoryID");

  const { sopSummaries, sopSummariesIsLoading, sopSummariesIsError } =
    useSOPSummaries("Draft", categoryID, sopsRead);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [items, setItems] = useState<SOPSummary[]>(sopSummaries);

  useEffect(() => {
    if (!sopSummaries) {
      return;
    }
    setItems(sopSummaries);
  }, [sopSummaries]);

  const [showFilterBox, setShowFilterBox] = useState(false);
  const [filterStatus, setFilterStatus] = useState(statusOptions[0]);

  const pageEndIndex = currentPage * itemsPerPage;
  const pageStartIndex = pageEndIndex - itemsPerPage;
  const pageSOPSummaries = items.slice(pageStartIndex, pageEndIndex);

  if (sopSummariesIsLoading) {
    return <LoadingComponent />;
  }

  if (sopSummariesIsError) {
    return <ErrorComponent />;
  }

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  function clearFilter() {
    (document.getElementById("sopIdFilter") as HTMLInputElement).value = "";
    (document.getElementById("titleFilter") as HTMLInputElement).value = "";
    (document.getElementById("startDateFilter") as HTMLInputElement).value = "";
    (document.getElementById("endDateFilter") as HTMLInputElement).value = "";
    setFilterStatus(statusOptions[0]);
    setItems(Array.from(sopSummaries));
  }

  function handleFilter(e: React.SyntheticEvent) {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      sopIdFilter: { value: string };
      titleFilter: { value: string };
      startDateFilter: { value: string };
      endDateFilter: { value: string };
    };

    let newItems = sopSummaries;

    const sopIdFilter = target.sopIdFilter.value.trim();
    if (sopIdFilter !== "") {
      newItems = newItems.filter((i) => i.displayID === sopIdFilter);
    }
    const titleFilter = target.titleFilter.value.trim();
    if (titleFilter !== "") {
      newItems = newItems.filter((i) => i.title === titleFilter);
    }
    const startDateFilter = target.startDateFilter.value.trim();
    if (startDateFilter !== "") {
      newItems = newItems.filter(
        (i) =>
          new Date(i.created) >= new Date(formatDateInputValue(startDateFilter))
      );
    }
    const endDateFilter = target.endDateFilter.value.trim();
    if (endDateFilter !== "") {
      newItems = newItems.filter(
        (i) =>
          new Date(i.created) <= new Date(formatDateInputValue(endDateFilter))
      );
    }
    const statusFilter = filterStatus.value;
    if (statusFilter !== "Select") {
      newItems = newItems.filter((i) => i.status === statusFilter);
    }

    setItems(Array.from(newItems));
  }

  return (
    <div>
      <div className="bg-white p-3 border border-gray-200 rounded-lg shadow-sm">
        <h3 className="ml-1 text-md leading-6 font-medium text-gray-900">
          Filter
        </h3>
        <div className="-mt-6 text-right">
          <button onClick={() => setShowFilterBox(!showFilterBox)}>
            {showFilterBox ? (
              <ChevronUpIcon
                className="h-6 w-6 text-gray-500"
                aria-hidden="true"
              />
            ) : (
              <ChevronDownIcon
                className="h-6 w-6 text-gray-500"
                aria-hidden="true"
              />
            )}
          </button>
        </div>
        <div hidden={!showFilterBox}>
          <form
            className="grid grid-cols-4 gap-4"
            onSubmit={(e) => handleFilter(e)}
          >
            <div className="ml-1">
              <label
                htmlFor="sopIdFilter"
                className="mt-2.5 block text-sm font-medium text-gray-700"
              >
                SOP ID
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="sopIdFilter"
                  id="sopIdFilter"
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="ml-1">
              <label
                htmlFor="titleFilter"
                className="mt-2.5 block text-sm font-medium text-gray-700"
              >
                Title
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="titleFilter"
                  id="titleFilter"
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="ml-1">
              <CustomSelectMenu
                label={"Status"}
                options={statusOptions}
                selected={filterStatus}
                setSelected={setFilterStatus}
              />
            </div>
            <div className="ml-1">
              <label
                htmlFor="startDateFilter"
                className="mt-2.5 block text-sm font-medium text-gray-700 mb-1"
              >
                Date Created
              </label>
              <input
                type="date"
                name="startDateFilter"
                id="startDateFilter"
                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
              <p className="text-center">to</p>
              <input
                type="date"
                name="endDateFilter"
                id="endDateFilter"
                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div className="text-right col-end-5 col-span-1">
              <button
                type="button"
                onClick={clearFilter}
                className="m-1 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Clear
              </button>
              <button
                type="submit"
                className="m-1 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Apply
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            {sopSummaries.length === 0 ? (
              <EmptyStateScreen
                item={"SOP"}
                createLink={`/regulator/sops/add?categoryID=${categoryID}`}
                writeAccess={sopsWrite}
              />
            ) : (
              <div>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        SOP ID
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Title
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Version
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Date Created
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Status
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">View</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageSOPSummaries.map((sop, sopIdx) => (
                      <tr
                        key={sop.id}
                        className={sopIdx % 2 === 0 ? "bg-white" : "bg-gray-50"}
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {sop.displayID}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {sop.title}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {`${sop.majorVersion}.${sop.minorVersion}`}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {getDate(sop.created)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span
                            className={classNames(
                              sop.status === "Rejected"
                                ? "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
                                : sop.status === "Approved"
                                ? "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                                : "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800"
                            )}
                          >
                            {sop.status}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <Link
                            to={`/regulator/sops/${sop.id}/draft-review`}
                            className="text-blue-600 hover:text-blue-900"
                          >
                            View
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination
                  itemsPerPage={itemsPerPage}
                  totalItems={items ? items.length : sopSummaries.length}
                  currentPage={currentPage}
                  paginate={paginate}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
