import { PersonnelSummary, FormCategoryPointer } from "./Entities.d";
import React from "react";

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function getFullName(first_name: string, last_name: string) {
  return `${first_name} ${last_name}`;
}

export function getDate(dateString?: string) {
  const date = dateString ? new Date(dateString) : new Date();
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export function getTime(dateString?: string) {
  const date = dateString ? new Date(dateString) : new Date();
  return date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function getInitials(name: string) {
  const nameSplit = name.split(" ");
  return `${nameSplit[0].charAt(0)}${nameSplit[1].charAt(0)}`;
}

export function formOrderContainsError(
  order: { pointer: FormCategoryPointer; error: boolean }[]
) {
  const errors = order.filter((e) => e.error);
  return errors.length > 0;
}

export function getDefaultValueFromDateString(date: string) {
  const dateTime = new Date(date);

  const year = dateTime.getFullYear().toString();
  let month = (dateTime.getMonth() + 1).toString();
  if (parseInt(month) < 10) {
    month = "0" + month;
  }
  let day = dateTime.getDate().toString();
  if (parseInt(day) < 10) {
    day = "0" + day;
  }

  return year + "-" + month + "-" + day;
}

export function formatDateInputValue(date: string) {
  return date.replace(/-/g, "/");
}

export function containsLaboratoryDirector(
  ids: Set<string>,
  personnelSummaries: PersonnelSummary[]
) {
  const personnelRoleNames = new Map<string, string>();
  personnelSummaries.forEach((personnel) =>
    personnelRoleNames.set(personnel.id, personnel.role.name)
  );

  let containsLD = false;
  ids.forEach((id) => {
    containsLD =
      containsLD || personnelRoleNames.get(id) === "Laboratory Director";
  });

  return containsLD;
}

export function getFormattedAmount(amountToFormat: number) {
  // Format price details and detect zero decimal currencies
  let amount = amountToFormat;
  const numberFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    currencyDisplay: "symbol",
  });
  const parts = numberFormat.formatToParts(amount);
  let zeroDecimalCurrency = true;
  for (const part of parts) {
    if (part.type === "decimal") {
      zeroDecimalCurrency = false;
    }
  }
  amount = zeroDecimalCurrency ? amount : amount / 100;
  return numberFormat.format(amount);
}

export function capitalizeEachWord(string: string) {
  const words = string.split(" ");
  const capitalizedWords = words.map((word) => {
    if (word.length === 0) {
      return word;
    }

    return word[0].toUpperCase() + word.substring(1);
  });
  return capitalizedWords.join();
}

export function formatErrorMessage(error: {
  statusCode: number;
  message: string;
  traceID: string;
}) {
  const formattedMessage =
    error.message.charAt(0).toUpperCase() + error.message.slice(1);
  return `Error: ${formattedMessage}. Status Code: ${error.statusCode}. TraceID: ${error.traceID}`;
}
