import { useState } from "react";
import { FormElementAttachment } from "../../common/Entities.d";
import FileUploadComponent from "../../common/FileUploadComponent";

export default function Attachment(props: {
  attachmentInput: FormElementAttachment;
  error: boolean;
  recordPointer: { recordValueArray: string; recordValueIndex: number };
  attachments: Array<File[]>;
  setAttachments: (attachments: Array<File[]>) => void;
}) {
  const initialFiles: File[] = [];
  const [files] = useState(initialFiles);

  props.attachments[props.recordPointer.recordValueIndex] = files;
  props.setAttachments(props.attachments);

  return (
    <div>
      <FileUploadComponent
        label={props.attachmentInput.label}
        fileTypes={"PDF"}
        files={files}
      />
      <p className="mt-2 text-sm text-red-500" hidden={!props.error}>
        * {props.attachmentInput.label} requires at least one file.
      </p>
    </div>
  );
}
