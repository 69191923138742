import { classNames, getDate, getFullName, getTime } from "./Utils";
import {
  ArchiveIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  PencilAltIcon,
  PencilIcon,
  PlusIcon,
  ThumbUpIcon,
  XIcon,
} from "@heroicons/react/solid";
import { useState } from "react";
import { Link } from "react-router-dom";
import { AuditEventDetails } from "./Entities.d";
import { useAuditEvents } from "./DataFetchers";
import { REGULATOR_BOT } from "./Constants.d";

function createAuditEventMessage(
  event: AuditEventDetails,
  fieldDisplayMap: Map<string, string>,
  type: string
) {
  if (
    event.action === "created" ||
    event.action === "archived" ||
    event.action === "approved"
  ) {
    return `${event.action} ${type}`;
  } else if (event.action === "added") {
    return `${event.action} "${event.after}" to ${fieldDisplayMap.get(
      event.field
    )}`;
  } else if (event.action === "deleted") {
    return `${event.action} "${event.before}" from ${fieldDisplayMap.get(
      event.field
    )}`;
  } else if (event.action === "updated" && event.field === "lastCalibration") {
    return `${event.action} ${fieldDisplayMap.get(event.field)} from "${getDate(
      event.before
    )}" to "${getDate(event.after)}"`;
  } else if (event.action === "updated" && event.field === "reportIssued") {
    const before = event.before ? getDate(event.before) : "";
    const after = event.after ? getDate(event.after) : "";
    return `${event.action} ${fieldDisplayMap.get(
      event.field
    )} from "${before}" to "${after}"`;
  } else {
    return `${event.action} ${fieldDisplayMap.get(event.field)} from "${
      event.before
    }" to "${event.after}"`;
  }
}

interface AuditTrailCardProps {
  fieldDisplayMap: Map<string, string>;
  type: string;
  fetchPath: string;
}

export default function AuditTrailCard(props: AuditTrailCardProps) {
  const actionIconMap = new Map([
    ["created", { icon: PencilIcon, background: "bg-green-500" }],
    ["updated", { icon: PencilAltIcon, background: "bg-blue-500" }],
    ["added", { icon: PlusIcon, background: "bg-blue-500" }],
    ["deleted", { icon: XIcon, background: "bg-red-500" }],
    ["archived", { icon: ArchiveIcon, background: "bg-gray-500" }],
    ["approved", { icon: ThumbUpIcon, background: "bg-green-500" }],
  ]);

  const [hideAuditTrail, setHideAuditTrail] = useState(true);
  const { auditEvents } = useAuditEvents(props.fetchPath, !hideAuditTrail);

  return (
    <div className="bg-white mt-3 px-4 rounded-md shadow-sm">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Audit Trail
        </h3>
        <div className="flex justify-end">
          <button
            type="button"
            className="-mt-8 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={() => setHideAuditTrail(!hideAuditTrail)}
          >
            {hideAuditTrail ? (
              <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            ) : (
              <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
            )}
          </button>
        </div>
      </div>
      <div className="flow-root">
        {auditEvents.length === 0 ? (
          <ul className="-mb-4 px-6" hidden={hideAuditTrail}>
            <li key={"no-events"}>
              <div className="relative pb-8">
                <p className="text-sm italic">None</p>
              </div>
            </li>
          </ul>
        ) : (
          <ul className="-mb-4 px-6" hidden={hideAuditTrail}>
            {auditEvents.map((event, eventIdx) => {
              const actionIcon = actionIconMap.get(event.action)!.icon;
              const background = actionIconMap.get(event.action)!.background;

              return (
                <li key={event.id}>
                  <div className="relative pb-8">
                    {eventIdx !== auditEvents.length - 1 ? (
                      <span
                        className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                        aria-hidden="true"
                      />
                    ) : null}
                    <div className="relative flex space-x-3">
                      <div>
                        <span
                          className={classNames(
                            background,
                            "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                          )}
                        >
                          {actionIcon({
                            className: "h-5 w-5 text-white",
                            "aria-hidden": "true",
                          })}
                        </span>
                      </div>
                      <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                        <div>
                          {!event.user?.personnelID ? (
                            <p className="text-sm text-gray-500">
                              <span className="font-medium text-gray-900">
                                {event.user === null
                                  ? REGULATOR_BOT
                                  : getFullName(
                                      event.user.firstName,
                                      event.user.lastName
                                    )}{" "}
                              </span>
                              {createAuditEventMessage(
                                event,
                                props.fieldDisplayMap,
                                props.type
                              )}
                            </p>
                          ) : (
                            <p className="text-sm text-gray-500">
                              <Link
                                to={`/regulator/personnel/${event.user.personnelID}`}
                                target="_blank"
                                rel="noreferrer"
                                className="font-medium text-blue-700"
                              >
                                {getFullName(
                                  event.user.firstName,
                                  event.user.lastName
                                )}{" "}
                              </Link>
                              {createAuditEventMessage(
                                event,
                                props.fieldDisplayMap,
                                props.type
                              )}
                            </p>
                          )}
                        </div>
                        <div className="inline-flex text-right text-sm whitespace-nowrap text-gray-500">
                          <time dateTime={event.occurred}>
                            {`${getDate(event.occurred)} ${getTime(
                              event.occurred
                            )}`}
                          </time>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}
