import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Pagination from "../common/Pagination";
import EmptyStateScreen from "../common/EmptyStateScreen";
import {
  formatDateInputValue,
  getDate,
  getFullName,
  getTime,
} from "../common/Utils";
import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import CustomSelectMenu from "../common/CustomSelectMenu";
import {
  useFormSummaries,
  useRecordSummaries,
  useUserSummaries,
} from "../common/DataFetchers";
import { RecordSummary, UserSummary } from "../common/Entities.d";
import { useUserContext } from "../common/BasePageScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";

const pages = [{ name: "Records", path: "/regulator/records" }];

export default function RecordsList() {
  const navigate = useNavigate();
  const { recordsRead, formsWrite } = useUserContext();

  const { formSummaries } = useFormSummaries("Active", recordsRead);
  const { recordSummaries, recordSummariesIsError, recordSummariesIsLoading } =
    useRecordSummaries(recordsRead);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [items, setItems] = useState<RecordSummary[]>([]);

  useEffect(() => {
    if (recordSummaries.length === 0) {
      return;
    }
    setItems(recordSummaries);
  }, [recordSummaries]);

  const [showFilterBox, setShowFilterBox] = useState(false);

  const typeOptions: { id: string; value: any; display: string }[] = [];
  typeOptions.push({ id: "0", value: "Select", display: "Select" });
  formSummaries.map((f) =>
    typeOptions.push({
      id: typeOptions.length.toString(),
      value: f,
      display: f.title,
    })
  );
  const [filterType, setFilterType] = useState(typeOptions[0]);

  const userStr = (user: UserSummary) =>
    `${user.firstName} ${user.lastName} - ${user.email}`;

  const { userSummaries } = useUserSummaries("Active");
  const [userOptions] = useState<{ id: string; value: any; display: string }[]>(
    []
  );
  const [filterUser, setFilterUser] =
    useState<{ id: string; value: any; display: string }>();
  useEffect(() => {
    userSummaries.forEach((user) => {
      const selectIdx = userOptions.findIndex(
        (option) => option.value === "Select"
      );
      if (selectIdx < 0) {
        userOptions.push({ id: "0", value: "Select", display: "Select" });
      }
      const idx = userOptions.findIndex((option) => option.value === user);
      if (idx < 0) {
        userOptions.push({
          id: userOptions.length.toString(),
          value: user,
          display: userStr(user),
        });
      }
    });
    setFilterUser(userOptions[0]);
  }, [userSummaries]);

  if (!recordsRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (recordSummariesIsLoading) {
    return <LoadingComponent />;
  }

  if (recordSummariesIsError) {
    return <ErrorComponent />;
  }

  const pageEndIndex = currentPage * itemsPerPage;
  const pageStartIndex = pageEndIndex - itemsPerPage;
  const pageRecordSummaries: RecordSummary[] = items
    ? items.slice(pageStartIndex, pageEndIndex)
    : recordSummaries.slice(pageStartIndex, pageEndIndex);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  function clearFilter() {
    (document.getElementById("timeFilter") as HTMLInputElement).value = "";
    (document.getElementById("startDateFilter") as HTMLInputElement).value = "";
    (document.getElementById("endDateFilter") as HTMLInputElement).value = "";
    setFilterType(typeOptions[0]);
    setFilterUser(userOptions[0]);
    setItems(Array.from(recordSummaries));
  }

  function handleFilter(e: React.SyntheticEvent) {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      timeFilter: { value: string };
      startDateFilter: { value: string };
      endDateFilter: { value: string };
    };

    let newItems = recordSummaries;

    const timeFilter = target.timeFilter.value.trim();
    if (timeFilter !== "") {
      newItems = newItems.filter((r) => getTime(r.completed) === timeFilter);
    }
    const startDateFilter = target.startDateFilter.value.trim();
    if (startDateFilter !== "") {
      newItems = newItems.filter(
        (r) =>
          new Date(r.completed) >=
          new Date(formatDateInputValue(startDateFilter))
      );
    }
    const endDateFilter = target.endDateFilter.value.trim();
    if (endDateFilter !== "") {
      newItems = newItems.filter(
        (r) =>
          new Date(r.completed) <= new Date(formatDateInputValue(endDateFilter))
      );
    }
    const typeFilter = filterType.value;
    if (typeFilter !== "Select") {
      newItems = newItems.filter((r) => r.form.title === typeFilter.title);
    }
    const userFilter = filterUser!.value;
    if (userFilter !== "Select") {
      newItems = newItems.filter(
        (r) =>
          getFullName(r.authorUser.firstName, r.authorUser.lastName) ===
          getFullName(userFilter.firstName, userFilter.lastName)
      );
    }

    setItems(Array.from(newItems));
  }

  return (
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="mt-3 py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <FullWidthBreadcrumbBar pages={pages} />
        <div className="bg-white mt-2 p-3 border border-gray-200 rounded-lg shadow-sm">
          <h3 className="ml-1 text-md leading-6 font-medium text-gray-900">
            Filter
          </h3>
          <div className="-mt-6 text-right">
            <button onClick={() => setShowFilterBox(!showFilterBox)}>
              {showFilterBox ? (
                <ChevronUpIcon
                  className="h-6 w-6 text-gray-500"
                  aria-hidden="true"
                />
              ) : (
                <ChevronDownIcon
                  className="h-6 w-6 text-gray-500"
                  aria-hidden="true"
                />
              )}
            </button>
          </div>
          <div hidden={!showFilterBox}>
            <form
              className="grid grid-cols-4 gap-4"
              onSubmit={(e) => handleFilter(e)}
            >
              <div className="ml-1">
                <CustomSelectMenu
                  label={"Form Type"}
                  options={typeOptions}
                  selected={filterType}
                  setSelected={setFilterType}
                />
              </div>
              <div className="ml-1">
                <CustomSelectMenu
                  label={"User"}
                  options={userOptions}
                  selected={filterUser}
                  setSelected={setFilterUser}
                />
              </div>
              <div className="ml-1">
                <label
                  htmlFor="timeFilter"
                  className="mt-2.5 block text-sm font-medium text-gray-700"
                >
                  Time
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="timeFilter"
                    id="timeFilter"
                    className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="ml-1">
                <label
                  htmlFor="startDateFilter"
                  className="mt-2.5 block text-sm font-medium text-gray-700 mb-1"
                >
                  Date
                </label>
                <input
                  type="date"
                  name="startDateFilter"
                  id="startDateFilter"
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
                <p className="text-center">to</p>
                <input
                  type="date"
                  name="endDateFilter"
                  id="endDateFilter"
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <div className="text-right col-end-5 col-span-1">
                <button
                  type="button"
                  onClick={clearFilter}
                  className="m-1 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Clear
                </button>
                <button
                  type="submit"
                  className="m-1 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Apply
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          {recordSummaries.length === 0 ? (
            <EmptyStateScreen
              item={"record"}
              createLink={"/regulator/forms"}
              writeAccess={formsWrite}
            />
          ) : (
            <div>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Form Type
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      User
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Time
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">View</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pageRecordSummaries.map((record, recordIdx) => (
                    <tr
                      key={record.id}
                      className={
                        recordIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                      }
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {record.form.title}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {getFullName(
                          record.authorUser.firstName,
                          record.authorUser.lastName
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {getDate(record.completed)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {getTime(record.completed)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <Link
                          to={`/regulator/records/${record.id}`}
                          className="text-blue-600 hover:text-blue-900"
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={items ? items.length : recordSummaries.length}
                currentPage={currentPage}
                paginate={paginate}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
