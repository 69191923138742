import { Link, useNavigate, useParams } from "react-router-dom";
import { PencilAltIcon } from "@heroicons/react/solid";
import { DocumentDuplicateIcon, UserIcon } from "@heroicons/react/outline";
import { classNames } from "../common/Utils";
import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import {
  personnelRoleAuditPath,
  usePersonnelRole,
} from "../common/DataFetchers";
import React from "react";
import { useUserContext } from "../common/BasePageScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import AuditTrailCard from "../common/AuditTrailCard";

const fieldDisplayMap = new Map([
  ["name", "Role Name"],
  ["jobDescription", "Job Description"],
  ["letterOfDelegation", "Letter of Delegation"],
  ["reports", "Reports"],
  ["reportsTo", "Reports To"],
]);

export default function RoleDetailCard(): JSX.Element {
  const { personnelRead, personnelWrite } = useUserContext();
  const navigate = useNavigate();

  const params = useParams<{ id: string }>();
  const roleID = params.id as string;

  const { role, roleIsLoading, roleIsError } = usePersonnelRole(
    roleID,
    personnelRead
  );

  if (!personnelRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (roleIsLoading) {
    return <LoadingComponent />;
  }

  if (roleIsError || !role) {
    return <ErrorComponent />;
  }

  const pages = [
    { name: "Roles", path: "/regulator/personnel/roles" },
    { name: role.name, path: `/regulator/personnel/roles/${role.id}` },
  ];

  return (
    <div>
      <FullWidthBreadcrumbBar pages={pages} />
      <div className="py-3 pt-5">
        <div className="flex justify-end">
          <Link
            to={`/regulator/personnel/roles/${role.id}/edit`}
            hidden={!personnelWrite}
          >
            <button
              type="button"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <PencilAltIcon
                className="-ml-1 mr-3 h-5 w-5"
                aria-hidden="true"
              />
              Edit
            </button>
          </Link>
        </div>
      </div>
      <div className="bg-white mt-3 shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Role Details
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Details regarding role and associated information
          </p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Role Name</dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium sm:mt-0 sm:col-span-2">
                {role.name}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Job Description
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                  {role.jobDescription === null ? (
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      None
                    </li>
                  ) : (
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      <div className="w-0 flex-1 flex items-center">
                        <DocumentDuplicateIcon
                          className="flex-shrink-0 h-5 w-5 text-blue-400"
                          aria-hidden="true"
                        />
                        <span className="ml-2 flex-1 w-0 truncate">
                          {role.jobDescription.title}
                        </span>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <Link
                          to={`/regulator/sops/${role.jobDescription.id}`}
                          className="font-medium text-blue-600 hover:text-blue-500"
                        >
                          View
                        </Link>
                      </div>
                    </li>
                  )}
                </ul>
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Letter of Delegation
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                  {role.letterOfDelegation === null ? (
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      None
                    </li>
                  ) : (
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      <div className="w-0 flex-1 flex items-center">
                        <DocumentDuplicateIcon
                          className="flex-shrink-0 h-5 w-5 text-blue-400"
                          aria-hidden="true"
                        />
                        <span className="ml-2 flex-1 w-0 truncate">
                          {role.letterOfDelegation.title}
                        </span>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <Link
                          to={`/regulator/sops/${role.letterOfDelegation.id}`}
                          className="font-medium text-blue-600 hover:text-blue-500"
                        >
                          View
                        </Link>
                      </div>
                    </li>
                  )}
                </ul>
              </dd>
            </div>
            {role.name === "Laboratory Director" ? null : (
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Reports To
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {role.reportsTo.length === 0 ? (
                    <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                      <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm italic">
                        None
                      </li>
                    </ul>
                  ) : (
                    <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                      {role.reportsTo.map((reportingRole) => (
                        <li
                          key={reportingRole.id}
                          className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                        >
                          <div className="w-0 flex-1 flex items-center">
                            <UserIcon
                              className="flex-shrink-0 h-5 w-5 text-blue-400"
                              aria-hidden="true"
                            />
                            <span className="ml-2 flex-1 w-0 truncate">
                              {reportingRole.name}
                            </span>
                          </div>
                          <div className="ml-4 flex-shrink-0">
                            <Link
                              to={`/regulator/personnel/roles/${reportingRole.id}`}
                              className="font-medium text-blue-600 hover:text-blue-500"
                            >
                              View
                            </Link>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </dd>
              </div>
            )}
            <div
              className={classNames(
                role.name === "Laboratory Director" ? "bg-white" : "bg-gray-50",
                "px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
              )}
            >
              <dt className="text-sm font-medium text-gray-500">Reports</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {role.reports.length === 0 ? (
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm italic">
                      None
                    </li>
                  </ul>
                ) : (
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    {role.reports.map((report) => (
                      <li
                        key={report.id}
                        className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                      >
                        <div className="w-0 flex-1 flex items-center">
                          <UserIcon
                            className="flex-shrink-0 h-5 w-5 text-blue-400"
                            aria-hidden="true"
                          />
                          <span className="ml-2 flex-1 w-0 truncate">
                            {report.name}
                          </span>
                        </div>
                        <div className="ml-4 flex-shrink-0">
                          <Link
                            to={`/regulator/personnel/roles/${report.id}`}
                            className="font-medium text-blue-600 hover:text-blue-500"
                          >
                            View
                          </Link>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <AuditTrailCard
        fieldDisplayMap={fieldDisplayMap}
        type={"role"}
        fetchPath={personnelRoleAuditPath(roleID)}
      />
    </div>
  );
}
