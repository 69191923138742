import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/solid";
import {
  updateSOPDraftApproval,
  useUserSignature,
} from "../common/DataFetchers";
import { KeyedMutator } from "swr";
import { SOPDetails } from "../common/Entities.d";
import { useUserContext } from "../common/BasePageScreen";
import { Link } from "react-router-dom";
import ErrorBanner from "../common/ErrorBanner";
import { formatErrorMessage } from "../common/Utils";

interface SOPApprovalModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  approvalID: string;
  sopID: string;
  mutateSOP: KeyedMutator<SOPDetails>;
}

export default function SOPApprovalModal(props: SOPApprovalModalProps) {
  const { user } = useUserContext();
  const { userSignature } = useUserSignature(user.id);

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const [pin, setPin] = useState("");
  const [pinError, setPinError] = useState(false);
  const cancelButtonRef = useRef(null);

  function recordApproval() {
    let isValid = true;

    setPinError(false);
    if (pin.trim() === "") {
      setPinError(true);
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    updateSOPDraftApproval(props.approvalID, "Approved", pin, props.sopID)
      .then(() => props.mutateSOP())
      .catch((error) => {
        setErrorBannerText(formatErrorMessage(error));
        setShowErrorBanner(true);
      })
      .finally(() => props.setOpen(false));
  }

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={(e) => {
          setShowErrorBanner(false);
          props.setOpen(e);
        }}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="pb-4">
                <ErrorBanner
                  showError={showErrorBanner}
                  setShowError={setShowErrorBanner}
                  errorText={errorBannerText}
                />
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                  <CheckIcon
                    className="h-6 w-6 text-green-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Approve SOP
                  </Dialog.Title>
                  <div className="mt-2">
                    {userSignature?.encodedSignature === "" ? (
                      <p className="text-sm text-gray-500">
                        You must set up your signature before continuing. You
                        can set up your user signature{" "}
                        <Link
                          to={"/regulator/settings?type=signature"}
                          target="_blank"
                          rel={"noreferrer"}
                          className="text-blue-600 font-medium"
                        >
                          here
                        </Link>
                        .
                      </p>
                    ) : (
                      <>
                        <p className="text-sm text-gray-500">
                          By entering your signature PIN you are approving this
                          procedure and providing your E-Signature.
                        </p>
                        <div className="mt-2">
                          <label
                            htmlFor="signaturePin"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Signature Pin
                          </label>
                          <div className="mt-1">
                            <input
                              type="password"
                              name="signaturePin"
                              id="signaturePin"
                              value={pin}
                              onChange={(e) => setPin(e.currentTarget.value)}
                              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                            <p
                              className="mt-2 text-sm text-red-500"
                              hidden={!pinError}
                            >
                              * Signature Pin is required. If you have not set
                              up your E-Signature do so now.
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:w-auto sm:text-sm"
                  onClick={() => recordApproval()}
                >
                  Approve
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    setShowErrorBanner(false);
                    props.setOpen(false);
                  }}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
