import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  updateWorkflowTemplateArchive,
  useWorkflowTemplate,
  workflowTemplateAuditPath,
} from "../common/DataFetchers";
import { useUserContext } from "../common/BasePageScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import React from "react";
import AuditTrailCard from "../common/AuditTrailCard";
import {
  AcademicCapIcon,
  FingerPrintIcon,
  ArchiveIcon,
  BeakerIcon,
  CogIcon,
  PencilAltIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/outline";

export default function WorkflowTemplateDetailsCard() {
  const { workflowTemplatesRead, workflowTemplatesWrite } = useUserContext();
  const navigate = useNavigate();

  const params = useParams<{ id: string }>();
  const workflowTemplateID = params.id as string;

  const {
    workflowTemplate,
    workflowTemplateIsLoading,
    workflowTemplateIsError,
    mutateWorkflowTemplate,
  } = useWorkflowTemplate(workflowTemplateID, workflowTemplatesRead);

  if (!workflowTemplatesRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (workflowTemplateIsLoading) {
    return <LoadingComponent />;
  }

  if (workflowTemplateIsError || !workflowTemplate) {
    return <ErrorComponent />;
  }

  const pages = [
    { name: "Workflow Templates", path: "/regulator/workflows/templates" },
    {
      name: workflowTemplate.name,
      path: `/regulator/workflows/templates/${workflowTemplateID}`,
    },
  ];

  return (
    <div>
      <FullWidthBreadcrumbBar pages={pages} />
      <div className="py-3 pt-5">
        <div className="flex justify-end">
          <Link
            to={`/regulator/workflows/templates/${workflowTemplate.id}/edit`}
            hidden={
              !workflowTemplatesWrite || workflowTemplate.status === "Archived"
            }
          >
            <button
              type="button"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <PencilAltIcon
                className="-ml-1 mr-3 h-5 w-5"
                aria-hidden="true"
              />
              Edit
            </button>
          </Link>
          <div hidden={workflowTemplate.status === "Archived"}>
            <button
              type="button"
              onClick={() =>
                mutateWorkflowTemplate(
                  updateWorkflowTemplateArchive(workflowTemplateID)
                )
              }
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <ArchiveIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              Archive
            </button>
          </div>
        </div>
      </div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Workflow Template Information
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Details regarding workflow template
          </p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Workflow Template Name
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {workflowTemplate.name}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Status</dt>
              <dd
                className={
                  workflowTemplate.status === "Active"
                    ? "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 w-14"
                    : "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800 w-min"
                }
              >
                {workflowTemplate.status}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Version</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {`${workflowTemplate.majorVersion}.${workflowTemplate.minorVersion}`}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">SOP</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                  <li
                    key={workflowTemplate.sop.id}
                    className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                  >
                    <div className="w-0 flex-1 flex items-center">
                      <DocumentDuplicateIcon
                        className="flex-shrink-0 h-5 w-5 text-blue-500"
                        aria-hidden="true"
                      />
                      <span className="ml-2 flex-1 w-0 truncate">
                        {`${workflowTemplate.sop.displayID} - ${workflowTemplate.sop.title} v${workflowTemplate.sop.majorVersion}.${workflowTemplate.sop.minorVersion}`}
                      </span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <Link
                        to={`/regulator/sops/${workflowTemplate.sop.id}`}
                        className="font-medium text-blue-600 hover:text-blue-500"
                      >
                        View
                      </Link>
                    </div>
                  </li>
                </ul>
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Competency Type
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                  <li
                    key={workflowTemplate.competencyType.id}
                    className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                  >
                    <div className="w-0 flex-1 flex items-center">
                      <AcademicCapIcon
                        className="flex-shrink-0 h-5 w-5 text-blue-500"
                        aria-hidden="true"
                      />
                      <span className="ml-2 flex-1 w-0 truncate">
                        {workflowTemplate.competencyType.name}
                      </span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <Link
                        to={`/regulator/competency/type/${workflowTemplate.competencyType.id}`}
                        className="font-medium text-blue-600 hover:text-blue-500"
                      >
                        View
                      </Link>
                    </div>
                  </li>
                </ul>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Equipment Types
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                  {workflowTemplate.equipmentTypes.map((equipmentType) => (
                    <li
                      key={equipmentType.id}
                      className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                    >
                      <div className="w-0 flex-1 flex items-center">
                        <CogIcon
                          className="flex-shrink-0 h-5 w-5 text-blue-500"
                          aria-hidden="true"
                        />
                        <span className="ml-2 flex-1 w-0 truncate">
                          {equipmentType.name}
                        </span>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <Link
                          to={`/regulator/equipment/types/${equipmentType.id}`}
                          className="font-medium text-blue-600 hover:text-blue-500"
                        >
                          View
                        </Link>
                      </div>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Reagent Types
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                  {workflowTemplate.reagentTypes.map((reagentType) => (
                    <li
                      key={reagentType.id}
                      className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                    >
                      <div className="w-0 flex-1 flex items-center">
                        <BeakerIcon
                          className="flex-shrink-0 h-5 w-5 text-blue-500"
                          aria-hidden="true"
                        />
                        <span className="ml-2 flex-1 w-0 truncate">
                          {reagentType.name}
                        </span>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <Link
                          to={`/regulator/reagents/types/${reagentType.id}`}
                          className="font-medium text-blue-600 hover:text-blue-500"
                        >
                          View
                        </Link>
                      </div>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Sample Types
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                  {workflowTemplate.sampleTypes.map((sampleType) => (
                    <li
                      key={sampleType.id}
                      className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                    >
                      <div className="w-0 flex-1 flex items-center">
                        <FingerPrintIcon
                          className="flex-shrink-0 h-5 w-5 text-blue-500"
                          aria-hidden="true"
                        />
                        <span className="ml-2 flex-1 w-0 truncate">
                          {sampleType.name}
                        </span>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <Link
                          to={`/regulator/samples/types/${sampleType.id}`}
                          className="font-medium text-blue-600 hover:text-blue-500"
                        >
                          View
                        </Link>
                      </div>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <AuditTrailCard
        fieldDisplayMap={new Map()}
        type={`${workflowTemplate.name} v${workflowTemplate.majorVersion}.${workflowTemplate.minorVersion}`}
        fetchPath={workflowTemplateAuditPath(workflowTemplateID)}
      />
    </div>
  );
}
