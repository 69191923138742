import { useUserContext } from "../common/BasePageScreen";
import { Link, useSearchParams } from "react-router-dom";
import {
  useCompetencySummaries,
  usePersonnelSummaries,
} from "../common/DataFetchers";
import React, { useEffect, useState } from "react";
import { CompetencyStatus, CompetencySummary } from "../common/Entities.d";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import EmptyStateScreen from "../common/EmptyStateScreen";
import { classNames, getFullName } from "../common/Utils";
import Pagination from "../common/Pagination";
import CustomSelectMenu from "../common/CustomSelectMenu";

const statusOptions = [
  { id: "0", value: "Select", display: "Select" },
  { id: "1", value: "Pending", display: "Pending" },
  { id: "2", value: "In Review", display: "In Review" },
];

const versionOptions = [
  { id: "3", value: -1, display: "Select" },
  { id: "0", value: 0, display: "Initial" },
  { id: "1", value: 1, display: "6-Month" },
  { id: "2", value: 2, display: "Annual" },
];

export default function PendingList() {
  const { competenciesRead, competenciesWrite } = useUserContext();

  const [searchParams] = useSearchParams();
  const typeID = searchParams.get("typeID");

  const {
    competencySummaries,
    competencySummariesIsLoading,
    competencySummariesIsError,
  } = useCompetencySummaries("Pending", typeID, competenciesRead);

  const { personnelSummaries } = usePersonnelSummaries(competenciesRead);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [items, setItems] = useState<CompetencySummary[]>([]);
  useEffect(() => {
    if (competencySummaries.length === 0) {
      return;
    }
    setItems(competencySummaries);
  }, [competencySummaries]);

  const [showFilterBox, setShowFilterBox] = useState(false);
  const [personnelOptions, setPersonnelOptions] = useState<
    { id: string; value: any; display: string }[]
  >([]);
  useEffect(() => {
    if (personnelSummaries.length === 0) {
      return;
    }
    const newOptions: { id: string; value: any; display: string }[] = [
      { id: "0", value: "Select", display: "Select" },
    ];
    personnelSummaries.map((personnel) => {
      newOptions.push({
        id: personnel.id,
        value: personnel,
        display: getFullName(personnel.firstName, personnel.lastName),
      });
    });
    setPersonnelOptions(newOptions);
    setFilterPersonnel(newOptions[0]);
  }, [personnelSummaries]);
  const [filterPersonnel, setFilterPersonnel] = useState(personnelOptions[0]);
  const [filterVersion, setFilterVersion] = useState(versionOptions[0]);
  const [filterStatus, setFilterStatus] = useState(statusOptions[0]);

  const pageEndIndex = currentPage * itemsPerPage;
  const pageStartIndex = pageEndIndex - itemsPerPage;
  const pageCompetencySummaries = items.slice(pageStartIndex, pageEndIndex);

  if (competencySummariesIsLoading) {
    return <LoadingComponent />;
  }

  if (competencySummariesIsError) {
    return <ErrorComponent />;
  }

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  function clearFilter() {
    setFilterPersonnel(personnelOptions[0]);
    setFilterVersion(versionOptions[0]);
    setFilterStatus(statusOptions[0]);
    setItems(Array.from(competencySummaries));
  }

  function handleFilter(e: React.SyntheticEvent) {
    e.preventDefault();

    let newItems = competencySummaries;

    if (filterPersonnel.value !== "Select") {
      newItems = newItems.filter(
        (i) => i.personnel.id === filterPersonnel.value.id
      );
    }

    if (filterStatus.value !== "Select") {
      newItems = newItems.filter(
        (i) => i.status === (filterStatus.value as CompetencyStatus)
      );
    }

    const versionFilter = filterVersion.value;
    if (versionFilter >= 0) {
      if (versionFilter === 0) {
        newItems = newItems.filter((i) => i.version === 0);
      } else if (versionFilter === 1) {
        newItems = newItems.filter((i) => i.version === 1);
      } else {
        newItems = newItems.filter((i) => i.version > 1);
      }
    }

    setItems(Array.from(newItems));
  }

  return (
    <div>
      <div className="bg-white p-3 border border-gray-200 rounded-lg shadow-sm">
        <h3 className="ml-1 text-md leading-6 font-medium text-gray-900">
          Filter
        </h3>
        <div className="-mt-6 text-right">
          <button onClick={() => setShowFilterBox(!showFilterBox)}>
            {showFilterBox ? (
              <ChevronUpIcon
                className="h-6 w-6 text-gray-500"
                aria-hidden="true"
              />
            ) : (
              <ChevronDownIcon
                className="h-6 w-6 text-gray-500"
                aria-hidden="true"
              />
            )}
          </button>
        </div>
        <div hidden={!showFilterBox}>
          <form
            className="grid grid-cols-3 gap-4"
            onSubmit={(e) => handleFilter(e)}
          >
            <div className="ml-1">
              <CustomSelectMenu
                label={"Personnel"}
                options={personnelOptions}
                selected={filterPersonnel}
                setSelected={setFilterPersonnel}
              />
            </div>
            <div className="ml-1">
              <CustomSelectMenu
                label={"Version"}
                options={versionOptions}
                selected={filterVersion}
                setSelected={setFilterVersion}
              />
            </div>
            <div className="ml-1">
              <CustomSelectMenu
                label={"Status"}
                options={statusOptions}
                selected={filterStatus}
                setSelected={setFilterStatus}
              />
            </div>
            <div className="text-right col-end-4 col-span-1">
              <button
                type="button"
                onClick={clearFilter}
                className="m-1 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Clear
              </button>
              <button
                type="submit"
                className="m-1 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Apply
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            {competencySummaries.length === 0 ? (
              <EmptyStateScreen
                item={"competency"}
                createLink={`/regulator/competency/add?typeID=${typeID}`}
                writeAccess={competenciesWrite}
              />
            ) : (
              <div>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Personnel
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Version
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Status
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">View</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageCompetencySummaries.map(
                      (competency, competencyIdx) => (
                        <tr
                          key={competency.id}
                          className={
                            competencyIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                          }
                        >
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {getFullName(
                              competency.personnel.firstName,
                              competency.personnel.lastName
                            )}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {competency.version === 0 ? (
                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                Initial
                              </span>
                            ) : competency.version === 1 ? (
                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                                6-Month
                              </span>
                            ) : (
                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                Annual
                              </span>
                            )}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            <span
                              className={classNames(
                                competency.status === "Pending"
                                  ? "bg-cyan-100 text-cyan-800"
                                  : "bg-purple-100 text-purple-800",
                                "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium"
                              )}
                            >
                              {competency.status}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <Link
                              to={`/regulator/competency/${competency.id}`}
                              className="text-blue-600 hover:text-blue-900"
                            >
                              View
                            </Link>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
                <Pagination
                  itemsPerPage={itemsPerPage}
                  totalItems={items ? items.length : competencySummaries.length}
                  currentPage={currentPage}
                  paginate={paginate}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
