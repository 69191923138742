import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  logout,
  updateUserInvitation,
  useUserInvitation,
} from "./common/DataFetchers";
import Footer from "./common/Footer";
import LoadingComponent from "./common/LoadingComponent";
import ErrorComponent from "./common/ErrorComponent";
import LinkNotFoundComponent from "./LinkNotFoundComponent";
import { formatErrorMessage } from "./common/Utils";
import ErrorBanner from "./common/ErrorBanner";

export default function ActivateUserForm() {
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const [searchParams] = useSearchParams();
  const inviteID = searchParams.get("id");

  const { userInvitation, userInvitationIsLoading, userInvitationIsError } =
    useUserInvitation(inviteID!);

  if (userInvitationIsLoading) {
    return <LoadingComponent />;
  }

  if (userInvitationIsError || !userInvitation) {
    return <ErrorComponent />;
  }

  if (new Date(userInvitation.expiration).getTime() <= new Date().getTime()) {
    return <LinkNotFoundComponent />;
  }

  return (
    <div>
      <div className="relative bg-white pt-16 px-4 pb-56 sm:px-6 lg:pt-24 lg:px-8">
        <ErrorBanner
          showError={showErrorBanner}
          setShowError={setShowErrorBanner}
          errorText={errorBannerText}
        />
        <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full space-y-8">
            <div>
              <img
                className="mx-auto -mt-48 -mb-48"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/dl_regulator_logo-full_color-black.svg"
                }
                alt="Regulator"
              />

              <h2 className="mt-12 sm:mt-6 text-center font-semibold text-blue-600">
                Activate your account
              </h2>
              <p className="mt-2 text-center text-sm text-gray-600">
                Enter and confirm your password below to finish activating your
                account.
              </p>
            </div>
            <form
              className="mt-8 space-y-6"
              onSubmit={(e: React.SyntheticEvent) => {
                e.preventDefault();

                setShowErrorBanner(false);

                const target = e.target as typeof e.target & {
                  password: { value: string };
                  "confirm-password": { value: string };
                };

                const password = target.password.value.trim();
                const confirmPassword = target["confirm-password"].value.trim();

                let isValid = true;

                setPasswordError(false);
                if (
                  password === "" ||
                  password.length < 8 ||
                  password.length > 64
                ) {
                  isValid = false;
                  setPasswordError(true);
                }

                setConfirmPasswordError(false);
                if (password !== confirmPassword) {
                  isValid = false;
                  setConfirmPasswordError(true);
                }

                if (!isValid) {
                  return;
                }

                updateUserInvitation(inviteID!, { password: password })
                  .then(() => {
                    logout();
                    window.location.assign(
                      `${window.location.origin}/regulator/login`
                    );
                  })
                  .catch((error) => {
                    setErrorBannerText(formatErrorMessage(error));
                    setShowErrorBanner(true);
                  });
              }}
            >
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    className={
                      "appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                    }
                  />
                  <p className="text-sm text-red-500" hidden={!passwordError}>
                    * Password length must be 8-64 characters.
                  </p>
                </div>
                <div>
                  <label
                    htmlFor="confirm-password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Confirm Password
                  </label>
                  <input
                    id="confirm-password"
                    name="confirm-password"
                    type="password"
                    className={
                      "appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                    }
                  />
                  <p
                    className="text-sm text-red-500"
                    hidden={!confirmPasswordError}
                  >
                    * Passwords do not match.
                  </p>
                </div>
                <div>
                  <button
                    type="submit"
                    className="mt-2 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Activate
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 w-full">
        <Footer />
      </div>
    </div>
  );
}
