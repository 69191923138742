import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  ArrowLeftIcon,
  CalendarIcon,
  PencilAltIcon,
  ThumbUpIcon,
} from "@heroicons/react/solid";
import { useUserContext } from "../common/BasePageScreen";
import {
  formatURL,
  updateProcedureManualReview,
  usePersonnelSummaries,
  useSOP,
  useSOPFile,
} from "../common/DataFetchers";
import PDFViewer from "../common/PDFViewer";
import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import SOPAuditTrailCard from "./SOPAuditTrailCard";
import ErrorBanner from "../common/ErrorBanner";
import { formatErrorMessage } from "../common/Utils";

export default function AnnualProcedureManualReviewForm() {
  const { user, sopsRead } = useUserContext();
  const navigate = useNavigate();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const params = useParams<{ id: string }>();
  const sopID = params.id;

  const { sop, sopIsLoading, sopIsError, mutateSOP } = useSOP(sopID, sopsRead);
  const { sopFile } = useSOPFile(sopID, sopsRead);
  const { personnelSummaries } = usePersonnelSummaries(sopsRead);

  const [isLabDirector, setIsLabDirector] = useState(false);

  useEffect(() => {
    const personnelIndex = personnelSummaries.findIndex(
      (personnel) => personnel.userID === user.id
    );

    if (personnelIndex === -1) {
      return;
    }

    const role = personnelSummaries[personnelIndex].role;
    setIsLabDirector(role.name === "Laboratory Director");
  }, [user, personnelSummaries]);

  if (!sopsRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (sopIsLoading) {
    return <LoadingComponent />;
  }

  if (sopIsError || !sop) {
    return <ErrorComponent />;
  }

  if (sop.state === "Draft") {
    navigate(`/regulator/sops/${sopID}/draft-review`, { replace: true });
    return <></>;
  }

  if (sop.state === "Archived" || !isLabDirector) {
    navigate(`/regulator/sops/${sopID}`, { replace: true });
    return <></>;
  }

  const pages = [
    { name: "SOPs", path: "/regulator/sops" },
    { name: sop.displayID, path: location.pathname },
  ];

  return (
    <div className="space-y-8">
      <ErrorBanner
        showError={showErrorBanner}
        setShowError={setShowErrorBanner}
        errorText={errorBannerText}
      />
      <FullWidthBreadcrumbBar pages={pages} />
      <div className="pt-1">
        <div className="flex justify-end">
          <Link to={`/regulator/sops/${sop.id}`}>
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-blue-600 shadow-sm text-base font-medium rounded-md text-blue-600 bg-white hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <ArrowLeftIcon
                className="-ml-1 mr-3 h-5 w-5"
                aria-hidden="true"
              />
              Cancel
            </button>
          </Link>
          <span className="px-1" />
          <button
            type="button"
            onClick={() =>
              mutateSOP(updateProcedureManualReview(sop.id, "0000"), false)
                .then(() => navigate(`/regulator/sops/${sop.id}`))
                .catch((error) => {
                  setErrorBannerText(formatErrorMessage(error));
                  setShowErrorBanner(true);
                })
            }
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <ThumbUpIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
            Approve
          </button>
          <span className="px-1" />
          <Link to={`/regulator/sops/${sop.id}/edit`}>
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <PencilAltIcon
                className="-ml-1 mr-3 h-5 w-5"
                aria-hidden="true"
              />
              Update
            </button>
          </Link>
        </div>
      </div>
      <PDFViewer fileUrl={formatURL(`/sops/${sopID}/file`)} file={sopFile} />
      <SOPAuditTrailCard sop={sop} />
    </div>
  );
}
