import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CheckCircleIcon,
  CheckIcon,
  DocumentAddIcon,
  DotsCircleHorizontalIcon,
  MailIcon,
  PencilAltIcon,
  TrashIcon,
  XCircleIcon,
  XIcon,
} from "@heroicons/react/solid";
import CommentsSection from "../common/CommentsSection";
import { getDate } from "../common/Utils";
import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import {
  formatURL,
  updateSOPDraftReview,
  useSOP,
  useSOPDraftApprovals,
  useSOPFile,
} from "../common/DataFetchers";
import { useUserContext } from "../common/BasePageScreen";
import PDFViewer from "../common/PDFViewer";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import SOPDiscardModal from "./SOPDiscardModal";
import { useState } from "react";
import SOPPublishModal from "./SOPPublishModal";
import SOPApprovalModal from "./SOPApprovalModal";
import SOPRejectionModal from "./SOPRejectionModal";

export default function DraftViewer() {
  const { user, sopsRead, sopsWrite } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();

  const params = useParams<{ id: string }>();
  const sopID = params.id;

  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  const [publishModalOpen, setPublishModalOpen] = useState(false);

  const [approvalModalOpen, setApprovalModalOpen] = useState(false);
  const [rejectionModalOpen, setRejectionModalOpen] = useState(false);

  const { sop, mutateSOP, sopIsLoading, sopIsError } = useSOP(sopID, sopsRead);
  const { sopFile } = useSOPFile(sopID, sopsRead);
  const { approvals, mutateApprovals } = useSOPDraftApprovals(
    sop?.id,
    sopsRead
  );

  if (!sopsRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (sopIsLoading) {
    return <LoadingComponent />;
  }

  if (sopIsError || !sop) {
    return <ErrorComponent />;
  }

  if (sop.state !== "Draft") {
    navigate(`/regulator/sops/${sopID}`, { replace: true });
    return <></>;
  }

  const pages = [
    { name: "SOPs", path: "/regulator/sops" },
    { name: sop.displayID, path: location.pathname },
  ];

  const isSOPOwner = sop.creatorUserID === user.id;
  const canEdit =
    (sop.status === "Drafting" || sop.status === "In Review") &&
    sopsWrite &&
    isSOPOwner;
  const canSendForReview = sop.status === "Drafting" && sopsWrite && isSOPOwner;
  const canPublish = sop.status === "Approved" && sopsWrite && isSOPOwner;
  const canDiscard = sopsWrite && isSOPOwner;

  return (
    <div>
      <FullWidthBreadcrumbBar pages={pages} />
      <div className="py-3">
        <div className="flex justify-end">
          {canEdit && (
            <Link to={`/regulator/sops/${sopID}/draft-edit`}>
              <button
                type="button"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <PencilAltIcon
                  className="-ml-1 mr-3 h-5 w-5"
                  aria-hidden="true"
                />
                Update
              </button>
            </Link>
          )}
          {canSendForReview && (
            <button
              type="button"
              onClick={() =>
                mutateSOP(updateSOPDraftReview(sop.id), false)
                  .then(() => mutateApprovals())
                  .catch((error) => console.log(error))
              }
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <MailIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              Send for Review
            </button>
          )}
          {canPublish && (
            <button
              type="button"
              onClick={() => setPublishModalOpen(true)}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <DocumentAddIcon
                className="-ml-1 mr-3 h-5 w-5"
                aria-hidden="true"
              />
              Publish SOP
              <SOPPublishModal
                open={publishModalOpen}
                setOpen={setPublishModalOpen}
                sop={sop}
                mutateSOP={mutateSOP}
              />
            </button>
          )}
          {canDiscard && (
            <button
              type="button"
              onClick={() => setDiscardModalOpen(true)}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <TrashIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              Discard
              <SOPDiscardModal
                open={discardModalOpen}
                setOpen={setDiscardModalOpen}
                sop={sop}
                mutateSOP={mutateSOP}
              />
            </button>
          )}
        </div>
      </div>
      <PDFViewer fileUrl={formatURL(`/sops/${sopID}/file`)} file={sopFile} />
      <div className="mt-2 bg-white shadow overflow-hidden sm:rounded-md">
        <ul role="list" className="divide-y divide-gray-200">
          {approvals.map((approval) => (
            <li key={approval.id}>
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="min-w-0 flex-1 flex items-center">
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div>
                      <Link
                        to={`/regulator/personnel/${approval.personnel.id}`}
                      >
                        <p className="text-sm font-medium text-blue-600 truncate">
                          {`${approval.personnel.firstName} ${approval.personnel.lastName}`.trim()}
                        </p>
                      </Link>
                      <Link
                        to={`/regulator/personnel/roles/${approval.personnel.role.id}`}
                      >
                        <p className="mt-2 flex items-center text-sm text-gray-500">
                          <span className="truncate">
                            {approval.personnel.role.name}
                          </span>
                        </p>
                      </Link>
                    </div>
                    <div className="hidden md:block">
                      <div>
                        {approval.reviewSent ? (
                          <p className="text-sm text-gray-900">
                            Sent for review on{" "}
                            <time dateTime={"2021-10-29"}>
                              {getDate(approval.created)}
                            </time>
                          </p>
                        ) : (
                          <p className="text-sm text-gray-900">
                            Review not yet sent
                          </p>
                        )}

                        <p className="mt-2 flex items-center text-sm text-gray-500">
                          {approval.status === "Pending" ? (
                            <DotsCircleHorizontalIcon
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-blue-400"
                              aria-hidden="true"
                            />
                          ) : approval.status === "Approved" ? (
                            <CheckCircleIcon
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                              aria-hidden="true"
                            />
                          ) : (
                            <XCircleIcon
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
                              aria-hidden="true"
                            />
                          )}
                          {approval.status}
                        </p>
                        {sop.status === "In Review" &&
                        approval.personnel.userID === user.id &&
                        approval.status === "Pending" ? (
                          <div className="flex justify-end -mt-10">
                            <button
                              type="button"
                              onClick={() => setApprovalModalOpen(true)}
                              className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                              <SOPApprovalModal
                                open={approvalModalOpen}
                                setOpen={setApprovalModalOpen}
                                approvalID={approval.id}
                                sopID={sop.id}
                                mutateSOP={mutateSOP}
                              />
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </button>
                            <button
                              type="button"
                              onClick={() => setRejectionModalOpen(true)}
                              className="ml-2 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                              <SOPRejectionModal
                                open={rejectionModalOpen}
                                setOpen={setRejectionModalOpen}
                                approvalID={approval.id}
                                sopID={sop.id}
                                mutateSOP={mutateSOP}
                              />
                              <XIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {sop.status !== "Drafting" && <CommentsSection />}
    </div>
  );
}
