import { PaperClipIcon, PencilAltIcon } from "@heroicons/react/solid";
import { Link, useNavigate, useParams } from "react-router-dom";
import { classNames, getDate } from "../common/Utils";
import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import {
  personnelAttachmentDownloadLink,
  personnelAuditPath,
  usePersonnel,
} from "../common/DataFetchers";
import React from "react";
import { PersonnelAttachmentDetails } from "../common/Entities.d";
import { useUserContext } from "../common/BasePageScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import AuditTrailCard from "../common/AuditTrailCard";
import { AcademicCapIcon, DocumentTextIcon } from "@heroicons/react/outline";

const attachmentBadgeMap = new Map([
  ["diploma", { display: "Diploma", color: "blue" }],
  ["license", { display: "License", color: "purple" }],
  ["resume", { display: "Resume", color: "gray" }],
  ["cv", { display: "CV", color: "yellow" }],
  ["continuingEducation", { display: "Continuing Education", color: "green" }],
]);
const fieldDisplayMap = new Map([["role", "Role"]]);

export default function PersonnelDetailCard(): JSX.Element {
  const { personnelRead, personnelWrite } = useUserContext();
  const navigate = useNavigate();

  const params = useParams<{ id: string }>();
  const personnelID = params.id as string;

  const { personnel, personnelIsLoading, personnelIsError } = usePersonnel(
    personnelID,
    personnelRead
  );

  if (!personnelRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (personnelIsLoading) {
    return <LoadingComponent />;
  }

  if (personnelIsError || !personnel) {
    return <ErrorComponent />;
  }

  const pages = [
    { name: "Personnel", path: "/regulator/personnel" },
    {
      name: `${personnel.firstName} ${personnel.lastName}`,
      path: `/regulator/personnel/${personnel.id}`,
    },
  ];

  const createAttachmentSection = (
    sectionName: string,
    attachments: PersonnelAttachmentDetails[]
  ) => {
    const activeAttachments = attachments.filter((a) => !a.archived);
    const archivedAttachments = attachments.filter((a) => a.archived);

    return (
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">{sectionName}</dt>
        <dd className="mt-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <p className="ml-1 text-sm">Active</p>
          {activeAttachments.length === 0 ? (
            <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
              <li
                key={"no-active-attachments"}
                className="pl-3 pr-4 py-3 flex items-center justify-between text-sm italic"
              >
                None
              </li>
            </ul>
          ) : (
            <div>
              {activeAttachments.map((attachment) => (
                <ul
                  key={attachment.id}
                  className="border border-gray-200 rounded-md divide-y divide-gray-200"
                >
                  <li
                    key={attachment.id}
                    className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                  >
                    <div className="w-0 flex-1 flex items-center">
                      <PaperClipIcon
                        className="flex-shrink-0 h-5 w-5 text-blue-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 flex-1 w-0 truncate">
                        {attachment.fileName}
                      </span>
                      <span
                        className={classNames(
                          `bg-${
                            attachmentBadgeMap.get(attachment.type)!.color
                          }-100`,
                          `text-${
                            attachmentBadgeMap.get(attachment.type)!.color
                          }-800`,
                          "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium"
                        )}
                      >
                        {attachmentBadgeMap.get(attachment.type)!.display}
                      </span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <a
                        download={attachment.fileName}
                        href={personnelAttachmentDownloadLink(attachment.id)}
                        className="font-medium text-blue-600 hover:text-blue-500"
                      >
                        Download
                      </a>
                    </div>
                  </li>
                </ul>
              ))}
            </div>
          )}
        </dd>
        <dd className="mt-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <p className="ml-1 text-sm">Archived</p>
          {archivedAttachments.length === 0 ? (
            <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
              <li
                key={"no-archived-attachments"}
                className="pl-3 pr-4 py-3 flex items-center justify-between text-sm italic"
              >
                None
              </li>
            </ul>
          ) : (
            <div>
              {archivedAttachments.map((attachment) => (
                <ul
                  key={attachment.id}
                  className="border border-gray-200 rounded-md divide-y divide-gray-200"
                >
                  <li
                    key={attachment.id}
                    className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                  >
                    <div className="w-0 flex-1 flex items-center">
                      <PaperClipIcon
                        className="flex-shrink-0 h-5 w-5 text-blue-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 flex-1 w-0 truncate">
                        {attachment.fileName}
                      </span>
                      <span
                        className={classNames(
                          `bg-${
                            attachmentBadgeMap.get(attachment.type)!.color
                          }-100`,
                          `text-${
                            attachmentBadgeMap.get(attachment.type)!.color
                          }-800`,
                          "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium"
                        )}
                      >
                        {attachmentBadgeMap.get(attachment.type)!.display}
                      </span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <a
                        download={attachment.fileName}
                        href={personnelAttachmentDownloadLink(attachment.id)}
                        className="font-medium text-blue-600 hover:text-blue-500"
                      >
                        Download
                      </a>
                    </div>
                  </li>
                </ul>
              ))}
            </div>
          )}
        </dd>
      </div>
    );
  };

  return (
    <div className="flex flex-col">
      <FullWidthBreadcrumbBar pages={pages} />
      <div className="py-3 px-5">
        <div className="flex justify-end">
          <Link
            to={`/regulator/personnel/${personnel.id}/edit`}
            hidden={!personnelWrite}
          >
            <button
              type="button"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <PencilAltIcon
                className="-ml-1 mr-3 h-5 w-5"
                aria-hidden="true"
              />
              Edit
            </button>
          </Link>
        </div>
      </div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Personnel Information
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Personnel details and documentation.
          </p>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">First name</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {personnel.firstName}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Last name</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {personnel.lastName}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Hire Date</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {getDate(personnel.hired)}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Status</dt>
              <dd
                className={
                  personnel.status === "Active"
                    ? "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 w-min"
                    : "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800 w-min"
                }
              >
                {personnel.status}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Role</dt>
              <Link to={`/regulator/personnel_roles/${personnel.role.id}`}>
                <dd className="mt-1 text-sm font-medium text-blue-600">
                  {personnel.role.name}
                </dd>
              </Link>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Reports To</dt>
              {personnel.role.reportsTo.length === 0 ? (
                <dd className="mt-1 text-sm text-gray-900 italic">None</dd>
              ) : (
                <div>
                  {personnel.role.reportsTo.map((role) => (
                    <dd
                      key={role.id}
                      className="px-2 inline-flex text-xs text-center leading-5 font-semibold rounded-full bg-blue-100 text-blue-800"
                    >
                      {role.name}
                    </dd>
                  ))}
                </div>
              )}
            </div>
            <div className="sm:col-span-1 sm:col-start-2">
              <dt className="text-sm font-medium text-gray-500">Reports</dt>
              {personnel.role.reports.length === 0 ? (
                <dd className="mt-1 text-sm text-gray-900 italic">None</dd>
              ) : (
                <div>
                  {personnel.role.reports.map((role) => (
                    <dd
                      key={role.id}
                      className="px-2 inline-flex text-xs text-center leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800"
                    >
                      {role.name}
                    </dd>
                  ))}
                </div>
              )}
            </div>
            {createAttachmentSection(
              "Attachments",
              personnel.attachments.sort((a, b) => {
                if (a.type < b.type) {
                  return -1;
                }
                if (a.type > b.type) {
                  return 1;
                }
                return 0;
              })
            )}
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">
                Training & Competency
              </dt>
              <dd className="mt-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {personnel.latestCompetencies.length === 0 ? (
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    <li
                      key={"no-competencies"}
                      className="pl-3 pr-4 py-3 flex items-center justify-between text-sm italic"
                    >
                      None
                    </li>
                  </ul>
                ) : (
                  <div>
                    {personnel.latestCompetencies.map((competency) => (
                      <ul
                        key={competency.id}
                        className="border border-gray-200 rounded-md divide-y divide-gray-200"
                      >
                        <li
                          key={competency.id}
                          className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                        >
                          <div className="w-0 flex-1 flex items-center">
                            <AcademicCapIcon
                              className="flex-shrink-0 h-5 w-5 text-blue-400"
                              aria-hidden="true"
                            />
                            <span className="ml-2 flex-1 w-0 truncate">
                              {competency.typeName}
                              {competency.version === 0 ? (
                                <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                  Initial
                                </span>
                              ) : competency.version === 1 ? (
                                <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                                  6-Month
                                </span>
                              ) : (
                                <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                  Annual
                                </span>
                              )}
                            </span>
                            {competency.expiration ? (
                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium">
                                {`Expiration: ${getDate(
                                  competency.expiration
                                )}`}
                              </span>
                            ) : null}
                          </div>
                          <div className="ml-4 flex-shrink-0">
                            <Link
                              to={`/regulator/competency/${competency.id}`}
                              className="font-medium text-blue-600 hover:text-blue-500"
                            >
                              View
                            </Link>
                          </div>
                        </li>
                      </ul>
                    ))}
                  </div>
                )}
              </dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">Records</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {personnel.records.length === 0 ? (
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    <li
                      key={"no-records"}
                      className="pl-3 pr-4 py-3 flex items-center justify-between text-sm italic"
                    >
                      None
                    </li>
                  </ul>
                ) : (
                  <div>
                    <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                      {personnel.records.map((record) => (
                        <li
                          key={record.id}
                          className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                        >
                          <div className="w-0 flex-1 flex items-center">
                            <DocumentTextIcon
                              className="flex-shrink-0 h-5 w-5 text-blue-400"
                              aria-hidden="true"
                            />
                            <span className="ml-2 flex-1 w-0 truncate">
                              {`${record.form.title} - ${getDate(
                                record.completed
                              )}`}
                            </span>
                          </div>
                          <div className="ml-4 flex-shrink-0">
                            <Link
                              to={`/regulator/records/${record.id}`}
                              className="font-medium text-blue-600 hover:text-blue-500"
                            >
                              View
                            </Link>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <AuditTrailCard
        fieldDisplayMap={fieldDisplayMap}
        type={"personnel"}
        fetchPath={personnelAuditPath(personnelID)}
      />
    </div>
  );
}
