import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Pagination from "../common/Pagination";
import EmptyStateScreen from "../common/EmptyStateScreen";
import { ReagentTypeSummary } from "../common/Entities.d";
import { useReagentTypeSummaries } from "../common/DataFetchers";
import { useUserContext } from "../common/BasePageScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";

export default function ReagentTypesList(): JSX.Element {
  const { reagentsRead, reagentsWrite } = useUserContext();
  const navigate = useNavigate();

  const {
    reagentTypeSummaries,
    reagentTypeSummariesIsLoading,
    reagentTypeSummariesIsError,
  } = useReagentTypeSummaries(reagentsRead);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [items, setItems] = useState<ReagentTypeSummary[]>([]);

  useEffect(() => {
    if (reagentTypeSummaries.length === 0) {
      return;
    }
    setItems(reagentTypeSummaries);
  }, [reagentTypeSummaries]);

  if (!reagentsRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (reagentTypeSummariesIsLoading) {
    return <LoadingComponent />;
  }

  if (reagentTypeSummariesIsError) {
    return <ErrorComponent />;
  }

  const pageEndIndex = currentPage * itemsPerPage;
  const pageStartIndex = pageEndIndex - itemsPerPage;
  const pageReagentTypeSummaries: ReagentTypeSummary[] = items
    ? items.slice(pageStartIndex, pageEndIndex)
    : reagentTypeSummaries.slice(pageStartIndex, pageEndIndex);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const tableReagentTypeRows = pageReagentTypeSummaries.map(
    (reagentType, index) => (
      <tr
        key={reagentType.id}
        className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
      >
        <td className="px-6 py-4 whitespace-nowrap font-medium text-sm text-gray-900">
          {reagentType.name}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
          <Link
            to={`/regulator/reagents/types/${reagentType.id}`}
            className="text-blue-600 hover:text-blue-900"
          >
            View
          </Link>
        </td>
      </tr>
    )
  );

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            {reagentTypeSummaries.length === 0 ? (
              <EmptyStateScreen
                item={"reagent type"}
                createLink={"/regulator/reagents/types/add"}
                writeAccess={reagentsWrite}
              />
            ) : (
              <div>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Reagent Type
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">View</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{tableReagentTypeRows}</tbody>
                </table>
                <Pagination
                  itemsPerPage={itemsPerPage}
                  totalItems={reagentTypeSummaries.length}
                  currentPage={currentPage}
                  paginate={paginate}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
