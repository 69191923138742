import { useUserContext } from "../common/BasePageScreen";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  competencyAttachmentDownloadLink,
  updateCompetency,
  useCompetency,
  useCompetencyApprovals,
  usePersonnelSummaries,
  useRecordSummaries,
  useWorkflowRunSummaries,
} from "../common/DataFetchers";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import React, { useEffect, useState } from "react";
import {
  formatErrorMessage,
  getDate,
  getFullName,
  getTime,
} from "../common/Utils";
import TableWithCheckbox from "../common/TableWithCheckbox";
import FileUploadComponent from "../common/FileUploadComponent";
import { XCircleIcon, XIcon } from "@heroicons/react/solid";
import { PaperClipIcon } from "@heroicons/react/outline";
import { CompetencyAttachmentDetails } from "../common/Entities.d";
import ErrorBanner from "../common/ErrorBanner";

export default function EditCompetencyForm() {
  const { competenciesRead, competenciesWrite } = useUserContext();
  const navigate = useNavigate();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const params = useParams<{ id: string }>();
  const competencyID = params.id as string;

  const {
    competency,
    competencyIsLoading,
    competencyIsError,
    mutateCompetency,
  } = useCompetency(competencyID, competenciesWrite);
  const { approvals } = useCompetencyApprovals(
    competency?.id,
    competenciesWrite
  );

  const { personnelSummaries } = usePersonnelSummaries(competenciesWrite);
  const { recordSummaries } = useRecordSummaries(true);
  const { workflowRunSummaries } = useWorkflowRunSummaries(true);

  const [deletedAttachments] = useState<string[]>([]);
  const [newAttachments] = useState<File[]>([]);

  const [links, setLinks] = useState<{ alias: string; url: string }[]>([]);

  const [currentAttachments, setCurrentAttachments] = useState<
    CompetencyAttachmentDetails[]
  >([]);

  const [selectedRecords, setSelectedRecords] = useState<
    { id: string; display: string }[]
  >([]);

  const [selectedRuns, setSelectedRuns] = useState<
    { id: string; display: string }[]
  >([]);

  useEffect(() => {
    if (!competency) {
      return;
    }

    setLinks(competency.links);
    setCurrentAttachments(competency.attachments);
    setSelectedRecords(
      competency.records.map((record) => {
        return {
          id: record.id,
          display: `${record.form.title} - ${getFullName(
            record.authorUser.firstName,
            record.authorUser.lastName
          )} ${getDate(record.completed)} ${getTime(record.completed)}`,
        };
      })
    );

    setSelectedRuns(
      competency.workflowRuns.map((run) => {
        return {
          id: run.id,
          display: run.displayID,
        };
      })
    );
  }, [competency]);

  const [approverError, setApproverError] = useState(false);
  const [approvers] = useState<Set<string>>(
    new Set<string>(
      approvals.map((approval) => {
        return approval.personnel.id;
      })
    )
  );

  const [recordOptions, setRecordOptions] = useState<
    { id: string; display: string }[]
  >([]);
  useEffect(() => {
    const updatedRecordOptions = recordSummaries.map((record) => {
      return {
        id: record.id,
        display: `${record.form.title} - ${getFullName(
          record.authorUser.firstName,
          record.authorUser.lastName
        )} ${getDate(record.completed)} ${getTime(record.completed)}`,
      };
    });

    setRecordOptions(updatedRecordOptions);
  }, [recordSummaries]);

  const [runOptions, setRunOptions] = useState<
    { id: string; display: string }[]
  >([]);
  useEffect(() => {
    const updatedRunOptions = workflowRunSummaries.map((run) => {
      return {
        id: run.id,
        display: `${run.displayID} - ${run.template.name}`,
      };
    });
    setRunOptions(updatedRunOptions);
  }, [workflowRunSummaries]);

  if (!competenciesRead || !competenciesWrite) {
    navigate("/forbidden");
    return <></>;
  }

  if (competencyIsLoading) {
    return <LoadingComponent />;
  }

  if (competencyIsError || !competency) {
    return <ErrorComponent />;
  }

  return (
    <form
      className="mt-3 px-3 space-y-8 divide-y divide-gray-200"
      onSubmit={(e: React.SyntheticEvent) => {
        e.preventDefault();

        setShowErrorBanner(false);

        setApproverError(false);
        if (approvers.size === 0 || approvers.has(competency.personnel.id)) {
          setApproverError(true);
          return;
        }

        updateCompetency(
          competencyID,
          Array.from(approvers),
          selectedRecords.map((record) => {
            return record.id;
          }),
          selectedRuns.map((run) => {
            return run.id;
          }),
          links,
          deletedAttachments,
          newAttachments
        )
          .then((competency) => {
            mutateCompetency(competency, false);
            navigate(`/regulator/competency/${competencyID}`);
          })
          .catch((error) => {
            setErrorBannerText(formatErrorMessage(error));
            setShowErrorBanner(true);
          });
      }}
    >
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <ErrorBanner
            showError={showErrorBanner}
            setShowError={setShowErrorBanner}
            errorText={errorBannerText}
          />
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Competency Information
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Fill in required details to create a new competency record.
            </p>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Competency Type
              </label>
              <Link
                to={`/regulator/competency/type/${competency.type.id}`}
                target="_blank"
                rel="noreferrer"
                className="mt-2 text-sm font-medium text-blue-600"
              >
                {competency.type.name}
              </Link>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Personnel
              </label>
              <Link
                to={`/regulator/personnel/${competency.personnel.id}`}
                target="_blank"
                rel="noreferrer"
                className="mt-2 text-sm font-medium text-blue-600"
              >
                {getFullName(
                  competency.personnel.firstName,
                  competency.personnel.lastName
                )}
              </Link>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Approvers
              </label>
              <div className="sm:col-span-2">
                {personnelSummaries.map((personnel) => {
                  return (
                    <div className="relative flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          id={personnel.id}
                          name={personnel.id}
                          type="checkbox"
                          defaultChecked={approvers.has(personnel.id)}
                          className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                          onChange={(e) => {
                            if (e.currentTarget.checked) {
                              approvers.add(e.currentTarget.id);
                            } else {
                              approvers.delete(e.currentTarget.id);
                            }
                          }}
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor={personnel.id}
                          className="font-medium text-gray-700"
                        >
                          {getFullName(personnel.firstName, personnel.lastName)}
                        </label>
                      </div>
                    </div>
                  );
                })}
              </div>
              <p
                className="text-sm text-red-500 col-start-2 col-span-2"
                hidden={!approverError}
              >
                * At least one approver must be selected. Personnel cannot be
                approver on own competency.
              </p>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="records"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Records
              </label>
              <div className="sm:col-span-2">
                {recordSummaries.length === 0 ? (
                  <p className="mt-2 text-sm font-medium text-gray-700">
                    No records
                  </p>
                ) : (
                  <TableWithCheckbox
                    header={"Record"}
                    options={recordOptions}
                    linkPrefix={"/records"}
                    selected={selectedRecords}
                    setSelected={setSelectedRecords}
                  />
                )}
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="workflowRuns"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Workflow Runs
              </label>
              <div className="sm:col-span-2">
                {workflowRunSummaries.length === 0 ? (
                  <p className="mt-2 text-sm font-medium text-gray-700">
                    No workflow runs
                  </p>
                ) : (
                  <TableWithCheckbox
                    header={"Workflow Run"}
                    options={runOptions}
                    linkPrefix={"/workflows/history"}
                    selected={selectedRuns}
                    setSelected={setSelectedRuns}
                  />
                )}
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="Links"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Links
              </label>
              <div className="sm:col-span-2">
                <button
                  type="button"
                  onClick={() => {
                    links.push({ alias: "", url: "" });
                    setLinks(Array.from(links));
                  }}
                  className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Add Link
                </button>
                {links.map((link, linkIdx) => {
                  return (
                    <div>
                      <div className="mt-3 inline-flex">
                        <div className="pr-4">
                          <label
                            htmlFor={`link-display-${linkIdx}`}
                            className="block text-sm font-medium text-gray-700"
                          >
                            Display
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name={`link-display-${linkIdx}`}
                              id={`link-display-${linkIdx}`}
                              defaultValue={link.alias}
                              key={link.alias}
                              onChange={(e) => {
                                link.alias = e.currentTarget.value;
                                setLinks(links);
                              }}
                              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor={`link-${linkIdx}`}
                            className="block text-sm font-medium text-gray-700"
                          >
                            Link
                          </label>
                          <div className="mt-1">
                            <input
                              type="url"
                              name={`link-${linkIdx}`}
                              id={`link-${linkIdx}`}
                              defaultValue={link.url}
                              key={link.url}
                              onChange={(e) => {
                                link.url = e.currentTarget.value;
                                setLinks(links);
                              }}
                              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>

                        <button
                          className="rounded-full h-5 w-5"
                          onClick={() => {
                            links.splice(linkIdx, 1);
                            setLinks(Array.from(links));
                          }}
                        >
                          <XCircleIcon className="h-5 w-5 text-red-500" />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700">
                Current Attachments
              </label>
              <div>
                {currentAttachments.length === 0 ? (
                  <ul className="bg-white border border-gray-200 rounded-md divide-y divide-gray-200">
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      None
                    </li>
                  </ul>
                ) : (
                  currentAttachments.map((attachment, idx) => (
                    <ul className="bg-white border border-gray-200 rounded-md divide-y divide-gray-200">
                      <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                        <div className="w-0 flex-1 flex items-center">
                          <PaperClipIcon
                            className="flex-shrink-0 h-5 w-5 text-blue-400"
                            aria-hidden="true"
                          />
                          <span className="ml-2 flex-1 w-0 truncate">
                            {attachment.fileName}
                          </span>
                        </div>
                        <div className="ml-4 flex-shrink-0">
                          <a
                            download={attachment.fileName}
                            href={competencyAttachmentDownloadLink(
                              attachment.id
                            )}
                            className="font-medium text-blue-600 hover:text-blue-500"
                          >
                            Download
                          </a>
                        </div>
                        <div className="mt-0.5 ml-4 flex-shrink-0">
                          <button
                            type="button"
                            onClick={() => {
                              deletedAttachments.push(attachment.id);
                              currentAttachments?.splice(idx, 1);
                              setCurrentAttachments(
                                Array.from(currentAttachments)
                              );
                            }}
                            className="inline-flex items-center border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                          >
                            <XIcon className="h-4 w-4" aria-hidden="true" />
                          </button>
                        </div>
                      </li>
                    </ul>
                  ))
                )}
              </div>
            </div>
            <div className="sm: col-span-3">
              <FileUploadComponent
                label={"New Attachments"}
                fileTypes={"PDF"}
                files={newAttachments}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <Link to={`/regulator/competency/${competencyID}`}>
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
