import { PencilAltIcon } from "@heroicons/react/solid";
import { Link, useNavigate, useParams } from "react-router-dom";
import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import { equipmentAuditPath, useEquipmentType } from "../common/DataFetchers";
import React from "react";
import { useUserContext } from "../common/BasePageScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import AuditTrailCard from "../common/AuditTrailCard";

const fieldDisplayMap = new Map([
  ["name", "Equipment Type Name"],
  ["calibrationCycle", "Calibration Cycle"],
]);

export default function EquipmentTypeDetailsCard(): JSX.Element {
  const { equipmentRead, equipmentWrite } = useUserContext();
  const navigate = useNavigate();

  const params = useParams<{ id: string }>();
  const equipmentTypeId = params.id as string;

  const { equipmentType, equipmentTypeIsError, equipmentTypeIsLoading } =
    useEquipmentType(equipmentTypeId, equipmentRead);

  if (!equipmentRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (equipmentTypeIsLoading) {
    return <LoadingComponent />;
  }

  if (equipmentTypeIsError || !equipmentType) {
    return <ErrorComponent />;
  }

  const pages = [
    { name: "Equipment Types", path: "/regulator/equipment/types" },
    {
      name: equipmentType.name,
      path: `/regulator/equipment/types/${equipmentType.id}`,
    },
  ];

  return (
    <div>
      <FullWidthBreadcrumbBar pages={pages} />
      <div className="py-3 pt-5">
        <div className="flex justify-end">
          <Link
            to={`/regulator/equipment/types/${equipmentType.id}/edit`}
            hidden={!equipmentWrite}
          >
            <button
              type="button"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <PencilAltIcon
                className="-ml-1 mr-3 h-5 w-5"
                aria-hidden="true"
              />
              Edit
            </button>
          </Link>
        </div>
      </div>
      <div className="bg-white mt-3 shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Equipment Type Details
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Details regarding equipment type
          </p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Equipment Type
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {equipmentType.name}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Calibration Cycle
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {equipmentType.calibrationCycle}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <AuditTrailCard
        fieldDisplayMap={fieldDisplayMap}
        type={"Equipment Type"}
        fetchPath={equipmentAuditPath(equipmentTypeId)}
      />
    </div>
  );
}
