import {
  ArchiveIcon,
  ArrowSmDownIcon,
  ArrowSmUpIcon,
  CheckCircleIcon,
  ChevronRightIcon,
  CursorClickIcon,
  DotsCircleHorizontalIcon,
  MailIcon,
  MailOpenIcon,
  ShieldCheckIcon,
  UserAddIcon,
  UsersIcon,
} from "@heroicons/react/solid";
import { classNames, getFullName, getInitials } from "../common/Utils";
import React, { useEffect, useState } from "react";
import {
  useAdminUserSummaries,
  useCurrentLab,
  useUserSummaries,
} from "../common/DataFetchers";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import { Link, useNavigate } from "react-router-dom";
import { useUserContext } from "../common/BasePageScreen";

const statusIconMap = new Map([
  [
    "Active",
    {
      icon: CheckCircleIcon,
      className: "flex-shrink-0 mr-1.5 h-5 w-5 text-green-400",
    },
  ],
  [
    "Inactive",
    {
      icon: ArchiveIcon,
      className: "flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400",
    },
  ],
  [
    "Pending",
    {
      icon: DotsCircleHorizontalIcon,
      className: "flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-400",
    },
  ],
]);

export default function UsersCard() {
  const navigate = useNavigate();
  const { user } = useUserContext();

  useEffect(() => {
    if (!user) {
      return;
    }
    if (!user.isAdmin) {
      navigate("/forbidden");
    }
  }, [user]);

  const { currentLab, currentLabIsLoading, currentLabIsError } =
    useCurrentLab();
  const { userSummaries, userSummariesIsLoading, userSummariesIsError } =
    useUserSummaries("");
  const {
    adminUserSummaries,
    adminUserSummariesIsLoading,
    adminUserSummariesIsError,
  } = useAdminUserSummaries("Active");

  if (
    currentLabIsLoading ||
    userSummariesIsLoading ||
    adminUserSummariesIsLoading
  ) {
    return <LoadingComponent />;
  }

  if (currentLabIsError || userSummariesIsError || adminUserSummariesIsError) {
    return <ErrorComponent />;
  }

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      <div className="mt-2 sm:rounded-md sm:border sm:border-gray-200 sm:overflow-hidden">
        <div className="bg-white py-6 px-4 sm:p-6">
          <div>
            <h2
              id="payment-details-heading"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              Manage Users
            </h2>
            <div className="inline-flex grid grid-cols-3">
              <p className="mt-1 text-sm text-gray-500 col-span-2">
                Add, remove, and update users. Note: Please contact{" "}
                <a
                  className="text-blue-600 font-medium"
                  href="mailto:support@decotolabs.com"
                >
                  support@decotolabs.com
                </a>{" "}
                if you would like to transfer admin privileges on the Regulator
                Starter Plan.
              </p>

              <div className="-mt-3 text-right">
                <Link to="/regulator/settings/user/add">
                  <button
                    type="button"
                    className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <UserAddIcon
                      className="-ml-0.5 mr-2 h-4 w-4"
                      aria-hidden="true"
                    />
                    Add User
                  </button>
                </Link>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-2">
              <dl className="mt-5 mx-1 grid grid-cols-1 gap-5">
                <div
                  key={"current-users"}
                  className="relative bg-white pt-5 px-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
                >
                  <dt>
                    <div className="absolute bg-blue-500 rounded-md p-3">
                      <UsersIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                    <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                      Current Users
                    </p>
                  </dt>
                  <dd className="ml-16 flex items-baseline sm:pb-7">
                    <p className="text-2xl font-semibold text-blue-700">
                      {
                        userSummaries.filter(
                          (user) => user.status !== "Inactive"
                        ).length
                      }
                    </p>
                  </dd>
                </div>
              </dl>
              <dl className="mt-5 mx-1 grid grid-cols-1 gap-5">
                <div
                  key={"remaining-seats"}
                  className="relative bg-white pt-5 px-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
                >
                  <dt>
                    <div className="absolute bg-blue-500 rounded-md p-3">
                      <UsersIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                    <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                      Remaining Seats
                    </p>
                  </dt>
                  <dd className="ml-16 flex items-baseline sm:pb-7">
                    <p className="text-2xl font-semibold text-blue-700">
                      {currentLab!.maxUsers -
                        userSummaries.filter(
                          (user) => user.status !== "Inactive"
                        ).length}
                    </p>
                  </dd>
                </div>
              </dl>
              <dl className="mt-5 mx-1 grid grid-cols-1 gap-5">
                <div
                  key={"current-admin-users"}
                  className="relative bg-white pt-5 px-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
                >
                  <dt>
                    <div className="absolute bg-blue-500 rounded-md p-3">
                      <ShieldCheckIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                    <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                      Current Admin Users
                    </p>
                  </dt>
                  <dd className="ml-16 flex items-baseline sm:pb-7">
                    <p className="text-2xl font-semibold text-blue-700">
                      {
                        adminUserSummaries.filter(
                          (user) => user.status !== "Inactive"
                        ).length
                      }
                    </p>
                  </dd>
                </div>
              </dl>
              <dl className="mt-5 mx-1 grid grid-cols-1 gap-5">
                <div
                  key={"remaining-admin-users"}
                  className="relative bg-white pt-5 px-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
                >
                  <dt>
                    <div className="absolute bg-blue-500 rounded-md p-3">
                      <ShieldCheckIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                    <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                      Remaining Admin Users
                    </p>
                  </dt>
                  <dd className="ml-16 flex items-baseline sm:pb-7">
                    <p className="text-2xl font-semibold text-blue-700">
                      {currentLab!.maxAdminUsers -
                        adminUserSummaries.filter(
                          (user) => user.status !== "Inactive"
                        ).length}
                    </p>
                  </dd>
                </div>
              </dl>
            </div>
            <div className="sm:grid sm:grid-cols-4"></div>
          </div>
          <ul role="list" className="mt-6 divide-y divide-gray-200">
            {userSummaries.map((user, userIdx) => (
              <li key={user.email}>
                <div className="flex items-center px-4 py-4 sm:px-6">
                  <div className="min-w-0 flex-1 flex items-center">
                    <div className="flex-shrink-0">
                      <span className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-blue-500">
                        <span className="text-sm font-medium leading-none text-white">
                          {getInitials(
                            getFullName(user.firstName, user.lastName)
                          )}
                        </span>
                      </span>
                    </div>
                    <div className="min-w-0 flex-1 px-4 grid grid-cols-3 gap-4">
                      <div>
                        <p className="text-sm font-medium text-blue-600">
                          {getFullName(user.firstName, user.lastName)}
                        </p>
                        <p className="mt-2 flex items-center text-sm text-gray-500">
                          <MailIcon
                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="truncate">{user.email}</span>
                        </p>
                      </div>
                      <div className="hidden md:block">
                        <div>
                          <p className="text-sm text-gray-900">
                            Start Date: January 5, 2022
                          </p>
                          <p className="mt-2 flex items-center text-sm text-gray-500">
                            {statusIconMap.get(user.status)!.icon({
                              className: statusIconMap.get(user.status)!
                                .className,
                            })}
                            {user.status}
                          </p>
                        </div>
                      </div>
                      <div
                        className={classNames(
                          adminUserSummaries.findIndex(
                            (admin) => admin.id === user.id
                          ) < 0
                            ? "hidden"
                            : "hidden md:flex",
                          "items-center justify-center"
                        )}
                      >
                        <ShieldCheckIcon className="flex-shrink-0 h-6 w-6 text-blue-600" />
                        <span className="text-sm">Admin User</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Link to={`/regulator/settings/user/${user.id}`}>
                      <button className="my-auto">
                        <ChevronRightIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </button>
                    </Link>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
