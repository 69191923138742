import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Pagination from "../common/Pagination";
import { useUserContext } from "../common/BasePageScreen";
import { useWorkflowTemplateSummaries } from "../common/DataFetchers";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import { WorkflowTemplateSummary } from "../common/Entities.d";

export default function ActiveWorkflowTemplatesList(): JSX.Element {
  const { workflowTemplatesRead } = useUserContext();
  const navigate = useNavigate();

  const {
    workflowTemplateSummaries,
    workflowTemplateSummariesIsLoading,
    workflowTemplateSummariesIsError,
  } = useWorkflowTemplateSummaries("Archived", workflowTemplatesRead);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [items, setItems] = useState<WorkflowTemplateSummary[]>([]);

  const [showFilterBox, setShowFilterBox] = useState(false);

  useEffect(() => {
    if (workflowTemplateSummaries.length === 0) {
      return;
    }
    setItems(workflowTemplateSummaries);
  }, [workflowTemplateSummaries]);

  if (!workflowTemplatesRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (workflowTemplateSummariesIsLoading) {
    return <LoadingComponent />;
  }

  if (workflowTemplateSummariesIsError) {
    return <ErrorComponent />;
  }

  const pageEndIndex = currentPage * itemsPerPage;
  const pageStartIndex = pageEndIndex - itemsPerPage;
  const pageWorkflowTemplateSummaries: WorkflowTemplateSummary[] = items
    ? items.slice(pageStartIndex, pageEndIndex)
    : workflowTemplateSummaries.slice(pageStartIndex, pageEndIndex);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  function clearFilter() {
    (document.getElementById("titleFilter") as HTMLInputElement).value = "";
    setItems(workflowTemplateSummaries);
  }

  function handleFilter(e: React.SyntheticEvent) {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      titleFilter: { value: string };
    };

    let newItems = workflowTemplateSummaries;

    const titleFilter = target.titleFilter.value.trim();
    if (titleFilter !== "") {
      newItems = newItems.filter((i) => i.name === titleFilter);
    }

    setItems(Array.from(newItems));
  }

  return (
    <div className="flex flex-col">
      <div className="bg-white p-3 border border-gray-200 rounded-lg shadow-sm">
        <h3 className="ml-1 text-md leading-6 font-medium text-gray-900">
          Filter
        </h3>
        <div className="-mt-6 text-right">
          <button onClick={() => setShowFilterBox(!showFilterBox)}>
            {showFilterBox ? (
              <ChevronUpIcon
                className="h-6 w-6 text-gray-500"
                aria-hidden="true"
              />
            ) : (
              <ChevronDownIcon
                className="h-6 w-6 text-gray-500"
                aria-hidden="true"
              />
            )}
          </button>
        </div>
        <div hidden={!showFilterBox}>
          <form
            className="grid grid-cols-6 gap-4"
            onSubmit={(e) => handleFilter(e)}
          >
            <div className="ml-1 col-start-1 col-span-2">
              <label
                htmlFor="titleFilter"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="titleFilter"
                  id="titleFilter"
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="mt-3 text-center col-start-6">
              <button
                type="button"
                onClick={clearFilter}
                className="m-1 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Clear
              </button>
              <button
                type="submit"
                className="m-1 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Apply
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            {workflowTemplateSummaries.length === 0 ? (
              <div className="p-3 pb-16 bg-white text-center">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                  />
                </svg>
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  No archived workflow templates
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Once you have archived a workflow template, it will appear
                  here.
                </p>
              </div>
            ) : (
              <div>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Workflow Template
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Create New Run</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageWorkflowTemplateSummaries.map(
                      (workflow, workflowIdx) => (
                        <tr
                          key={workflow.name}
                          className={
                            workflowIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                          }
                        >
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {workflow.name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <Link
                              to={`/workflows/templates/${workflow.id}`}
                              className="text-blue-600 hover:text-blue-900"
                            >
                              View
                            </Link>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
                <Pagination
                  itemsPerPage={itemsPerPage}
                  totalItems={
                    items ? items.length : workflowTemplateSummaries.length
                  }
                  currentPage={currentPage}
                  paginate={paginate}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
