import { useLocation } from "react-router-dom";
import Tabs from "../common/Tabs";
import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import * as React from "react";
import OpenTasksList from "./OpenTasksList";
import ClosedTasksList from "./ClosedTasksList";

const openPages = [{ name: "Tasks", path: "/regulator/tasks" }];
const closedPages = [
  { name: "Tasks", path: "/regulator/tasks" },
  { name: "Closed", path: "/regulator/tasks/closed" },
];

const personnelTabs = [
  { name: "Open", href: "/regulator/tasks" },
  { name: "Closed", href: "/regulator/tasks/closed" },
];

export default function TasksList(): JSX.Element {
  const location = useLocation();

  const isTasksPath = location.pathname === "/regulator/tasks";

  const breadcrumbBar = isTasksPath ? (
    <FullWidthBreadcrumbBar pages={openPages} />
  ) : (
    <FullWidthBreadcrumbBar pages={closedPages} />
  );

  const component = isTasksPath ? <OpenTasksList /> : <ClosedTasksList />;

  return (
    <div className="flex flex-col">
      {breadcrumbBar}
      <div className="mt-3">
        <Tabs tabs={personnelTabs} />
        {component}
      </div>
    </div>
  );
}
