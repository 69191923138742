import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  classNames,
  formatDateInputValue,
  formatErrorMessage,
  getFullName,
} from "../common/Utils";
import { useUserContext } from "../common/BasePageScreen";
import {
  addWorkflowRun,
  useCompetencySummaries,
  useEquipmentSummaries,
  usePersonnelSummaries,
  useReagentSummaries,
  useSampleSummaries,
  useWorkflowTemplate,
  useWorkflowTemplateSummaries,
} from "../common/DataFetchers";
import CustomSelectMenu from "../common/CustomSelectMenu";
import CustomSelectMapMenu from "../common/CustomSelectMapMenu";
import { ArrowSmLeftIcon, PlusIcon } from "@heroicons/react/solid";
import { Switch } from "@headlessui/react";
import { CompetencySummary, PersonnelSummary } from "../common/Entities.d";
import FileUploadComponent from "../common/FileUploadComponent";
import TableWithPagination from "../common/TableWithPagination";
import TableWithCheckbox from "../common/TableWithCheckbox";
import ErrorBanner from "../common/ErrorBanner";

function competencyAndPersonnelMatch(
  competency: CompetencySummary,
  personnel: PersonnelSummary
) {
  return competency.personnel.id === personnel.id;
}

export default function AddWorkflowRunForm() {
  const navigate = useNavigate();
  const { workflowHistoryRead, workflowHistoryWrite } = useUserContext();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const { personnelSummaries } = usePersonnelSummaries(workflowHistoryWrite);
  const { equipmentSummaries } = useEquipmentSummaries(workflowHistoryWrite);
  const { reagentSummaries } = useReagentSummaries(workflowHistoryWrite);
  const { sampleSummaries } = useSampleSummaries(workflowHistoryWrite);

  const [templateSelection, setTemplateSelection] =
    useState<{ id: string; value: any; display: string }>();
  const [templateOptions, setTemplateOptions] = useState<
    { id: string; value: any; display: string }[]
  >([]);
  const [runIDError, setRunIDError] = useState(false);
  const [runDateError, setRunDateError] = useState(false);
  const [techniciansError, setTechniciansError] = useState(false);
  const [equipmentError, setEquipmentError] = useState(false);
  const [reagentsError, setReagentsError] = useState(false);
  const [samplesError, setSamplesError] = useState(false);

  const [competencies] = useState<Set<string>>(new Set<string>());
  const [additionalTechnicians] = useState<Set<string>>(new Set<string>());
  const [attachments] = useState<File[]>([]);
  const runEquipment = new Map<string, { id: string; display: string }>();
  const runReagents = new Map<string, { id: string; display: string }>();
  const [runSamples, setRunSamples] = useState<
    { id: string; display: string }[]
  >([]);

  const { workflowTemplate } = useWorkflowTemplate(
    templateSelection?.id,
    workflowHistoryWrite
  );

  const [reagentQualification, setReagentQualification] = useState(
    new Array(workflowTemplate?.reagentTypes.length).fill(false)
  );

  const [validSampleTypes, setValidSampleTypes] = useState<Set<string>>();

  const { competencySummaries } = useCompetencySummaries(
    "Active",
    workflowTemplate?.competencyType.id,
    workflowHistoryWrite
  );

  const { workflowTemplateSummaries } = useWorkflowTemplateSummaries(
    "Active",
    workflowHistoryWrite
  );

  useEffect(() => {
    if (workflowTemplateSummaries.length === 0) {
      return;
    }

    const templateOptions = workflowTemplateSummaries.map((template) => {
      return {
        id: template.id,
        value: template,
        display: template.name,
      };
    });
    setTemplateOptions(templateOptions);
    if (!templateSelection) {
      setTemplateSelection(templateOptions[0]);
    }
  }, [workflowTemplateSummaries, templateSelection]);

  useEffect(() => {
    if (!workflowTemplate) {
      return;
    }
    const sampleTypeIds = workflowTemplate.sampleTypes.map((sampleType) => {
      return sampleType.id;
    });
    setValidSampleTypes(new Set<string>(sampleTypeIds));
  }, [workflowTemplate]);

  if (!workflowHistoryWrite || !workflowHistoryRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (workflowTemplateSummaries.length === 0) {
    return (
      <div className="p-6 bg-white rounded-md">
        <div className="text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 className="mt-2 text-sm font-medium text-gray-900">
            No workflow templates
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            A workflow run cannot be created until a workflow template has been
            made.
          </p>
          <div className="mt-6">
            <Link to="/regulator/workflows/templates/add">
              <button
                type="button"
                className="mx-1 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                New workflow template
              </button>
            </Link>
            <Link to="/regulator/workflows/history">
              <button
                type="button"
                className="mx-1 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <ArrowSmLeftIcon
                  className="-ml-1 mr-2 h-5 w-5"
                  aria-hidden="true"
                />
                Back to Workflow History
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <form
        className="mt-3 px-3 space-y-8"
        onSubmit={(e: React.SyntheticEvent) => {
          e.preventDefault();

          setShowErrorBanner(false);

          const target = e.target as typeof e.target & {
            runId: { value: string };
            runDate: { value: string };
          };

          target.runId.value = target.runId.value.trim();
          const runID = target.runId.value;
          const runDate = target.runDate.value;

          const formatInputDate = formatDateInputValue(runDate);

          setRunIDError(false);
          setRunDateError(false);
          setTechniciansError(false);
          setEquipmentError(false);
          setReagentsError(false);
          setSamplesError(false);

          let isValid = true;

          if (runID === "") {
            setRunIDError(true);
            isValid = false;
          }

          if (runDate.trim() === "") {
            setRunDateError(true);
            isValid = false;
          }

          if (competencies.size === 0) {
            setTechniciansError(true);
            isValid = false;
          }

          if (runEquipment.size === 0) {
            setEquipmentError(true);
            isValid = false;
          }

          if (runReagents.size === 0) {
            setReagentsError(true);
            isValid = false;
          }

          if (runSamples.length === 0) {
            setSamplesError(true);
            isValid = false;
          }

          if (!isValid) {
            return;
          }

          if (!workflowTemplate) {
            return;
          }

          const equipmentIds: string[] = [];
          const reagentIds: { id: string; qualified: boolean }[] = [];

          runEquipment.forEach((value) => equipmentIds.push(value.id));
          runReagents.forEach((value, key) => {
            const reagentQualificationIdx =
              workflowTemplate.reagentTypes.findIndex(
                (type) => type.id === key
              );
            console.log(value.display);
            console.log(reagentQualification[reagentQualificationIdx]);
            reagentIds.push({
              id: value.id,
              qualified: reagentQualification[reagentQualificationIdx],
            });
          });

          addWorkflowRun(
            workflowTemplate.id,
            runID,
            new Date(formatInputDate),
            Array.from(competencies),
            Array.from(additionalTechnicians),
            equipmentIds,
            reagentIds,
            runSamples.map((s) => {
              return s.id;
            }),
            attachments
          )
            .then((run) => navigate(`/regulator/workflows/history/${run.id}`))
            .catch((error) => {
              setErrorBannerText(formatErrorMessage(error));
              setShowErrorBanner(true);
            });
        }}
      >
        <div className="p-3 space-y-8 sm:space-y-5">
          <div>
            <ErrorBanner
              showError={showErrorBanner}
              setShowError={setShowErrorBanner}
              errorText={errorBannerText}
            />
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Run Details
            </h3>
          </div>
          <div>
            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <CustomSelectMenu
                  label={"Workflow Template"}
                  options={templateOptions}
                  selected={templateSelection}
                  setSelected={setTemplateSelection}
                />
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="runId"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Run ID
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-1">
                  <div className="max-w-lg flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="runId"
                      id="runId"
                      className={classNames(
                        "flex-1 block w-full min-w-0 rounded-md sm:text-sm",
                        runIDError
                          ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                          : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                      )}
                    />
                  </div>
                  <p className="mt-2 text-sm text-red-500" hidden={!runIDError}>
                    * Run ID is required
                  </p>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="runDate"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Run Date
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-1">
                  <div className="max-w-lg flex rounded-md shadow-sm">
                    <input
                      type="date"
                      name="runDate"
                      id="runDate"
                      className={classNames(
                        "flex-1 block w-full min-w-0 rounded-md sm:text-sm",
                        runDateError
                          ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                          : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                      )}
                    />
                  </div>
                  <p
                    className="mt-2 text-sm text-red-500"
                    hidden={!runDateError}
                  >
                    * Run Date is required
                  </p>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="sop"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  SOP
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Link
                    to={`/regulator/sops/${workflowTemplate?.sop.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className="mt-1 text-sm font-semibold text-blue-500">
                      {`${workflowTemplate?.sop.displayID} -
                    ${workflowTemplate?.sop.title}
                    v${workflowTemplate?.sop.majorVersion}.${workflowTemplate?.sop.minorVersion}`}
                    </p>
                  </Link>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="sop"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Technicians
                </label>
                {competencySummaries.length === 0 ? (
                  <div className="mt-4 sm:mt-0 sm:col-span-2">
                    <div className="max-w-lg space-y-4">
                      <p className="mt-2 text-sm text-gray-500">
                        Please{" "}
                        <Link
                          to={`/regulator/competency/add?typeID=${workflowTemplate?.competencyType.id}`}
                          className="font-medium text-blue-500"
                          target="_blank"
                          rel="noreferrer"
                        >
                          create
                        </Link>{" "}
                        competency before creating a workflow run.
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="mt-4 sm:mt-0 sm:col-span-2">
                    <div className="max-w-lg space-y-4">
                      {competencySummaries.map((competency) => (
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              id={competency.id}
                              name={competency.id}
                              type="checkbox"
                              onChange={(e) =>
                                e.currentTarget.checked
                                  ? competencies.add(e.currentTarget.id)
                                  : competencies.delete(e.currentTarget.id)
                              }
                              className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                              htmlFor={competency.id}
                              className="font-medium text-gray-700"
                            >
                              {getFullName(
                                competency.personnel.firstName,
                                competency.personnel.lastName
                              )}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <p
                  className="text-sm text-red-500 sm:col-start-2"
                  hidden={!techniciansError}
                >
                  * At least one technician must be selected.
                </p>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="sop"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Additional Technicians
                </label>
                {personnelSummaries.length === 0 ? (
                  <div className="mt-4 sm:mt-0 sm:col-span-2">
                    <div className="max-w-lg space-y-4">
                      <p className="mt-2 text-sm text-gray-500">
                        Please{" "}
                        <Link
                          to="/regulator/personnel/add"
                          className="font-medium text-blue-500"
                          target="_blank"
                          rel="noreferrer"
                        >
                          create
                        </Link>{" "}
                        personnel before creating a workflow run.
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="mt-4 sm:mt-0 sm:col-span-2">
                    <div className="max-w-lg space-y-4">
                      {personnelSummaries
                        .filter(
                          (personnel) =>
                            competencySummaries.findIndex((competency) =>
                              competencyAndPersonnelMatch(competency, personnel)
                            ) < 0
                        )
                        .map((personnel) => (
                          <div className="relative flex items-start">
                            <div className="flex items-center h-5">
                              <input
                                id={personnel.id}
                                name={personnel.id}
                                type="checkbox"
                                onChange={(e) =>
                                  e.currentTarget.checked
                                    ? additionalTechnicians.add(
                                        e.currentTarget.id
                                      )
                                    : additionalTechnicians.delete(
                                        e.currentTarget.id
                                      )
                                }
                                className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label
                                htmlFor={personnel.id}
                                className="font-medium text-gray-700"
                              >
                                {getFullName(
                                  personnel.firstName,
                                  personnel.lastName
                                )}
                              </label>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Equipment
                </label>
                {workflowTemplate?.equipmentTypes.map((equipmentType) => {
                  const equipmentOfType = equipmentSummaries.filter(
                    (equipment) =>
                      equipment.type.id === equipmentType.id &&
                      equipment.status === "In Use"
                  );
                  if (equipmentOfType.length === 0) {
                    return (
                      <div className="mt-4 sm:mt-0 sm:col-start-2 sm:col-span-2">
                        <div className="max-w-lg space-y-4">
                          <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            {equipmentType.name}
                          </label>
                          <p className="mt-2 text-sm text-gray-500">
                            Please{" "}
                            <Link
                              to="/regulator/equipment/add"
                              className="font-medium text-blue-500"
                              target="_blank"
                              rel="noreferrer"
                            >
                              create
                            </Link>{" "}
                            equipment before creating a workflow run. <br />*
                            Note only equipment with "In Use" Status and valid
                            calibration are selectable.
                          </p>
                        </div>
                      </div>
                    );
                  }
                  return (
                    <div className="mt-2 sm:col-start-2 sm:col-span-1">
                      <CustomSelectMapMenu
                        label={equipmentType.name}
                        type={equipmentType.name}
                        filteredStoreValues={equipmentOfType}
                        map={runEquipment}
                      />
                    </div>
                  );
                })}
                <p
                  className="text-sm text-red-500 sm:col-start-2"
                  hidden={!equipmentError}
                >
                  * One equipment entity must be selected for each workflow
                  template equipment type.
                </p>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Reagents
                </label>
                {workflowTemplate?.reagentTypes.map(
                  (reagentType, qualificationIndex) => {
                    const reagentsOfType = reagentSummaries.filter(
                      (reagent) =>
                        reagent.type.id === reagentType.id &&
                        reagent.status === "In Use" &&
                        reagent.qcStatus === "Pass"
                    );
                    const reagentsForQualification = reagentSummaries.filter(
                      (reagent) =>
                        reagent.type.id === reagentType.id &&
                        reagent.status === "In Use" &&
                        reagent.qcStatus === "Queued"
                    );
                    if (
                      reagentsOfType.length === 0 &&
                      reagentsForQualification.length > 0
                    ) {
                      reagentQualification[qualificationIndex] = true;
                    }
                    if (reagentsOfType.length > 0) {
                      reagentQualification[qualificationIndex] = false;
                    }
                    if (
                      reagentsOfType.length === 0 &&
                      reagentsForQualification.length === 0
                    ) {
                      return (
                        <div className="mt-4 sm:mt-0 sm:col-start-2 sm:col-span-2">
                          <div className="max-w-lg space-y-4">
                            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              {reagentType.name}
                            </label>
                            <p className="mt-2 text-sm text-gray-500">
                              Please{" "}
                              <Link
                                to="/regulator/reagents/add"
                                className="font-medium text-blue-500"
                                target="_blank"
                                rel="noreferrer"
                              >
                                create
                              </Link>{" "}
                              reagents before creating a workflow run.
                              <br />* Note only reagents with "In Use" Status
                              and "Pass" QC Status are selectable.
                            </p>
                          </div>
                        </div>
                      );
                    }
                    return (
                      <div className="mt-2 sm:col-start-2 sm:col-span-1">
                        <CustomSelectMapMenu
                          label={reagentType.name}
                          type={reagentType.id}
                          filteredStoreValues={
                            reagentQualification[qualificationIndex] ||
                            reagentsOfType.length === 0
                              ? reagentsForQualification
                              : reagentsOfType
                          }
                          map={runReagents}
                        />
                        <Switch.Group as="div" className="mt-3 flex items-end">
                          <Switch
                            checked={reagentQualification[qualificationIndex]}
                            onChange={(e: boolean) => {
                              reagentQualification[qualificationIndex] = e;
                              setReagentQualification(
                                Array.from(reagentQualification)
                              );
                            }}
                            className={classNames(
                              reagentQualification[qualificationIndex]
                                ? "bg-blue-600"
                                : "bg-gray-200",
                              "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            )}
                          >
                            <span
                              aria-hidden="true"
                              className={classNames(
                                reagentQualification[qualificationIndex]
                                  ? "translate-x-5"
                                  : "translate-x-0",
                                "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                              )}
                            />
                          </Switch>
                          <Switch.Label as="span" className="ml-3">
                            <span className="text-sm font-medium text-gray-900">
                              Qualification Run?
                            </span>
                          </Switch.Label>
                        </Switch.Group>
                      </div>
                    );
                  }
                )}
                <p
                  className="text-sm text-red-500 sm:col-start-2"
                  hidden={!reagentsError}
                >
                  * One reagent entity must be selected for each workflow
                  template reagent type.
                </p>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="samples"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Samples
                </label>
                <div className="mt-4 sm:mt-0 sm:col-span-2">
                  <div className="max-w-lg space-y-4">
                    {sampleSummaries.filter((s) =>
                      validSampleTypes?.has(s.type.id)
                    ).length === 0 ? (
                      <p className="mt-2 text-sm text-gray-500">
                        Please{" "}
                        <Link
                          to="/regulator/samples/add"
                          className="font-medium text-blue-500"
                          target="_blank"
                          rel="noreferrer"
                        >
                          create
                        </Link>{" "}
                        samples before creating a workflow run.
                      </p>
                    ) : (
                      <TableWithCheckbox
                        header={"Samples"}
                        options={sampleSummaries
                          .filter((s) => validSampleTypes?.has(s.type.id))
                          .map((sample) => {
                            return {
                              id: sample.id,
                              display: sample.displayID,
                            };
                          })}
                        linkPrefix={"/regulator/samples"}
                        selected={runSamples}
                        setSelected={setRunSamples}
                      />
                    )}
                    <p
                      className="text-sm text-red-500 sm:col-start-2"
                      hidden={!samplesError}
                    >
                      * At least one sample must be selected.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sm:col-span-3">
          <FileUploadComponent
            label={"Attachments"}
            fileTypes={"PDF"}
            files={attachments}
          />
        </div>
        <div className="p-5">
          <div className="flex justify-end">
            <Link to="/regulator/workflows/history">
              <button
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Cancel
              </button>
            </Link>
            <button
              type="submit"
              disabled={!workflowHistoryWrite}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
