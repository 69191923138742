export default function LoadingComponent() {
  return (
    <div className="py-16 bg-white rounded-md shadow-sm">
      <div className="m-auto text-center animate-pulse">
        <span className="font-semibold text-2xl text-blue-500">Loa</span>
        <span className="font-semibold text-2xl text-blue-600">din</span>
        <span className="font-semibold text-2xl text-blue-700">g...</span>
      </div>
    </div>
  );
}
