import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ArchiveIcon,
  CalendarIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DotsCircleHorizontalIcon,
  PencilAltIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import {
  formatURL,
  usePersonnelSummaries,
  useSOP,
  useSOPDraftApprovals,
  useSOPFile,
} from "../common/DataFetchers";
import PDFViewer from "../common/PDFViewer";
import { useUserContext } from "../common/BasePageScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import SOPArchiveModal from "./SOPArchiveModal";
import SOPAuditTrailCard from "./SOPAuditTrailCard";
import { getDate, getTime } from "../common/Utils";
import { DocumentTextIcon } from "@heroicons/react/outline";

export default function SOPViewer() {
  const { user, sopsRead, sopsWrite } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();

  const params = useParams<{ id: string }>();
  const sopID = params.id;

  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [hideSignatures, setHideSignatures] = useState(true);
  const [hideRecords, setHideRecords] = useState(true);

  const { sop, sopIsLoading, sopIsError, mutateSOP } = useSOP(sopID, sopsRead);
  const { sopFile } = useSOPFile(sopID, sopsRead);
  const { approvals } = useSOPDraftApprovals(sopID, sopsRead);
  const { personnelSummaries } = usePersonnelSummaries(sopsRead);

  const [isLabDirector, setIsLabDirector] = useState(false);

  useEffect(() => {
    const personnelIndex = personnelSummaries.findIndex(
      (personnel) => personnel.userID === user.id
    );

    if (personnelIndex === -1) {
      return;
    }

    const role = personnelSummaries[personnelIndex].role;
    setIsLabDirector(role.name === "Laboratory Director");
  }, [user, personnelSummaries]);

  if (!sopsRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (sopIsLoading) {
    return <LoadingComponent />;
  }

  if (sopIsError || !sop) {
    return <ErrorComponent />;
  }

  if (sop.state === "Draft") {
    navigate(`/regulator/sops/${sopID}/draft-review`, { replace: true });
    return <></>;
  }

  const pages = [
    { name: "SOPs", path: "/regulator/sops" },
    {
      name: `${sop.displayID} - ${sop.title} v${sop.majorVersion}.${sop.minorVersion}`,
      path: location.pathname,
    },
  ];

  const canPerformProcedureManualReview =
    sop.state === "Active" && isLabDirector && sopsWrite;
  const canEdit = sop.state === "Active" && sopsWrite;
  const canArchive = sop.state === "Active" && sopsWrite;

  return (
    <div>
      <FullWidthBreadcrumbBar pages={pages} />
      <div className="py-3">
        <div className="flex justify-end">
          {canPerformProcedureManualReview && (
            <Link to={`/regulator/sops/${sop.id}/procedure-manual-review`}>
              <button
                type="button"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <CalendarIcon
                  className="-ml-1 mr-3 h-5 w-5"
                  aria-hidden="true"
                />
                Procedure Manual Review
              </button>
            </Link>
          )}
          {canEdit && (
            <Link to={`/regulator/sops/${sop.id}/edit`}>
              <button
                type="button"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <PencilAltIcon
                  className="-ml-1 mr-3 h-5 w-5"
                  aria-hidden="true"
                />
                Edit
              </button>
            </Link>
          )}
          {canArchive && (
            <button
              type="button"
              onClick={() => {
                setArchiveModalOpen(true);
              }}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <ArchiveIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              Archive
              <SOPArchiveModal
                open={archiveModalOpen}
                setOpen={setArchiveModalOpen}
                sop={sop}
                mutateSOP={mutateSOP}
              />
            </button>
          )}
        </div>
      </div>
      <PDFViewer fileUrl={formatURL(`/sops/${sop.id}/file`)} file={sopFile} />
      <div className="bg-white mt-3 px-4 rounded-md shadow-sm">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Signatures
          </h3>
          <div className="flex justify-end">
            <button
              type="button"
              className="-mt-8 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={() => setHideSignatures(!hideSignatures)}
            >
              {hideSignatures ? (
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
              ) : (
                <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
        <div className="flow-root">
          {approvals.length === 0 ? (
            <ul className="-mb-4 px-6" hidden={hideSignatures}>
              <li>
                <div className="relative pb-8">
                  <p className="text-sm italic">None</p>
                </div>
              </li>
            </ul>
          ) : (
            <ul
              role="list"
              className="divide-y divide-gray-200"
              hidden={hideSignatures}
            >
              {approvals.map((approval) => (
                <li key={approval.id}>
                  <div className="flex items-center px-4 py-4 sm:px-6">
                    <div className="min-w-0 flex-1 flex items-center">
                      <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <div className="pl-6">
                          <Link
                            to={`/regulator/personnel/${approval.personnel.id}`}
                          >
                            <p className="text-sm font-medium text-blue-600 truncate">
                              {`${approval.personnel.firstName} ${approval.personnel.lastName}`.trim()}
                            </p>
                          </Link>
                          <Link
                            to={`/regulator/personnel/roles/${approval.personnel.role.id}`}
                          >
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              <span className="truncate">
                                {approval.personnel.role.name}
                              </span>
                            </p>
                          </Link>
                          <p className="mt-2 flex items-center text-sm text-gray-500">
                            {approval.status === "Pending" ? (
                              <DotsCircleHorizontalIcon
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-blue-400"
                                aria-hidden="true"
                              />
                            ) : approval.status === "Approved" ? (
                              <CheckCircleIcon
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                aria-hidden="true"
                              />
                            ) : (
                              <XCircleIcon
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
                                aria-hidden="true"
                              />
                            )}
                            {approval.status}
                          </p>
                        </div>
                        <div className="hidden md:block">
                          <div className="pt-5">
                            <img
                              className="-mt-5 border rounded-md bg-gray-50 flex-shrink-0 w-4/5 min-h-full"
                              src={approval.encodedSignature}
                              alt={"signature"}
                            />
                            <p className="mt-2 flex justify-center text-sm text-gray-500 w-4/5">
                              {getDate(
                                approval.approved
                                  ? approval.approved
                                  : undefined
                              )}{" "}
                              {getTime(
                                approval.approved
                                  ? approval.approved
                                  : undefined
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className="bg-white mt-3 px-4 rounded-md shadow-sm">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Records
          </h3>
          <div className="flex justify-end">
            <button
              type="button"
              className="-mt-8 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={() => setHideRecords(!hideRecords)}
            >
              {hideRecords ? (
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
              ) : (
                <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
        <div className="flow-root">
          {sop.records.length === 0 ? (
            <ul className="-mb-4 px-6" hidden={hideRecords}>
              <li>
                <div className="relative pb-8">
                  <p className="text-sm italic">None</p>
                </div>
              </li>
            </ul>
          ) : (
            <ul
              className="my-3 border border-gray-200 rounded-md divide-y divide-gray-200"
              hidden={hideRecords}
            >
              {sop.records.map((record) => (
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div className="w-0 flex-1 flex items-center">
                    <DocumentTextIcon
                      className="flex-shrink-0 h-5 w-5 text-blue-400"
                      aria-hidden="true"
                    />
                    <span className="ml-2 flex-1 w-0 truncate">
                      {`${record.form.title} - ${getDate(record.completed)}`}
                    </span>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <Link
                      to={`/regulator/records/${record.id}`}
                      className="font-medium text-blue-600 hover:text-blue-500"
                    >
                      View
                    </Link>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <SOPAuditTrailCard sop={sop} />
    </div>
  );
}
