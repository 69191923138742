import { ProgressBar, SpecialZoomLevel, Viewer } from "@react-pdf-viewer/core";
import type { ToolbarSlot } from "@react-pdf-viewer/toolbar";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import {
  RenderCurrentScaleProps,
  RenderZoomInProps,
  RenderZoomOutProps,
} from "@react-pdf-viewer/zoom";
import {
  DownloadIcon,
  ZoomInIcon,
  ZoomOutIcon,
} from "@heroicons/react/outline";
import React from "react";
import { PDFDocument, rgb } from "pdf-lib";
import { getDate, getFullName, getTime } from "./Utils";
import { useUserContext } from "./BasePageScreen";
import { UserDetails } from "./Entities.d";

async function timestampPdf(file: File | undefined, user: UserDetails) {
  if (!file) {
    return;
  }
  const existingPdfBytes = await file.arrayBuffer();
  const pdfDoc = await PDFDocument.load(existingPdfBytes);

  const pages = pdfDoc.getPages();
  pages.forEach((page) =>
    page.drawText(
      `Downloaded: ${getDate()}, ${getTime()} By: ${getFullName(
        user.firstName,
        user.lastName
      )}`,
      {
        x: 6,
        y: 6,
        size: 12,
        opacity: 0.75,
        color: rgb(0.15, 0.39, 0.92),
      }
    )
  );

  const pdfBytes = await pdfDoc.save();
  const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });

  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(pdfBlob);
  link.download = file.name;
  link.click();
}

export default function PDFViewer(props: {
  fileUrl: string;
  file: File | undefined;
}) {
  const url = props.fileUrl;
  const file = props.file;

  const { user } = useUserContext();

  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  return (
    <div className="h-192">
      <div className="flex flex-col h-full border border-gray-400">
        <div className="flex items-center p-1 border-b border-gray-400 bg-gray-200">
          <Toolbar>
            {(props: ToolbarSlot) => {
              const {
                CurrentPageInput,
                CurrentScale,
                NumberOfPages,
                ZoomIn,
                ZoomOut,
              } = props;
              return (
                <>
                  <div>
                    <ZoomOut>
                      {(props: RenderZoomOutProps) => (
                        <button
                          className="ml-3 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          onClick={props.onClick}
                        >
                          <ZoomOutIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      )}
                    </ZoomOut>
                  </div>
                  <div className="ml-3">
                    <CurrentScale>
                      {(props: RenderCurrentScaleProps) => (
                        <span>{`${Math.round(props.scale * 100)}%`}</span>
                      )}
                    </CurrentScale>
                  </div>
                  <div>
                    <ZoomIn>
                      {(props: RenderZoomInProps) => (
                        <button
                          className="ml-3 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          onClick={props.onClick}
                        >
                          <ZoomInIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      )}
                    </ZoomIn>
                  </div>
                  <div className="flex ml-auto w-min pl-2">
                    <span className="mt-1">Page</span>
                    <CurrentPageInput />
                  </div>
                  <div>
                    &nbsp;/ <NumberOfPages /> &nbsp;
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        timestampPdf(file, user);
                      }}
                      className="mr-3 inline-flex justify-center py-1 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <DownloadIcon
                        className="mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      Download
                    </button>
                  </div>
                </>
              );
            }}
          </Toolbar>
        </div>
        <div className="flex overflow-hidden">
          <Viewer
            fileUrl={url}
            plugins={[toolbarPluginInstance]}
            defaultScale={SpecialZoomLevel.PageWidth}
            renderLoader={(percentages: number) => (
              <div className="w-60">
                <ProgressBar progress={Math.round(percentages)} />
              </div>
            )}
            withCredentials={true}
          />
        </div>
      </div>
    </div>
  );
}
