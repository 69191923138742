import { useUserContext } from "../common/BasePageScreen";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  addCompetency,
  useCompetencyTypeSummaries,
  usePersonnelSummaries,
  useRecordSummaries,
  useWorkflowRunSummaries,
} from "../common/DataFetchers";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import React, { useEffect, useState } from "react";
import {
  formatErrorMessage,
  getDate,
  getFullName,
  getTime,
} from "../common/Utils";
import CustomSelectMenu from "../common/CustomSelectMenu";
import TableWithCheckbox from "../common/TableWithCheckbox";
import FileUploadComponent from "../common/FileUploadComponent";
import { XCircleIcon } from "@heroicons/react/solid";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import ErrorBanner from "../common/ErrorBanner";

export default function AddCompetencyForm() {
  const { competenciesRead, competenciesWrite } = useUserContext();
  const navigate = useNavigate();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const [searchParams] = useSearchParams();
  const typeID = searchParams.get("typeID");

  const {
    competencyTypeSummaries,
    competencyTypeSummariesIsLoading,
    competencyTypeSummariesIsError,
  } = useCompetencyTypeSummaries(competenciesWrite);

  const { personnelSummaries } = usePersonnelSummaries(competenciesWrite);
  const { recordSummaries } = useRecordSummaries(true);
  const { workflowRunSummaries } = useWorkflowRunSummaries(true);

  const [typeError, setTypeError] = useState(false);
  const [personnelError, setPersonnelError] = useState(false);
  const [approverError, setApproverError] = useState(false);

  const [approvers] = useState<Set<string>>(new Set<string>());
  const [links, setLinks] = useState<{ alias: string; url: string }[]>([]);
  const [attachments] = useState<File[]>([]);

  const [selectedRecords, setSelectedRecords] = useState<
    { id: string; display: string }[]
  >([]);

  const [selectedRuns, setSelectedRuns] = useState<
    { id: string; display: string }[]
  >([]);

  const [typeOption, setTypeOption] =
    useState<{ id: string; value: any; display: string }>();
  const [typeOptions, setTypeOptions] = useState<
    { id: string; value: any; display: string }[]
  >([]);
  useEffect(() => {
    const updatedTypeOptions: { id: string; value: any; display: string }[] =
      [];
    competencyTypeSummaries.forEach((type) =>
      updatedTypeOptions.push({
        id: type.id,
        value: type,
        display: type.name,
      })
    );

    let defaultOptionIndex = 0;
    if (typeID) {
      const optionIndex = updatedTypeOptions.findIndex(
        (type) => type.id === typeID
      );
      if (optionIndex !== -1) {
        defaultOptionIndex = optionIndex;
      }
    }
    setTypeOptions(updatedTypeOptions);
    setTypeOption(updatedTypeOptions[defaultOptionIndex]);
  }, [competencyTypeSummaries]);

  const [personnelOptions, setPersonnelOptions] = useState<
    { id: string; value: any; display: string }[]
  >([]);
  const [personnel, setPersonnel] = useState<{
    id: string;
    value: any;
    display: string;
  }>();
  useEffect(() => {
    const updatedPersonnelOptions: {
      id: string;
      value: any;
      display: string;
    }[] = [];
    personnelSummaries.forEach((personnel) =>
      updatedPersonnelOptions.push({
        id: personnel.id,
        value: personnel,
        display: getFullName(personnel.firstName, personnel.lastName),
      })
    );
    setPersonnelOptions(updatedPersonnelOptions);
    setPersonnel(updatedPersonnelOptions[0]);
  }, [personnelSummaries]);

  const [recordOptions, setRecordOptions] = useState<
    { id: string; display: string }[]
  >([]);
  useEffect(() => {
    const updatedRecordOptions = recordSummaries.map((record) => {
      return {
        id: record.id,
        display: `${record.form.title} - 
        ${getFullName(record.authorUser.firstName, record.authorUser.lastName)} 
        ${getDate(record.completed)} ${getTime(record.completed)}
        `,
      };
    });

    setRecordOptions(updatedRecordOptions);
  }, [recordSummaries]);

  const [runOptions, setRunOptions] = useState<
    { id: string; display: string }[]
  >([]);
  useEffect(() => {
    const updatedRunOptions = workflowRunSummaries.map((run) => {
      return {
        id: run.id,
        display: `${run.displayID} - ${run.template.name}`,
      };
    });
    setRunOptions(updatedRunOptions);
  }, [workflowRunSummaries]);

  if (!competenciesRead || !competenciesWrite) {
    navigate("/forbidden");
    return <></>;
  }

  if (competencyTypeSummariesIsLoading) {
    return <LoadingComponent />;
  }

  if (competencyTypeSummariesIsError) {
    return <ErrorComponent />;
  }

  return (
    <form
      className="mt-3 px-3 space-y-8 divide-y divide-gray-200"
      onSubmit={(e: React.SyntheticEvent) => {
        e.preventDefault();

        setShowErrorBanner(false);

        let isValid = true;

        setTypeError(false);
        if (!typeOption) {
          isValid = false;
          setTypeError(true);
        }

        setPersonnelError(false);
        if (!personnel) {
          isValid = false;
          setPersonnelError(true);
        }

        setApproverError(false);
        if (approvers.size === 0 || approvers.has(personnel!.id)) {
          isValid = false;
          setApproverError(true);
        }

        if (!isValid) {
          return;
        }

        addCompetency(
          typeOption!.id,
          personnel!.id,
          Array.from(approvers),
          selectedRecords.map((record) => {
            return record.id;
          }),
          selectedRuns.map((run) => {
            return run.id;
          }),
          links,
          attachments
        )
          .then((competency) =>
            navigate(`/regulator/competency/${competency.id}`)
          )
          .catch((error) => {
            setErrorBannerText(formatErrorMessage(error));
            setShowErrorBanner(true);
          });
      }}
    >
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <ErrorBanner
            showError={showErrorBanner}
            setShowError={setShowErrorBanner}
            errorText={errorBannerText}
          />
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Competency Information
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Fill in required details to create a new competency record.
            </p>
          </div>
          <div className="space-y-6 sm:space-y-5">
            {competencyTypeSummaries.length === 0 ? (
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="competencyType"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Competency Type
                  <span hidden={competencyTypeSummaries.length !== 0}>
                    <ExclamationCircleIcon className="ml-1 text-red-500 inline-flex h-5 w-5" />
                  </span>
                </label>
                <p className="mt-2 text-sm text-gray-500">
                  Please{" "}
                  <Link
                    to="/regulator/competency/type/add"
                    className="font-medium text-blue-500"
                    target="_blank"
                    rel="noreferrer"
                  >
                    create
                  </Link>{" "}
                  competency types before creating competency.
                </p>
              </div>
            ) : (
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <CustomSelectMenu
                  label={"Competency Type"}
                  options={typeOptions}
                  selected={typeOption}
                  setSelected={setTypeOption}
                />
                <p
                  className="text-sm text-red-500 col-start-2 col-span-2"
                  hidden={!typeError}
                >
                  * Competency Type is required.
                </p>
              </div>
            )}
            {personnelOptions.length === 0 ? (
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="personnel"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Personnel
                  <span>
                    <ExclamationCircleIcon className="ml-1 text-red-500 inline-flex h-5 w-5" />
                  </span>
                </label>
                <p className="mt-2 text-sm text-gray-500">
                  Please{" "}
                  <Link
                    to="/regulator/personnel/add"
                    className="font-medium text-blue-500"
                    target="_blank"
                    rel="noreferrer"
                  >
                    create
                  </Link>{" "}
                  personnel before creating competency.
                </p>
              </div>
            ) : (
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <CustomSelectMenu
                  label={"Personnel"}
                  options={personnelOptions}
                  selected={personnel}
                  setSelected={setPersonnel}
                />
                <p
                  className="text-sm text-red-500 col-start-2 col-span-2"
                  hidden={!personnelError}
                >
                  * Personnel is required.
                </p>
              </div>
            )}
            {personnelSummaries.length === 0 ? (
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="approvers"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Approvers
                  <span>
                    <ExclamationCircleIcon className="ml-1 text-red-500 inline-flex h-5 w-5" />
                  </span>
                </label>
                <p className="mt-2 text-sm text-gray-500">
                  Please{" "}
                  <Link
                    to="/regulator/personnel/add"
                    className="font-medium text-blue-500"
                    target="_blank"
                    rel="noreferrer"
                  >
                    create
                  </Link>{" "}
                  personnel before creating competency.
                </p>
              </div>
            ) : (
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="approvers"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Approvers
                </label>
                <div className="sm:col-span-2">
                  {personnelSummaries.map((personnel) => {
                    return (
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id={personnel.id}
                            name={personnel.id}
                            type="checkbox"
                            className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                            onChange={(e) => {
                              if (e.currentTarget.checked) {
                                approvers.add(e.currentTarget.id);
                              } else {
                                approvers.delete(e.currentTarget.id);
                              }
                            }}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor={personnel.id}
                            className="font-medium text-gray-700"
                          >
                            {getFullName(
                              personnel.firstName,
                              personnel.lastName
                            )}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <p
                  className="text-sm text-red-500 col-start-2 col-span-2"
                  hidden={!approverError}
                >
                  * At least one approver must be selected. Personnel cannot be
                  approver on own competency.
                </p>
              </div>
            )}
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="records"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Records
              </label>
              <div className="sm:col-span-2">
                {recordSummaries.length === 0 ? (
                  <p className="mt-2 text-sm font-medium text-gray-700">
                    No records
                  </p>
                ) : (
                  <TableWithCheckbox
                    header={"Record"}
                    options={recordOptions}
                    linkPrefix={"/records"}
                    selected={selectedRecords}
                    setSelected={setSelectedRecords}
                  />
                )}
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="workflowRuns"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Workflow Runs
              </label>
              <div className="sm:col-span-2">
                {workflowRunSummaries.length === 0 ? (
                  <p className="mt-2 text-sm font-medium text-gray-700">
                    No workflow runs
                  </p>
                ) : (
                  <TableWithCheckbox
                    header={"Workflow Run"}
                    options={runOptions}
                    linkPrefix={"/workflows/history"}
                    selected={selectedRuns}
                    setSelected={setSelectedRuns}
                  />
                )}
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="Links"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Links
              </label>
              <div className="sm:col-span-2">
                <button
                  type="button"
                  onClick={() => {
                    links.push({ alias: "", url: "" });
                    setLinks(Array.from(links));
                  }}
                  className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Add Link
                </button>
                {links.map((link, linkIdx) => {
                  return (
                    <div>
                      <div className="mt-3 inline-flex">
                        <div className="pr-4">
                          <label
                            htmlFor={`link-display-${linkIdx}`}
                            className="block text-sm font-medium text-gray-700"
                          >
                            Display
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name={`link-display-${linkIdx}`}
                              id={`link-display-${linkIdx}`}
                              defaultValue={link.alias}
                              key={link.alias}
                              onChange={(e) => {
                                link.alias = e.currentTarget.value;
                                setLinks(links);
                              }}
                              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor={`link-${linkIdx}`}
                            className="block text-sm font-medium text-gray-700"
                          >
                            Link
                          </label>
                          <div className="mt-1">
                            <input
                              type="url"
                              name={`link-${linkIdx}`}
                              id={`link-${linkIdx}`}
                              defaultValue={link.url}
                              key={link.url}
                              onChange={(e) => {
                                link.url = e.currentTarget.value;
                                setLinks(links);
                              }}
                              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>

                        <button
                          className="rounded-full h-5 w-5"
                          onClick={() => {
                            links.splice(linkIdx, 1);
                            setLinks(Array.from(links));
                          }}
                        >
                          <XCircleIcon className="h-5 w-5 text-red-500" />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="sm: col-span-3">
              <FileUploadComponent
                label={"Attachments"}
                fileTypes={"PDF"}
                files={attachments}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <Link to="/regulator/competency">
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
