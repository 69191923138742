import { useUserContext } from "../common/BasePageScreen";
import { Link, useNavigate } from "react-router-dom";
import { useSampleTypeSummaries } from "../common/DataFetchers";
import React, { useEffect, useState } from "react";
import { ReagentTypeSummary } from "../common/Entities.d";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import EmptyStateScreen from "../common/EmptyStateScreen";
import Pagination from "../common/Pagination";

export default function SamplesTypesList() {
  const { samplesRead, samplesWrite } = useUserContext();
  const navigate = useNavigate();

  const {
    sampleTypeSummaries,
    sampleTypeSummariesIsLoading,
    sampleTypeSummariesIsError,
  } = useSampleTypeSummaries(samplesRead);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [items, setItems] = useState<ReagentTypeSummary[]>([]);

  useEffect(() => {
    if (sampleTypeSummaries.length === 0) {
      return;
    }
    setItems(sampleTypeSummaries);
  }, [sampleTypeSummaries]);

  if (!samplesRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (sampleTypeSummariesIsLoading) {
    return <LoadingComponent />;
  }

  if (sampleTypeSummariesIsError) {
    return <ErrorComponent />;
  }

  const pageEndIndex = currentPage * itemsPerPage;
  const pageStartIndex = pageEndIndex - itemsPerPage;
  const pageSampleTypeSummaries: ReagentTypeSummary[] = items
    ? items.slice(pageStartIndex, pageEndIndex)
    : sampleTypeSummaries.slice(pageStartIndex, pageEndIndex);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const tableSampleTypeRows = pageSampleTypeSummaries.map(
    (sampleType, index) => (
      <tr
        key={sampleType.id}
        className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
      >
        <td className="px-6 py-4 whitespace-nowrap font-medium text-sm text-gray-900">
          {sampleType.name}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
          <Link
            to={`/regulator/samples/types/${sampleType.id}`}
            className="text-blue-600 hover:text-blue-900"
          >
            View
          </Link>
        </td>
      </tr>
    )
  );

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            {sampleTypeSummaries.length === 0 ? (
              <EmptyStateScreen
                item={"sample type"}
                createLink={"/regulator/samples/types/add"}
                writeAccess={samplesWrite}
              />
            ) : (
              <div>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Sample Type
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">View</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{tableSampleTypeRows}</tbody>
                </table>
                <Pagination
                  itemsPerPage={itemsPerPage}
                  totalItems={items ? items.length : sampleTypeSummaries.length}
                  currentPage={currentPage}
                  paginate={paginate}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
