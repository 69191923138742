import { useEffect, useState } from "react";
import BulkSOPFileComponent from "./BulkSOPFileComponent";
import { useUserContext } from "../common/BasePageScreen";
import { useNavigate } from "react-router-dom";

export default function BulkSOPCard() {
  const navigate = useNavigate();
  const { user } = useUserContext();

  useEffect(() => {
    if (!user) {
      return;
    }
    if (!user.isAdmin) {
      navigate("/forbidden");
    }
  }, [user]);

  const [files, setFiles] = useState<File[]>([]);

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      <section aria-labelledby="payment-details-heading">
        <div className="mt-2 sm:rounded-md sm:border sm:border-gray-200 sm:overflow-hidden">
          <div className="bg-white py-6 px-4 sm:p-6">
            <div>
              <h2
                id="payment-details-heading"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                Bulk SOP Import
              </h2>
              <p className="mt-1 text-sm text-gray-500">
                Add your existing SOPs without having to route through approvals
                again. Please note that the audit trail will indicate these SOPs
                were added by Bulk Import.
              </p>
              <div className="mt-2">
                <span className="font-medium text-sm text-gray-900">
                  Note: Pending SOPs will NOT persist once you navigate away
                  from this page or refresh.
                </span>
              </div>
            </div>

            <div className="mt-6">
              <BulkSOPFileComponent files={files} setFiles={setFiles} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
