import { RecordSectionTexts } from "../../common/Entities.d";
import * as React from "react";

export default function ParagraphRecordElement(props: {
  value: RecordSectionTexts;
}) {
  return (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <p className="max-w-4xl text-sm text-gray-500">
        {props.value.values.join(", ")}
      </p>
    </div>
  );
}
