import React, {
  createContext,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  AcademicCapIcon,
  BeakerIcon,
  BellIcon,
  ClipboardCheckIcon,
  CogIcon,
  DocumentDuplicateIcon,
  DocumentTextIcon,
  FingerPrintIcon,
  HomeIcon,
  MenuAlt2Icon,
  TemplateIcon,
  UsersIcon,
  ViewGridAddIcon,
  ViewListIcon,
  XIcon,
} from "@heroicons/react/outline";
import { ArrowRightIcon } from "@heroicons/react/solid";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import { TaskSummary, UserDetails } from "./Entities.d";
import { logout, useCurrentUser, useTaskSummaries } from "./DataFetchers";
import { classNames, getFullName } from "./Utils";
import { taskTypeToDisplayMap } from "./Constants.d";

interface UserContextInterface {
  user: UserDetails;
  workflowTemplatesRead: boolean;
  workflowTemplatesWrite: boolean;
  workflowHistoryRead: boolean;
  workflowHistoryWrite: boolean;
  samplesRead: boolean;
  samplesWrite: boolean;
  reagentsRead: boolean;
  reagentsWrite: boolean;
  equipmentRead: boolean;
  equipmentWrite: boolean;
  personnelRead: boolean;
  personnelWrite: boolean;
  competenciesRead: boolean;
  competenciesWrite: boolean;
  sopsRead: boolean;
  sopsWrite: boolean;
  formsRead: boolean;
  formsWrite: boolean;
  recordsRead: boolean;
  recordsWrite: boolean;
}

function createUserCtx() {
  const userCtx = createContext<UserContextInterface | undefined>(undefined);

  function useUserCtx() {
    const ctx = useContext(userCtx);
    if (ctx === undefined) {
      throw new Error("useUserCtx must be inside a Provider with a value");
    }

    return ctx;
  }

  return [useUserCtx, userCtx.Provider] as const;
}

export const [useUserContext, UserContextProvider] = createUserCtx();

export default function BasePageScreen(): JSX.Element {
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { taskSummaries } = useTaskSummaries("Open");

  const { currentUser, isAuthenticated, isCurrentUserLoading } =
    useCurrentUser();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/regulator/login");
    }
  }, [isAuthenticated]);

  if (isCurrentUserLoading || !currentUser) {
    return <></>;
  }

  const userNavigation = [
    {
      name: "Settings",
      action: () => {
        navigate("/regulator/settings");
      },
    },
    // {
    //   name: "Checklist Manager",
    //   action: () => {
    //     navigate("/regulator/checklist-manager");
    //   },
    // },
    {
      name: "Sign out",
      action: () => {
        logout().then(() => navigate("/regulator/login"));
      },
    },
  ];

  const currentUserContext: UserContextInterface = {
    user: currentUser,
    workflowTemplatesRead:
      currentUser.isAdmin || currentUser.workflowTemplates.includes("r"),
    workflowTemplatesWrite:
      currentUser.isAdmin || currentUser.workflowTemplates.includes("w"),
    workflowHistoryRead:
      currentUser.isAdmin || currentUser.workflowHistory.includes("r"),
    workflowHistoryWrite:
      currentUser.isAdmin || currentUser.workflowHistory.includes("w"),
    samplesRead: currentUser.isAdmin || currentUser.samples.includes("r"),
    samplesWrite: currentUser.isAdmin || currentUser.samples.includes("w"),
    reagentsRead: currentUser.isAdmin || currentUser.reagents.includes("r"),
    reagentsWrite: currentUser.isAdmin || currentUser.reagents.includes("w"),
    equipmentRead: currentUser.isAdmin || currentUser.equipment.includes("r"),
    equipmentWrite: currentUser.isAdmin || currentUser.equipment.includes("w"),
    personnelRead: currentUser.isAdmin || currentUser.personnel.includes("r"),
    personnelWrite: currentUser.isAdmin || currentUser.personnel.includes("w"),
    competenciesRead:
      currentUser.isAdmin || currentUser.competencies.includes("r"),
    competenciesWrite:
      currentUser.isAdmin || currentUser.competencies.includes("w"),
    sopsRead: currentUser.isAdmin || currentUser.sops.includes("r"),
    sopsWrite: currentUser.isAdmin || currentUser.sops.includes("w"),
    formsRead: currentUser.isAdmin || currentUser.forms.includes("r"),
    formsWrite: currentUser.isAdmin || currentUser.forms.includes("w"),
    recordsRead: currentUser.isAdmin || currentUser.records.includes("r"),
    recordsWrite: currentUser.isAdmin || currentUser.records.includes("w"),
  };

  const navEntries: {
    name: string;
    href: string;
    icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
    shouldAdd: boolean;
  }[] = [
    { name: "Home", href: "/regulator/home", icon: HomeIcon, shouldAdd: true },
    {
      name: "Workflow Templates",
      href: "/regulator/workflows/templates",
      icon: TemplateIcon,
      shouldAdd: currentUserContext.workflowTemplatesRead,
    },
    {
      name: "Workflow History",
      href: "/regulator/workflows/history",
      icon: ClipboardCheckIcon,
      shouldAdd: currentUserContext.workflowHistoryRead,
    },
    {
      name: "Samples",
      href: "/regulator/samples",
      icon: FingerPrintIcon,
      shouldAdd: currentUserContext.samplesRead,
    },
    {
      name: "Reagents",
      href: "/regulator/reagents",
      icon: BeakerIcon,
      shouldAdd: currentUserContext.reagentsRead,
    },
    {
      name: "Equipment",
      href: "/regulator/equipment",
      icon: CogIcon,
      shouldAdd: currentUserContext.equipmentRead,
    },
    {
      name: "Personnel",
      href: "/regulator/personnel",
      icon: UsersIcon,
      shouldAdd: currentUserContext.personnelRead,
    },
    {
      name: "Training & Competency",
      href: "/regulator/competency",
      icon: AcademicCapIcon,
      shouldAdd: true,
    },
    {
      name: "SOPs",
      href: "/regulator/sops",
      icon: DocumentDuplicateIcon,
      shouldAdd: currentUserContext.sopsRead,
    },
    {
      name: "Forms",
      href: "/regulator/forms",
      icon: ViewGridAddIcon,
      shouldAdd: currentUserContext.formsRead,
    },
    {
      name: "Records",
      href: "/regulator/records",
      icon: DocumentTextIcon,
      shouldAdd: currentUserContext.recordsRead,
    },
    {
      name: "Tasks",
      href: "/regulator/tasks",
      icon: ViewListIcon,
      shouldAdd: true,
    },
  ].filter((navEntry) => navEntry.shouldAdd);

  const navigation = navEntries.map((navEntry) => (
    <NavLink
      to={navEntry.href}
      key={navEntry.name}
      className={({ isActive }) =>
        classNames(
          isActive
            ? "bg-blue-800 text-white"
            : "text-blue-100 hover:bg-blue-600",
          "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
        )
      }
    >
      <navEntry.icon
        className="mr-3 flex-shrink-0 h-6 w-6 text-blue-300"
        aria-hidden="true"
      />
      {navEntry.name}
    </NavLink>
  ));

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-blue-700">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-4">
                <img
                  className="-ml-2 -mt-32 -mb-32 h-auto w-auto"
                  src={`${process.env.PUBLIC_URL}/dl_regulator_logo-full_color.svg`}
                  alt="Regulator"
                />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-2 space-y-1">{navigation}</nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden bg-blue-700 md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <img
                className="-ml-2 -mt-24 -mb-24 h-auto w-auto"
                src={`${process.env.PUBLIC_URL}/dl_regulator_logo-full_color.svg`}
                alt="Regulator"
              />
            </div>
            <div className="mt-5 flex-1 flex flex-col">
              <nav className="flex-1 px-2 space-y-1">{navigation}</nav>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
          <button
            type="button"
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 px-4 flex justify-between">
            <div className="flex-1 flex">
              {/*<form className="w-full flex md:ml-0" action="#" method="GET">*/}
              {/*    <label htmlFor="search-field" className="sr-only">*/}
              {/*        Search*/}
              {/*    </label>*/}
              {/*    <div className="relative w-full text-gray-400 focus-within:text-gray-600">*/}
              {/*        <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">*/}
              {/*            <SearchIcon className="h-5 w-5" aria-hidden="true"/>*/}
              {/*        </div>*/}
              {/*        <input*/}
              {/*            id="search-field"*/}
              {/*            className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"*/}
              {/*            placeholder="Search"*/}
              {/*            type="search"*/}
              {/*            name="search"*/}
              {/*        />*/}
              {/*    </div>*/}
              {/*</form>*/}
            </div>
            <div className="ml-4 flex items-center md:ml-6">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                    {taskSummaries.filter((task: TaskSummary) => !task.read)
                      .length !== 0 ? (
                      <span className="absolute top-1 right-1 block h-2 w-2 rounded-full ring-2 ring-white bg-red-400" />
                    ) : (
                      false
                    )}
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {taskSummaries.length === 0 ? (
                      <div className="py-1">
                        <p className="p-4 text-center text-gray-700">
                          No tasks
                        </p>
                      </div>
                    ) : (
                      <div className="py-1">
                        {taskSummaries.slice(0, 5).map((task) => (
                          <Menu.Item key={task.id}>
                            {({ active }) => (
                              <Link
                                to={`/regulator/tasks/${task.id}`}
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                <span className="inline-flex items-center -ml-1 mr-1 px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                  {taskTypeToDisplayMap.get(task.type)}
                                </span>
                                {`${
                                  task.assigner
                                    ? getFullName(
                                        task.assigner.firstName,
                                        task.assigner.lastName
                                      )
                                    : "Regulator Bot"
                                } sent a task`}
                              </Link>
                            )}
                          </Menu.Item>
                        ))}
                        <Menu.Item key="open tasks">
                          {({ active }) => (
                            <Link
                              to="/regulator/tasks"
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Open Tasks
                              <ArrowRightIcon className="absolute bottom-3.5 right-4 h-4 w-4 text-gray-400" />
                            </Link>
                          )}
                        </Menu.Item>
                      </div>
                    )}
                  </Menu.Items>
                </Transition>
              </Menu>

              {/* Profile dropdown */}
              <Menu as="div" className="ml-3 relative">
                <div>
                  <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                    <span className="sr-only">Open user menu</span>
                    <span className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-blue-500">
                      <span className="text-sm font-medium leading-none text-white">{`${currentUser.firstName.charAt(
                        0
                      )}${currentUser.lastName.charAt(0)}`}</span>
                    </span>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <button
                            type="button"
                            onClick={item.action}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "w-full block px-4 py-2 text-left text-sm text-gray-700"
                            )}
                          >
                            {item.name}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        <main className="flex-1 relative overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <UserContextProvider value={currentUserContext}>
                <Outlet />
              </UserContextProvider>
            </div>
          </div>
        </main>
        <footer>
          <p className="mt-2 mb-2 text-center text-base text-gray-400">
            2022 Decoto Labs, LLC. All rights reserved.
          </p>
        </footer>
      </div>
    </div>
  );
}
