import { Link, useNavigate, useParams } from "react-router-dom";
import FileUploadComponent from "../common/FileUploadComponent";
import React, { useEffect, useReducer, useState } from "react";
import CustomSelectMenu from "../common/CustomSelectMenu";
import { formatErrorMessage, getDate } from "../common/Utils";
import { updateReagent, useReagent } from "../common/DataFetchers";
import { AttachmentDetails, ReagentStatus, Status } from "../common/Entities.d";
import { PaperClipIcon } from "@heroicons/react/outline";
import { ArchiveIcon } from "@heroicons/react/solid";
import { useUserContext } from "../common/BasePageScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import ErrorBanner from "../common/ErrorBanner";

const statusOptions = [
  { id: "1", value: "Quarantined", display: "Quarantined" },
  { id: "2", value: "In Use", display: "In Use" },
  { id: "3", value: "Archived", display: "Archived" },
];

const qcStatusOptions = [
  { id: "1", value: "Queued", display: "Queued" },
  { id: "2", value: "Pass", display: "Pass" },
  { id: "3", value: "Fail", display: "Fail" },
];

export default function EditReagentForm(): JSX.Element {
  const { reagentsRead, reagentsWrite } = useUserContext();
  const navigate = useNavigate();

  const params = useParams<{ id: string }>();
  const reagentID = params.id as string;

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const { reagent, reagentIsLoading, reagentIsError, mutateReagent } =
    useReagent(reagentID, reagentsRead);

  const statusOptionIndex = reagent
    ? statusOptions.findIndex((option) => option.value === reagent.status)
    : 0;
  const [status, setStatus] = useState(statusOptions[statusOptionIndex]);

  const qcStatusOptionIndex = reagent
    ? qcStatusOptions.findIndex((option) => option.value === reagent.qcStatus)
    : 0;
  const [qcStatus, setQcStatus] = useState(
    qcStatusOptions[qcStatusOptionIndex]
  );

  const initialAttachmentsState = {
    active: Array<AttachmentDetails>(),
    archived: Array<AttachmentDetails>(),
  };

  type AttachmentAction = {
    type: "archive" | "active" | "initialize";
    id: string;
  };

  function reducer(
    state: typeof initialAttachmentsState,
    action: AttachmentAction
  ) {
    const updatedState = {
      active: Array.from(state.active),
      archived: Array.from(state.archived),
    };

    let srcAttachments: AttachmentDetails[];
    let dstAttachments: AttachmentDetails[];
    switch (action.type) {
      case "active":
        srcAttachments = updatedState.archived;
        dstAttachments = updatedState.active;
        break;
      case "archive":
        srcAttachments = updatedState.active;
        dstAttachments = updatedState.archived;
        break;
      case "initialize":
        return {
          active:
            reagent?.attachments
              .filter((attachment) => !attachment.archived)
              .map((attachment) => Object.assign({}, attachment)) || [],
          archived:
            reagent?.attachments
              .filter((attachment) => attachment.archived)
              .map((attachment) => Object.assign({}, attachment)) || [],
        };
    }

    const idx = srcAttachments.findIndex(
      (attachment) => attachment.id === action.id
    );
    if (idx < 0) {
      return state;
    }

    const attachment = srcAttachments.splice(idx, 1)[0];
    dstAttachments.push(attachment);

    return updatedState;
  }

  const [attachmentsState, dispatch] = useReducer(
    reducer,
    initialAttachmentsState
  );

  useEffect(() => {
    if (!reagent) {
      return;
    }

    dispatch({ type: "initialize", id: "" });
  }, [reagent]);

  const [addedAttachments] = useState<File[]>([]);

  if (!reagentsWrite || !reagentsRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (reagentIsLoading) {
    return <LoadingComponent />;
  }

  if (reagentIsError || !reagent) {
    return <ErrorComponent />;
  }

  const activeAttachmentSection = attachmentsState.active.map((attachment) => (
    <ul className="bg-white border border-gray-200 rounded-md divide-y divide-gray-200">
      <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
        <div className="w-0 flex-1 flex items-center">
          <PaperClipIcon
            className="flex-shrink-0 h-5 w-5 text-blue-400"
            aria-hidden="true"
          />
          <span className="ml-2 flex-1 w-0 truncate">
            {attachment.fileName}
          </span>
        </div>
        <div className="ml-4 flex-shrink-0">
          <button
            type="button"
            onClick={() => dispatch({ type: "archive", id: attachment.id })}
            className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <ArchiveIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
            Archive
          </button>
        </div>
      </li>
    </ul>
  ));

  const archivedAttachmentSection = attachmentsState.archived.map(
    (attachment) => (
      <ul className="bg-white border border-gray-200 rounded-md divide-y divide-gray-200">
        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
          <div className="w-0 flex-1 flex items-center">
            <ArchiveIcon
              className="flex-shrink-0 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <span className="ml-2 flex-1 w-0 truncate italic text-gray-500">
              {attachment.fileName}
            </span>
          </div>
          <div className="ml-4 flex-shrink-0">
            <button
              type="button"
              onClick={() => dispatch({ type: "active", id: attachment.id })}
              className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <ArchiveIcon
                className="-ml-0.5 mr-2 h-4 w-4"
                aria-hidden="true"
              />
              Unarchive
            </button>
          </div>
        </li>
      </ul>
    )
  );

  return (
    <form
      className="py-3 px-3 space-y-8 divide-y divide-gray-200"
      onSubmit={(e: React.SyntheticEvent) => {
        e.preventDefault();

        setShowErrorBanner(false);

        if (reagentsWrite) {
          updateReagent(
            reagentID,
            status.value as ReagentStatus,
            qcStatus.value as Status,
            addedAttachments,
            attachmentsState.active.map((attachment) => attachment.id),
            attachmentsState.archived.map((attachment) => attachment.id)
          )
            .then((reagent) => {
              mutateReagent(reagent, false);
              navigate(`/regulator/reagents/${reagentID}`);
            })
            .catch((error) => {
              setErrorBannerText(formatErrorMessage(error));
              setShowErrorBanner(true);
            });
        }
      }}
    >
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <ErrorBanner
            showError={showErrorBanner}
            setShowError={setShowErrorBanner}
            errorText={errorBannerText}
          />
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Edit Reagent
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Fill in required details to edit existing reagent.
            </p>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Reagent ID
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <p className="mt-2 font-medium text-sm text-gray-900">
                  {reagent.displayID}
                </p>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Reagent Type
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <p className="mt-2 font-medium text-sm text-gray-900">
                  {reagent.type.name}
                </p>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Date Received
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <p className="mt-2 font-medium text-sm text-gray-900">
                  {getDate(reagent.received)}
                </p>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Expiration Date
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <p className="mt-2 font-medium text-sm text-gray-900">
                  {getDate(reagent.expiration)}
                </p>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Lot
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <p className="mt-2 font-medium text-sm text-gray-900">
                  {reagent.lot}
                </p>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Aliquot Number
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <p className="mt-2 font-medium text-sm text-gray-900">
                  {reagent.aliquot.trim() === ""
                    ? "None"
                    : `${reagent.aliquot}`}
                </p>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <CustomSelectMenu
                label="Status"
                options={statusOptions}
                selected={status}
                setSelected={setStatus}
              />
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <CustomSelectMenu
                label="QC Status"
                options={qcStatusOptions}
                selected={qcStatus}
                setSelected={setQcStatus}
              />
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Current Attachments
              </label>
              <div className="col-span-2">
                <p className="ml-1 text-sm">Active</p>
                {attachmentsState.active.length === 0 ? (
                  <ul className="bg-white border border-gray-200 rounded-md divide-y divide-gray-200">
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm italic">
                      None
                    </li>
                  </ul>
                ) : (
                  <div>{activeAttachmentSection}</div>
                )}
              </div>
              <div className="col-start-2 col-span-2">
                <p className="ml-1 text-sm">Archived</p>
                {attachmentsState.archived.length === 0 ? (
                  <ul className="bg-white border border-gray-200 rounded-md divide-y divide-gray-200">
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm italic">
                      None
                    </li>
                  </ul>
                ) : (
                  <div>{archivedAttachmentSection}</div>
                )}
              </div>
            </div>
            <FileUploadComponent
              label={"New Attachments"}
              files={addedAttachments}
              fileTypes={"PDF, PNG, JPG"}
            />
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <Link to={`/regulator/reagents/${reagentID}`}>
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
