import React, { useEffect, useState } from "react";
import {
  classNames,
  formatDateInputValue,
  formatErrorMessage,
} from "../common/Utils";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { SOPCategorySummary } from "../common/Entities.d";
import { importSOP, useSOPCategorySummaries } from "../common/DataFetchers";
import { useUserContext } from "../common/BasePageScreen";
import CustomSelectMenu from "../common/CustomSelectMenu";
import { Link } from "react-router-dom";
import SuccessBanner from "../common/SuccessBanner";
import ErrorComponent from "../common/ErrorComponent";
import ErrorBanner from "../common/ErrorBanner";

interface categoryOptionInterface {
  id: string;
  value: SOPCategorySummary;
  display: string;
}

interface BulkSOPFileComponent {
  files: File[];
  setFiles: (files: File[]) => void;
}

export default function BulkSOPFileComponent(props: BulkSOPFileComponent) {
  const { user } = useUserContext();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const [showSuccess, setShowSuccess] = useState(false);
  const [successText, setSuccessText] = useState("");

  const [sopCategoryError, setSopCategoryError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [sopIdError, setSopIdError] = useState(false);

  const [fileIdx, setFileIdx] = useState(0);
  const file = props.files[fileIdx];

  const { sopCategorySummaries } = useSOPCategorySummaries(user.isAdmin);

  const [sopMajorVersion, setSOPMajorVersion] = useState(1);
  const [sopMinorVersion, setSOPMinorVersion] = useState(0);

  const [categoryOption, setCategoryOption] =
    useState<categoryOptionInterface>();
  const [categoryOptions, setCategoryOptions] = useState<
    categoryOptionInterface[]
  >([]);

  useEffect(() => {
    if (sopCategorySummaries.length == 0) {
      return;
    }

    const updatedCategoryOptions: categoryOptionInterface[] = [];
    sopCategorySummaries.forEach((category) =>
      updatedCategoryOptions.push({
        id: updatedCategoryOptions.length.toString(),
        value: category,
        display: category.name,
      })
    );

    setCategoryOptions(updatedCategoryOptions);
    setCategoryOption(updatedCategoryOptions[0]);
  }, [sopCategorySummaries]);

  function dropHandler(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    const data = e.dataTransfer.files;
    for (let i = 0; i < data.length; i++) {
      props.files.push(data[i]);
      props.setFiles(Array.from(props.files));
    }
  }

  function allowDrop(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
  }

  function handleUpload(e: React.ChangeEvent<HTMLInputElement>) {
    const data = e.target.files;
    if (data) {
      for (let i = 0; i < data.length; i++) {
        props.files.push(data[i]);
        props.setFiles(Array.from(props.files));
      }
    }
  }

  function removeFile(index: number) {
    props.files.splice(index, 1);
    props.setFiles(Array.from(props.files));

    if (props.files.length === 0) {
      setFileIdx(0);
    }
  }

  if (props.files.length > 0 && !file) {
    return <ErrorComponent />;
  }

  return (
    <div>
      <ErrorBanner
        showError={showErrorBanner}
        setShowError={setShowErrorBanner}
        errorText={errorBannerText}
      />
      <SuccessBanner
        showSuccess={showSuccess}
        setShowSuccess={setShowSuccess}
        successText={successText}
      />
      <div className="sm:grid sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
        <div className="mt-1 sm:mt-0">
          <div
            className="w-full flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed bg-white rounded-md"
            onDragOver={allowDrop}
            onDrop={dropHandler}
          >
            <div className="space-y-1 text-center">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="flex text-sm text-gray-600">
                <label
                  htmlFor={"file-upload"}
                  className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                >
                  <span>Upload a file</span>
                  <input
                    id={"file-upload"}
                    name="file-upload"
                    type="file"
                    className="sr-only"
                    onChange={handleUpload}
                  />
                </label>
                <p className="pl-1">or drag and drop</p>
              </div>
              <p className="text-xs text-gray-500">{"PDF up to 10 MB"}</p>
            </div>
          </div>
        </div>
        <div>
          <h2 className="font-semibold text-lg">Pending SOPs</h2>
          {props.files.length === 0 ? (
            <p className="mt-2 italic">None</p>
          ) : (
            <form
              onSubmit={(e: React.SyntheticEvent) => {
                e.preventDefault();

                setShowSuccess(false);
                setShowErrorBanner(false);

                const target = e.target as typeof e.target & {
                  sopDisplayIDSuffix: { value: string };
                  reviewedDate: { value: string };
                  sopTitle: { value: string };
                };

                let isValid = true;

                setSopIdError(false);
                target.sopDisplayIDSuffix.value =
                  target.sopDisplayIDSuffix.value.trim();
                if (target.sopDisplayIDSuffix.value === "") {
                  setSopIdError(true);
                  isValid = false;
                }

                setTitleError(false);
                target.sopTitle.value = target.sopTitle.value.trim();
                if (target.sopTitle.value === "") {
                  setTitleError(true);
                  isValid = false;
                }

                setSopCategoryError(false);
                if (!categoryOption) {
                  setSopCategoryError(true);
                  isValid = false;
                }

                if (!isValid) {
                  return;
                }
                const reviewed =
                  target.reviewedDate.value === ""
                    ? target.reviewedDate.value
                    : new Date(formatDateInputValue(target.reviewedDate.value));

                importSOP(
                  categoryOption!.value.id,
                  target.sopDisplayIDSuffix.value.trim(),
                  target.sopTitle.value.trim(),
                  file,
                  sopMajorVersion,
                  sopMinorVersion,
                  reviewed
                )
                  .then((sop) => {
                    const newLength = props.files.length - 1;
                    if (fileIdx >= newLength) {
                      setFileIdx(newLength - 1);
                    }
                    removeFile(fileIdx);
                    setSuccessText(
                      `Successfully imported ${sop.displayID} - ${sop.title}v${sop.majorVersion}.${sop.minorVersion}`
                    );
                    setShowSuccess(true);
                  })
                  .catch((error) => {
                    setErrorBannerText(formatErrorMessage(error));
                    setShowErrorBanner(true);
                  });
              }}
            >
              <div>
                <span className="relative z-0 inline-flex shadow-sm rounded-md">
                  <button
                    type="button"
                    disabled={fileIdx - 1 < 0}
                    onClick={() => setFileIdx(fileIdx - 1)}
                    className="relative inline-flex items-center px-1 py-1 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                  <button
                    type="button"
                    disabled={fileIdx + 1 >= props.files.length}
                    onClick={() => setFileIdx(fileIdx + 1)}
                    className="-ml-px relative inline-flex items-center px-1 py-1 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
                  </button>
                </span>
                <table className="min-w-full divide-y divide-gray-200 mt-2 border border-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {`Import Info - File ${fileIdx + 1} of ${
                          props.files.length
                        }`}
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Action</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    <tr key={file?.name} className="grid grid-cols-2">
                      <td className="px-3 py-4 whitespace-nowrap text-sm font-medium">
                        {sopCategorySummaries.length === 0 ? (
                          <div>
                            <div>
                              File:{" "}
                              <span className="font-normal">{file?.name}</span>
                            </div>
                            <p className="text-sm text-gray-500">
                              Please{" "}
                              <Link
                                to="/regulator/sops/categories/add"
                                className="font-medium text-blue-500"
                                target="_blank"
                                rel="noreferrer"
                              >
                                create
                              </Link>{" "}
                              SOP category before importing SOP.
                            </p>
                          </div>
                        ) : (
                          <div>
                            <div>
                              <CustomSelectMenu
                                label={"SOP Category"}
                                options={categoryOptions}
                                selected={categoryOption}
                                setSelected={setCategoryOption}
                              />
                              <p
                                className="mt-2 text-sm text-red-500"
                                hidden={!sopCategoryError}
                              >
                                *SOP Category is required.
                              </p>
                            </div>
                            <div className="mt-3">
                              <label
                                htmlFor="sopDisplayIDSuffix"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                              >
                                SOP ID
                              </label>
                              <div className="max-w-lg flex rounded-md shadow-sm">
                                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                                  {categoryOption?.value.initials}
                                </span>
                                <input
                                  type="text"
                                  name="sopDisplayIDSuffix"
                                  id="sopDisplayIDSuffix"
                                  className={classNames(
                                    "flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md sm:text-sm border",
                                    "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                                  )}
                                />
                              </div>
                              <p
                                className="mt-2 text-sm text-red-500"
                                hidden={!sopIdError}
                              >
                                *SOP ID is required.
                              </p>
                            </div>
                          </div>
                        )}
                        <div
                          className="mt-3"
                          hidden={sopCategorySummaries.length === 0}
                        >
                          <label
                            htmlFor="reviewedDate"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Date Reviewed
                            <p className="text-gray-500 font-normal">
                              (Optional)
                            </p>
                          </label>
                          <div className="mt-1">
                            <input
                              type="date"
                              name="reviewedDate"
                              id="reviewedDate"
                              className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              placeholder="Enter title"
                            />
                          </div>
                        </div>
                      </td>
                      <td className="mt-2.5 px-3 py-4 whitespace-nowrap text-sm font-medium">
                        <div hidden={sopCategorySummaries.length === 0}>
                          <div>
                            <label
                              htmlFor="title"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Title
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="sopTitle"
                                id="sopTitle"
                                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                placeholder="Enter title"
                              />
                              <p
                                className="mt-2 text-sm text-red-500"
                                hidden={!titleError}
                              >
                                *Title is required.
                              </p>
                            </div>
                          </div>
                          <div className="mt-3">
                            <label
                              htmlFor="version"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Version
                            </label>
                            <div className="mt-1">
                              <div className="flex sm:inline-flex sm:w-32">
                                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm shadow-sm">
                                  Major
                                </span>
                                <input
                                  type="number"
                                  step="1"
                                  min="0"
                                  name="sopMajorVersion"
                                  id="sopMajorVersion"
                                  defaultValue={sopMajorVersion}
                                  onChange={(e) =>
                                    setSOPMajorVersion(
                                      parseInt(e.currentTarget.value)
                                    )
                                  }
                                  className={classNames(
                                    "flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md sm:text-sm border",
                                    "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                                  )}
                                />
                              </div>
                              <div className="flex sm:inline-flex sm:w-32">
                                <span className="sm:ml-4 inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm shadow-sm">
                                  Minor
                                </span>
                                <input
                                  type="number"
                                  step="1"
                                  min="0"
                                  name="sopMinorVersion"
                                  id="sopMinorVersion"
                                  defaultValue={sopMinorVersion}
                                  onChange={(e) =>
                                    setSOPMinorVersion(
                                      parseInt(e.currentTarget.value)
                                    )
                                  }
                                  className={classNames(
                                    "flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md sm:text-sm border",
                                    "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                                  )}
                                />
                              </div>
                            </div>
                            <div className="mt-8">
                              File:{" "}
                              <p className="font-normal truncate">
                                {file?.name}
                              </p>
                            </div>
                            <div className="mt-5 flex flex-col">
                              <div className="flex justify-end">
                                <button
                                  type="submit"
                                  className="ml-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                >
                                  Import
                                </button>
                                <button
                                  type="button"
                                  onClick={() => {
                                    const newLength = props.files.length - 1;
                                    if (fileIdx >= newLength) {
                                      setFileIdx(newLength - 1);
                                    }
                                    removeFile(fileIdx);
                                  }}
                                  className="ml-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
