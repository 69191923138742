import { Fragment, useCallback, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { v4 as uuid } from "uuid";

interface SelectProps {
  label: string;
  type: string;
  filteredStoreValues: any[];
  map: Map<string, { id: string; display: string }>;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function getOptions(filteredStoreValues: any[]) {
  const options: { id: string; display: string }[] = [];
  filteredStoreValues.map((value) =>
    options.push({ id: value.id, display: value.displayID })
  );

  return options;
}

export default function CustomSelectMapMenu(props: SelectProps) {
  const options = getOptions(props.filteredStoreValues);
  const [selected, setSelected] = useState(options[0]);

  if (options.findIndex((o) => o.id === selected.id) < 0) {
    setSelected(options[0]);
  }

  if (!props.map.has(props.type)) {
    props.map.set(props.type, {
      id: options[0].id,
      display: options[0].display,
    });
  }

  const updateSelected = (selected: {
    type: string;
    id: string;
    display: string;
  }) => {
    props.map.set(selected.type, {
      id: selected.id,
      display: selected.display,
    });
    setSelected(selected);
  };

  return (
    <Listbox value={selected} onChange={updateSelected}>
      <Listbox.Label className="block text-sm font-medium text-gray-700">
        {props.label}
      </Listbox.Label>
      <div className="mt-1 relative">
        <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
          <span className="block truncate">{selected.display}</span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
            {options.map((option) => (
              <Listbox.Option
                key={uuid()}
                className={({ active }) =>
                  classNames(
                    active ? "text-white bg-blue-600" : "text-gray-900",
                    "cursor-default select-none relative py-2 pl-3 pr-9"
                  )
                }
                value={option}
              >
                {({ active }) => (
                  <>
                    <span
                      className={classNames(
                        selected.display === option.display
                          ? "font-semibold"
                          : "font-normal",
                        "block truncate"
                      )}
                    >
                      {option.display}
                    </span>

                    {selected.display === option.display ? (
                      <span
                        className={classNames(
                          active ? "text-white" : "text-blue-600",
                          "absolute inset-y-0 right-0 flex items-center pr-4"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
