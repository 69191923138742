import { NavLink, resolvePath, useLocation } from "react-router-dom";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface TabsProps {
  tabs: {
    name: string;
    href: string;
  }[];
}

export default function Tabs(props: TabsProps): JSX.Element {
  const location = useLocation();

  return (
    <div>
      <div className="hidden sm:block">
        <nav
          className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
          aria-label="Tabs"
        >
          {props.tabs.map((tab, tabIdx) => (
            <NavLink
              key={tabIdx}
              end
              to={tab.href}
              className={({ isActive }) =>
                classNames(
                  isActive
                    ? "text-gray-900"
                    : "text-gray-500 hover:text-gray-700",
                  tabIdx === 0 ? "rounded-l-lg" : "",
                  tabIdx === props.tabs.length - 1 ? "rounded-r-lg" : "",
                  "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                )
              }
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  location.pathname === resolvePath(tab.href).pathname
                    ? "bg-blue-500"
                    : "bg-transparent",
                  "absolute inset-x-0 bottom-0 h-0.5"
                )}
              />
            </NavLink>
          ))}
        </nav>
      </div>
    </div>
  );
}
