import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";
import { classNames, formatErrorMessage } from "../common/Utils";
import { updateSampleType, useSampleType } from "../common/DataFetchers";
import { useUserContext } from "../common/BasePageScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import ErrorBanner from "../common/ErrorBanner";

export default function EditSampleTypeForm(): JSX.Element {
  const { samplesRead, samplesWrite } = useUserContext();
  const navigate = useNavigate();

  const params = useParams<{ id: string }>();
  const typeID = params.id as string;

  const {
    sampleType,
    sampleTypeIsLoading,
    sampleTypeIsError,
    mutateSampleType,
  } = useSampleType(typeID, samplesWrite);

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const [nameError, setNameError] = useState(false);

  if (!samplesWrite || !samplesRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (sampleTypeIsLoading) {
    return <LoadingComponent />;
  }

  if (sampleTypeIsError || !sampleType) {
    return <ErrorComponent />;
  }

  return (
    <form
      className="py-3 px-3 space-y-8 divide-y divide-gray-200"
      onSubmit={(e: React.SyntheticEvent) => {
        e.preventDefault();

        setShowErrorBanner(false);

        const target = e.target as typeof e.target & {
          name: { value: string };
        };

        target.name.value = target.name.value.trim();
        const sampleTypeName = target.name.value;

        setNameError(false);
        if (sampleTypeName === "") {
          setNameError(true);
          return;
        }

        if (samplesWrite) {
          updateSampleType(sampleType?.id, { name: target.name.value })
            .then((sampleType) => {
              mutateSampleType(sampleType, false);
              navigate(`/regulator/samples/types/${sampleType?.id}`);
            })
            .catch((error) => {
              setErrorBannerText(formatErrorMessage(error));
              setShowErrorBanner(true);
            });
        }
      }}
    >
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <ErrorBanner
            showError={showErrorBanner}
            setShowError={setShowErrorBanner}
            errorText={errorBannerText}
          />
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Add Sample Type
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Fill in required details to create new sample type.
            </p>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Sample Type Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="name"
                  id="name"
                  defaultValue={sampleType.name}
                  className={classNames(
                    "max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md",
                    nameError
                      ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                      : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                  )}
                />
                <p className="mt-2 text-sm text-red-500" hidden={!nameError}>
                  * Sample Type Name is required.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <Link to={"/regulator/samples/types"}>
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
