import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import { Link, useNavigate } from "react-router-dom";
import { AcademicCapIcon } from "@heroicons/react/outline";
import EmptyStateScreen from "../common/EmptyStateScreen";
import InformationCircleIcon from "@heroicons/react/outline/InformationCircleIcon";
import * as React from "react";
import { useCompetencyTypeSummaries } from "../common/DataFetchers";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import { useUserContext } from "../common/BasePageScreen";

const pages = [
  { name: "Training & Competency", path: "/regulator/competency" },
];

export default function CompetencyTypeList() {
  const navigate = useNavigate();
  const { competenciesRead, competenciesWrite } = useUserContext();

  const {
    competencyTypeSummaries,
    competencyTypeSummariesIsError,
    competencyTypeSummariesIsLoading,
  } = useCompetencyTypeSummaries(true);

  if (competencyTypeSummariesIsLoading) {
    return <LoadingComponent />;
  }

  if (competencyTypeSummariesIsError) {
    return <ErrorComponent />;
  }

  if (!competenciesRead) {
    navigate("/forbidden");
  }

  return (
    <div>
      <FullWidthBreadcrumbBar pages={pages} />
      <div className="py-3 text-right">
        <Link
          to="/regulator/competency/type/add"
          hidden={!competenciesWrite}
          className="px-2"
        >
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <AcademicCapIcon
              className="-ml-1 mr-3 h-5 w-5"
              aria-hidden="true"
            />
            Add Competency Type
          </button>
        </Link>
        <Link to="/regulator/competency/add" hidden={!competenciesWrite}>
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <AcademicCapIcon
              className="-ml-1 mr-3 h-5 w-5"
              aria-hidden="true"
            />
            Add Competency
          </button>
        </Link>
      </div>
      <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
        Competency Types
      </h2>
      {competencyTypeSummaries.length === 0 ? (
        <div className="mt-2 p-1 bg-white rounded-md shadow-sm">
          <EmptyStateScreen
            item={"competency"}
            createLink={"/competency/type/add"}
            writeAccess={true}
          />
        </div>
      ) : (
        <div className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
          {competencyTypeSummaries.map((competencyType) => (
            <li
              key={competencyType.name}
              className="col-span-1 flex shadow-sm rounded-md"
            >
              <div className="flex-1 flex items-center justify-between border border-gray-200 bg-white rounded-md truncate">
                <div className="flex-1 px-4 py-2 text-sm truncate">
                  <Link
                    to={`/regulator/competency/active?typeID=${competencyType.id}`}
                    className="text-gray-900 font-medium hover:text-gray-600"
                  >
                    {competencyType.name}
                  </Link>
                  <p className="text-gray-500">
                    {competencyType.activeCompetencyCount} Active Personnel
                  </p>
                </div>
                <div className="flex-shrink-0 pr-2">
                  <Link to={`/regulator/competency/type/${competencyType.id}`}>
                    <button
                      type="button"
                      className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <span className="sr-only">Open options</span>
                      <InformationCircleIcon
                        className="w-5 h-5 text-blue-500"
                        aria-hidden="true"
                      />
                    </button>
                  </Link>
                </div>
              </div>
            </li>
          ))}
        </div>
      )}
    </div>
  );
}
