import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { SaveIcon, XIcon } from "@heroicons/react/outline";
import { KeyedMutator } from "swr";
import { TaskDetails } from "../common/Entities.d";
import { updateTaskClose } from "../common/DataFetchers";
import { useNavigate } from "react-router-dom";
import { formatErrorMessage } from "../common/Utils";
import ErrorBanner from "../common/ErrorBanner";

interface TaskCloseModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  mutateTask: KeyedMutator<TaskDetails>;
  taskId: string;
}

export default function TaskCloseModal(props: TaskCloseModalProps) {
  const navigate = useNavigate();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState(false);

  function closeTask() {
    setReasonError(false);
    if (reason.trim() === "") {
      setReasonError(true);
      return;
    }

    updateTaskClose(props.taskId, reason.trim())
      .then((task) => {
        props.mutateTask(task, false);
        props.setOpen(false);
        navigate("/regulator/tasks");
      })
      .catch((error) => {
        setErrorBannerText(formatErrorMessage(error));
        setShowErrorBanner(true);
      });
  }

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={(e) => {
          setShowErrorBanner(false);
          props.setOpen(e);
        }}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="py-5">
                <ErrorBanner
                  showError={showErrorBanner}
                  setShowError={setShowErrorBanner}
                  errorText={errorBannerText}
                />
              </div>
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  onClick={() => props.setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                  <SaveIcon
                    className="h-6 w-6 text-blue-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Mark Task as Closed
                  </Dialog.Title>
                </div>
              </div>
              <div className="mt-2">
                <label
                  htmlFor="comment"
                  className="block text-sm font-medium text-gray-700"
                >
                  Provide your reason for marking the task as closed
                </label>
                <div className="mt-1">
                  <textarea
                    rows={4}
                    name="comment"
                    id="comment"
                    className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    defaultValue={reason}
                    onChange={(e) => {
                      setReason(e.currentTarget.value);
                    }}
                  />
                </div>
                <p className="mt-2 text-sm text-red-500" hidden={!reasonError}>
                  *Reason for marking the task as closed is required.
                </p>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => closeTask()}
                >
                  Mark as Closed
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => {
                    setShowErrorBanner(false);
                    props.setOpen(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
