import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "./form_elements/Header";
import Paragraph from "./form_elements/Paragraph";
import React, { useEffect, useState } from "react";
import { PencilAltIcon } from "@heroicons/react/solid";
import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import TextInput from "./form_elements/TextInput";
import TextArea from "./form_elements/TextArea";
import NumberInput from "./form_elements/NumberInput";
import DateInput from "./form_elements/DateInput";
import LinkInput from "./form_elements/LinkInput";
import Checkbox from "./form_elements/Checkbox";
import SelectMenu from "./form_elements/SelectMenu";
import RadioGroup from "./form_elements/RadioGroup";
import InternalEntityInput from "./form_elements/InternalEntityInput";
import Attachment from "./form_elements/Attachment";
import { addRecord, formAuditPath, useForm } from "../common/DataFetchers";
import { useUserContext } from "../common/BasePageScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import AuditTrailCard from "../common/AuditTrailCard";
import { v4 as uuid } from "uuid";

function containsError(errors: boolean[]) {
  let containsError = false;
  errors.forEach((e) => {
    if (e) {
      containsError = true;
    }
  });
  return containsError;
}

function textValueIsEmpty(value: string[]) {
  let isEmpty = true;
  if (value.length === 0) {
    return isEmpty;
  }
  value.forEach((str) => {
    if (str.trim() !== "") {
      isEmpty = false;
    }
  });

  return isEmpty;
}

export default function FormViewer() {
  const navigate = useNavigate();
  const { formsRead, formsWrite } = useUserContext();

  const params = useParams<{ id: string }>();
  const formID = params.id as string;

  const [errors, setErrors] = useState<boolean[]>();
  const [recordOrder] = useState<
    {
      recordValueArray: string;
      recordValueIndex: number;
    }[]
  >([]);

  const [textValues, setTextValues] = useState<Array<string[]>>([]);
  const [attachments, setAttachments] = useState<Array<File[]>>([]);

  const { form, formIsLoading, formIsError } = useForm(formID, formsRead);

  useEffect(() => {
    if (!form) {
      return;
    }

    setErrors(new Array(form.order.length).fill(false));

    form.order.forEach((formOrderElement) => {
      if (formOrderElement.category === "Attachment") {
        attachments.push([]);
        recordOrder.push({
          recordValueArray: "attachments",
          recordValueIndex: attachments.length - 1,
        });
      } else {
        textValues.push([]);
        recordOrder.push({
          recordValueArray: "textValues",
          recordValueIndex: textValues.length - 1,
        });
      }
    });
  }, [form]);

  if (!formsRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (formIsLoading || !errors) {
    return <LoadingComponent />;
  }

  if (formIsError || !form) {
    return <ErrorComponent />;
  }

  const pages = [
    { name: "Forms", path: "/regulator/forms" },
    { name: form.title, path: `/regulator/forms/${form.id}` },
  ];

  function createRecord(e: React.SyntheticEvent) {
    e.preventDefault();

    if (!form || !errors) {
      return;
    }

    setErrors(new Array(errors.length).fill(false));

    form.order.forEach((orderComponent, orderIdx) => {
      const categoryIndex = orderComponent.categoryIndex;
      const category = orderComponent.category;

      if (category === "SimpleInput") {
        const element = form.simpleInputs[categoryIndex];
        const value = textValues[recordOrder[orderIdx].recordValueIndex];
        errors[orderIdx] = element.required && textValueIsEmpty(value);
      }

      if (category === "SimpleSelect") {
        const element = form.simpleSelects[categoryIndex];
        const value = textValues[recordOrder[orderIdx].recordValueIndex];
        errors[orderIdx] = element.required && textValueIsEmpty(value);
      }

      if (category === "EntitySelect") {
        const element = form.entitySelects[categoryIndex];
        const value = textValues[recordOrder[orderIdx].recordValueIndex];
        errors[orderIdx] = element.required && textValueIsEmpty(value);
      }

      if (category === "Attachment") {
        const element = form.attachments[categoryIndex];
        const value = attachments[recordOrder[orderIdx].recordValueIndex];
        errors[orderIdx] = element.required && value.length === 0;
      }
    });

    setErrors(Array.from(errors));

    const order: string[] = [];
    const recordTextValues: string[] = [];
    const recordAttachments: File[] = [];

    let currTextStart = 0;
    let currAttachmentStart = 0;

    if (containsError(errors)) {
      return;
    } else {
      const formID = form.id;
      form.order.forEach((orderComponent, orderIdx) => {
        const category = orderComponent.category;

        if (category === "Text") {
          order.push("0:0");
        }

        if (
          category === "SimpleInput" ||
          category === "SimpleSelect" ||
          category === "EntitySelect"
        ) {
          const textValuesIndex = recordOrder[orderIdx].recordValueIndex;
          const valueArray = textValues[textValuesIndex];
          valueArray.forEach((v) => recordTextValues.push(v));

          const start = currTextStart;
          const end = start + valueArray.length;

          order.push(`${start}:${end}`);

          currTextStart = end;
        }

        if (category === "Attachment") {
          const attachmentValuesIndex = recordOrder[orderIdx].recordValueIndex;
          const valueArray = attachments[attachmentValuesIndex];
          valueArray.forEach((v) => recordAttachments.push(v));

          const start = currAttachmentStart;
          const end = start + valueArray.length;

          order.push(`${start}:${end}`);

          currAttachmentStart = end;
        }
      });

      addRecord(formID, order, recordTextValues, recordAttachments)
        .then((record) => navigate(`/regulator/records/${record.id}`))
        .catch((e) => {
          console.log(e);
        });
    }
  }

  return (
    <div>
      <FullWidthBreadcrumbBar pages={pages} />
      <div className="py-3" hidden={form.status === "Archived"}>
        <div className="flex justify-end">
          <Link to={`/regulator/forms/${form.id}/edit`} hidden={!formsWrite}>
            <button
              type="button"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <PencilAltIcon
                className="-ml-1 mr-3 h-5 w-5"
                aria-hidden="true"
              />
              Edit
            </button>
          </Link>
        </div>
      </div>
      <form className="mt-3 py-3 px-3 space-y-8" onSubmit={createRecord}>
        <div className="px-3 pb-2">
          <h1 className="text-2xl leading-6 font-medium text-gray-900">
            {form.title}
          </h1>
          <div className="mt-6 space-y-6 sm:space-y-5">
            {form.order.map(function (orderComponent, orderIdx) {
              const index = orderComponent.categoryIndex;
              const type = orderComponent.category;
              if (type === "Text") {
                const textElement = form.texts[index];
                if (textElement.elementType === "SectionHeader") {
                  return (
                    <div key={uuid()}>
                      <Header formText={textElement} />
                    </div>
                  );
                }
                if (textElement.elementType === "Paragraph") {
                  return (
                    <div key={uuid()}>
                      <Paragraph formText={textElement} />
                    </div>
                  );
                }
              }
              if (type === "SimpleInput") {
                const simpleInputElement = form.simpleInputs[index];
                if (simpleInputElement.elementType === "TextInput") {
                  return (
                    <div key={uuid()}>
                      <TextInput
                        simpleFormInput={simpleInputElement}
                        error={errors[orderIdx]}
                        recordPointer={recordOrder[orderIdx]}
                        textValues={textValues}
                        setTextValues={setTextValues}
                      />
                    </div>
                  );
                }
                if (simpleInputElement.elementType === "TextArea") {
                  return (
                    <div key={uuid()}>
                      <TextArea
                        simpleFormInput={simpleInputElement}
                        error={errors[orderIdx]}
                        recordPointer={recordOrder[orderIdx]}
                        textValues={textValues}
                        setTextValues={setTextValues}
                      />
                    </div>
                  );
                }
                if (simpleInputElement.elementType === "Number") {
                  return (
                    <div key={uuid()}>
                      <NumberInput
                        simpleFormInput={simpleInputElement}
                        error={errors[orderIdx]}
                        recordPointer={recordOrder[orderIdx]}
                        textValues={textValues}
                        setTextValues={setTextValues}
                      />
                    </div>
                  );
                }
                if (simpleInputElement.elementType === "Date") {
                  return (
                    <div key={uuid()}>
                      <DateInput
                        simpleFormInput={simpleInputElement}
                        error={errors[orderIdx]}
                        recordPointer={recordOrder[orderIdx]}
                        textValues={textValues}
                        setTextValues={setTextValues}
                      />
                    </div>
                  );
                }
                if (simpleInputElement.elementType === "Link") {
                  return (
                    <div key={uuid()}>
                      <LinkInput
                        simpleFormInput={simpleInputElement}
                        error={errors[orderIdx]}
                        recordPointer={recordOrder[orderIdx]}
                        textValues={textValues}
                        setTextValues={setTextValues}
                      />
                    </div>
                  );
                }
              }
              if (type === "SimpleSelect") {
                const simpleSelectElement = form.simpleSelects[index];
                if (simpleSelectElement.elementType === "Checkbox") {
                  return (
                    <div key={uuid()}>
                      <Checkbox
                        checkboxInput={simpleSelectElement}
                        error={errors[orderIdx]}
                        recordPointer={recordOrder[orderIdx]}
                        textValues={textValues}
                        setTextValues={setTextValues}
                      />
                    </div>
                  );
                }
                if (simpleSelectElement.elementType === "SelectMenu") {
                  return (
                    <div key={uuid()}>
                      <SelectMenu
                        selectMenuInput={simpleSelectElement}
                        recordPointer={recordOrder[orderIdx]}
                        textValues={textValues}
                        setTextValues={setTextValues}
                      />
                    </div>
                  );
                }
                if (simpleSelectElement.elementType === "RadioGroup") {
                  return (
                    <div key={uuid()}>
                      <RadioGroup
                        radioGroupInput={simpleSelectElement}
                        recordPointer={recordOrder[orderIdx]}
                        textValues={textValues}
                        setTextValues={setTextValues}
                      />
                    </div>
                  );
                }
              }
              if (type === "EntitySelect") {
                const internalEntityInput = form.entitySelects[index];
                return (
                  <div key={uuid()}>
                    <InternalEntityInput
                      internalEntityInput={internalEntityInput}
                      error={errors[orderIdx]}
                      recordPointer={recordOrder[orderIdx]}
                      textValues={textValues}
                      setTextValues={setTextValues}
                    />
                  </div>
                );
              }
              if (type === "Attachment") {
                const attachmentInput = form?.attachments[index];
                return (
                  <div key={uuid()}>
                    <Attachment
                      attachmentInput={attachmentInput}
                      error={errors[orderIdx]}
                      recordPointer={recordOrder[orderIdx]}
                      attachments={attachments}
                      setAttachments={setAttachments}
                    />
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <Link
              to={
                form.status === "Archived"
                  ? "/regulator/forms/archived"
                  : "/regulator/forms"
              }
            >
              <button
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Cancel
              </button>
            </Link>
            <button
              type="submit"
              disabled={!formsWrite || form.status === "Archived"}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-gray-100 disabled:text-gray-400 disabled:border-gray-300"
            >
              Save
            </button>
          </div>
        </div>
      </form>
      <AuditTrailCard
        fieldDisplayMap={new Map()}
        type={`${form.title} v${form.majorVersion}.${form.minorVersion}`}
        fetchPath={formAuditPath(formID)}
      />
    </div>
  );
}
