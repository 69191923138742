const footerNavigation = {
  main: [
    { name: "Home", href: "https://decotolabs.com" },
    { name: "Team", href: "https://decotolabs.com/team" },
    { name: "Blog", href: "https://decotolabs.com/blog" },
    { name: "Pricing", href: "https://decotolabs.com/pricing" },
  ],
};

export default function Footer() {
  return (
    <footer className="mt-24 bg-blue-600 sm:mt-12">
      <div className="mx-auto max-w-md py-12 px-4 overflow-hidden sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <nav
          className="-mx-5 -my-2 flex flex-wrap justify-center"
          aria-label="Footer"
        >
          {footerNavigation.main.map((item) => (
            <div key={item.name} className="px-5 py-2">
              <a
                href={item.href}
                className="text-base text-white hover:text-blue-200"
              >
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <p className="mt-8 text-center text-base text-white">
          2022 Decoto Labs, LLC. All rights reserved. |{" "}
          <a
            href={"https://decotolabs.com/privacy-policy#top"}
            className="font-medium underline"
          >
            Privacy Policy
          </a>{" "}
          |{" "}
          <a
            href={"https://decotolabs.com/terms-of-service#top"}
            className="font-medium underline"
          >
            Terms of Service
          </a>
        </p>
      </div>
    </footer>
  );
}
