import { FormElementSimpleSelect } from "../../common/Entities.d";
import React from "react";

export default function Checkbox(props: {
  checkboxInput: FormElementSimpleSelect;
  error: boolean;
  recordPointer: { recordValueArray: string; recordValueIndex: number };
  textValues: Array<string[]>;
  setTextValues: (textValues: Array<string[]>) => void;
}) {
  function handleCheck(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.id;
    if (e.target.checked) {
      props.textValues[props.recordPointer.recordValueIndex].push(value);
    } else {
      const index = props.textValues[
        props.recordPointer.recordValueIndex
      ].findIndex((val: string) => val === value);
      if (index !== -1) {
        props.textValues[props.recordPointer.recordValueIndex].splice(index, 1);
      }
    }
    props.setTextValues(props.textValues);
  }

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">
        {props.checkboxInput.label}
      </label>
      <fieldset className="space-y-5" id={props.checkboxInput.label}>
        <legend className="sr-only">{props.checkboxInput.label}</legend>
        {props.checkboxInput.options.map((option) => (
          <div className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id={option.value}
                aria-describedby="comments-description"
                name={option.value}
                type="checkbox"
                onChange={(e) => handleCheck(e)}
                className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
              />
            </div>
            <div className="ml-3 text-sm">
              <label
                htmlFor={option.value}
                className="font-medium text-gray-700"
              >
                {option.value}
              </label>
              <p id={`${option.value}-description`} className="text-gray-500">
                {option.description}
              </p>
            </div>
          </div>
        ))}
      </fieldset>
      <p className="mt-2 text-sm text-red-500" hidden={!props.error}>
        * {props.checkboxInput.label} is required.
      </p>
    </div>
  );
}
