import { useUserContext } from "../common/BasePageScreen";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import { FingerPrintIcon } from "@heroicons/react/solid";
import Tabs from "../common/Tabs";
import * as React from "react";
import SamplesList from "./SamplesList";
import SamplesTypesList from "./SampleTypesList";
import { TableIcon } from "@heroicons/react/outline";

const samplePages = [{ name: "Samples", path: "/regulator/samples" }];
const typePages = [
  { name: "Samples", path: "/regulator/samples" },
  { name: "Sample Types", path: "/regulator/samples/types" },
];
const reportedPages = [
  { name: "Samples", path: "/regulator/samples" },
  { name: "Reported", path: "/regulator/samples/reported" },
];

const reagentTabs = [
  { name: "Active", href: "/regulator/samples" },
  { name: "Types", href: "/regulator/samples/types" },
  { name: "Reported", href: "/regulator/samples/reported" },
];

export default function SampleOrTypeList() {
  const { samplesRead, samplesWrite } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();

  if (!samplesRead) {
    navigate("/forbidden");
    return <></>;
  }

  let breadcrumbBar = <FullWidthBreadcrumbBar pages={samplePages} />;

  if (location.pathname === "/regulator/samples/types") {
    breadcrumbBar = <FullWidthBreadcrumbBar pages={typePages} />;
  }

  if (location.pathname === "/regulator/samples/reported") {
    breadcrumbBar = <FullWidthBreadcrumbBar pages={reportedPages} />;
  }

  let addButton = [
    { text: "Add Sample", href: "/regulator/samples/add" },
    { text: "Bulk Sample Upload", href: "/regulator/samples/bulk-add" },
  ];

  if (location.pathname === "/regulator/samples/types") {
    addButton = [
      { text: "Add Sample Type", href: "/regulator/samples/types/add" },
    ];
  }

  let component = <SamplesList />;

  if (location.pathname === "/regulator/samples/types") {
    component = <SamplesTypesList />;
  }

  return (
    <div className="flex flex-col">
      {breadcrumbBar}
      <div className="py-3 text-right">
        {addButton.map((button) => (
          <Link key={button.text} to={button.href} hidden={!samplesWrite}>
            <button
              type="button"
              className="ml-2 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {button.href === "/samples/bulk-add" ? (
                <TableIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              ) : (
                <FingerPrintIcon
                  className="-ml-1 mr-3 h-5 w-5"
                  aria-hidden="true"
                />
              )}
              {button.text}
            </button>
          </Link>
        ))}
      </div>
      <Tabs tabs={reagentTabs} />
      {component}
    </div>
  );
}
