import SignaturePad from "react-signature-canvas";
import React, { useState } from "react";
import { useUserContext } from "../common/BasePageScreen";
import { addUserSignature, useUserSignature } from "../common/DataFetchers";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import { formatErrorMessage } from "../common/Utils";
import ErrorBanner from "../common/ErrorBanner";
import SuccessBanner from "../common/SuccessBanner";

export default function SignatureCard() {
  const { user } = useUserContext();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const [showSuccess, setShowSuccess] = useState(false);
  const [successText, setSuccessText] = useState("");

  const {
    userSignature,
    userSignatureIsLoading,
    userSignatureIsError,
    mutateUserSignature,
  } = useUserSignature(user.id);

  let sigPad: SignaturePad | null;

  const [agreementError, setAgreementError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [pinError, setPinError] = useState(false);
  const [confirmPinError, setConfirmPinError] = useState(false);

  const [eSignatureAgreement, setEsignatureAgreement] = useState(false);

  if (userSignatureIsLoading) {
    return <LoadingComponent />;
  }

  if (userSignatureIsError || !userSignature) {
    return <ErrorComponent />;
  }

  function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault();

    setShowSuccess(false);
    setShowErrorBanner(false);

    let isValid = true;

    const target = e.target as typeof e.target & {
      password: { value: string };
      signaturePin: { value: string };
      confirmSignaturePin: { value: string };
    };

    const password = target.password.value;
    const pin = target.signaturePin.value;
    const confirmPin = target.confirmSignaturePin.value;

    setPasswordError(false);
    if (password.trim() === "") {
      setPasswordError(true);
      isValid = false;
    }

    setPinError(false);
    if (
      pin.trim() === "" ||
      pin.length !== 4 ||
      pin.match(/^[0-9]+$/) == null
    ) {
      setPinError(true);
      isValid = false;
    }

    setConfirmPinError(false);
    if (confirmPin.trim() !== pin) {
      setConfirmPinError(true);
      isValid = false;
    }

    setAgreementError(false);
    if (!eSignatureAgreement) {
      setAgreementError(true);
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    mutateUserSignature(
      addUserSignature(user.id, {
        encodedSignature: sigPad!.toDataURL()!,
        signaturePIN: pin,
        password: password,
      })
    )
      .then(() => {
        target.signaturePin.value = "";
        target.confirmSignaturePin.value = "";
        target.password.value = "";
        setEsignatureAgreement(false);
        setSuccessText(`Successfully updated your signature!`);
        setShowSuccess(true);
      })
      .catch((error) => {
        setErrorBannerText(formatErrorMessage(error));
        setShowErrorBanner(true);
      });
  }

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      <ErrorBanner
        showError={showErrorBanner}
        setShowError={setShowErrorBanner}
        errorText={errorBannerText}
      />
      <SuccessBanner
        showSuccess={showSuccess}
        setShowSuccess={setShowSuccess}
        successText={successText}
      />
      <section aria-labelledby="payment-details-heading">
        <form onSubmit={handleSubmit}>
          <div className="mt-2 sm:rounded-md sm:border sm:border-gray-200 sm:overflow-hidden">
            <div className="bg-white py-6 px-4 sm:p-6">
              <div>
                <h2
                  id="payment-details-heading"
                  className="text-lg leading-6 font-medium text-gray-900"
                >
                  Signature
                </h2>
                <div className="mt-6 grid grid-cols-3">
                  <label className="ml-1 block text-center text-sm font-medium text-gray-700 col-start-2">
                    Current Signature
                  </label>
                  <div className="p-4 flex justify-center overflow-hidden col-start-2 rounded-md border border-gray-300">
                    {userSignature.encodedSignature ? (
                      <img
                        className="flex-shrink-0 min-w-full min-h-full"
                        src={userSignature.encodedSignature}
                      />
                    ) : (
                      <p className="text-sm text-gray-500 text-center">
                        Signature Preview will appear once a signature is saved
                        for this user.
                      </p>
                    )}
                  </div>
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  Set up or update your digital signature by drawing in the blue
                  box below. Note that this signature will be used for all
                  Regulator signature requirements.
                </p>
              </div>
              <div className="mt-3">
                <SignaturePad
                  ref={(ref) => (sigPad = ref)}
                  clearOnResize={false}
                  canvasProps={{
                    className:
                      "h-32 w-full rounded-md border-2 border-blue-300",
                  }}
                />
                <div className="py-3 text-right">
                  <button
                    type="button"
                    onClick={() => sigPad!.clear()}
                    className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Clear Signature
                  </button>
                </div>
              </div>
              <div>
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                  Signature PIN
                </h2>
                <p className="mt-1 text-sm text-gray-500">
                  Set up your 4-digit signature PIN. Note that your signature
                  PIN will be required for every signature entry.
                </p>
                <div className="mt-3 grid grid-cols-4">
                  <label
                    htmlFor="password"
                    className="my-auto block text-sm font-medium text-gray-700 col-start-2"
                  >
                    Enter password
                  </label>
                  <div className="mt-1">
                    <input
                      type="password"
                      name="password"
                      id="password"
                      className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                    <p
                      className="mt-2 text-sm text-red-500"
                      hidden={!passwordError}
                    >
                      * Password is required.
                    </p>
                  </div>
                </div>
                <div className="mt-3 grid grid-cols-4">
                  <label
                    htmlFor="signaturePin"
                    className="my-auto block text-sm font-medium text-gray-700 col-start-2"
                  >
                    Signature PIN
                  </label>
                  <div className="mt-1">
                    <input
                      type="password"
                      name="signaturePin"
                      id="signaturePin"
                      className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                    <p className="mt-2 text-sm text-red-500" hidden={!pinError}>
                      * Signature PIN is required and must be 4 numeric digits.
                    </p>
                  </div>
                </div>
                <div className="mt-3 grid grid-cols-4">
                  <label
                    htmlFor="confirmSignaturePin"
                    className="my-auto block text-sm font-medium text-gray-700 col-start-2"
                  >
                    Confirm Signature PIN
                  </label>
                  <div className="mt-1">
                    <input
                      type="password"
                      name="confirmSignaturePin"
                      id="confirmSignaturePin"
                      className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                    <p
                      className="mt-2 text-sm text-red-500"
                      hidden={!confirmPinError}
                    >
                      * Confirm Signature PIN must match Signature PIN entered
                      above.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-6 relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="esignature-agreement"
                    aria-describedby="comments-description"
                    name="esignature-agreement"
                    type="checkbox"
                    checked={eSignatureAgreement}
                    onChange={() =>
                      setEsignatureAgreement(!eSignatureAgreement)
                    }
                    className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="esignature-agreement"
                    className="font-medium text-gray-700"
                  >
                    E-Signature Consent
                  </label>
                  <p
                    id="comments-esignature-agreement-description"
                    className="text-gray-500"
                  >
                    By checking the provided box the user consents to have the
                    above shown signature used in the closed system, Regulator.
                    The user also agrees the above shown signature is the same
                    as handwritten signatures for the purposes of validity,
                    enforceability and admissibility.
                  </p>
                  <p
                    className="mt-2 text-sm text-red-500"
                    hidden={!agreementError}
                  >
                    * You must provide E-Signature consent before saving.
                  </p>
                </div>
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button
                type="submit"
                className="bg-blue-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}
