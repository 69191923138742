import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import {
  updateRegistrationAdminUser,
  useCurrentRegistration,
  useRegistrationReservedEmailDomains,
} from "../common/DataFetchers";
import { formatErrorMessage } from "../common/Utils";
import ErrorBanner from "../common/ErrorBanner";

export default function AdminEmailSignUp() {
  const navigate = useNavigate();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const { currentRegistration } = useCurrentRegistration();
  const { reservedEmailDomains } = useRegistrationReservedEmailDomains();

  const [laboratoryNameError, setLaboratoryNameError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [reservedEmailError, setReservedEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  function handleAdminUserForm(e: React.SyntheticEvent) {
    e.preventDefault();

    setShowErrorBanner(false);

    const target = e.target as typeof e.target & {
      laboratoryName: { value: string };
      "first-name": { value: string };
      "last-name": { value: string };
      email: { value: string };
      password: { value: string };
      "confirm-password": { value: string };
    };

    target.laboratoryName.value = target.laboratoryName.value.trim();
    const laboratoryName = target.laboratoryName.value;

    target["first-name"].value = target["first-name"].value.trim();
    const firstName = target["first-name"].value;

    target["last-name"].value = target["last-name"].value.trim();
    const lastName = target["last-name"].value;

    target.email.value = target.email.value.trim();
    const email = target.email.value;

    const password = target.password.value;
    const confirmPassword = target["confirm-password"].value;

    let isValid = true;

    setLaboratoryNameError(false);
    if (laboratoryName === "") {
      setLaboratoryNameError(true);
      isValid = false;
    }

    setFirstNameError(false);
    if (firstName === "") {
      setFirstNameError(true);
      isValid = false;
    }

    setLastNameError(false);
    if (lastName === "") {
      setLastNameError(true);
      isValid = false;
    }

    setEmailError(false);
    if (email === "") {
      setEmailError(true);
      isValid = false;
    }

    setReservedEmailError(false);
    if (
      reservedEmailDomains.findIndex((domain) => email.includes(domain)) >= 0
    ) {
      setReservedEmailError(true);
      isValid = false;
    }

    setPasswordError(false);
    if (password.length < 8 || password.length > 64) {
      setPasswordError(true);
      isValid = false;
    }

    setConfirmPasswordError(false);
    if (confirmPassword !== password) {
      setConfirmPasswordError(true);
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    updateRegistrationAdminUser({
      labName: laboratoryName,
      email: email,
      firstName: firstName,
      lastName: lastName,
      password: password,
    })
      .then(() => navigate("/signup/plan"))
      .catch((error) => {
        setErrorBannerText(formatErrorMessage(error));
        setShowErrorBanner(true);
      });
  }

  return (
    <div className="flex h-screen bg-gradient-to-b from-blue-300 to-blue-600">
      <div className="max-w-7xl m-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="relative rounded-md bg-white shadow-xl">
          <form onSubmit={handleAdminUserForm}>
            <div className="grid grid-cols-1 lg:grid-cols-2">
              <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                <ErrorBanner
                  showError={showErrorBanner}
                  setShowError={setShowErrorBanner}
                  errorText={errorBannerText}
                />
                <h3 className="text-center text-pasha font-bold text-2xl mt-4 mb-6">
                  Create Admin User
                </h3>
                <p className="text-base text-gray-700 max-w-3xl">
                  Each Regulator instance must have an administrator who adds
                  and removes users, manages permissions and billing and
                  completes onboarding activities.
                </p>
                <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                  <div className="col-span-2">
                    <label
                      htmlFor="laboratoryName"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Laboratory name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="laboratoryName"
                        id="laboratoryName"
                        defaultValue={
                          currentRegistration?.registration?.adminUser?.labName
                        }
                        className={
                          laboratoryNameError
                            ? "py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-red-500 focus:border-red-500 border-red-300 rounded-md"
                            : "py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                        }
                      />
                      <p
                        className="mt-2 text-sm text-red-500"
                        hidden={!laboratoryNameError}
                      >
                        * Laboratory name is required.
                      </p>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-900"
                    >
                      First name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        defaultValue={
                          currentRegistration?.registration?.adminUser
                            ?.firstName
                        }
                        className={
                          firstNameError
                            ? "py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-red-500 focus:border-red-500 border-red-300 rounded-md"
                            : "py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                        }
                      />
                      <p
                        className="mt-2 text-sm text-red-500"
                        hidden={!firstNameError}
                      >
                        * First name is required.
                      </p>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Last name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        defaultValue={
                          currentRegistration?.registration?.adminUser?.lastName
                        }
                        className={
                          lastNameError
                            ? "py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-red-500 focus:border-red-500 border-red-300 rounded-md"
                            : "py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                        }
                      />
                      <p
                        className="mt-2 text-sm text-red-500"
                        hidden={!lastNameError}
                      >
                        * Last name is required.
                      </p>
                    </div>
                  </div>
                  <div className="col-span-2">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-900 col-span-2"
                    >
                      Email
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        defaultValue={
                          currentRegistration?.registration?.adminUser?.email
                        }
                        className={
                          emailError
                            ? "py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-red-500 focus:border-red-500 border-red-300 rounded-md"
                            : "py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                        }
                      />
                      <p
                        className="mt-2 text-sm text-red-500"
                        hidden={!emailError}
                      >
                        * Email is required.
                      </p>
                      <p
                        className="mt-2 text-sm text-red-500"
                        hidden={!reservedEmailError}
                      >
                        * Email domain is not permitted. Please use an email
                        address with a different domain. Reserved domains:{" "}
                        {reservedEmailDomains.join(", ")}
                      </p>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-900 col-span-2"
                    >
                      Password
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        className={
                          passwordError
                            ? "py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-red-500 focus:border-red-500 border-red-300 rounded-md"
                            : "py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                        }
                      />
                      <p
                        className="mt-2 text-sm text-red-500"
                        hidden={!passwordError}
                      >
                        * Password length must be 8-64 characters.
                      </p>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="confirm-password"
                      className="block text-sm font-medium text-gray-900 col-span-2"
                    >
                      Confirm Password
                    </label>
                    <div className="mt-1">
                      <input
                        id="confirm-password"
                        name="confirm-password"
                        type="password"
                        className={
                          confirmPasswordError
                            ? "py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-red-500 focus:border-red-500 border-red-300 rounded-md"
                            : "py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                        }
                      />
                      <p
                        className="mt-2 text-sm text-red-500"
                        hidden={!confirmPasswordError}
                      >
                        * Passwords do not match.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white px-4 py-3 flex items-center justify-between rounded-b-md border-t border-gray-200 sm:px-6">
              <div className="-mt-4 w-0 flex-1 flex">
                <button
                  type="button"
                  onClick={() => navigate("/regulator/login")}
                  className="pt-4 pr-1 inline-flex items-center text-sm font-medium text-blue-500 hover:text-blue-700"
                >
                  <ArrowNarrowLeftIcon
                    className="mr-3 h-5 w-5 text-blue-400"
                    aria-hidden="true"
                  />
                  Back
                </button>
              </div>
              <div className="-mt-4 w-0 flex-1 flex justify-end">
                <button
                  type="submit"
                  className="pt-4 pl-1 inline-flex items-center text-sm font-medium text-blue-500 hover:text-blue-700"
                >
                  Next
                  <ArrowNarrowRightIcon
                    className="ml-3 h-5 w-5 text-blue-400"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
