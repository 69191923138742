import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import CustomSelectMenu from "../common/CustomSelectMenu";
import PersonnelRecordUploadComponent from "../common/PersonnelRecordUploadComponent";
import { formatDateInputValue, formatErrorMessage } from "../common/Utils";
import {
  addPersonnel,
  usePersonnelRoleSummaries,
  useUserSummaries,
} from "../common/DataFetchers";
import { PersonnelAttachmentType, UserSummary } from "../common/Entities.d";
import { useUserContext } from "../common/BasePageScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import ErrorBanner from "../common/ErrorBanner";

export default function AddPersonnelForm(): JSX.Element {
  const { personnelRead, personnelWrite } = useUserContext();
  const navigate = useNavigate();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const { roleSummaries } = usePersonnelRoleSummaries(personnelRead);

  const [userError, setUserError] = useState(false);
  const [hireDateError, setHireDateError] = useState(false);
  const [roleError, setRoleError] = useState(false);

  const userStr = (user: UserSummary) =>
    `${user.firstName} ${user.lastName} - ${user.email}`;

  const { userSummaries, userSummariesIsLoading, userSummariesIsError } =
    useUserSummaries("Active");
  const [userOptions] = useState<{ id: string; value: any; display: string }[]>(
    []
  );
  const [user, setUser] =
    useState<{ id: string; value: any; display: string }>();
  useEffect(() => {
    userSummaries.forEach((user) => {
      const idx = userOptions.findIndex((option) => option.value === user);
      if (idx < 0) {
        userOptions.push({
          id: userOptions.length.toString(),
          value: user,
          display: userStr(user),
        });
      }
    });
    setUser(userOptions[0]);
  }, [userSummaries]);

  const [roleOptions] = useState<{ id: string; value: any; display: string }[]>(
    []
  );
  const [role, setRole] =
    useState<{ id: string; value: any; display: string }>();
  useEffect(() => {
    roleSummaries.forEach((role, roleIdx) => {
      const roleOption = {
        id: roleIdx.toString(),
        value: role,
        display: role.name,
      };
      const idx = roleOptions.findIndex((option) => option.value === role);
      if (idx < 0) {
        roleOptions.push(roleOption);
      }
    });
    setRole(roleOptions[0]);
  }, [roleSummaries]);

  const [attachments] = useState<
    { file: File; type: PersonnelAttachmentType }[]
  >([]);

  if (!personnelWrite || !personnelRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (userSummariesIsLoading) {
    return <LoadingComponent />;
  }

  if (userSummariesIsError) {
    return <ErrorComponent />;
  }

  return (
    <form
      className="py-3 px-3 space-y-8 divide-y divide-gray-200"
      onSubmit={(e: React.SyntheticEvent) => {
        e.preventDefault();

        setShowErrorBanner(false);

        const target = e.target as typeof e.target & {
          hireDate: { value: string };
        };

        let isValid = true;

        setHireDateError(false);
        if (target.hireDate.value.trim() === "") {
          setHireDateError(true);
          isValid = false;
        }

        setUserError(false);
        if (!user) {
          setUserError(true);
          isValid = false;
        }

        setRoleError(false);
        if (!role) {
          setRoleError(true);
          isValid = false;
        }

        if (!isValid || !user || !role || !personnelWrite) {
          return;
        }

        addPersonnel(
          user.value.id,
          role.value.id,
          new Date(formatDateInputValue(target.hireDate.value)),
          attachments
        )
          .then((personnel) => navigate(`/regulator/personnel/${personnel.id}`))
          .catch((error) => {
            setErrorBannerText(formatErrorMessage(error));
            setShowErrorBanner(true);
          });
      }}
    >
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <ErrorBanner
            showError={showErrorBanner}
            setShowError={setShowErrorBanner}
            errorText={errorBannerText}
          />
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Add Personnel
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Fill in required details to create new personnel.
            </p>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <CustomSelectMenu
                label="User"
                options={userOptions}
                selected={user}
                setSelected={setUser}
              />
              <p
                className="text-sm text-red-500 sm:col-span-3 sm:col-start-2 sm:ml-2"
                hidden={!userError}
              >
                * User is required
              </p>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="hireDate"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Hire Date
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-1">
                <input
                  type="date"
                  name="hireDate"
                  id="hirDate"
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
                <p
                  className="mt-2 text-sm text-red-500"
                  hidden={!hireDateError}
                >
                  * Hire Date is required
                </p>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <CustomSelectMenu
                label="Role"
                options={roleOptions}
                selected={role}
                setSelected={setRole}
              />
              <p
                className="text-sm text-red-500 sm:col-span-3 sm:col-start-2 sm:ml-2"
                hidden={!roleError}
              >
                * Role is required
              </p>
            </div>
            <PersonnelRecordUploadComponent
              label={"Attachments"}
              files={attachments}
              fileTypes={"PDF, PNG, JPG"}
            />
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <Link to="/regulator/personnel">
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
