import React, { Suspense, useEffect, useState } from "react";
import Pagination from "../common/Pagination";
import { Link } from "react-router-dom";
import { formatDateInputValue, getDate, getFullName } from "../common/Utils";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import CustomSelectMenu from "../common/CustomSelectMenu";
import { useTaskSummaries } from "../common/DataFetchers";
import { TaskSummary } from "../common/Entities.d";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import { REGULATOR_BOT, taskTypeToDisplayMap } from "../common/Constants.d";

const typeOptions = [
  { id: "0", value: "Select", display: "Select" },
  { id: "1", value: "SOPDraftReview", display: "SOP Draft Review" },
  { id: "2", value: "ProcedureManualReview", display: "SOP Annual Review" },
  { id: "3", value: "EquipmentCalibration", display: "Equipment Calibration" },
  { id: "4", value: "ReagentExpiration", display: "Reagent Expiration" },
  { id: "5", value: "CompetencyExpiration", display: "Competency Expiration" },
  { id: "6", value: "CompetencyReview", display: "Competency Review" },
];

export default function OpenTasksList() {
  const { taskSummaries, taskSummariesIsLoading, taskSummariesIsError } =
    useTaskSummaries("Open");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const [showFilterBox, setShowFilterBox] = useState(false);

  const [filterType, setFilterType] = useState(typeOptions[0]);

  const [items, setItems] = useState<TaskSummary[]>([]);

  useEffect(() => {
    if (taskSummaries.length === 0) {
      return;
    }
    setItems(taskSummaries);
  }, [taskSummaries]);

  const pageEndIndex = currentPage * itemsPerPage;
  const pageStartIndex = pageEndIndex - itemsPerPage;
  const pageTaskSummaries = items
    ? items.slice(pageStartIndex, pageEndIndex)
    : taskSummaries.slice(pageStartIndex, pageEndIndex);

  if (taskSummariesIsLoading) {
    return <LoadingComponent />;
  }

  if (taskSummariesIsError) {
    return <ErrorComponent />;
  }

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  function clearFilter() {
    (document.getElementById("assignerFilter") as HTMLInputElement).value = "";
    (document.getElementById("dueStartDateFilter") as HTMLInputElement).value =
      "";
    (document.getElementById("dueEndDateFilter") as HTMLInputElement).value =
      "";
    setFilterType(typeOptions[0]);
    setItems(taskSummaries);
  }

  function handleFilter(e: React.SyntheticEvent) {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      assignerFilter: { value: string };
      notificationItemFilter: { value: string };
      dueStartDateFilter: { value: string };
      dueEndDateFilter: { value: string };
    };

    let newItems = taskSummaries;

    const assignerFilter = target.assignerFilter.value.trim();
    if (assignerFilter !== "") {
      newItems = newItems.filter(
        (n) =>
          getFullName(n.assigner!.firstName, n.assigner!.lastName) ===
          assignerFilter
      );
    }

    const typeFilter = filterType.value;
    if (typeFilter !== "Select") {
      newItems = newItems.filter((n) => n.type === typeFilter);
    }
    const dueStart = target.dueStartDateFilter.value.trim();
    if (dueStart !== "") {
      newItems = newItems.filter(
        (n) => new Date(n.due) >= new Date(formatDateInputValue(dueStart))
      );
    }
    const dueEnd = target.dueEndDateFilter.value.trim();
    if (dueEnd !== "") {
      newItems = newItems.filter(
        (n) => new Date(n.due) <= new Date(formatDateInputValue(dueEnd))
      );
    }

    setItems(newItems);
  }

  return (
    <div className="flex flex-col">
      <div className="bg-white p-3 border border-gray-200 rounded-lg shadow-sm">
        <h3 className="ml-1 text-md leading-6 font-medium text-gray-900">
          Filter
        </h3>
        <div className="-mt-6 text-right">
          <button onClick={() => setShowFilterBox(!showFilterBox)}>
            {showFilterBox ? (
              <ChevronUpIcon
                className="h-6 w-6 text-gray-500"
                aria-hidden="true"
              />
            ) : (
              <ChevronDownIcon
                className="h-6 w-6 text-gray-500"
                aria-hidden="true"
              />
            )}
          </button>
        </div>
        <div hidden={!showFilterBox}>
          <form
            className="grid grid-cols-3 gap-4"
            onSubmit={(e) => handleFilter(e)}
          >
            <div className="ml-1">
              <CustomSelectMenu
                label={"Action"}
                options={typeOptions}
                selected={filterType}
                setSelected={setFilterType}
              />
            </div>
            <div className="ml-1">
              <label
                htmlFor="assignerFilter"
                className="mt-2.5 block text-sm font-medium text-gray-700"
              >
                Assigner
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="assignerFilter"
                  id="assignerFilter"
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="ml-1">
              <label
                htmlFor="endDateFilter"
                className="mt-2.5 block text-sm font-medium text-gray-700 mb-1"
              >
                Due Date
              </label>
              <input
                type="date"
                name="dueStartDateFilter"
                id="dueStartDateFilter"
                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
              <p className="text-center">to</p>
              <input
                type="date"
                name="dueEndDateFilter"
                id="dueEndDateFilter"
                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div className="text-right col-end-4 col-span-1">
              <button
                type="button"
                onClick={clearFilter}
                className="m-1 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Clear
              </button>
              <button
                type="submit"
                className="m-1 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Apply
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            {taskSummaries.length === 0 ? (
              <div className="bg-white p-5 text-center">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                  />
                </svg>
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  No tasks
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  No tasks are assigned at this time.
                </p>
              </div>
            ) : (
              <div>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Type
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Assigner
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Due Date
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">View</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageTaskSummaries.map((task, taskIdx) => (
                      <tr
                        key={task.id}
                        className={
                          taskIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                        }
                      >
                        <td
                          className={
                            task.read
                              ? "px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                              : "px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                          }
                        >
                          {taskTypeToDisplayMap.get(task.type)}
                        </td>
                        <td
                          className={
                            task.read
                              ? "px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                              : "px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                          }
                        >
                          {task.assigner
                            ? `${task.assigner.firstName} ${task.assigner.lastName}`
                            : REGULATOR_BOT}
                        </td>
                        <td
                          className={
                            task.read
                              ? "px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                              : "px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                          }
                        >
                          {getDate(task.due)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <Link
                            to={`/regulator/tasks/${task.id}`}
                            className="text-blue-600 hover:text-blue-900"
                          >
                            View
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination
                  itemsPerPage={itemsPerPage}
                  totalItems={items ? items.length : taskSummaries.length}
                  currentPage={currentPage}
                  paginate={paginate}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
