import { Link, useNavigate, useParams } from "react-router-dom";
import { classNames, formatErrorMessage } from "../common/Utils";
import React, { useEffect, useState } from "react";
import {
  updateSOPCategory,
  usePersonnelRoleSummaries,
  useSOPCategory,
} from "../common/DataFetchers";
import { useUserContext } from "../common/BasePageScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import ErrorBanner from "../common/ErrorBanner";

export default function EditSOPCategoryForm(): JSX.Element {
  const { sopsRead, sopsWrite } = useUserContext();
  const navigate = useNavigate();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const [nameError, setNameError] = useState(false);
  const [initialsError, setInitialsError] = useState(false);

  const params = useParams<{ id: string }>();
  const categoryID = params.id as string;

  const {
    sopCategory,
    mutateSOPCategory,
    sopCategoryIsLoading,
    sopCategoryIsError,
  } = useSOPCategory(categoryID, sopsRead);

  const { roleSummaries } = usePersonnelRoleSummaries(sopsRead);

  const [selectedApproverIDs, setSelectedApproverIDs] = useState<Set<string>>();

  useEffect(() => {
    if (!sopCategory) {
      return;
    }

    const updatedSelectedApproverIDs = new Set<string>(selectedApproverIDs);
    roleSummaries.forEach((role) => {
      if (role.name === "Laboratory Director") {
        updatedSelectedApproverIDs.add(role.id);
      }
    });
    sopCategory.approverRoles.forEach((role) =>
      updatedSelectedApproverIDs.add(role.id)
    );
    setSelectedApproverIDs(updatedSelectedApproverIDs);
  }, [roleSummaries, sopCategory]);

  if (!sopsWrite || !sopsRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (sopCategoryIsLoading) {
    return <LoadingComponent />;
  }

  if (sopCategoryIsError || !sopCategory) {
    return <ErrorComponent />;
  }

  if (!selectedApproverIDs) {
    return <></>;
  }

  return (
    <form
      className="py-3 px-3 space-y-8 divide-y divide-gray-200"
      onSubmit={(e) => {
        e.preventDefault();

        setShowErrorBanner(false);

        const target = e.target as typeof e.target & {
          categoryName: { value: string };
          initials: { value: string };
        };

        target.categoryName.value = target.categoryName.value.trim();
        const categoryName = target.categoryName.value;

        target.initials.value = target.initials.value.trim();
        const initials = target.initials.value;

        let isValid = true;

        setNameError(false);
        if (categoryName === "") {
          setNameError(true);
          isValid = false;
        }

        setInitialsError(false);
        if (!/^[A-Z]{3}$/.test(initials)) {
          setInitialsError(true);
          isValid = false;
        }

        if (!isValid || !sopsWrite) {
          return;
        }

        updateSOPCategory(categoryID, {
          name: categoryName,
          initials: initials,
          approverRoleIDs: Array.from(selectedApproverIDs),
        })
          .then((sopCategory) => {
            mutateSOPCategory(sopCategory, false);
            navigate(`/regulator/sops/categories/${categoryID}`);
          })
          .catch((error) => {
            setErrorBannerText(formatErrorMessage(error));
            setShowErrorBanner(true);
          });
      }}
    >
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <ErrorBanner
            showError={showErrorBanner}
            setShowError={setShowErrorBanner}
            errorText={errorBannerText}
          />
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              SOP Category Information
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Fill in required details to edit this SOP category.
            </p>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Category Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="categoryName"
                  id="categoryName"
                  className={classNames(
                    "max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md",
                    nameError
                      ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                      : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                  )}
                  defaultValue={sopCategory.name}
                />
                <p className="mt-2 text-sm text-red-500" hidden={!nameError}>
                  * Category Name is required
                </p>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Initials
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="initials"
                  id="initials"
                  className={classNames(
                    "max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md",
                    initialsError
                      ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                      : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                  )}
                  defaultValue={sopCategory.initials}
                />
                <p
                  className="mt-2 text-sm text-red-500"
                  hidden={!initialsError}
                >
                  * Initials must be 3 uppercase letters
                </p>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Approvers
              </label>
              <div className="mt-4 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg space-y-4">
                  {roleSummaries.map((role) => (
                    <div>
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id={role.id}
                            name={role.name}
                            type="checkbox"
                            defaultChecked={
                              role.name === "Laboratory Director"
                                ? undefined
                                : selectedApproverIDs.has(role.id)
                            }
                            checked={
                              role.name === "Laboratory Director"
                                ? true
                                : undefined
                            }
                            className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                            onChange={(e) => {
                              if (role.name === "Laboratory Director") {
                                return;
                              }

                              e.currentTarget.checked
                                ? selectedApproverIDs.add(e.currentTarget.id)
                                : selectedApproverIDs.delete(
                                    e.currentTarget.id
                                  );
                            }}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor={role.name}
                            className="font-medium text-gray-700"
                          >
                            {role.name}
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <Link to={`/regulator/sops/categories/${categoryID}`}>
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
