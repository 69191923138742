import { ExclamationCircleIcon, XIcon } from "@heroicons/react/solid";
import React from "react";

interface ErrorBannerProps {
  showError: boolean;
  setShowError: (showError: boolean) => void;
  errorText: string;
}

export default function ErrorBanner(props: ErrorBannerProps) {
  return (
    <div className="rounded-md bg-red-50 p-4" hidden={!props.showError}>
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationCircleIcon
            className="h-5 w-5 text-red-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-red-800">{props.errorText}</p>
          <br />
          <p className="text-sm font-medium text-red-800">
            Visit our documentation{" "}
            <a
              className="underline"
              href="https://decotolabs.notion.site/Decoto-Labs-Documentation-9c49392dc9b54c44af10973ee5f59b12"
              target="_blank"
              rel="noreferrer"
            >
              page
            </a>{" "}
            for troubleshooting tips. If you still require assistance, contact{" "}
            <a className="underline" href="mailto:support@decotolabs.com">
              support@decotolabs.com{" "}
            </a>
            and ensure you include the TraceID and any other information
            surrounding your issue.
          </p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              onClick={() => props.setShowError(false)}
              className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
