import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useState } from "react";

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/signup/confirmation`,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message!);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <div className="py-3 text-center">
        <button
          disabled={isLoading || !stripe || !elements}
          id={"submit"}
          className="w-full bg-blue-600 hover:bg-white hover:shadow-outline hover:text-blue-600 hover:border hover:border-blue-600 focus:shadow-outline text-white focus:bg-white focus:text-blue-600 font-light py-2 px-4 rounded-md"
        >
          <span id="button-text">
            {isLoading ? "Subscribing..." : "Subscribe"}
          </span>
        </button>
        {message && <div id="payment-message">{message}</div>}
      </div>
    </form>
  );
}
