import { FormElementText } from "../../common/Entities.d";

export default function Header(props: { formText: FormElementText }) {
  return (
    <div className="mt-2">
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        {props.formText.text}
      </h3>
    </div>
  );
}
