import {
  BeakerIcon,
  CogIcon,
  DocumentIcon,
  DocumentTextIcon,
  FingerPrintIcon,
  PaperClipIcon,
} from "@heroicons/react/outline";
import { Link, useNavigate, useParams } from "react-router-dom";
import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import React from "react";
import { useUserContext } from "../common/BasePageScreen";
import {
  useWorkflowRun,
  workflowRunAttachmentDownloadLink,
  workflowRunAuditPath,
} from "../common/DataFetchers";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import { getDate, getFullName } from "../common/Utils";
import AuditTrailCard from "../common/AuditTrailCard";

const fieldDisplayMap = new Map([["status", "Status"]]);

export default function WorkflowRunDetailCard() {
  const { workflowHistoryRead, workflowHistoryWrite } = useUserContext();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();

  const workflowRunID = params.id as string;

  const { workflowRun, workflowRunIsLoading, workflowRunIsError } =
    useWorkflowRun(workflowRunID, workflowHistoryRead);

  if (!workflowHistoryRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (workflowRunIsLoading) {
    return <LoadingComponent />;
  }

  if (workflowRunIsError || !workflowRun) {
    return <ErrorComponent />;
  }

  const pages = [
    { name: "Workflow History", path: "/regulator/workflows/history" },
    {
      name: workflowRun?.displayID as string,
      path: `/regulator/workflows/history/${workflowRun?.id}`,
    },
  ];

  return (
    <div className="flex flex-col">
      <FullWidthBreadcrumbBar pages={pages} />
      <div className="py-3 pt-3">
        <div className="flex justify-end">
          <Link
            to={`/regulator/workflows/history/${workflowRun.id}/edit`}
            hidden={!workflowHistoryWrite}
          >
            <button
              type="button"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Update Run
            </button>
          </Link>
        </div>
      </div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Workflow Run Information
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Workflow run details and documentation.
          </p>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Run ID</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {workflowRun.displayID}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Run Type</dt>
              <Link to={`/workflows/templates/${workflowRun.template.id}`}>
                <dd className="mt-1 text-sm text-blue-600 font-bold">
                  {workflowRun.template.name}
                </dd>
              </Link>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Status</dt>
              <dd
                className={
                  workflowRun.status === "Pass"
                    ? "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 w-min"
                    : workflowRun.status === "Fail"
                    ? "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 w-min"
                    : "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800 w-min"
                }
              >
                {workflowRun.status}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Run Date</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {getDate(workflowRun.ran)}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">SOP</dt>
              <Link to={`/regulator/sops/${workflowRun.sop.id}`}>
                <dd className="mt-1 text-sm text-blue-600 font-bold">
                  {`${workflowRun.sop.displayID} - ${workflowRun.sop.title}`}
                </dd>
              </Link>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Technician(s)
              </dt>
              {workflowRun.competencies.map((competency) => (
                <Link
                  key={competency.id}
                  to={`/regulator/competency/${competency.id}`}
                >
                  <dd className="mt-1 text-sm text-blue-600 font-bold">
                    {getFullName(
                      competency.personnel.firstName,
                      competency.personnel.lastName
                    )}
                  </dd>
                </Link>
              ))}
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">
                Additional Technician(s)
              </dt>
              {workflowRun.personnel.length === 0 ? (
                <dd>
                  <p className="text-sm italic">None</p>
                </dd>
              ) : (
                workflowRun.personnel.map((personnel) => (
                  <Link
                    key={personnel.id}
                    to={`/regulator/personnel/${personnel.id}`}
                  >
                    <dd className="mt-1 text-sm text-blue-600 font-bold">
                      {getFullName(personnel.firstName, personnel.lastName)}
                    </dd>
                  </Link>
                ))
              )}
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">Equipment</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {workflowRun.equipment.length === 0 ? (
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm italic">
                      None
                    </li>
                  </ul>
                ) : (
                  <div>
                    {workflowRun.equipment.map((equipment) => {
                      return (
                        <ul
                          key={equipment.id}
                          className="border border-gray-200 rounded-md divide-y divide-gray-200"
                        >
                          <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                            <div className="w-0 flex-1 flex items-center">
                              <CogIcon
                                className="flex-shrink-0 h-5 w-5 text-blue-400"
                                aria-hidden="true"
                              />
                              <span className="ml-2 flex-1 w-0 truncate">
                                {`${equipment.displayID} - ${equipment.type.name}`}
                              </span>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                              <Link
                                to={`/regulator/equipment/${equipment.id}`}
                                className="font-medium text-blue-600 hover:text-blue-500"
                              >
                                View
                              </Link>
                            </div>
                          </li>
                        </ul>
                      );
                    })}
                  </div>
                )}
              </dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">Reagents</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {workflowRun.reagents.length === 0 ? (
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm italic">
                      None
                    </li>
                  </ul>
                ) : (
                  <div>
                    {workflowRun.reagents.map((reagent) => (
                      <ul
                        key={reagent.id}
                        className="border border-gray-200 rounded-md divide-y divide-gray-200"
                      >
                        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                          <div className="w-0 flex-1 flex items-center">
                            <BeakerIcon
                              className="flex-shrink-0 h-5 w-5 text-blue-400"
                              aria-hidden="true"
                            />
                            <span className="ml-2 flex-1 w-0 truncate">
                              {`${reagent.displayID} - ${reagent.type.name}`}
                            </span>
                          </div>
                          <div className="ml-4 flex-shrink-0">
                            <Link
                              to={`/regulator/reagents/${reagent.id}`}
                              className="font-medium text-blue-600 hover:text-blue-500"
                            >
                              View
                            </Link>
                          </div>
                        </li>
                      </ul>
                    ))}
                  </div>
                )}
              </dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">Samples</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {workflowRun.samples.length === 0 ? (
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm italic">
                      None
                    </li>
                  </ul>
                ) : (
                  <div>
                    {workflowRun.samples.map((sample) => (
                      <ul
                        key={sample.id}
                        className="border border-gray-200 rounded-md divide-y divide-gray-200"
                      >
                        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                          <div className="w-0 flex-1 flex items-center">
                            <FingerPrintIcon
                              className="flex-shrink-0 h-5 w-5 text-blue-400"
                              aria-hidden="true"
                            />
                            <span className="ml-2 flex-1 w-0 truncate">
                              {sample.displayID}
                            </span>
                          </div>
                          <div className="ml-4 flex-shrink-0">
                            <Link
                              to={`/regulator/samples/${sample.id}`}
                              className="font-medium text-blue-600 hover:text-blue-500"
                            >
                              View
                            </Link>
                          </div>
                        </li>
                      </ul>
                    ))}
                  </div>
                )}
              </dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">Attachments</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {workflowRun.attachments.length === 0 ? (
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm italic">
                      None
                    </li>
                  </ul>
                ) : (
                  <div>
                    {workflowRun.attachments.map((attachment) => (
                      <ul
                        key={attachment.id}
                        className="border border-gray-200 rounded-md divide-y divide-gray-200"
                      >
                        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                          <div className="w-0 flex-1 flex items-center">
                            <PaperClipIcon
                              className="flex-shrink-0 h-5 w-5 text-blue-400"
                              aria-hidden="true"
                            />
                            <span className="ml-2 flex-1 w-0 truncate">
                              {attachment.fileName}
                            </span>
                          </div>
                          <div className="ml-4 flex-shrink-0">
                            <a
                              download={attachment.fileName}
                              href={workflowRunAttachmentDownloadLink(
                                attachment.id
                              )}
                              className="font-medium text-blue-600 hover:text-blue-500"
                            >
                              Download
                            </a>
                          </div>
                        </li>
                      </ul>
                    ))}
                  </div>
                )}
              </dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">Records</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {workflowRun.records.length === 0 ? (
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm italic">
                      None
                    </li>
                  </ul>
                ) : (
                  <div>
                    {workflowRun.records.map((record) => (
                      <ul
                        key={record.id}
                        className="border border-gray-200 rounded-md divide-y divide-gray-200"
                      >
                        <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                          <div className="w-0 flex-1 flex items-center">
                            <DocumentTextIcon
                              className="flex-shrink-0 h-5 w-5 text-blue-400"
                              aria-hidden="true"
                            />
                            <span className="ml-2 flex-1 w-0 truncate">
                              {`${record.form.title} - ${getDate(
                                record.completed
                              )}`}
                            </span>
                          </div>
                          <div className="ml-4 flex-shrink-0">
                            <Link
                              to={`/regulator/records/${record.id}`}
                              className="font-medium text-blue-600 hover:text-blue-500"
                            >
                              View
                            </Link>
                          </div>
                        </li>
                      </ul>
                    ))}
                  </div>
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <AuditTrailCard
        fieldDisplayMap={fieldDisplayMap}
        type={`${workflowRun.displayID}`}
        fetchPath={workflowRunAuditPath(workflowRunID)}
      />
    </div>
  );
}
