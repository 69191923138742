import { EmojiSadIcon } from "@heroicons/react/outline";

export default function ErrorComponent() {
  return (
    <div className="py-16 bg-white rounded-md shadow-sm">
      <div className="mx-auto flex items-center justify-center h-20 w-20 rounded-full bg-red-100">
        <EmojiSadIcon className="h-10 w-10 text-red-500" aria-hidden="true" />
      </div>
      <div className="m-auto text-center justify-center">
        <span className="font-semibold text-2xl text-red-500">
          Sorry, it looks like something went wrong.
        </span>
      </div>
      <div className="m-auto text-center">
        If this problem persists, please email support at&nbsp;
        <a className="text-blue-600" href="mailto: support@decotolabs.com">
          support@decotolabs.com
        </a>
        .
      </div>
    </div>
  );
}
