import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Pagination from "../common/Pagination";
import EmptyStateScreen from "../common/EmptyStateScreen";
import {
  useSampleSummaries,
  useSampleTypeSummaries,
} from "../common/DataFetchers";
import { formatDateInputValue, getDate } from "../common/Utils";
import { SampleSummary } from "../common/Entities.d";
import CustomSelectMenu from "../common/CustomSelectMenu";
import { useUserContext } from "../common/BasePageScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";

const statusToClassnameMap = new Map([
  [
    "Queued",
    "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800",
  ],
  [
    "Pass",
    "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800",
  ],
  [
    "Fail",
    "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800",
  ],
]);

const statusOptions = [
  { id: "0", value: "Select", display: "Select" },
  { id: "1", value: "Queued", display: "Queued" },
  { id: "2", value: "Pass", display: "Pass" },
  { id: "3", value: "Fail", display: "Fail" },
];

export default function SamplesList(): JSX.Element {
  const { samplesRead, samplesWrite } = useUserContext();
  const navigate = useNavigate();

  const isReported = location.pathname === "/regulator/samples/reported";

  const { sampleSummaries, sampleSummariesIsLoading, sampleSummariesIsError } =
    useSampleSummaries(samplesRead);
  const { sampleTypeSummaries } = useSampleTypeSummaries(samplesRead);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [items, setItems] = useState<SampleSummary[]>([]);

  const [showFilterBox, setShowFilterBox] = useState(false);

  useEffect(() => {
    if (sampleSummaries.length === 0) {
      return;
    }
    isReported
      ? setItems(sampleSummaries.filter((s) => s.reportIssued))
      : setItems(sampleSummaries.filter((s) => !s.reportIssued));
  }, [sampleSummaries]);

  const [sampleTypeOptions, setSampleTypeOptions] = useState<
    { id: string; value: any; display: string }[]
  >([{ id: "0", value: "Select", display: "Select" }]);
  const [sampleType, setSampleType] = useState<{
    id: string;
    value: any;
    display: string;
  }>(sampleTypeOptions[0]);

  useEffect(() => {
    if (sampleTypeSummaries.length === 0) {
      return;
    }
    const updatedTypeOptions: { id: string; value: any; display: string }[] =
      [];
    updatedTypeOptions.push({ id: "0", value: "Select", display: "Select" });
    sampleTypeSummaries.map((sampleType) => {
      updatedTypeOptions.push({
        id: sampleType.id,
        value: sampleType,
        display: sampleType.name,
      });
    });

    setSampleTypeOptions(updatedTypeOptions);
    setSampleType(updatedTypeOptions[0]);
  }, [sampleTypeSummaries]);

  const [filterStatus, setFilterStatus] = useState(statusOptions[0]);

  if (!samplesRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (sampleSummariesIsLoading) {
    return <LoadingComponent />;
  }

  if (sampleSummariesIsError) {
    return <ErrorComponent />;
  }

  const pageEndIndex = currentPage * itemsPerPage;
  const pageStartIndex = pageEndIndex - itemsPerPage;
  let pageSampleSummaries: SampleSummary[];
  if (items) {
    pageSampleSummaries = items.slice(pageStartIndex, pageEndIndex);
  } else {
    pageSampleSummaries = sampleSummaries
      .filter((s) => (isReported ? s.reportIssued : !s.reportIssued))
      .slice(pageStartIndex, pageEndIndex);
  }

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const tableSampleRows = pageSampleSummaries.map((sample, index) => (
    <tr key={sample.id} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {sample.displayID}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        {sample.type.name}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {sample.latestRun ? sample.latestRun.displayID : ""}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {sample.latestRun ? getDate(sample.latestRun.ran) : ""}
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <span className={statusToClassnameMap.get(sample.status)}>
          {sample.status}
        </span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <Link
          to={`/regulator/samples/${sample.id}`}
          className="text-blue-600 hover:text-blue-900"
        >
          View
        </Link>
      </td>
    </tr>
  ));

  function clearFilter() {
    (document.getElementById("sampleIdFilter") as HTMLInputElement).value = "";
    (document.getElementById("startDateFilter") as HTMLInputElement).value = "";
    (document.getElementById("endDateFilter") as HTMLInputElement).value = "";
    setSampleType(sampleTypeOptions[0]);
    setFilterStatus(statusOptions[0]);
    setItems(
      Array.from(
        sampleSummaries.filter((s) =>
          isReported ? s.reportIssued : !s.reportIssued
        )
      )
    );
  }

  function handleFilter(e: React.SyntheticEvent) {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      sampleIdFilter: { value: string };
      startDateFilter: { value: string };
      endDateFilter: { value: string };
    };

    let newItems = sampleSummaries.filter((s) =>
      isReported ? s.reportIssued : !s.reportIssued
    );
    const sampleIdFilter = target.sampleIdFilter.value.trim();
    if (sampleIdFilter !== "") {
      newItems = newItems.filter((s) => s.displayID === sampleIdFilter);
    }
    const sampleTypeFilter = sampleType!.value;
    if (sampleTypeFilter !== "Select") {
      newItems = newItems.filter((s) => s.type.id === sampleTypeFilter.id);
    }
    const startDateFilter = target.startDateFilter.value.trim();
    if (startDateFilter !== "") {
      newItems = newItems.filter(
        (s) =>
          s.latestRun &&
          new Date(s.latestRun.ran) >=
            new Date(formatDateInputValue(startDateFilter))
      );
    }
    const endDateFilter = target.endDateFilter.value.trim();
    if (endDateFilter !== "") {
      newItems = newItems.filter(
        (s) =>
          s.latestRun &&
          new Date(s.latestRun.ran) <=
            new Date(formatDateInputValue(endDateFilter))
      );
    }
    const statusFilter = filterStatus.value;
    if (statusFilter !== "Select") {
      newItems = newItems.filter((s) => s.status === statusFilter);
    }

    setItems(Array.from(newItems));
  }

  return (
    <div className="flex flex-col">
      <div className="bg-white p-3 border border-gray-200 rounded-lg shadow-sm">
        <h3 className="ml-1 text-md leading-6 font-medium text-gray-900">
          Filter
        </h3>
        <div className="-mt-6 text-right">
          <button onClick={() => setShowFilterBox(!showFilterBox)}>
            {showFilterBox ? (
              <ChevronUpIcon
                className="h-6 w-6 text-gray-500"
                aria-hidden="true"
              />
            ) : (
              <ChevronDownIcon
                className="h-6 w-6 text-gray-500"
                aria-hidden="true"
              />
            )}
          </button>
        </div>
        <div hidden={!showFilterBox}>
          <form
            className="grid grid-cols-4 gap-4"
            onSubmit={(e) => handleFilter(e)}
          >
            <div className="ml-1">
              <label
                htmlFor="sampleIdFilter"
                className="mt-2.5 block text-sm font-medium text-gray-700"
              >
                Sample ID
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="sampleIdFilter"
                  id="sampleIdFilter"
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="ml-1">
              <CustomSelectMenu
                label={"Sample Type"}
                options={sampleTypeOptions}
                selected={sampleType}
                setSelected={setSampleType}
              />
            </div>
            <div className="ml-1">
              <CustomSelectMenu
                label={"Status"}
                options={statusOptions}
                selected={filterStatus}
                setSelected={setFilterStatus}
              />
            </div>
            <div className="ml-1">
              <label
                htmlFor="startDateFilter"
                className="mt-2.5 block text-sm font-medium text-gray-700 mb-1"
              >
                Latest Run Date
              </label>
              <input
                type="date"
                name="startDateFilter"
                id="startDateFilter"
                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
              <p className="text-center">to</p>
              <input
                type="date"
                name="endDateFilter"
                id="endDateFilter"
                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div className="text-right col-end-5 col-span-1">
              <button
                type="button"
                onClick={clearFilter}
                className="m-1 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Clear
              </button>
              <button
                type="submit"
                className="m-1 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Apply
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            {sampleSummaries.length === 0 ? (
              <EmptyStateScreen
                item={"sample"}
                createLink={"/regulator/samples/add"}
                writeAccess={samplesWrite}
              />
            ) : (
              <div>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Sample ID
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Sample Type
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Latest Run ID
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Latest Run Date
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Status
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">View</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{tableSampleRows}</tbody>
                </table>
                <Pagination
                  itemsPerPage={itemsPerPage}
                  totalItems={
                    items
                      ? items.length
                      : sampleSummaries.filter((s) =>
                          isReported ? s.reportIssued : !s.reportIssued
                        ).length
                  }
                  currentPage={currentPage}
                  paginate={paginate}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
