import useSWR, { mutate } from "swr";
import useSWRImmutable from "swr/immutable";
import {
  AuditEventDetails,
  BillingDetails,
  BillingPortalSessionDetails,
  BillingPreviewDetails,
  CompetencyApprovalDetails,
  CompetencyDetails,
  CompetencyState,
  CompetencySummary,
  CompetencyTypeDetails,
  CompetencyTypeSummary,
  CurrentRegistrationDetails,
  EquipmentDetails,
  EquipmentStatus,
  EquipmentSummary,
  EquipmentTypeDetails,
  EquipmentTypeSummary,
  ErrorDetails,
  FormCategoryPointer,
  FormDetails,
  FormElementAttachment,
  FormElementEntitySelect,
  FormElementSimpleInput,
  FormElementSimpleSelect,
  FormElementText,
  FormStatus,
  FormSummary,
  LabDetails,
  LabSubdomainCheckDetails,
  PersonnelAttachmentType,
  PersonnelDetails,
  PersonnelRoleDetails,
  PersonnelRoleSummary,
  PersonnelSummary,
  ReagentDetails,
  ReagentStatus,
  ReagentSummary,
  ReagentTypeDetails,
  ReagentTypeSummary,
  RecordDetails,
  RecordSummary,
  SampleDetails,
  SampleSummary,
  SampleTypeDetails,
  SampleTypeSummary,
  SOPCategoryDetails,
  SOPCategorySummary,
  SOPDetails,
  SOPDraftApprovalDetails,
  SOPDraftCommentDetails,
  SOPState,
  SOPSummary,
  Status,
  TaskDetails,
  TaskStatus,
  TaskSummary,
  UserDetails,
  UserInvitationDetails,
  UserPasswordResetDetails,
  UserPermissions,
  UserSignatureDetails,
  UserStatus,
  UserSummary,
  WorkflowRunDetails,
  WorkflowRunSummary,
  WorkflowTemplateDetails,
  WorkflowTemplateStatus,
  WorkflowTemplateSummary,
} from "./Entities.d";

interface requestOptions {
  method: "GET" | "POST" | "PATCH" | "DELETE";
  headers?: {
    "Content-Type": string;
  };
  body?: string | FormData;
}

function commonFetch(path: string, options?: requestOptions) {
  const init: RequestInit = {
    method: options?.method,
    headers: options?.headers,
    body: options?.body,
    credentials: "include",
    mode: "cors",
  };

  return fetch(formatURL(path), init).then(async (res) => {
    if (!res.ok) {
      throw await res.json();
    }

    return res;
  });
}

function defaultFetcher<Type>(
  path: string,
  options?: requestOptions
): Promise<Type> {
  return commonFetch(path, options).then((res) => res.json());
}

function createFileFetcher() {
  return (path: string) =>
    commonFetch(path).then(async (res) => {
      const blob = await res.blob();
      const fileName = res.headers.get("X-Regulator-FileName");
      return new File([blob], fileName ? fileName : "unknown", {
        type: blob.type,
      });
    });
}

export function formatURL(path: string) {
  const apiOrigin = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : window.location.origin;

  return `${apiOrigin}/api/v0${path}`;
  // return `${window.location.protocol}//localhost:9000/api/v0${path}`;
}

export function applySubdomain(subdomain?: string) {
  if (!subdomain) {
    return window.location.origin;
  }

  const domain = window.location.hostname.split(".").slice(-2);
  domain.unshift(subdomain);
  const hostname = domain.join(".");
  const port = window.location.port === "" ? "" : ":" + window.location.port;
  const protocol = window.location.protocol;

  return `${protocol}//${hostname}${port}`;
}

export function documentDownloadLink(id: string) {
  return formatURL(`/documents/${id}/download`);
}

function requestState(data?: any, error?: any) {
  return {
    isLoading: !error && !data,
    isError: !!error,
  };
}

export function useSampleTypeSummaries(isAuthorized: boolean) {
  const { data, error } = useSWR<{ sampleTypes: SampleTypeSummary[] }>(
    isAuthorized ? "/samples/types" : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    sampleTypeSummaries: data?.sampleTypes || [],
    sampleTypeSummariesIsLoading: isLoading,
    sampleTypeSummariesIsError: isError,
  };
}

export function useSampleType(id: string, isAuthorized: boolean) {
  const { data, error, mutate } = useSWR<SampleTypeDetails>(
    isAuthorized ? `/samples/types/${id}` : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    sampleType: data,
    mutateSampleType: mutate,
    sampleTypeIsLoading: isLoading,
    sampleTypeIsError: isError,
  };
}

export async function addSampleType(sampleType: { name: string }) {
  const options: requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(sampleType),
  };

  const createdSampleType = await defaultFetcher<SampleTypeDetails>(
    "/samples/types",
    options
  );

  await mutate(
    `/samples/types/${createdSampleType.id}`,
    createdSampleType,
    false
  );

  return createdSampleType;
}

export function updateSampleType(
  id: string,
  sampleType: {
    name: string;
  }
) {
  const options: requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(sampleType),
  };

  return defaultFetcher<SampleTypeDetails>(`/samples/types/${id}`, options);
}

export function sampleTypeAuditPath(id: string) {
  return `/samples/types/${id}/audit`;
}

export function useSample(id: string, isAuthorized: boolean) {
  const { data, error, mutate } = useSWR<SampleDetails>(
    isAuthorized ? `/samples/${id}` : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    sample: data,
    mutateSample: mutate,
    sampleIsLoading: isLoading,
    sampleIsError: isError,
  };
}

export function useSampleSummaries(isAuthorized: boolean) {
  const { data, error } = useSWR<{ samples: SampleSummary[] }>(
    isAuthorized ? `/samples` : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    sampleSummaries: data?.samples || [],
    sampleSummariesIsLoading: isLoading,
    sampleSummariesIsError: isError,
  };
}

export function updateSample(id: string, status: Status, reportIssued?: Date) {
  const options: requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      status: status,
      reportIssued: reportIssued?.toUTCString(),
    }),
  };

  return defaultFetcher<SampleDetails>(`/samples/${id}`, options);
}

export async function addSamples(
  samples: {
    typeID: string;
    displayID: string;
    received: Date;
    preservationMethod: string;
  }[]
) {
  const formattedSamples = samples.map((sample) => {
    return {
      typeID: sample.typeID,
      displayID: sample.displayID,
      received: sample.received.toUTCString(),
      preservationMethod: sample.preservationMethod,
    };
  });

  const options: requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ samples: formattedSamples }),
  };

  const createdSamples = await defaultFetcher<{ samples: SampleDetails[] }>(
    "/samples",
    options
  );
  await Promise.all(
    createdSamples.samples.map((createdSample) => {
      return mutate<SampleDetails>(
        `/samples/${createdSample.id}`,
        createdSample,
        false
      );
    })
  );
  await mutate("/samples");

  return createdSamples.samples;
}

export function sampleAuditPath(id: string) {
  return `/samples/${id}/audit`;
}

export function useReagentTypeSummaries(isAuthorized: boolean) {
  const { data, error } = useSWR<{ reagentTypes: ReagentTypeSummary[] }>(
    isAuthorized ? "/reagents/types" : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    reagentTypeSummaries: data?.reagentTypes || [],
    reagentTypeSummariesIsLoading: isLoading,
    reagentTypeSummariesIsError: isError,
  };
}

export function useReagentType(id: string, isAuthorized: boolean) {
  const { data, error, mutate } = useSWR<ReagentTypeDetails>(
    isAuthorized ? `/reagents/types/${id}` : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    reagentType: data,
    mutateReagentType: mutate,
    reagentTypeIsLoading: isLoading,
    reagentTypeIsError: isError,
  };
}

export async function addReagentType(reagentType: {
  name: string;
  manufacturer: string;
  partNumber: string;
}) {
  const options: requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reagentType),
  };

  const createdReagentType = await defaultFetcher<ReagentTypeDetails>(
    "/reagents/types",
    options
  );

  await mutate(
    `/reagents/types/${createdReagentType.id}`,
    createdReagentType,
    false
  );

  return createdReagentType;
}

export function updateReagentType(
  id: string,
  reagentType: {
    name: string;
    manufacturer: string;
    partNumber: string;
  }
) {
  const options: requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reagentType),
  };

  return defaultFetcher<ReagentTypeDetails>(`/reagents/types/${id}`, options);
}

export function reagentTypeAuditPath(id: string) {
  return `/reagents/types/${id}/audit`;
}

export function useReagentSummaries(isAuthorized: boolean) {
  const { data, error } = useSWR<{ reagents: ReagentSummary[] }>(
    isAuthorized ? `/reagents` : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    reagentSummaries: data?.reagents || [],
    reagentSummariesIsLoading: isLoading,
    reagentSummariesIsError: isError,
  };
}

export function useReagent(id: string, isAuthorized: boolean) {
  const { data, error, mutate } = useSWR<ReagentDetails>(
    isAuthorized ? `/reagents/${id}` : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    reagent: data,
    mutateReagent: mutate,
    reagentIsLoading: isLoading,
    reagentIsError: isError,
  };
}

export async function addReagent(
  typeID: string,
  displayID: string,
  status: ReagentStatus,
  lot: string,
  aliquot: string,
  received: Date | string,
  expiration: Date | string,
  attachments: File[]
) {
  if (received instanceof Date) {
    received = received.toUTCString();
  }

  if (expiration instanceof Date) {
    expiration = expiration.toUTCString();
  }

  const fd = new FormData();
  fd.append("typeID", typeID);
  fd.append("displayID", displayID);
  fd.append("status", status);
  fd.append("lot", lot);
  fd.append("aliquot", aliquot);
  fd.append("received", received);
  fd.append("expiration", expiration);
  attachments.forEach((attachment) => fd.append("attachments", attachment));

  const options: requestOptions = {
    method: "POST",
    body: fd,
  };

  const createdReagent = await defaultFetcher<ReagentDetails>(
    "/reagents",
    options
  );
  await mutate(`/reagents/${createdReagent.id}`, createdReagent, false);

  return createdReagent;
}

export function updateReagent(
  id: string,
  status: ReagentStatus,
  qcStatus: Status,
  addedAttachments: File[],
  activeAttachmentIDs: string[],
  archivedAttachmentIDs: string[]
) {
  const fd = new FormData();
  fd.append("status", status);
  fd.append("qcStatus", qcStatus);
  addedAttachments.forEach((attachment) =>
    fd.append("addedAttachments", attachment)
  );
  activeAttachmentIDs.forEach((id) => fd.append("activeAttachmentIDs", id));
  archivedAttachmentIDs.forEach((id) => fd.append("archivedAttachmentIDs", id));

  const options: requestOptions = {
    method: "PATCH",
    body: fd,
  };

  return defaultFetcher<ReagentDetails>(`/reagents/${id}`, options);
}

export function reagentAuditPath(id: string) {
  return `/reagents/${id}/audit`;
}

export function useEquipmentTypeSummaries(isAuthorized: boolean) {
  const { data, error } = useSWR<{ equipmentTypes: EquipmentTypeSummary[] }>(
    isAuthorized ? "/equipment/types" : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    equipmentTypeSummaries: data?.equipmentTypes || [],
    equipmentTypeSummariesIsLoading: isLoading,
    equipmentTypeSummariesIsError: isError,
  };
}

export function useEquipmentType(id: string, isAuthorized: boolean) {
  const { data, error, mutate } = useSWR<EquipmentTypeDetails>(
    isAuthorized ? `/equipment/types/${id}` : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    equipmentType: data,
    mutateEquipmentType: mutate,
    equipmentTypeIsLoading: isLoading,
    equipmentTypeIsError: isError,
  };
}

export async function addEquipmentType(equipmentType: {
  name: string;
  calibrationCycle: number;
}) {
  const options: requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(equipmentType),
  };

  const createdEquipmentType = await defaultFetcher<EquipmentTypeDetails>(
    "/equipment/types",
    options
  );

  await mutate(
    `/equipment/types/${createdEquipmentType.id}`,
    createdEquipmentType,
    false
  );

  return createdEquipmentType;
}

export function updateEquipmentType(
  id: string,
  equipmentType: {
    name: string;
    calibrationCycle: number;
  }
) {
  const options: requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(equipmentType),
  };

  return defaultFetcher<EquipmentTypeDetails>(
    `/equipment/types/${id}`,
    options
  );
}

export function equipmentTypeAuditPath(id: string) {
  return `/equipment/types/${id}/audit`;
}

export function useEquipmentSummaries(isAuthorized: boolean) {
  const { data, error } = useSWR<{ equipment: EquipmentSummary[] }>(
    isAuthorized ? `/equipment` : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    equipmentSummaries: data?.equipment || [],
    equipmentSummariesIsLoading: isLoading,
    equipmentSummariesIsError: isError,
  };
}

export function useEquipment(id: string, isAuthorized: boolean) {
  const { data, error, mutate } = useSWR<EquipmentDetails>(
    isAuthorized ? `/equipment/${id}` : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    equipment: data,
    mutateEquipment: mutate,
    equipmentIsLoading: isLoading,
    equipmentIsError: isError,
  };
}

export async function addEquipment(
  typeID: string,
  displayID: string,
  status: EquipmentStatus,
  manufacturer: string,
  lastCalibration: Date | string,
  attachments: File[]
) {
  if (lastCalibration instanceof Date) {
    lastCalibration = lastCalibration.toUTCString();
  }

  const fd = new FormData();
  fd.append("typeID", typeID);
  fd.append("displayID", displayID);
  fd.append("status", status);
  fd.append("manufacturer", manufacturer);
  fd.append("lastCalibration", lastCalibration);
  attachments.forEach((attachment) => fd.append("attachments", attachment));

  const options: requestOptions = {
    method: "POST",
    body: fd,
  };

  const createdEquipment = await defaultFetcher<EquipmentDetails>(
    "/equipment",
    options
  );
  await mutate(`/equipment/${createdEquipment.id}`, createdEquipment, false);

  return createdEquipment;
}

export function updateEquipment(
  id: string,
  status: EquipmentStatus,
  lastCalibration: Date | string,
  addedAttachments: File[],
  activeAttachmentIDs: string[],
  archivedAttachmentIDs: string[]
) {
  if (lastCalibration instanceof Date) {
    lastCalibration = lastCalibration.toUTCString();
  }

  const fd = new FormData();
  fd.append("status", status);
  fd.append("lastCalibration", lastCalibration);
  addedAttachments.forEach((attachment) =>
    fd.append("addedAttachments", attachment)
  );
  activeAttachmentIDs.forEach((id) => fd.append("activeAttachmentIDs", id));
  archivedAttachmentIDs.forEach((id) => fd.append("archivedAttachmentIDs", id));

  const options: requestOptions = {
    method: "PATCH",
    body: fd,
  };

  return defaultFetcher<EquipmentDetails>(`/equipment/${id}`, options);
}

export function equipmentAuditPath(id: string) {
  return `/equipment/${id}/audit`;
}

export function usePersonnelRoleSummaries(isAuthorized: boolean) {
  const { data, error } = useSWR<{ roles: PersonnelRoleSummary[] }>(
    isAuthorized ? "/personnel/roles" : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    roleSummaries: data?.roles || [],
    roleSummariesIsLoading: isLoading,
    roleSummariesIsError: isError,
  };
}

export function usePersonnelRole(id: string, isAuthorized: boolean) {
  const { data, error, mutate } = useSWR<PersonnelRoleDetails>(
    isAuthorized ? `/personnel/roles/${id}` : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    role: data,
    mutateRole: mutate,
    roleIsLoading: isLoading,
    roleIsError: isError,
  };
}

export async function addPersonnelRole(role: {
  name: string;
  jobDescriptionDisplayID: string | null;
  letterOfDelegationDisplayID: string | null;
  reportsTo: string[];
  reports: string[];
}) {
  const options: requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(role),
  };

  const createdRole = await defaultFetcher<PersonnelRoleDetails>(
    "/personnel/roles",
    options
  );
  await mutate(`/personnel/roles/${createdRole.id}`, createdRole, false);

  return createdRole;
}

export function updatePersonnelRole(
  id: string,
  role: {
    name: string;
    jobDescriptionDisplayID: string | null;
    letterOfDelegationDisplayID: string | null;
    reportsTo: string[];
    reports: string[];
  }
) {
  const options: requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(role),
  };

  return defaultFetcher<PersonnelRoleDetails>(
    `/personnel/roles/${id}`,
    options
  );
}

export function personnelRoleAuditPath(id: string) {
  return `/personnel/roles/${id}/audit`;
}

export function usePersonnelSummaries(isAuthorized: boolean) {
  const { data, error } = useSWR<{ personnel: PersonnelSummary[] }>(
    isAuthorized ? `/personnel` : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    personnelSummaries: data?.personnel || [],
    personnelSummariesIsLoading: isLoading,
    personnelSummariesIsError: isError,
  };
}

export function usePersonnel(id: string, isAuthorized: boolean) {
  const { data, error, mutate } = useSWR<PersonnelDetails>(
    isAuthorized ? `/personnel/${id}` : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    personnel: data,
    mutatePersonnel: mutate,
    personnelIsLoading: isLoading,
    personnelIsError: isError,
  };
}

export async function addPersonnel(
  userID: string,
  roleID: string,
  hired: Date,
  attachments: { file: File; type: PersonnelAttachmentType }[]
) {
  const fd = new FormData();
  fd.append("userID", userID);
  fd.append("roleID", roleID);
  fd.append("hired", hired.toUTCString());
  attachments.forEach((a) => {
    fd.append("attachments", a.file);
    fd.append("attachmentTypes", a.type);
  });

  const options: requestOptions = {
    method: "POST",
    body: fd,
  };

  const createdPersonnel = await defaultFetcher<PersonnelDetails>(
    "/personnel",
    options
  );
  await mutate(`/personnel/${createdPersonnel.id}`, createdPersonnel, false);

  return createdPersonnel;
}

export function updatePersonnel(
  id: string,
  roleID: string,
  addedAttachments: { file: File; type: PersonnelAttachmentType }[],
  activeAttachmentIDs: string[],
  archivedAttachmentIDs: string[]
) {
  const fd = new FormData();
  fd.append("roleID", roleID);
  addedAttachments.forEach((a) => {
    fd.append("addedAttachments", a.file);
    fd.append("addedAttachmentTypes", a.type);
  });
  activeAttachmentIDs.forEach((id) => fd.append("activeAttachmentIDs", id));
  archivedAttachmentIDs.forEach((id) => fd.append("archivedAttachmentIDs", id));

  const options: requestOptions = {
    method: "PATCH",
    body: fd,
  };

  return defaultFetcher<PersonnelDetails>(`/personnel/${id}`, options);
}

export function personnelAttachmentDownloadLink(attachmentID: string) {
  return formatURL(`/personnel/attachments/${attachmentID}/download`);
}

export function personnelAuditPath(id: string) {
  return `/personnel/${id}/audit`;
}

export function useCompetencyTypeSummaries(isAuthorized: boolean) {
  const { data, error } = useSWR<{ competencyTypes: CompetencyTypeSummary[] }>(
    isAuthorized ? "/competencies/types" : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    competencyTypeSummaries: data?.competencyTypes || [],
    competencyTypeSummariesIsLoading: isLoading,
    competencyTypeSummariesIsError: isError,
  };
}

export function useCompetencyType(id: string, isAuthorized: boolean) {
  const { data, error, mutate } = useSWR<CompetencyTypeDetails>(
    isAuthorized ? `/competencies/types/${id}` : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    competencyType: data,
    mutateCompetencyType: mutate,
    competencyTypeIsLoading: isLoading,
    competencyTypeIsError: isError,
  };
}

export async function addCompetencyType(competencyType: { name: string }) {
  const options: requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(competencyType),
  };

  const createdCompetencyType = await defaultFetcher<CompetencyTypeDetails>(
    "/competencies/types",
    options
  );

  await mutate(
    `/competencies/types/${createdCompetencyType.id}`,
    createdCompetencyType,
    false
  );

  return createdCompetencyType;
}

export function updateCompetencyType(
  id: string,
  competencyType: {
    name: string;
  }
) {
  const options: requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(competencyType),
  };

  return defaultFetcher<CompetencyTypeDetails>(
    `/competencies/types/${id}`,
    options
  );
}

export function competencyTypeAuditPath(id: string) {
  return `/competencies/types/${id}/audit`;
}

export function useCompetencySummaries(
  state: CompetencyState,
  typeID: string | null | undefined,
  isAuthorized: boolean
) {
  const url = `/competencies?state=${state}&typeID=${typeID}`;
  const { data, error } = useSWR<{ competencies: CompetencySummary[] }>(
    isAuthorized && typeID ? url : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    competencySummaries: data?.competencies || [],
    competencySummariesIsLoading: isLoading,
    competencySummariesIsError: isError,
  };
}

export function useCompetency(id: string | undefined, isAuthorized: boolean) {
  const { data, error, mutate } = useSWR<CompetencyDetails>(
    isAuthorized && id ? `/competencies/${id}` : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    competency: data,
    mutateCompetency: mutate,
    competencyIsLoading: isLoading,
    competencyIsError: isError,
  };
}

export async function addCompetency(
  typeID: string,
  personnelID: string,
  approverIDs: string[],
  recordIDs: string[],
  workflowRunIDs: string[],
  links: {
    url: string;
    alias: string;
  }[],
  attachments: File[]
) {
  const fd = new FormData();
  fd.append("typeID", typeID);
  fd.append("personnelID", personnelID);
  approverIDs.forEach((approverID) =>
    fd.append("approverPersonnelIDs", approverID)
  );
  recordIDs.forEach((recordID) => fd.append("recordIDs", recordID));
  workflowRunIDs.forEach((runID) => fd.append("workflowRunIDs", runID));
  links.forEach((link) => {
    fd.append("links", link.url);
    fd.append("linkAliases", link.alias);
  });
  attachments.forEach((attachment) => fd.append("attachments", attachment));

  const options: requestOptions = {
    method: "POST",
    body: fd,
  };

  const createdCompetency = await defaultFetcher<CompetencyDetails>(
    "/competencies",
    options
  );
  await mutate(
    `/competencies/${createdCompetency.id}`,
    createdCompetency,
    false
  );

  return createdCompetency;
}

export function updateCompetency(
  id: string,
  approverIDs: string[],
  recordIDs: string[],
  workflowRunIDs: string[],
  links: {
    url: string;
    alias: string;
  }[],
  deletedAttachmentIDs: string[],
  addedAttachments: File[]
) {
  const fd = new FormData();
  approverIDs.forEach((approverID) =>
    fd.append("approverPersonnelIDs", approverID)
  );
  recordIDs.forEach((recordID) => fd.append("recordIDs", recordID));
  workflowRunIDs.forEach((runID) => fd.append("workflowRunIDs", runID));
  links.forEach((link) => {
    fd.append("links", link.url);
    fd.append("linkAliases", link.alias);
  });
  deletedAttachmentIDs.forEach((deletedAttachmentID) =>
    fd.append("deletedAttachmentIDs", deletedAttachmentID)
  );
  addedAttachments.forEach((addedAttachment) =>
    fd.append("addedAttachments", addedAttachment)
  );

  const options: requestOptions = {
    method: "PATCH",
    body: fd,
  };

  return defaultFetcher<CompetencyDetails>(`/competencies/${id}`, options);
}

export function updateCompetencyReview(competencyID: string) {
  const options: requestOptions = {
    method: "PATCH",
  };

  return defaultFetcher<CompetencyDetails>(
    `/competencies/${competencyID}/review`,
    options
  );
}

export function updateCompetencyApprove(
  competencyID: string,
  signaturePIN: string
) {
  const options: requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ signaturePIN: signaturePIN }),
  };

  return defaultFetcher<CompetencyDetails>(
    `/competencies/${competencyID}/approve`,
    options
  );
}

export async function deleteCompetency(competency: CompetencyDetails) {
  return await mutate<{ competencies: CompetencySummary[] }>(
    `/competencies?state=${competency.state}&typeID=${competency.type.id}`,
    async (existingCompetencies) => {
      const options: requestOptions = {
        method: "DELETE",
      };

      await defaultFetcher(`/competencies/${competency.id}`, options);

      if (!existingCompetencies) {
        return existingCompetencies;
      }

      return {
        competencies: existingCompetencies.competencies.filter(
          (c) => c.id !== competency.id
        ),
      };
    }
  );
}

export function competencyAttachmentDownloadLink(attachmentID: string) {
  return formatURL(`/competencies/attachments/${attachmentID}/download`);
}

export function competencyAuditPath(id: string) {
  return `/competencies/${id}/audit`;
}

export function useCompetencyApprovals(
  competencyID: string | undefined,
  isAuthorized: boolean
) {
  const { data, error, mutate } = useSWR<{
    approvals: CompetencyApprovalDetails[];
  }>(
    isAuthorized && competencyID
      ? `/competencies/${competencyID}/approvals`
      : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    approvals: data?.approvals || [],
    mutateApprovals: mutate,
    approvalsIsLoading: isLoading,
    approvalsIsError: isError,
  };
}

export function login(email: string, password: string) {
  const fd = new FormData();
  fd.append("email", email);
  fd.append("password", password);

  const options: requestOptions = {
    method: "POST",
    body: fd,
  };

  return defaultFetcher("/login", options);
}

export function logout() {
  return defaultFetcher("/logout");
}

export function useUserSummaries(status: UserStatus | "") {
  const { data, error } = useSWR<{ users: UserSummary[] }>(
    `/users?status=${status}`,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    userSummaries: data?.users || [],
    userSummariesIsLoading: isLoading,
    userSummariesIsError: isError,
  };
}

export function useAdminUserSummaries(status: UserStatus | "") {
  const { data, error } = useSWR<{ users: UserSummary[] }>(
    `/users/admins?status=${status}`,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    adminUserSummaries: data?.users || [],
    adminUserSummariesIsLoading: isLoading,
    adminUserSummariesIsError: isError,
  };
}

export function useCurrentUser() {
  const { data, error } = useSWR<UserDetails, ErrorDetails>(
    "/users/self",
    defaultFetcher,
    {
      onErrorRetry: (error) => {
        // Never retry on 401.
        if (error.statusCode === 401) return;
      },
    }
  );
  const { isLoading, isError } = requestState(data, error);

  return {
    currentUser: data,
    isAuthenticated: !error,
    isCurrentUserLoading: isLoading,
    isCurrentUserError: isError,
  };
}

export function useUser(id: string) {
  const { data, error } = useSWR<UserDetails>(`/users/${id}`, defaultFetcher);
  const { isLoading, isError } = requestState(data, error);

  return {
    user: data,
    userIsLoading: isLoading,
    userIsError: isError,
  };
}

export async function addUser(user: {
  email: string;
  firstName: string;
  lastName: string;
  isAdmin: boolean;
  workflowTemplates: UserPermissions;
  workflowHistory: UserPermissions;
  samples: UserPermissions;
  reagents: UserPermissions;
  equipment: UserPermissions;
  personnel: UserPermissions;
  competencies: UserPermissions;
  sops: UserPermissions;
  forms: UserPermissions;
  records: UserPermissions;
}) {
  const options: requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  const createdUser = await defaultFetcher<UserDetails>("/users", options);
  await mutate(`/users/${createdUser.id}`, createdUser, false);
  await mutate("/users?status=");
  await mutate("/users/admins?status=");

  return createdUser;
}

export async function updateUser(
  id: string,
  user: {
    status: "Active" | "Inactive";
    isAdmin: boolean;
    workflowTemplates: UserPermissions;
    workflowHistory: UserPermissions;
    samples: UserPermissions;
    reagents: UserPermissions;
    equipment: UserPermissions;
    personnel: UserPermissions;
    competencies: UserPermissions;
    sops: UserPermissions;
    forms: UserPermissions;
    records: UserPermissions;
  }
) {
  const options: requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  const updatedUser = await defaultFetcher<UserDetails>(
    `/users/${id}`,
    options
  );

  await mutate(`/users/${id}`, updatedUser, false);
  await mutate("/users?status=");
  await mutate("/users/admins?status=");

  return updatedUser;
}

export async function updateUserProfile(
  id: string,
  userProfile: {
    firstName: string;
    lastName: string;
  }
) {
  const options: requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userProfile),
  };

  const updatedUser = await defaultFetcher<UserDetails>(
    `/users/${id}/profile`,
    options
  );

  await mutate("/users/self", updatedUser, false);

  return updatedUser;
}

export function updateUserPassword(
  id: string,
  userPassword: {
    currentPassword: string;
    newPassword: string;
  }
) {
  const options: requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userPassword),
  };

  return defaultFetcher<UserDetails>(`/users/${id}/password`, options);
}

export async function deleteUser(id: string) {
  const options: requestOptions = {
    method: "DELETE",
  };

  await defaultFetcher(`/users/${id}`, options);
  await mutate("/users?status=");
  await mutate("/users/admins?status=");
}

export function useUserSignature(id: string | undefined) {
  const { data, error, mutate } = useSWR<UserSignatureDetails>(
    id ? `/users/${id}/signature` : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    userSignature: data,
    mutateUserSignature: mutate,
    userSignatureIsLoading: isLoading,
    userSignatureIsError: isError,
  };
}

export function addUserSignature(
  id: string,
  userSignature: {
    encodedSignature: string;
    signaturePIN: string;
    password: string;
  }
) {
  const options: requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userSignature),
  };

  return defaultFetcher<UserSignatureDetails>(
    `/users/${id}/signature`,
    options
  );
}

export function useUserInvitation(id: string) {
  const { data, error } = useSWR<UserInvitationDetails>(
    `/users/invitations/${id}`,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    userInvitation: data,
    userInvitationIsLoading: isLoading,
    userInvitationIsError: isError,
  };
}

export function addUserInvitation(invitation: { userID: string }) {
  const options: requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(invitation),
  };

  return defaultFetcher<UserInvitationDetails>(`/users/invitations`, options);
}

export function updateUserInvitation(
  id: string,
  updates: {
    password: string;
  }
) {
  const options: requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(updates),
  };

  return defaultFetcher(`/users/invitations/${id}`, options);
}

export function useUserPasswordReset(id: string) {
  const { data, error } = useSWR<UserPasswordResetDetails>(
    `/users/password-resets/${id}`,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    passwordReset: data,
    passwordResetIsLoading: isLoading,
    passwordResetIsError: isError,
  };
}

export function addUserPasswordReset(reset: { userID: string }) {
  const options: requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reset),
  };

  return defaultFetcher<UserPasswordResetDetails>(
    `/users/password-resets`,
    options
  );
}

export function updateUserPasswordReset(
  id: string,
  updates: {
    password: string;
  }
) {
  const options: requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(updates),
  };

  return defaultFetcher(`/users/password-resets/${id}`, options);
}

export function useSOPCategorySummaries(isAuthorized: boolean) {
  const { data, error, mutate } = useSWR<{ categories: SOPCategorySummary[] }>(
    isAuthorized ? "/sops/categories" : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    sopCategorySummaries: data?.categories || [],
    mutateSOPCategorySummaries: mutate,
    sopCategorySummariesIsLoading: isLoading,
    sopCategorySummariesIsError: isError,
  };
}

export function useSOPCategory(id: string | null, isAuthorized: boolean) {
  const { data, error, mutate } = useSWR<SOPCategoryDetails>(
    isAuthorized && id ? `/sops/categories/${id}` : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    sopCategory: data,
    mutateSOPCategory: mutate,
    sopCategoryIsLoading: isLoading,
    sopCategoryIsError: isError,
  };
}

export async function addSOPCategory(category: {
  name: string;
  initials: string;
  approverRoleIDs: string[];
}) {
  const options: requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(category),
  };

  const createdCategory = await defaultFetcher<SOPCategoryDetails>(
    "/sops/categories",
    options
  );

  await mutate(
    `/sops/categories/${createdCategory.id}`,
    createdCategory,
    false
  );

  return createdCategory;
}

export function updateSOPCategory(
  id: string,
  category: {
    name: string;
    initials: string;
    approverRoleIDs: string[];
  }
) {
  const options: requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(category),
  };

  return defaultFetcher<SOPCategoryDetails>(`/sops/categories/${id}`, options);
}

export function sopCategoryAuditPath(id: string) {
  return `/sops/categories/${id}/audit`;
}

export function useSOPSummaries(
  state: SOPState,
  categoryID: string | null,
  isAuthorized: boolean
) {
  const url = `/sops?state=${state}&categoryID=${categoryID}`;
  const { data, error } = useSWR<{ sops: SOPSummary[] }>(
    isAuthorized && categoryID !== null ? url : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    sopSummaries: data?.sops || [],
    sopSummariesIsLoading: isLoading,
    sopSummariesIsError: isError,
  };
}

export function useSOP(id: string | undefined, isAuthorized: boolean) {
  const { data, error, mutate } = useSWR<SOPDetails>(
    isAuthorized && id ? `/sops/${id}` : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    sop: data,
    mutateSOP: mutate,
    sopIsLoading: isLoading,
    sopIsError: isError,
  };
}

export async function addSOP(
  categoryID: string,
  displayIDSuffix: string,
  title: string,
  file: File,
  majorVersion: number,
  minorVersion: number,
  approvers: string[]
) {
  const fd = new FormData();
  fd.append("categoryID", categoryID);
  fd.append("displayIDSuffix", displayIDSuffix);
  fd.append("title", title);
  fd.append("file", file);
  fd.append("majorVersion", majorVersion.toString());
  fd.append("minorVersion", minorVersion.toString());
  approvers.forEach((approver) => fd.append("approverPersonnelIDs", approver));

  const options: requestOptions = {
    method: "POST",
    body: fd,
  };

  const createdSOP = await defaultFetcher<SOPDetails>("/sops", options);
  await mutate(`/sops/${createdSOP.id}`, createdSOP, false);

  return createdSOP;
}

export async function importSOP(
  categoryID: string,
  displayIDSuffix: string,
  title: string,
  file: File,
  majorVersion: number,
  minorVersion: number,
  reviewed: Date | string
) {
  if (reviewed instanceof Date) {
    reviewed = reviewed.toUTCString();
  }

  const fd = new FormData();
  fd.append("categoryID", categoryID);
  fd.append("displayIDSuffix", displayIDSuffix);
  fd.append("title", title);
  fd.append("file", file);
  fd.append("majorVersion", majorVersion.toString());
  fd.append("minorVersion", minorVersion.toString());
  fd.append("reviewed", reviewed);

  const options: requestOptions = {
    method: "POST",
    body: fd,
  };

  const importedSOP = await defaultFetcher<SOPDetails>("/sops/import", options);
  await mutate(`/sops/${importedSOP.id}`, importedSOP, false);

  return importedSOP;
}

export function updateSOP(
  id: string,
  categoryID: string,
  displayIDSuffix: string,
  title: string,
  file: File,
  majorVersion: number,
  minorVersion: number,
  approvers: string[]
) {
  const fd = new FormData();
  fd.append("categoryID", categoryID);
  fd.append("displayIDSuffix", displayIDSuffix);
  fd.append("title", title);
  fd.append("file", file);
  fd.append("majorVersion", majorVersion.toString());
  fd.append("minorVersion", minorVersion.toString());
  approvers.forEach((approver) => fd.append("approverPersonnelIDs", approver));

  const options: requestOptions = {
    method: "PATCH",
    body: fd,
  };

  return defaultFetcher<SOPDetails>(`/sops/${id}`, options);
}

export function updateSOPDraftReview(sopID: string) {
  const options: requestOptions = {
    method: "PATCH",
  };

  return defaultFetcher<SOPDetails>(`/sops/${sopID}/draft-review`, options);
}

export function updateSOPPublish(sopID: string) {
  const options: requestOptions = {
    method: "PATCH",
  };

  return defaultFetcher<SOPDetails>(`/sops/${sopID}/publish`, options);
}

export function updateProcedureManualReview(
  sopID: string,
  signaturePIN: string
) {
  const options: requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ signaturePIN: signaturePIN }),
  };

  return defaultFetcher<SOPDetails>(
    `/sops/${sopID}/procedure-manual-review`,
    options
  );
}

export function updateSOPArchive(sopID: string) {
  const options: requestOptions = {
    method: "PATCH",
  };

  return defaultFetcher<SOPDetails>(`/sops/${sopID}/archive`, options);
}

export async function deleteSOP(sop: SOPDetails) {
  return await mutate<{ sops: SOPSummary[] }>(
    `/sops?state=${sop.state}&categoryID=${sop.categoryID}`,
    async (existingSOPs) => {
      const options: requestOptions = {
        method: "DELETE",
      };

      await defaultFetcher(`/sops/${sop.id}`, options);

      if (!existingSOPs) {
        return existingSOPs;
      }

      return {
        sops: existingSOPs.sops.filter((s) => s.id !== sop.id),
      };
    }
  );
}

export function useSOPFile(sopID: string | undefined, isAuthorized: boolean) {
  const { data, error } = useSWR<File>(
    isAuthorized && sopID ? `/sops/${sopID}/file` : null,
    createFileFetcher()
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    sopFile: data,
    sopFileIsLoading: isLoading,
    sopFileIsError: isError,
  };
}

export function sopAuditPath(id: string) {
  return `/sops/${id}/audit`;
}

export function useSOPDraftComments(
  sopID: string | undefined,
  isAuthorized: boolean
) {
  const { data, error } = useSWR<{ comments: SOPDraftCommentDetails[] }>(
    isAuthorized && sopID ? `/sops/comments?sopID=${sopID}` : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    comments: data?.comments || [],
    commentsIsLoading: isLoading,
    commentsIsError: isError,
  };
}

export async function addSOPDraftComment(comment: {
  sopID: string;
  text: string;
}) {
  return await mutate<{ comments: SOPDraftCommentDetails[] }>(
    `/sops/comments?sopID=${comment.sopID}`,
    async (existingComments) => {
      const options: requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(comment),
      };

      const createdComment = await defaultFetcher<SOPDraftCommentDetails>(
        "/sops/comments",
        options
      );

      const existingCommentDetails = existingComments
        ? existingComments.comments
        : [];

      return { comments: [...existingCommentDetails, createdComment] };
    }
  );
}

export async function deleteSOPDraftComment(comment: {
  id: string;
  sopID: string;
}) {
  return await mutate<{ comments: SOPDraftCommentDetails[] }>(
    `/sops/comments?sopID=${comment.sopID}`,
    async (existingComments) => {
      const options: requestOptions = {
        method: "DELETE",
      };

      await defaultFetcher(`/sops/comments/${comment.id}`, options);

      const existingCommentDetails = existingComments
        ? existingComments.comments
        : [];

      return {
        comments: existingCommentDetails.filter(
          (existingComment) => existingComment.id !== comment.id
        ),
      };
    }
  );
}

export function useSOPDraftApprovals(
  sopID: string | undefined,
  isAuthorized: boolean
) {
  const { data, error, mutate } = useSWR<{
    approvals: SOPDraftApprovalDetails[];
  }>(isAuthorized && sopID ? `/sops/${sopID}/approvals` : null, defaultFetcher);

  const { isLoading, isError } = requestState(data, error);

  return {
    approvals: data?.approvals || [],
    mutateApprovals: mutate,
    approvalsIsLoading: isLoading,
    approvalsIsError: isError,
  };
}

export async function updateSOPDraftApproval(
  id: string,
  status: "Approved" | "Rejected",
  signaturePIN: string,
  sopID: string
) {
  return await mutate<{ approvals: SOPDraftApprovalDetails[] }>(
    `/sops/${sopID}/approvals`,
    async (existingApprovals) => {
      const options: requestOptions = {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ status: status, signaturePIN: signaturePIN }),
      };

      const updatedApproval = await defaultFetcher<SOPDraftApprovalDetails>(
        `/sops/approvals/${id}`,
        options
      );

      const updatedApprovals = existingApprovals
        ? existingApprovals.approvals
        : [];

      const index = updatedApprovals.findIndex(
        (approval) => approval.id === id
      );

      if (index !== -1) {
        updatedApprovals[index] = updatedApproval;
      }

      return {
        approvals: updatedApprovals,
      };
    },
    false
  );
}

export function useWorkflowTemplateSummaries(
  status: WorkflowTemplateStatus,
  isAuthorized: boolean
) {
  const { data, error } = useSWR<{
    templates: WorkflowTemplateSummary[];
  }>(
    isAuthorized ? `/workflows/templates?status=${status}` : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    workflowTemplateSummaries: data?.templates || [],
    workflowTemplateSummariesIsLoading: isLoading,
    workflowTemplateSummariesIsError: isError,
  };
}

export function useWorkflowTemplate(
  id: string | undefined,
  isAuthorized: boolean
) {
  const { data, error, mutate } = useSWR<WorkflowTemplateDetails>(
    isAuthorized && id ? `/workflows/templates/${id}` : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    workflowTemplate: data,
    mutateWorkflowTemplate: mutate,
    workflowTemplateIsLoading: isLoading,
    workflowTemplateIsError: isError,
  };
}

export async function addWorkflowTemplate(template: {
  name: string;
  sopID: string;
  competencyTypeID: string;
  majorVersion: number;
  minorVersion: number;
  equipmentTypeIDs: string[];
  reagentTypeIDs: string[];
  sampleTypeIDs: string[];
}) {
  const options: requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(template),
  };

  const createdWorkflowTemplate = await defaultFetcher<WorkflowTemplateDetails>(
    "/workflows/templates",
    options
  );

  await mutate(
    `/workflows/templates/${createdWorkflowTemplate.id}`,
    createdWorkflowTemplate,
    false
  );

  return createdWorkflowTemplate;
}

export function updateWorkflowTemplateArchive(id: string) {
  const options: requestOptions = {
    method: "PATCH",
  };

  return defaultFetcher<WorkflowTemplateDetails>(
    `/workflows/templates/${id}/archive`,
    options
  );
}

export function workflowTemplateAuditPath(id: string) {
  return `/workflows/templates/${id}/audit`;
}

export function useWorkflowRunSummaries(isAuthorized: boolean) {
  const { data, error } = useSWR<{ runs: WorkflowRunSummary[] }>(
    isAuthorized ? `/workflows/runs` : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    workflowRunSummaries: data?.runs || [],
    workflowRunSummariesIsLoading: isLoading,
    workflowRunSummariesIsError: isError,
  };
}

export function useWorkflowRun(id: string | null, isAuthorized: boolean) {
  const { data, error, mutate } = useSWR<WorkflowRunDetails>(
    isAuthorized && id ? `/workflows/runs/${id}` : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    workflowRun: data,
    mutateWorkflowRun: mutate,
    workflowRunIsLoading: isLoading,
    workflowRunIsError: isError,
  };
}

export async function addWorkflowRun(
  templateID: string,
  displayID: string,
  ran: Date | string,
  competencyIDs: string[],
  personnelIDs: string[],
  equipmentIDs: string[],
  reagents: {
    id: string;
    qualified: boolean;
  }[],
  sampleIDs: string[],
  attachments: File[]
) {
  const fd = new FormData();
  fd.append("templateID", templateID);
  fd.append("displayID", displayID);
  fd.append("ran", ran instanceof Date ? ran.toUTCString() : ran);
  competencyIDs.forEach((competencyID) =>
    fd.append("competencyIDs", competencyID)
  );
  personnelIDs.forEach((personnelID) => fd.append("personnelIDs", personnelID));
  equipmentIDs.forEach((equipmentID) => fd.append("equipmentIDs", equipmentID));
  reagents.forEach((reagent) => {
    fd.append("reagentIDs", reagent.id);
    fd.append("reagentQualifications", reagent.qualified.toString());
  });
  sampleIDs.forEach((sampleID) => fd.append("sampleIDs", sampleID));
  attachments.forEach((attachment) => fd.append("attachments", attachment));

  const options: requestOptions = {
    method: "POST",
    body: fd,
  };

  const createdWorkflowRun = await defaultFetcher<WorkflowRunDetails>(
    "/workflows/runs",
    options
  );

  await mutate(
    `/workflows/runs/${createdWorkflowRun.id}`,
    createdWorkflowRun,
    false
  );

  return createdWorkflowRun;
}

export function updateWorkflowRun(
  id: string,
  status: Status,
  attachments: File[]
) {
  const fd = new FormData();
  fd.append("status", status);
  attachments.forEach((attachment) => fd.append("attachments", attachment));

  const options: requestOptions = {
    method: "PATCH",
    body: fd,
  };

  return defaultFetcher<WorkflowRunDetails>(`/workflows/runs/${id}`, options);
}

export function workflowRunAttachmentDownloadLink(attachmentID: string) {
  return formatURL(`/workflows/runs/attachments/${attachmentID}/download`);
}

export function workflowRunAuditPath(id: string) {
  return `/workflows/runs/${id}/audit`;
}

export function useFormSummaries(status: FormStatus, isAuthorized: boolean) {
  const { data, error } = useSWR<{ forms: FormSummary[] }>(
    isAuthorized ? `/forms?status=${status}` : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    formSummaries: data?.forms || [],
    formSummariesIsLoading: isLoading,
    formSummariesIsError: isError,
  };
}

export function useForm(id: string | undefined, isAuthorized: boolean) {
  const { data, error, mutate } = useSWR<FormDetails>(
    isAuthorized && id ? `/forms/${id}` : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    form: data,
    mutateForm: mutate,
    formIsLoading: isLoading,
    formIsError: isError,
  };
}

export async function addForm(form: {
  title: string;
  majorVersion: number;
  minorVersion: number;
  order: FormCategoryPointer[];
  texts: FormElementText[];
  simpleInputs: FormElementSimpleInput[];
  simpleSelects: FormElementSimpleSelect[];
  entitySelects: FormElementEntitySelect[];
  attachments: FormElementAttachment[];
}) {
  const options: requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(form),
  };

  const createdForm = await defaultFetcher<FormDetails>("/forms", options);
  await mutate(`/forms/${createdForm.id}`, createdForm, false);
  return createdForm;
}

export function updateFormArchive(id: string) {
  const options: requestOptions = {
    method: "PATCH",
  };

  return defaultFetcher<FormDetails>(`/forms/${id}/archive`, options);
}

export function formAuditPath(id: string) {
  return `/forms/${id}/audit`;
}

export function useRecordSummaries(isAuthorized: boolean) {
  const { data, error } = useSWR<{ records: RecordSummary[] }>(
    isAuthorized ? "/records" : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    recordSummaries: data?.records || [],
    recordSummariesIsLoading: isLoading,
    recordSummariesIsError: isError,
  };
}

export function useRecord(id: string | undefined, isAuthorized: boolean) {
  const { data, error } = useSWR<RecordDetails>(
    isAuthorized && id ? `/records/${id}` : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    record: data,
    recordIsLoading: isLoading,
    recordIsError: isError,
  };
}

export async function addRecord(
  formID: string,
  order: string[],
  textValues: string[],
  attachments: File[]
) {
  const fd = new FormData();
  fd.append("formID", formID);
  order.forEach((pointer) => fd.append("order", pointer));
  textValues.forEach((textValue) => fd.append("textValues", textValue));
  attachments.forEach((attachment) => fd.append("attachments", attachment));

  const options: requestOptions = {
    method: "POST",
    body: fd,
  };

  const createdRecord = await defaultFetcher<RecordDetails>(
    "/records",
    options
  );
  await mutate(`/records/${createdRecord.id}`, createdRecord, false);

  return createdRecord;
}

export function recordAttachmentDownloadLink(id: string) {
  return formatURL(`/records/attachments/${id}/download`);
}

export function recordAuditPath(id: string) {
  return `/records/${id}/audit`;
}

export function useTaskSummaries(status: TaskStatus) {
  const { data, error } = useSWR<{ tasks: TaskSummary[] }>(
    `/tasks?status=${status}`,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    taskSummaries: data?.tasks || [],
    taskSummariesIsLoading: isLoading,
    taskSummariesIsError: isError,
  };
}

export function useTask(id: string | null) {
  const { data, error, mutate } = useSWR<TaskDetails>(
    id ? `/tasks/${id}` : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    task: data,
    mutateTask: mutate,
    taskIsLoading: isLoading,
    taskIsError: isError,
  };
}

export async function updateTaskClose(id: string, reason: string) {
  const options: requestOptions = {
    method: "PATCH",
    body: JSON.stringify({
      reason: reason,
    }),
  };

  const updatedTask = await defaultFetcher<TaskDetails>(
    `/tasks/${id}/close`,
    options
  );

  await mutate("/tasks?status=Open");
  await mutate("/tasks?status=Closed");

  return updatedTask;
}

export async function updateTaskRead(id: string) {
  const options: requestOptions = {
    method: "PATCH",
  };

  const updatedTask = await defaultFetcher<TaskDetails>(
    `/tasks/${id}/read`,
    options
  );

  await mutate("/tasks?status=Open");

  return updatedTask;
}

export async function updateTaskUnread(id: string) {
  const options: requestOptions = {
    method: "PATCH",
  };

  const updatedTask = await defaultFetcher<TaskDetails>(
    `/tasks/${id}/unread`,
    options
  );

  await mutate("/tasks?status=Open");

  return updatedTask;
}

export function useAuditEvents(fetchPath: string, shouldFetch: boolean) {
  const { data, error } = useSWR<{ events: AuditEventDetails[] }>(
    shouldFetch ? fetchPath : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    auditEvents: data?.events || [],
    auditEventsIsLoading: isLoading,
    auditEventsIsError: isError,
  };
}

export function useCurrentRegistration() {
  const { data, error } = useSWR<CurrentRegistrationDetails>(
    "/registrations/current",
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    currentRegistration: data,
    currentRegistrationIsLoading: isLoading,
    currentRegistrationIsError: isError,
  };
}

export function useRegistrationPublishableKey() {
  const { data, error } = useSWRImmutable<{ publishableKey: string }>(
    "/registrations/publishable-key",
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    publishableKey: data?.publishableKey,
    publishableKeyIsLoading: isLoading,
    publishableKeyIsError: isError,
  };
}

export function useRegistrationReservedEmailDomains() {
  const { data, error } = useSWRImmutable<{ emailDomains: string[] }>(
    "/registrations/reserved/email-domains",
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    reservedEmailDomains: data?.emailDomains || [],
    reservedEmailDomainsIsLoading: isLoading,
    reservedEmailDomainsIsError: isError,
  };
}

export async function addRegistration() {
  const options: requestOptions = {
    method: "POST",
  };

  const response = await defaultFetcher("/registrations", options);
  await mutate("/registrations/current");
  return response;
}

export async function updateRegistrationAdminUser(adminUser: {
  labName: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}) {
  const options: requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(adminUser),
  };

  const updatedRegistration = await defaultFetcher<CurrentRegistrationDetails>(
    "/registrations/current/admin-user",
    options
  );

  await mutate("/registrations/current", updatedRegistration, false);

  return updatedRegistration;
}

export async function updateRegistrationLab(lab: {
  name: string;
  subdomain: string;
}) {
  const options: requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(lab),
  };

  const updatedRegistration = await defaultFetcher<CurrentRegistrationDetails>(
    "/registrations/current/lab",
    options
  );

  await mutate("/registrations/current", updatedRegistration, false);

  return updatedRegistration;
}

export async function updateRegistrationSubscription(subscription: {
  priceID: string;
  quantity: number;
}) {
  const options: requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(subscription),
  };

  const updatedRegistration = await defaultFetcher<CurrentRegistrationDetails>(
    "/registrations/current/subscription",
    options
  );

  await mutate("/registrations/current", updatedRegistration, false);

  return updatedRegistration;
}

export function useCurrentLab() {
  const { data, error } = useSWR<LabDetails>("/labs/current", defaultFetcher);
  const { isLoading, isError } = requestState(data, error);

  return {
    currentLab: data,
    currentLabIsLoading: isLoading,
    currentLabIsError: isError,
  };
}

export function checkLabSubdomain(subdomain: string) {
  return defaultFetcher<LabSubdomainCheckDetails>(
    `/labs?subdomain=${subdomain}`
  );
}

export function useBilling(isAuthorized: boolean) {
  const { data, error } = useSWR<BillingDetails>(
    isAuthorized ? "/billing" : null,
    defaultFetcher
  );

  const { isLoading, isError } = requestState(data, error);

  return {
    billing: data,
    billingIsLoading: isLoading,
    billingIsError: isError,
  };
}

export function updateSubscriptionPreview(subscription: {
  priceID: string;
  quantity: number;
}) {
  const options: requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(subscription),
  };

  return defaultFetcher<BillingPreviewDetails>(
    "/billing/subscription/preview",
    options
  );
}

export async function updateSubscription(subscription: {
  priceID: string;
  quantity: number;
}) {
  const options: requestOptions = {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(subscription),
  };

  const updatedBilling = await defaultFetcher<BillingDetails>(
    "/billing/subscription",
    options
  );
  await mutate("/billing", updatedBilling, false);

  return updatedBilling;
}

export async function addBillingPortalSession() {
  const options: requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      returnURL: window.location.href,
    }),
  };

  return await defaultFetcher<BillingPortalSessionDetails>(
    "/billing/portal",
    options
  );
}
