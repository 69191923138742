import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";
import { classNames, formatErrorMessage } from "../common/Utils";
import { updateReagentType, useReagentType } from "../common/DataFetchers";
import { useUserContext } from "../common/BasePageScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import ErrorBanner from "../common/ErrorBanner";

export default function EditReagentTypeForm(): JSX.Element {
  const { reagentsRead, reagentsWrite } = useUserContext();
  const navigate = useNavigate();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const params = useParams<{ id: string }>();
  const reagentTypeID = params.id as string;

  const {
    reagentType,
    reagentTypeIsLoading,
    reagentTypeIsError,
    mutateReagentType,
  } = useReagentType(reagentTypeID, reagentsRead);

  const [nameError, setNameError] = useState(false);
  const [partNoError, setPartNoError] = useState(false);
  const [manufacturerError, setManufacturerError] = useState(false);

  if (!reagentsWrite || !reagentsRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (reagentTypeIsLoading) {
    return <LoadingComponent />;
  }

  if (reagentTypeIsError || !reagentType) {
    return <ErrorComponent />;
  }

  return (
    <form
      className="py-3 px-3 space-y-8 divide-y divide-gray-200"
      onSubmit={(e: React.SyntheticEvent) => {
        e.preventDefault();

        setShowErrorBanner(false);

        const target = e.target as typeof e.target & {
          name: { value: string };
          manufacturer: { value: string };
          partNo: { value: string };
        };

        target.name.value = target.name.value.trim();
        const newName = target.name.value;

        target.manufacturer.value = target.manufacturer.value.trim();
        const newManufacturer = target.manufacturer.value;

        target.partNo.value = target.partNo.value.trim();
        const newPartNo = target.partNo.value;

        setNameError(false);
        setManufacturerError(false);
        setPartNoError(false);

        let isValid = true;

        if (newName === "") {
          setNameError(true);
          isValid = false;
        }

        if (newManufacturer === "") {
          setManufacturerError(true);
          isValid = false;
        }

        if (newPartNo === "") {
          setPartNoError(true);
          isValid = false;
        }

        if (!isValid) {
          return;
        }

        if (reagentsWrite) {
          updateReagentType(reagentTypeID, {
            name: newName,
            manufacturer: newManufacturer,
            partNumber: newPartNo,
          })
            .then((reagentType) => {
              mutateReagentType(reagentType, false);
              navigate(`/regulator/reagents/types/${reagentTypeID}`);
            })
            .catch((error) => {
              setErrorBannerText(formatErrorMessage(error));
              setShowErrorBanner(true);
            });
        }
      }}
    >
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <ErrorBanner
            showError={showErrorBanner}
            setShowError={setShowErrorBanner}
            errorText={errorBannerText}
          />
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Edit Reagent Type
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Fill in required details to edit existing reagent type.
            </p>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Reagent Type Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="name"
                  id="name"
                  className={classNames(
                    "max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md",
                    nameError
                      ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                      : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                  )}
                  defaultValue={reagentType.name}
                />
                <p className="mt-2 text-sm text-red-500" hidden={!nameError}>
                  * Reagent Type Name is required.
                </p>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Manufacturer
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="manufacturer"
                  id="manufacturer"
                  className={classNames(
                    "max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md",
                    manufacturerError
                      ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                      : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                  )}
                  defaultValue={reagentType.manufacturer}
                />
                <p
                  className="mt-2 text-sm text-red-500"
                  hidden={!manufacturerError}
                >
                  * Manufacturer is required.
                </p>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Part Number
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="partNo"
                  id="partNo"
                  className={classNames(
                    "max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md",
                    partNoError
                      ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                      : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                  )}
                  defaultValue={reagentType.partNumber}
                />
                <p className="mt-2 text-sm text-red-500" hidden={!partNoError}>
                  * Part number is required.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <Link to={`/regulator/reagents/types/${reagentTypeID}`}>
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
