import React, { useState } from "react";
import { PaperClipIcon, XCircleIcon } from "@heroicons/react/outline";
import { v4 as uuid } from "uuid";

const typeOptions = [
  { value: "diploma", display: "Diploma" },
  { value: "license", display: "License" },
  { value: "resume", display: "Resume" },
  { value: "cv", display: "CV" },
  { value: "continuingEducation", display: "Continuing Education" },
];

interface FileUploadProps {
  label: string;
  fileTypes: string;
  files: { type: string; file: File }[];
}

export default function PersonnelRecordUploadComponent(props: FileUploadProps) {
  const [sameFileError, setSameFileError] = useState(false);
  const [content, setContent] = useState(props.files);

  function updateFiles(data: FileList) {
    for (let i = 0; i < data.length; i++) {
      if (props.files.findIndex((f) => f.file.name === data[i].name) !== -1) {
        setSameFileError(true);
      } else {
        props.files.push({ type: "diploma", file: data[i] });
      }
    }
  }

  function dropHandler(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    setSameFileError(false);
    const data = e.dataTransfer.files;
    updateFiles(data);
    setContent(Array.from(props.files));
  }

  function allowDrop(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
  }

  function handleUpload(e: React.ChangeEvent<HTMLInputElement>) {
    const data = e.target.files;
    setSameFileError(false);
    if (data) {
      updateFiles(data);
    }
    setContent(Array.from(props.files));
  }

  function removeFile(e: React.MouseEvent<HTMLButtonElement>) {
    const filename = e.currentTarget.name;
    const propsFileIndex = props.files.findIndex(
      (file) => file.file.name === filename
    );
    props.files.splice(propsFileIndex, 1);
    const fileIndex = props.files.findIndex(
      (file) => file.file.name === filename
    );
    props.files.splice(fileIndex, 1);
    setContent(Array.from(props.files));
  }

  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label
        htmlFor="file-upload"
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {props.label}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <div
          className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed bg-white rounded-md"
          onDragOver={allowDrop}
          onDrop={dropHandler}
        >
          <div className="space-y-1 text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="flex text-sm text-gray-600">
              <label
                htmlFor={`file-upload-${props.label}`}
                className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
              >
                <span>Upload a file</span>
                <input
                  id={`file-upload-${props.label}`}
                  name="file-upload"
                  type="file"
                  className="sr-only"
                  onChange={handleUpload}
                />
              </label>
              <p className="pl-1">or drag and drop</p>
            </div>
            <p className="text-xs text-gray-500">
              {`${props.fileTypes} up to 10 MB`}
            </p>
          </div>
        </div>
        <div id="currentFiles" className="py-2">
          {content.map((file) => (
            <li
              key={uuid()}
              className="pl-1 pr-4 flex items-center justify-between text-sm"
            >
              <div className="w-full flex items-center">
                <PaperClipIcon
                  className="flex-shrink-0 h-5 w-5 text-blue-400"
                  aria-hidden="true"
                />
                <span className="ml-2 flex-none w-1/3 truncate">
                  {file.file.name}
                </span>
                <div className="ml-6 flex-none w-48">
                  <label htmlFor="expiration" className="sr-only">
                    Type
                  </label>
                  <div className="mt-1">
                    <select
                      id="location"
                      name="location"
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md"
                      defaultValue="Canada"
                      onChange={(e) => {
                        file.type =
                          typeOptions[e.currentTarget.selectedIndex].value;
                      }}
                    >
                      {typeOptions.map((type) => (
                        <option key={type.value}>{type.display}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <button
                  id={file.file.name}
                  name={file.file.name}
                  onClick={removeFile}
                  className="mr-72"
                >
                  <XCircleIcon
                    className="flex-shrink-0 ml-4 h-5 w-5 text-blue-400"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </li>
          ))}
          <p hidden={!sameFileError} className="mt-1 text-sm text-red-500">
            * Files names must be unique.
          </p>
        </div>
      </div>
    </div>
  );
}
