import { FormElementSimpleSelect } from "../../common/Entities.d";
import { useEffect, useState } from "react";
import CustomRadioGroup from "../../common/CustomRadioGroup";

export default function RadioGroup(props: {
  radioGroupInput: FormElementSimpleSelect;
  recordPointer: { recordValueArray: string; recordValueIndex: number };
  textValues: Array<string[]>;
  setTextValues: (textValues: Array<string[]>) => void;
}) {
  const options = props.radioGroupInput.options.map((option) => {
    return {
      display: option.value,
      value: option.value,
      description: option.description,
    };
  });
  const [selected, setSelected] = useState(options[0]);

  useEffect(() => {
    props.textValues[props.recordPointer.recordValueIndex] = [selected.value];
    props.setTextValues(props.textValues);
  }, [selected]);

  return (
    <div>
      <label className="mb-1 block text-sm font-medium text-gray-700">
        {props.radioGroupInput.label}
      </label>
      <CustomRadioGroup
        options={options}
        selected={selected}
        setSelected={setSelected}
      />
    </div>
  );
}
