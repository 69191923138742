import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import SOPSelectMenu from "../common/SOPSelectMenu";
import {
  addWorkflowTemplate,
  updateWorkflowTemplateArchive,
  useCompetencyTypeSummaries,
  useEquipmentTypeSummaries,
  useReagentTypeSummaries,
  useSampleTypeSummaries,
  useWorkflowTemplate,
} from "../common/DataFetchers";
import { useUserContext } from "../common/BasePageScreen";
import CustomRadioGroup from "../common/CustomRadioGroup";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import CustomSelectMenu from "../common/CustomSelectMenu";
import { SOPSummary } from "../common/Entities.d";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { formatErrorMessage } from "../common/Utils";
import ErrorBanner from "../common/ErrorBanner";

const versionChangeOptions = [
  {
    display: "Minor Version Change",
    value: "minor",
    description:
      "Version changes that do not represent a change in " +
      "the underlying methods or procedures.",
  },
  {
    display: "Major Version Change",
    value: "major",
    description:
      "Version changes that involve changes in methodology " +
      "that could influence the resulting data values.",
  },
];

function handleCheckbox(
  e: React.ChangeEvent<HTMLInputElement>,
  eventCollection: Set<string>
) {
  const targetName = e.target.name;

  if (e.target.checked) {
    eventCollection.add(targetName);
  }

  if (!e.target.checked) {
    if (eventCollection.has(targetName)) {
      eventCollection.delete(targetName);
    }
  }
}

export default function EditWorkflowTemplateForm() {
  const navigate = useNavigate();
  const { workflowTemplatesRead, workflowTemplatesWrite } = useUserContext();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const params = useParams<{ id: string }>();
  const workflowTemplateID = params.id as string;

  const [sop, setSop] =
    useState<{ id: string; value: SOPSummary | null; display: string }>();

  const {
    workflowTemplate,
    workflowTemplateIsLoading,
    workflowTemplateIsError,
    mutateWorkflowTemplate,
  } = useWorkflowTemplate(workflowTemplateID, workflowTemplatesWrite);

  useEffect(() => {
    if (!workflowTemplate) {
      return;
    }
    setSop({
      id: workflowTemplate.sop.id,
      value: workflowTemplate.sop,
      display: `${workflowTemplate.sop.displayID} - ${workflowTemplate.sop.title}`,
    });
  }, [workflowTemplate]);

  const [versionChange, setVersionChange] = useState(versionChangeOptions[0]);

  const [sopError, setSopError] = useState(false);
  const [competencyError, setCompetencyError] = useState(false);
  const [equipmentErorr, setEquipmentError] = useState(false);
  const [reagentsError, setReagentsError] = useState(false);
  const [samplesError, setSamplesError] = useState(false);

  const [competencyType, setCompetencyType] =
    useState<{ id: string; value: any; display: string }>();

  const { competencyTypeSummaries } = useCompetencyTypeSummaries(
    workflowTemplatesWrite
  );
  const [competencyTypeOptions, setCompetencyTypeOptions] = useState<
    { id: string; value: any; display: string }[]
  >([]);

  useEffect(() => {
    if (competencyTypeSummaries.length === 0) {
      return;
    }
    const updatedCompetencyTypeOptions: {
      id: string;
      value: any;
      display: string;
    }[] = [];
    competencyTypeSummaries.forEach(
      (type) => {
        updatedCompetencyTypeOptions.push({
          id: type.id,
          value: type,
          display: type.name,
        });
      },
      [competencyTypeSummaries]
    );

    let defaultOptionIndex = 0;
    if (workflowTemplate?.competencyType) {
      const optionIndex = updatedCompetencyTypeOptions.findIndex(
        (type) => type.id === workflowTemplate.competencyType.id
      );
      if (optionIndex >= 0) {
        defaultOptionIndex = optionIndex;
      }

      setCompetencyTypeOptions(updatedCompetencyTypeOptions);
      setCompetencyType(updatedCompetencyTypeOptions[defaultOptionIndex]);
    }

    setCompetencyType(updatedCompetencyTypeOptions[0]);
  }, [competencyTypeSummaries]);

  const { reagentTypeSummaries } = useReagentTypeSummaries(
    workflowTemplatesWrite
  );
  const { equipmentTypeSummaries } = useEquipmentTypeSummaries(
    workflowTemplatesWrite
  );

  const { sampleTypeSummaries } = useSampleTypeSummaries(
    workflowTemplatesWrite
  );

  if (!workflowTemplatesWrite || !workflowTemplatesRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (workflowTemplateIsLoading) {
    return <LoadingComponent />;
  }

  if (workflowTemplateIsError || !workflowTemplate) {
    return <ErrorComponent />;
  }

  const majorVersion =
    versionChange.value === "major"
      ? workflowTemplate.majorVersion + 1
      : workflowTemplate.majorVersion;

  const minorVersion =
    versionChange.value === "minor"
      ? workflowTemplate.minorVersion + 1
      : workflowTemplate.minorVersion;

  const workflowReagents = new Set<string>(
    workflowTemplate.reagentTypes.map((reagentType) => {
      return reagentType.id;
    })
  );
  const workflowEquipment = new Set<string>(
    workflowTemplate.equipmentTypes.map((equipmentType) => {
      return equipmentType.id;
    })
  );
  const workflowSamples = new Set<string>(
    workflowTemplate.sampleTypes.map((sampleType) => {
      return sampleType.id;
    })
  );

  return (
    <form
      className="space-y-8 p-3 mt-3"
      onSubmit={(e: React.SyntheticEvent) => {
        e.preventDefault();

        setShowErrorBanner(false);

        let isValid = true;

        setSopError(false);
        if (!sop) {
          setSopError(true);
          isValid = false;
        }
        setCompetencyError(false);
        if (!competencyType) {
          setCompetencyError(true);
          isValid = false;
        }

        setEquipmentError(false);
        if (workflowEquipment.size === 0) {
          setEquipmentError(true);
          isValid = false;
        }
        setReagentsError(false);
        if (workflowReagents.size === 0) {
          setReagentsError(true);
          isValid = false;
        }

        setSamplesError(false);
        if (workflowSamples.size === 0) {
          setSamplesError(true);
          isValid = false;
        }

        if (!isValid) {
          return;
        }

        if (workflowTemplatesWrite) {
          addWorkflowTemplate({
            name: workflowTemplate.name,
            sopID: sop!.id,
            competencyTypeID: competencyType!.id,
            majorVersion: majorVersion,
            minorVersion: minorVersion,
            equipmentTypeIDs: Array.from(workflowEquipment),
            reagentTypeIDs: Array.from(workflowReagents),
            sampleTypeIDs: Array.from(workflowSamples),
          })
            .then((template) => {
              mutateWorkflowTemplate(
                updateWorkflowTemplateArchive(workflowTemplate.id)
              );
              navigate(`/regulator/workflows/templates/${template.id}`);
            })
            .catch((error) => {
              setErrorBannerText(formatErrorMessage(error));
              setShowErrorBanner(true);
            });
        }
      }}
    >
      <div className="px-3 space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div>
          <ErrorBanner
            showError={showErrorBanner}
            setShowError={setShowErrorBanner}
            errorText={errorBannerText}
          />
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Edit Workflow Template
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Fill in the information in the form below to edit a workflow
              template.
            </p>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="workflow_name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Workflow Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <p className="mt font-medium text-sm text-gray-900">
                  {workflowTemplate.name}
                </p>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="templateVersion"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Workflow Template Version
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <p className="mt-2 font-medium text-sm text-gray-900 pb-3">
                  {`${majorVersion}.${minorVersion}`}
                </p>
                <CustomRadioGroup
                  options={versionChangeOptions}
                  selected={versionChange}
                  setSelected={setVersionChange}
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <SOPSelectMenu
                label={"SOP"}
                selected={sop}
                setSelected={setSop}
              />
              <p
                className="text-sm text-red-500 sm:col-start-2"
                hidden={!sopError}
              >
                * SOP is required.
              </p>
            </div>
          </div>
        </div>
        <div className="divide-y divide-gray-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Competency
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Specify the competency types that are permitted to perform this
              workflow.
            </p>
          </div>
          <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
            <div className="pt-6 sm:pt-5">
              <div role="group" aria-labelledby="role-selection">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                  <CustomSelectMenu
                    label={"Competency Type"}
                    options={competencyTypeOptions}
                    selected={competencyType}
                    setSelected={setCompetencyType}
                  />
                  <p
                    className="text-sm text-red-500 sm:col-start-2"
                    hidden={!competencyError}
                  >
                    * Competency Type is required.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="divide-y divide-gray-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Equipment
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Specify the equipment that is used for this workflow.
            </p>
          </div>
          <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
            <div className="pt-6 sm:pt-5">
              <div role="group" aria-labelledby="equipment-selection">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                  <div>
                    <div
                      className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                      id="equipment-selection"
                    >
                      Equipment Types
                    </div>
                  </div>
                  <div className="mt-4 sm:mt-0 sm:col-span-2">
                    <div className="max-w-lg space-y-4">
                      {equipmentTypeSummaries.map((equipmentType) => (
                        <div
                          key={equipmentType.id}
                          className="relative flex items-start"
                        >
                          <div className="flex items-center h-5">
                            <input
                              id={equipmentType.id}
                              name={equipmentType.id}
                              type="checkbox"
                              className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                              onChange={(e) =>
                                handleCheckbox(e, workflowEquipment)
                              }
                              defaultChecked={
                                workflowTemplate.equipmentTypes.findIndex(
                                  (e) => equipmentType.id === e.id
                                ) >= 0
                              }
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                              htmlFor={equipmentType.id}
                              className="font-medium text-gray-700"
                            >
                              {equipmentType.name}
                            </label>
                          </div>
                        </div>
                      ))}
                      <p
                        className="text-sm text-red-500"
                        hidden={!equipmentErorr}
                      >
                        *At least one equipment type must be selected.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="divide-y divide-gray-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Reagents
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Specify the reagents that are used for this workflow.
            </p>
          </div>
          <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
            <div className="pt-6 sm:pt-5">
              <div role="group" aria-labelledby="reagent-selection">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                  <div>
                    <div
                      className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                      id="reagent-selection"
                    >
                      Reagent Types
                    </div>
                  </div>
                  <div className="mt-4 sm:mt-0 sm:col-span-2">
                    <div className="max-w-lg space-y-4">
                      {reagentTypeSummaries.map((reagentType) => (
                        <div
                          key={reagentType.id}
                          className="relative flex items-start"
                        >
                          <div className="flex items-center h-5">
                            <input
                              id={reagentType.id}
                              name={reagentType.id}
                              type="checkbox"
                              className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                              onChange={(e) =>
                                handleCheckbox(e, workflowReagents)
                              }
                              defaultChecked={
                                workflowTemplate.reagentTypes.findIndex(
                                  (r) => reagentType.id === r.id
                                ) >= 0
                              }
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                              htmlFor={reagentType.id}
                              className="font-medium text-gray-700"
                            >
                              {reagentType.name}
                            </label>
                          </div>
                        </div>
                      ))}
                      <p
                        className="text-sm text-red-500"
                        hidden={!reagentsError}
                      >
                        *At least one reagent type must be selected.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="divide-y divide-gray-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Samples
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Specify the sample types that are used for this workflow.
          </p>
        </div>
        <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
          <div className="pt-6 sm:pt-5">
            <div role="group" aria-labelledby="reagent-selection">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                <div>
                  <div
                    className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                    id="reagent-selection"
                  >
                    Sample Types
                    <span hidden={sampleTypeSummaries.length !== 0}>
                      <ExclamationCircleIcon className="ml-1 text-red-500 inline-flex h-5 w-5" />
                    </span>
                  </div>
                </div>
                <div className="mt-4 sm:mt-0 sm:col-span-2">
                  <div className="max-w-lg space-y-4">
                    {sampleTypeSummaries.map((sampleType) => (
                      <div
                        key={sampleType.id}
                        className="relative flex items-start"
                      >
                        <div className="flex items-center h-5">
                          <input
                            id={sampleType.id}
                            name={sampleType.id}
                            type="checkbox"
                            className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                            onChange={(e) => handleCheckbox(e, workflowSamples)}
                            defaultChecked={
                              workflowTemplate.sampleTypes.findIndex(
                                (s) => sampleType.id === s.id
                              ) >= 0
                            }
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor={sampleType.id}
                            className="font-medium text-gray-700"
                          >
                            {sampleType.name}
                          </label>
                        </div>
                      </div>
                    ))}
                    <p className="text-sm text-red-500" hidden={!samplesError}>
                      *At least one sample type must be selected.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <Link to={`/regulator/workflows/templates/${workflowTemplate.id}`}>
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
