import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Pagination from "../common/Pagination";
import EmptyStateScreen from "../common/EmptyStateScreen";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { useFormSummaries } from "../common/DataFetchers";
import { useUserContext } from "../common/BasePageScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import { FormSummary } from "../common/Entities.d";

export default function ActiveFormsList() {
  const navigate = useNavigate();
  const { formsRead, formsWrite } = useUserContext();

  const { formSummaries, formSummariesIsLoading, formSummariesIsError } =
    useFormSummaries("Active", formsRead);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const [items, setItems] = useState<FormSummary[]>([]);

  const [showFilterBox, setShowFilterBox] = useState(false);

  useEffect(() => {
    if (formSummaries.length === 0) {
      return;
    }
    setItems(formSummaries);
  }, [formSummaries]);

  if (!formsRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (formSummariesIsLoading) {
    return <LoadingComponent />;
  }

  if (formSummariesIsError) {
    return <ErrorComponent />;
  }

  const pageEndIndex = currentPage * itemsPerPage;
  const pageStartIndex = pageEndIndex - itemsPerPage;
  const pageFormSummaries: FormSummary[] = items
    ? items.slice(pageStartIndex, pageEndIndex)
    : formSummaries.slice(pageStartIndex, pageEndIndex);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  function clearFilter() {
    (document.getElementById("titleFilter") as HTMLInputElement).value = "";
    setItems(Array.from(formSummaries));
  }

  function handleFilter(e: React.SyntheticEvent) {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      titleFilter: { value: string };
    };

    let newItems = formSummaries;

    const titleFilter = target.titleFilter.value.trim();
    if (titleFilter !== "") {
      newItems = newItems.filter((i) => i.title === titleFilter);
    }

    setItems(Array.from(newItems));
  }

  return (
    <div className="flex flex-col">
      <div className="bg-white p-3 border border-gray-200 rounded-lg shadow-sm">
        <h3 className="ml-1 text-md leading-6 font-medium text-gray-900">
          Filter
        </h3>
        <div className="-mt-6 text-right">
          <button onClick={() => setShowFilterBox(!showFilterBox)}>
            {showFilterBox ? (
              <ChevronUpIcon
                className="h-6 w-6 text-gray-500"
                aria-hidden="true"
              />
            ) : (
              <ChevronDownIcon
                className="h-6 w-6 text-gray-500"
                aria-hidden="true"
              />
            )}
          </button>
        </div>
        <div hidden={!showFilterBox}>
          <form
            className="grid grid-cols-6 gap-4"
            onSubmit={(e) => handleFilter(e)}
          >
            <div className="ml-1 col-start-1 col-span-2">
              <label
                htmlFor="titleFilter"
                className="block text-sm font-medium text-gray-700"
              >
                Title
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="titleFilter"
                  id="titleFilter"
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="mt-3 text-center col-start-6">
              <button
                type="button"
                onClick={clearFilter}
                className="m-1 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Clear
              </button>
              <button
                type="submit"
                className="m-1 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Apply
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            {formSummaries.length === 0 ? (
              <EmptyStateScreen
                item={"form"}
                createLink={"/regulator/forms/add"}
                writeAccess={formsWrite}
              />
            ) : (
              <div>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Title
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Version
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Actions</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageFormSummaries.map((form, formIdx) => (
                      <tr
                        key={`${form.title}v${form.majorVersion}.${form.minorVersion}`}
                        className={
                          formIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                        }
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {form.title}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {`${form.majorVersion}.${form.minorVersion}`}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <Link to={`/regulator/forms/${form.id}`}>
                            <button
                              type="button"
                              className="mx-1 inline-flex items-center rounded-md border border-blue-600 bg-blue-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                            >
                              Create Record
                            </button>
                          </Link>
                          <Link to={`/regulator/forms/${form.id}/edit`}>
                            <button
                              type="button"
                              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                            >
                              Edit
                            </button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination
                  itemsPerPage={itemsPerPage}
                  totalItems={items ? items.length : formSummaries.length}
                  currentPage={currentPage}
                  paginate={paginate}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
