import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { classNames, formatErrorMessage } from "../common/Utils";
import { addEquipmentType } from "../common/DataFetchers";
import { useUserContext } from "../common/BasePageScreen";
import ErrorBanner from "../common/ErrorBanner";

export default function AddEquipmentTypeForm(): JSX.Element {
  const { equipmentRead, equipmentWrite } = useUserContext();
  const navigate = useNavigate();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const [nameError, setNameError] = useState(false);
  const [cycleError, setCycleError] = useState(false);

  if (!equipmentWrite || !equipmentRead) {
    navigate("/forbidden");
    return <></>;
  }

  return (
    <form
      className="py-3 px-3 space-y-8 divide-y divide-gray-200"
      onSubmit={(e: React.SyntheticEvent) => {
        e.preventDefault();

        setShowErrorBanner(false);

        const target = e.target as typeof e.target & {
          name: { value: string };
          cycle: { value: string };
        };

        target.name.value = target.name.value.trim();
        const equipmentTypeName = target.name.value;

        target.cycle.value = target.cycle.value.trim();
        const equipmentTypeCalibrationCycle = target.cycle.value;

        setNameError(false);
        setCycleError(false);

        if (equipmentTypeName === "") {
          setNameError(true);
          return;
        }

        if (equipmentTypeCalibrationCycle === "") {
          setCycleError(true);
          return;
        }

        if (equipmentWrite) {
          addEquipmentType({
            name: equipmentTypeName,
            calibrationCycle: parseInt(equipmentTypeCalibrationCycle),
          })
            .then((equipmentType) =>
              navigate(`/regulator/equipment/types/${equipmentType.id}`)
            )
            .catch((error) => {
              setErrorBannerText(formatErrorMessage(error));
              setShowErrorBanner(true);
            });
        }
      }}
    >
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <ErrorBanner
            showError={showErrorBanner}
            setShowError={setShowErrorBanner}
            errorText={errorBannerText}
          />
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Add Equipment
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Fill in required details to create a new equipment type.
            </p>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Equipment Type Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="name"
                  id="name"
                  className={classNames(
                    "max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md",
                    nameError
                      ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                      : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                  )}
                />
                <p className="mt-2 text-sm text-red-500" hidden={!nameError}>
                  * Equipment Type Name is required.
                </p>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Calibration Cycle
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="number"
                  name="cycle"
                  id="cycle"
                  className={classNames(
                    "max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md",
                    cycleError
                      ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                      : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                  )}
                />
                <p className="mt-2 text-sm text-red-500" hidden={!cycleError}>
                  * Calibration Cycle is required.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <Link to={"/regulator/equipment/types/"}>
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
