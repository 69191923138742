import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Pagination from "../common/Pagination";
import { formatDateInputValue, getDate } from "../common/Utils";
import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import CustomSelectMenu from "../common/CustomSelectMenu";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { useWorkflowRunSummaries } from "../common/DataFetchers";
import EmptyStateScreen from "../common/EmptyStateScreen";
import { useUserContext } from "../common/BasePageScreen";
import { ClipboardCheckIcon } from "@heroicons/react/outline";
import { WorkflowRunSummary } from "../common/Entities.d";

const statusOptions = [
  { id: "0", value: "Select", display: "Select" },
  { id: "1", value: "Queued", display: "Queued" },
  { id: "2", value: "Pass", display: "Pass" },
  { id: "3", value: "Fail", display: "Fail" },
];

const pages = [
  { name: "Workflow History", path: "/regulator/workflows/history" },
];

export default function WorkflowList() {
  const navigate = useNavigate();
  const { workflowHistoryRead, workflowHistoryWrite } = useUserContext();

  const { workflowRunSummaries } = useWorkflowRunSummaries(workflowHistoryRead);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const [items, setItems] = useState<WorkflowRunSummary[]>([]);

  const [showFilterBox, setShowFilterBox] = useState(false);

  useEffect(() => {
    if (workflowRunSummaries.length === 0) {
      return;
    }
    setItems(workflowRunSummaries);
  }, [workflowRunSummaries]);

  const [typeOptions, setTypeOptions] = useState<
    { id: string; value: any; display: string }[]
  >([{ id: "0", value: "Select", display: "Select" }]);

  useEffect(() => {
    const typeSet = new Set<string>();
    workflowRunSummaries.map((workflow) => typeSet.add(workflow.template.name));
    const updatedTypeOptions: { id: string; value: any; display: string }[] = [
      { id: "0", value: "Select", display: "Select" },
    ];
    typeSet.forEach((type) =>
      updatedTypeOptions.push({
        id: typeOptions.length.toString(),
        value: type,
        display: type,
      })
    );
    setTypeOptions(updatedTypeOptions);
  }, [workflowRunSummaries]);

  const [filterType, setFilterType] = useState(typeOptions[0]);
  const [filterStatus, setFilterStatus] = useState(statusOptions[0]);

  if (!workflowHistoryRead) {
    navigate("/forbidden");
    return <></>;
  }

  const pageEndIndex = currentPage * itemsPerPage;
  const pageStartIndex = pageEndIndex - itemsPerPage;
  const pageWorkflowRunSummaries: WorkflowRunSummary[] = items
    ? items.slice(pageStartIndex, pageEndIndex)
    : workflowRunSummaries.slice(pageStartIndex, pageEndIndex);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  function clearFilter() {
    (document.getElementById("runIdFilter") as HTMLInputElement).value = "";
    (document.getElementById("startDateFilter") as HTMLInputElement).value = "";
    (document.getElementById("endDateFilter") as HTMLInputElement).value = "";
    setFilterType(typeOptions[0]);
    setFilterStatus(statusOptions[0]);
    setItems(Array.from(workflowRunSummaries));
  }

  function handleFilter(e: React.SyntheticEvent) {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      runIdFilter: { value: string };
      startDateFilter: { value: string };
      endDateFilter: { value: string };
    };

    let newItems = workflowRunSummaries;

    const runIdFilter = target.runIdFilter.value.trim();
    if (runIdFilter !== "") {
      newItems = newItems.filter((i) => i.id === runIdFilter);
    }
    const typeFilter = filterType.value;
    if (typeFilter !== "Select") {
      newItems = newItems.filter((i) => i.template.name === typeFilter);
    }
    const startDateFilter = target.startDateFilter.value.trim();
    if (startDateFilter !== "") {
      newItems = newItems.filter(
        (i) =>
          new Date(i.ran) >= new Date(formatDateInputValue(startDateFilter))
      );
    }
    const endDateFilter = target.endDateFilter.value.trim();
    if (endDateFilter !== "") {
      newItems = newItems.filter(
        (i) => new Date(i.ran) <= new Date(formatDateInputValue(endDateFilter))
      );
    }

    const statusFilter = filterStatus.value;
    if (statusFilter !== "Select") {
      newItems = newItems.filter((i) => i.status === statusFilter);
    }

    setItems(Array.from(newItems));
  }

  return (
    <div className="flex flex-col">
      <FullWidthBreadcrumbBar pages={pages} />
      <div className="py-3 text-right">
        <Link
          to={"/regulator/workflows/history/add"}
          hidden={!workflowHistoryWrite}
        >
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <ClipboardCheckIcon
              className="-ml-1 mr-3 h-5 w-5"
              aria-hidden="true"
            />
            Add Workflow Run
          </button>
        </Link>
      </div>
      <div className="bg-white mt-2 p-3 border border-gray-200 rounded-lg shadow-sm">
        <h3 className="ml-1 text-md leading-6 font-medium text-gray-900">
          Filter
        </h3>
        <div className="-mt-6 text-right">
          <button onClick={() => setShowFilterBox(!showFilterBox)}>
            {showFilterBox ? (
              <ChevronUpIcon
                className="h-6 w-6 text-gray-500"
                aria-hidden="true"
              />
            ) : (
              <ChevronDownIcon
                className="h-6 w-6 text-gray-500"
                aria-hidden="true"
              />
            )}
          </button>
        </div>
        <div hidden={!showFilterBox}>
          <form
            className="grid grid-cols-4 gap-4"
            onSubmit={(e) => handleFilter(e)}
          >
            <div className="ml-1">
              <label
                htmlFor="runIdFilter"
                className="mt-2.5 block text-sm font-medium text-gray-700"
              >
                Run ID
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="runIdFilter"
                  id="runIdFilter"
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="ml-1">
              <CustomSelectMenu
                label={"Run Type"}
                options={typeOptions}
                selected={filterType}
                setSelected={setFilterType}
              />
            </div>
            <div className="ml-1">
              <CustomSelectMenu
                label={"Status"}
                options={statusOptions}
                selected={filterStatus}
                setSelected={setFilterStatus}
              />
            </div>
            <div className="ml-1">
              <label
                htmlFor="startDateFilter"
                className="mt-2.5 block text-sm font-medium text-gray-700 mb-1"
              >
                Run Date
              </label>
              <input
                type="date"
                name="startDateFilter"
                id="startDateFilter"
                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
              <p className="text-center">to</p>
              <input
                type="date"
                name="endDateFilter"
                id="endDateFilter"
                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <div className="text-right col-end-5 col-span-1">
              <button
                type="button"
                onClick={clearFilter}
                className="m-1 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Clear
              </button>
              <button
                type="submit"
                className="m-1 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Apply
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            {workflowRunSummaries.length === 0 ? (
              <EmptyStateScreen
                item={"workflow run"}
                createLink={"/regulator/workflows/history/add"}
                writeAccess={workflowHistoryWrite}
              />
            ) : (
              <div>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Run ID
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Run Type
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Run Date
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Status
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">View</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageWorkflowRunSummaries.map((workflow, workflowIdx) => (
                      <tr
                        key={workflow.id}
                        className={
                          workflowIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                        }
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {workflow.displayID}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {workflow.template.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {getDate(workflow.ran)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span
                            className={
                              workflow.status === "Pass"
                                ? "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 w-min"
                                : workflow.status === "Fail"
                                ? "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 w-min"
                                : "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800 w-min"
                            }
                          >
                            {workflow.status}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <Link
                            to={`/regulator/workflows/history/${workflow.id}`}
                            className="text-blue-600 hover:text-blue-900"
                          >
                            View
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination
                  itemsPerPage={itemsPerPage}
                  totalItems={
                    items ? items.length : workflowRunSummaries.length
                  }
                  currentPage={currentPage}
                  paginate={paginate}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
