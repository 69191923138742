import { useUserContext } from "../common/BasePageScreen";
import React, { useState } from "react";
import { classNames, formatErrorMessage } from "../common/Utils";
import { updateUserProfile } from "../common/DataFetchers";
import SuccessBanner from "../common/SuccessBanner";
import ErrorBanner from "../common/ErrorBanner";

export default function ProfileCard() {
  const { user } = useUserContext();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const [showSuccess, setShowSuccess] = useState(false);
  const [successText, setSuccessText] = useState("");

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      <ErrorBanner
        showError={showErrorBanner}
        setShowError={setShowErrorBanner}
        errorText={errorBannerText}
      />
      <SuccessBanner
        showSuccess={showSuccess}
        setShowSuccess={setShowSuccess}
        successText={successText}
      />
      <section aria-labelledby="payment-details-heading">
        <form
          onSubmit={(e) => {
            e.preventDefault();

            setShowSuccess(false);
            setShowErrorBanner(false);

            const target = e.target as typeof e.target & {
              firstName: { value: string };
              lastName: { value: string };
            };

            target.firstName.value = target.firstName.value.trim();
            const firstName = target.firstName.value;

            target.lastName.value = target.lastName.value.trim();
            const lastName = target.lastName.value;

            let isValid = true;

            setFirstNameError(false);
            if (firstName === "") {
              setFirstNameError(true);
              isValid = false;
            }

            setLastNameError(false);
            if (lastName === "") {
              setLastNameError(true);
              isValid = false;
            }

            if (!isValid) {
              return;
            }

            updateUserProfile(user.id, {
              firstName: firstName,
              lastName: lastName,
            })
              .then((user) => {
                setSuccessText(
                  `Successfully updated your profile! New name: ${user.firstName} ${user.lastName}`
                );
                setShowSuccess(true);
              })
              .catch((error) => {
                setErrorBannerText(formatErrorMessage(error));
                setShowErrorBanner(true);
              });
          }}
        >
          <div className="mt-2 sm:rounded-md sm:border sm:border-gray-200 sm:overflow-hidden">
            <div className="bg-white py-6 px-4 sm:p-6">
              <div>
                <h2
                  id="payment-details-heading"
                  className="text-lg leading-6 font-medium text-gray-900"
                >
                  Profile details
                </h2>
                <p className="mt-1 text-sm text-gray-500">
                  Update your profile information. If your email requires
                  updating, please reach out to your system administrator.
                </p>
              </div>

              <div className="mt-6 grid grid-cols-4 gap-6">
                <div className="col-span-4 sm:col-span-4">
                  <label
                    htmlFor="email-address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email address
                  </label>
                  <p className="font-medium text-blue-600">{user.email}</p>
                </div>
                <div className="col-span-4 sm:col-span-2">
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First name
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    defaultValue={user.firstName}
                    className={classNames(
                      "mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3",
                      firstNameError
                        ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                        : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                    )}
                  />
                  <p
                    className="mt-2 text-sm text-red-500"
                    hidden={!firstNameError}
                  >
                    * First Name is required.
                  </p>
                </div>

                <div className="col-span-4 sm:col-span-2">
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Last name
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    defaultValue={user.lastName}
                    className={classNames(
                      "mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3",
                      lastNameError
                        ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                        : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                    )}
                  />
                  <p
                    className="mt-2 text-sm text-red-500"
                    hidden={!lastNameError}
                  >
                    * Last Name is required.
                  </p>
                </div>
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button
                type="submit"
                className="bg-blue-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}
