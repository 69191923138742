import * as React from "react";
import { useUserContext } from "./common/BasePageScreen";
import { REGULATOR_BOT, taskTypeToDisplayMap } from "./common/Constants.d";
import { classNames, getDate } from "./common/Utils";
import { Link } from "react-router-dom";
import { ArrowRightIcon } from "@heroicons/react/solid";
import { useTaskSummaries } from "./common/DataFetchers";
import {
  AcademicCapIcon,
  BeakerIcon,
  ClipboardCheckIcon,
  ClipboardListIcon,
  CogIcon,
  FingerPrintIcon,
} from "@heroicons/react/outline";
import { UserDetails } from "./common/Entities.d";

const actions = [
  {
    title: "Create a sample",
    description:
      "Create a new sample to track patient specimens or control/calibration materials.",
    href: "/regulator/samples/add",
    icon: FingerPrintIcon,
    iconForeground: "text-green-700",
    iconBackground: "bg-green-50",
  },
  {
    title: "Create equipment",
    description: "Create a new equipment record to track calibration and usage",
    href: "/regulator/equipment/add",
    icon: CogIcon,
    iconForeground: "text-purple-700",
    iconBackground: "bg-purple-50",
  },
  {
    title: "Create a form",
    description:
      "Create a form to document processes and actions in your laboratory",
    href: "/regulator/forms/add",
    icon: ClipboardListIcon,
    iconForeground: "text-red-700",
    iconBackground: "bg-red-50",
  },
  {
    title: "Create a reagent",
    description: "Create a reagent to track qualification and expiration",
    href: "/regulator/reagents/add",
    icon: BeakerIcon,
    iconForeground: "text-indigo-700",
    iconBackground: "bg-indigo-50",
  },
  {
    title: "Create a workflow run",
    description:
      "Create a workflow run to reconcile procedures and supplies used for sample processing",
    href: "/regulator/workflows/history/add",
    icon: ClipboardCheckIcon,
    iconForeground: "text-sky-700",
    iconBackground: "bg-sky-50",
  },
  {
    title: "Create a competency record",
    description:
      "Create a competency record to track training and assessment activities",
    href: "/regulator/competency/add",
    icon: AcademicCapIcon,
    iconForeground: "text-yellow-700",
    iconBackground: "bg-yellow-50",
  },
];

function showAction(actionTitle: string, user: UserDetails) {
  if (actionTitle === "Create a sample") {
    return user.samples === "rw";
  }
  if (actionTitle === "Create equipment") {
    return user.equipment === "rw";
  }
  if (actionTitle === "Create a form") {
    return user.forms === "rw";
  }
  if (actionTitle === "Create a reagent") {
    return user.reagents === "rw";
  }
  if (actionTitle === "Create a workflow run") {
    return user.workflowHistory === "rw";
  }
  if (actionTitle === "Create a competency record") {
    return user.competencies === "rw";
  }
}

export default function HomePageActions(): JSX.Element {
  const { user } = useUserContext();

  const { taskSummaries } = useTaskSummaries("Open");
  const pageTaskSummaries = taskSummaries.slice(0, 5);

  return (
    <div>
      <div className="bg-white p-3 rounded-md shadow-md">
        <h1 className="text-lg leading-6 font-medium text-gray-900">
          Welcome,{" "}
          <span className="text-xl font-bold text-blue-600">
            {user.firstName}
          </span>
          !
        </h1>
      </div>
      {taskSummaries.length === 0 ? (
        <div className="mt-3 bg-white p-3 rounded-md shadow-md">
          <h3>
            Congrats, you have{" "}
            <span className="text-blue-600 font-bold text-lg">0</span> open
            tasks!
          </h3>
        </div>
      ) : (
        <div className="mt-3">
          <h3 className="mb-2 font-bold text-lg text-blue-600">
            Upcoming Tasks
          </h3>
          <table className="min-w-full divide-y divide-gray-200 border">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Assigner
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Due Date
                </th>
                <th scope="col" className="relative px-6 py-3">
                  <span className="sr-only">View</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {pageTaskSummaries.map((task, taskIdx) => (
                <tr
                  key={task.id}
                  className={taskIdx % 2 === 0 ? "bg-white" : "bg-gray-50"}
                >
                  <td
                    className={
                      task.read
                        ? "px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                        : "px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    }
                  >
                    {taskTypeToDisplayMap.get(task.type)}
                  </td>
                  <td
                    className={
                      task.read
                        ? "px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                        : "px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    }
                  >
                    {task.assigner
                      ? `${task.assigner.firstName} ${task.assigner.lastName}`
                      : REGULATOR_BOT}
                  </td>
                  <td
                    className={
                      task.read
                        ? "px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                        : "px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    }
                  >
                    {getDate(task.due)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <Link
                      to={`/regulator/tasks/${task.id}`}
                      className="text-blue-600 hover:text-blue-900"
                    >
                      View
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="mt-1 text-right" hidden={taskSummaries.length === 0}>
        <Link to={"/regulator/tasks"}>
          <button
            type="button"
            className="inline-flex items-center px-4 py-1 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Go to Tasks
            <ArrowRightIcon className="ml-1 h-5 w-5" aria-hidden="true" />
          </button>
        </Link>
      </div>
      <h3
        className="mt-3 ml-2 font-bold text-lg text-blue-600"
        hidden={
          actions.filter((action) => showAction(action.title, user)).length ===
          0
        }
      >
        Shortcuts
      </h3>
      <div className="mt-2 rounded-lg bg-gray-100 overflow-hidden divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-3 sm:gap-px">
        {actions.map((action) => (
          <div
            key={action.title}
            hidden={!showAction(action.title, user)}
            className="m-1 rounded-lg shadow-lg relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-500"
          >
            <div>
              <span
                className={classNames(
                  action.iconBackground,
                  action.iconForeground,
                  "rounded-lg inline-flex p-3 ring-4 ring-white"
                )}
              >
                <action.icon className="h-6 w-6" aria-hidden="true" />
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-lg font-medium">
                <Link
                  to={action.href}
                  className="focus:outline-none"
                  target="_blank"
                  rel="noreferrer"
                >
                  {/* Extend touch target to entire panel */}
                  <span className="absolute inset-0" aria-hidden="true" />
                  {action.title}
                </Link>
              </h3>
              <p className="mt-2 text-sm text-gray-500">{action.description}</p>
            </div>
            <span
              className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
              aria-hidden="true"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
              </svg>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
