import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { addSamples, useSampleTypeSummaries } from "../common/DataFetchers";
import {
  classNames,
  formatDateInputValue,
  formatErrorMessage,
} from "../common/Utils";
import { useUserContext } from "../common/BasePageScreen";
import CustomSelectMenu from "../common/CustomSelectMenu";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import ErrorBanner from "../common/ErrorBanner";

export default function AddSampleForm(): JSX.Element {
  const { samplesRead, samplesWrite } = useUserContext();
  const navigate = useNavigate();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const [sampleTypeError, setSampleTypeError] = useState(false);
  const [displayIDError, setDisplayIDError] = useState(false);
  const [preservationMethodError, setPreservationMethodError] = useState(false);
  const [dateReceivedError, setDateReceivedError] = useState(false);

  const [sampleType, setSampleType] =
    useState<{ id: string; value: any; display: string }>();
  const [sampleTypeOptions, setSampleTypeOptions] = useState<
    { id: string; value: any; display: string }[]
  >([]);
  const { sampleTypeSummaries } = useSampleTypeSummaries(samplesWrite);

  useEffect(() => {
    if (sampleTypeSummaries.length === 0) {
      return;
    }
    const updatedTypeOptions: { id: string; value: any; display: string }[] =
      [];
    sampleTypeSummaries.map((sampleType) => {
      updatedTypeOptions.push({
        id: sampleType.id,
        value: sampleType,
        display: sampleType.name,
      });
    });

    setSampleTypeOptions(updatedTypeOptions);
    setSampleType(updatedTypeOptions[0]);
  }, [sampleTypeSummaries]);

  if (!samplesWrite || !samplesRead) {
    navigate("/forbidden");
    return <></>;
  }

  return (
    <form
      className="py-3 px-3 space-y-8 divide-y divide-gray-200"
      onSubmit={(e: React.SyntheticEvent) => {
        e.preventDefault();

        setShowErrorBanner(false);

        const target = e.target as typeof e.target & {
          sampleDisplayID: { value: string };
          samplePreservationMethod: { value: string };
          dateReceived: { value: string };
        };

        let isValid = true;

        setDisplayIDError(false);
        target.sampleDisplayID.value = target.sampleDisplayID.value.trim();
        if (target.sampleDisplayID.value === "") {
          setDisplayIDError(true);
          isValid = false;
        }

        setPreservationMethodError(false);
        target.samplePreservationMethod.value =
          target.samplePreservationMethod.value.trim();
        if (target.samplePreservationMethod.value === "") {
          setPreservationMethodError(true);
          isValid = false;
        }

        setDateReceivedError(false);
        if (target.dateReceived.value.trim() === "") {
          setDateReceivedError(true);
          isValid = false;
        }

        setSampleTypeError(false);
        if (!sampleType) {
          setSampleTypeError(true);
          isValid = false;
        }

        if (!isValid) {
          return;
        }

        if (samplesWrite) {
          addSamples([
            {
              typeID: sampleType!.id,
              displayID: target.sampleDisplayID.value,
              received: new Date(
                formatDateInputValue(target.dateReceived.value)
              ),
              preservationMethod: target.samplePreservationMethod.value,
            },
          ])
            .then((samples) => navigate(`/regulator/samples/${samples[0].id}`))
            .catch((error) => {
              setErrorBannerText(formatErrorMessage(error));
              setShowErrorBanner(true);
            });
        }
      }}
    >
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <ErrorBanner
            showError={showErrorBanner}
            setShowError={setShowErrorBanner}
            errorText={errorBannerText}
          />
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Sample Information
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Fill in required details to create a new sample.
            </p>
          </div>
          <div className="space-y-6 sm:space-y-5">
            {sampleTypeSummaries.length === 0 ? (
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="equipmentDisplayID"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Sample Type
                  <span hidden={sampleTypeSummaries.length !== 0}>
                    <ExclamationCircleIcon className="ml-1 text-red-500 inline-flex h-5 w-5" />
                  </span>
                </label>
                <p className="mt-2 text-sm text-gray-500">
                  Please{" "}
                  <Link
                    to="/regulator/samples/types/add"
                    className="font-medium text-blue-500"
                    target="_blank"
                    rel="noreferrer"
                  >
                    create
                  </Link>{" "}
                  sample types before creating sample.
                </p>
              </div>
            ) : (
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <CustomSelectMenu
                  label={"Sample Type"}
                  options={sampleTypeOptions}
                  selected={sampleType}
                  setSelected={setSampleType}
                />
                <p
                  className="mt-2 text-sm text-red-500"
                  hidden={!sampleTypeError}
                >
                  * Sample Type is required
                </p>
              </div>
            )}
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="sampleDisplayID"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Sample ID
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="sampleDisplayID"
                  id="sampleDisplayID"
                  className={classNames(
                    "max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md",
                    displayIDError
                      ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                      : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                  )}
                />
                <p
                  className="mt-2 text-sm text-red-500"
                  hidden={!displayIDError}
                >
                  * Sample ID is required
                </p>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="samplePreservationMethod"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Preservation Method
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="samplePreservationMethod"
                  id="samplePreservationMethod"
                  className={classNames(
                    "max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md",
                    preservationMethodError
                      ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                      : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                  )}
                />
                <p
                  className="mt-2 text-sm text-red-500"
                  hidden={!preservationMethodError}
                >
                  * Preservation Method is required
                </p>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="dateReceived"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Date Received
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="date"
                  name="dateReceived"
                  id="dateReceived"
                  className={classNames(
                    "max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md",
                    dateReceivedError
                      ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                      : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                  )}
                />
                <p
                  className="mt-2 text-sm text-red-500"
                  hidden={!dateReceivedError}
                >
                  * Date Received is required
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <Link to="/regulator/samples">
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
