import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import FileUploadComponent from "../common/FileUploadComponent";
import CustomSelectMenu from "../common/CustomSelectMenu";
import {
  classNames,
  formatDateInputValue,
  formatErrorMessage,
} from "../common/Utils";
import {
  addEquipment,
  useEquipmentTypeSummaries,
} from "../common/DataFetchers";
import { EquipmentStatus } from "../common/Entities.d";
import { useUserContext } from "../common/BasePageScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import ErrorBanner from "../common/ErrorBanner";

const statusOptions = [
  { id: "1", value: "Quarantined", display: "Quarantined" },
  { id: "2", value: "In Use", display: "In Use" },
  { id: "3", value: "Archived", display: "Archived" },
];

export default function AddEquipmentForm(): JSX.Element {
  const { equipmentRead, equipmentWrite } = useUserContext();
  const navigate = useNavigate();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const {
    equipmentTypeSummaries,
    equipmentTypeSummariesIsLoading,
    equipmentTypeSummariesIsError,
  } = useEquipmentTypeSummaries(equipmentRead);

  const [idError, setIdError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [calibrationError, setCalibrationError] = useState(false);
  const [manufacturerError, setManufacturerError] = useState(false);

  const typeOptions: { id: string; value: any; display: string }[] = [];
  typeOptions.push({ id: "0", value: "Select", display: "Select" });
  const [equipmentType, setEquipmentType] = useState(typeOptions[0]);

  equipmentTypeSummaries.forEach((equipmentType) =>
    typeOptions.push({
      id: equipmentType.id,
      value: equipmentType,
      display: equipmentType.name,
    })
  );

  const [status, setStatus] = useState(statusOptions[0]);

  const [attachments] = useState<File[]>([]);

  if (!equipmentWrite || !equipmentRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (equipmentTypeSummariesIsLoading) {
    return <LoadingComponent />;
  }

  if (equipmentTypeSummariesIsError) {
    return <ErrorComponent />;
  }

  return (
    <form
      className="py-3 px-3 space-y-8 divide-y divide-gray-200"
      onSubmit={(e: React.SyntheticEvent) => {
        e.preventDefault();

        setShowErrorBanner(false);

        const target = e.target as typeof e.target & {
          equipmentDisplayID: { value: string };
          lastCalibration: { value: string };
          manufacturer: { value: string };
        };

        let isValid = true;

        setIdError(false);
        target.equipmentDisplayID.value =
          target.equipmentDisplayID.value.trim();
        if (target.equipmentDisplayID.value === "") {
          setIdError(true);
          isValid = false;
        }

        setTypeError(false);
        if (equipmentType.value === "Select") {
          setTypeError(true);
          isValid = false;
        }

        setCalibrationError(false);
        if (target.lastCalibration.value.trim() === "") {
          setCalibrationError(true);
          isValid = false;
        }

        setManufacturerError(false);
        target.manufacturer.value = target.manufacturer.value.trim();
        if (target.manufacturer.value === "") {
          setManufacturerError(true);
          isValid = false;
        }

        if (!isValid) {
          return;
        }

        if (equipmentWrite) {
          addEquipment(
            equipmentType.id,
            target.equipmentDisplayID.value,
            status.value as EquipmentStatus,
            target.manufacturer.value,
            new Date(formatDateInputValue(target.lastCalibration.value)),
            attachments
          )
            .then((equipment) =>
              navigate(`/regulator/equipment/${equipment.id}`)
            )
            .catch((error) => {
              setErrorBannerText(formatErrorMessage(error));
              setShowErrorBanner(true);
            });
        }
      }}
    >
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <ErrorBanner
            showError={showErrorBanner}
            setShowError={setShowErrorBanner}
            errorText={errorBannerText}
          />
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Equipment Information
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Fill in required details to add new equipment.
            </p>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="equipmentDisplayID"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Equipment ID
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="equipmentDisplayID"
                  id="equipmentDisplayID"
                  className={classNames(
                    "max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md",
                    idError
                      ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                      : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                  )}
                />
                <p className="mt-2 text-sm text-red-500" hidden={!idError}>
                  * Equipment ID is required
                </p>
              </div>
            </div>
            {equipmentTypeSummaries.length === 0 ? (
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="equipmentDisplayID"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Equipment Type
                  <span hidden={equipmentTypeSummaries.length !== 0}>
                    <ExclamationCircleIcon className="ml-1 text-red-500 inline-flex h-5 w-5" />
                  </span>
                </label>
                <p className="mt-2 text-sm text-gray-500">
                  Please{" "}
                  <Link
                    to="/regulator/equipment/types/add"
                    className="font-medium text-blue-500"
                    target="_blank"
                    rel="noreferrer"
                  >
                    create
                  </Link>{" "}
                  equipment types before creating equipment.
                </p>
              </div>
            ) : (
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <CustomSelectMenu
                  label={"Equipment Type"}
                  options={typeOptions}
                  selected={equipmentType}
                  setSelected={setEquipmentType}
                />
                <p
                  className="-mt-2 text-sm text-red-500 col-start-2"
                  hidden={!typeError}
                >
                  * Equipment Type is required
                </p>
              </div>
            )}
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="lastCalibration"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Last Calibration
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="date"
                  name="lastCalibration"
                  id="lastCalibration"
                  className={classNames(
                    "max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md",
                    calibrationError
                      ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                      : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                  )}
                />
                <p
                  className="mt-2 text-sm text-red-500"
                  hidden={!calibrationError}
                >
                  * Last Calibration is required
                </p>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="manufacturer"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Manufacturer
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="manufacturer"
                  id="manufacturer"
                  className={classNames(
                    "max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md",
                    manufacturerError
                      ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                      : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                  )}
                />
                <p
                  className="mt-2 text-sm text-red-500"
                  hidden={!manufacturerError}
                >
                  * Manufacturer is required
                </p>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <CustomSelectMenu
                label="Status"
                options={statusOptions}
                selected={status}
                setSelected={setStatus}
              />
            </div>
            <FileUploadComponent
              label={"Attachments"}
              files={attachments}
              fileTypes={"PDF, PNG, JPG"}
            />
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <Link to="/regulator/equipment">
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
