import React, { Fragment, useEffect, useState } from "react";
import { Listbox, Menu, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { ExclamationCircleIcon, FilterIcon } from "@heroicons/react/outline";
import { useSOPCategorySummaries, useSOPSummaries } from "./DataFetchers";
import { classNames } from "./Utils";
import { Link } from "react-router-dom";
import { SOPCategorySummary, SOPSummary } from "./Entities.d";

interface SOPSelectProps {
  label: string;
  selected?: { id: string; value: SOPSummary | null; display: string };
  setSelected: (selected: {
    id: string;
    value: SOPSummary | null;
    display: string;
  }) => void;
}

export default function SOPSelectMenu(props: SOPSelectProps) {
  const [categoryOptions, setCategoryOptions] = useState<
    { id: string; value: SOPCategorySummary | null; display: string }[]
  >([]);
  const [category, setCategory] = useState<{
    id: string;
    value: SOPCategorySummary | null;
    display: string;
  }>();

  const { sopCategorySummaries } = useSOPCategorySummaries(true);
  useEffect(() => {
    const activeCategories = sopCategorySummaries.filter(
      (category) => category.activeSOPCount > 0
    );

    if (activeCategories.length === 0) {
      return;
    }

    const updatedCategoryOptions = activeCategories.map((category) => {
      return {
        id: category.id,
        value: category,
        display: category.name,
      };
    });

    setCategoryOptions(updatedCategoryOptions);
    setCategory(updatedCategoryOptions[0]);
  }, [sopCategorySummaries]);

  const [sopOptions, setSopOptions] = useState<
    { id: string; value: SOPSummary | null; display: string }[]
  >([{ id: "0", value: null, display: "None" }]);

  const { sopSummaries } = useSOPSummaries(
    "Active",
    category ? category.id : null,
    true
  );

  useEffect(() => {
    if (sopSummaries.length === 0) {
      return;
    }

    const updatedSopOptions = sopSummaries.map<{
      id: string;
      value: SOPSummary | null;
      display: string;
    }>((s) => {
      return {
        id: s.id,
        value: s,
        display: `${s.displayID} - ${s.title}`,
      };
    });

    updatedSopOptions.unshift({
      id: "None",
      value: null,
      display: "None",
    });

    setSopOptions(updatedSopOptions);
    props.setSelected(updatedSopOptions[1]);
  }, [sopSummaries]);

  if (!props.selected) {
    return (
      <div className="w-max sm:grid sm:grid-cols-2">
        <label
          htmlFor="equipmentDisplayID"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          {props.label}
          <span>
            <ExclamationCircleIcon className="ml-1 text-red-500 inline-flex h-5 w-5" />
          </span>
        </label>
        <p className="text-sm text-gray-500 sm:ml-4">
          Please{" "}
          <Link
            to="/regulator/sops/categories"
            className="font-medium text-blue-500"
            target="_blank"
            rel="noreferrer"
          >
            create
          </Link>{" "}
          SOPs before creating workflow template.
        </p>
      </div>
    );
  }

  return (
    <Listbox value={props.selected} onChange={props.setSelected}>
      <Listbox.Label className="block text-sm font-medium text-gray-700">
        {props.label}
      </Listbox.Label>
      <div className="mt-1 relative">
        <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
          <span className="block truncate">{props.selected?.display}</span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-20 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
            {sopOptions.map((option) => (
              <Listbox.Option
                key={option.id}
                className={({ active }) =>
                  classNames(
                    active ? "text-white bg-blue-600" : "text-gray-900",
                    "cursor-default select-none relative py-2 pl-3 pr-9"
                  )
                }
                value={option}
              >
                {({ active }) => (
                  <>
                    <span
                      className={classNames(
                        props.selected?.id === option.id
                          ? "font-semibold"
                          : "font-normal",
                        "block truncate"
                      )}
                    >
                      {option.display}
                    </span>

                    {props.selected?.display === option.display ? (
                      <span
                        className={classNames(
                          active ? "text-white" : "text-blue-600",
                          "absolute inset-y-0 right-0 flex items-center pr-4"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
        <Menu as="div" className="relative inline-block text-left z-50">
          <div>
            <Menu.Button
              className="absolute -mt-5 ml-3 items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              hidden={sopCategorySummaries.length === 0}
            >
              <span className="sr-only">Open options</span>
              <FilterIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {categoryOptions.map((cat) => (
                  <Menu.Item
                    key={cat.id}
                    onClick={() => {
                      setCategory(cat);
                    }}
                  >
                    {({ active }) => (
                      <div
                        className={classNames(
                          "block px-4 py-2 text-sm",
                          active
                            ? cat === category
                              ? "bg-gray-100 font-semibold text-gray-900"
                              : "bg-gray-100 text-gray-900"
                            : cat === category
                            ? "font-semibold text-gray-900"
                            : "text-gray-700"
                        )}
                      >
                        {cat.display}
                      </div>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </Listbox>
  );
}
