import { UserIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import * as React from "react";
import { RecordSectionPersonnel } from "../../common/Entities.d";
import { getFullName } from "../../common/Utils";

export default function PersonnelRecordElement(props: {
  value: RecordSectionPersonnel;
}) {
  return (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">{props.value.label}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <div>
          <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
            {props.value.personnel.length === 0 ? (
              <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                <span className="italic">None</span>
              </li>
            ) : (
              props.value.personnel.map((personnel) => (
                <li
                  key={personnel.id}
                  className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                >
                  <div className="w-0 flex-1 flex items-center">
                    <UserIcon
                      className="flex-shrink-0 h-5 w-5 text-blue-400"
                      aria-hidden="true"
                    />
                    <span className="ml-2 flex-1 w-0 truncate">
                      {getFullName(personnel.firstName, personnel.lastName)}
                    </span>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <Link
                      to={`/regulator/personnel/${personnel.id}`}
                      className="font-medium text-blue-600 hover:text-blue-500"
                    >
                      View
                    </Link>
                  </div>
                </li>
              ))
            )}
          </ul>
        </div>
      </dd>
    </div>
  );
}
