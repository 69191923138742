import CustomSelectMenu from "../common/CustomSelectMenu";
import React, { useEffect, useState } from "react";
import FileUploadComponent from "../common/FileUploadComponent";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  classNames,
  containsLaboratoryDirector,
  formatErrorMessage,
} from "../common/Utils";
import {
  addSOP,
  usePersonnelSummaries,
  useSOPCategorySummaries,
} from "../common/DataFetchers";
import { SOPCategorySummary } from "../common/Entities.d";
import { useUserContext } from "../common/BasePageScreen";
import ApproverSection, { ApproverPersonnel } from "./ApproverSection";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import ErrorBanner from "../common/ErrorBanner";

interface categoryOptionInterface {
  id: string;
  value: SOPCategorySummary;
  display: string;
}

export default function AddSOPForm(): JSX.Element {
  const { sopsWrite, sopsRead } = useUserContext();
  const navigate = useNavigate();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const [searchParams] = useSearchParams();
  const categoryID = searchParams.get("categoryID");

  const {
    personnelSummaries,
    personnelSummariesIsLoading,
    personnelSummariesIsError,
  } = usePersonnelSummaries(sopsWrite);
  const {
    sopCategorySummaries,
    sopCategorySummariesIsLoading,
    sopCategorySummariesIsError,
  } = useSOPCategorySummaries(sopsRead);

  const [sopMajorVersion, setSOPMajorVersion] = useState(1);
  const [sopMinorVersion, setSOPMinorVersion] = useState(0);

  const [categoryOption, setCategoryOption] =
    useState<categoryOptionInterface>();
  const [categoryOptions, setCategoryOptions] = useState<
    categoryOptionInterface[]
  >([]);

  useEffect(() => {
    const updatedCategoryOptions: categoryOptionInterface[] = [];
    sopCategorySummaries.forEach((category) =>
      updatedCategoryOptions.push({
        id: updatedCategoryOptions.length.toString(),
        value: category,
        display: category.name,
      })
    );

    let defaultOptionIndex = 0;
    if (categoryID) {
      const optionIndex = updatedCategoryOptions.findIndex(
        (category) => category.value.id === categoryID
      );
      if (optionIndex !== -1) {
        defaultOptionIndex = optionIndex;
      }
    }

    setCategoryOptions(updatedCategoryOptions);
    setCategoryOption(updatedCategoryOptions[defaultOptionIndex]);
  }, [categoryID, sopCategorySummaries]);

  const [approvers, setApprovers] = useState(
    new Map<string, ApproverPersonnel>()
  );
  const [additionalApprovers, setAdditionalApprovers] = useState(
    new Map<string, ApproverPersonnel>()
  );
  const [selectedApproverIDs, setSelectedApproverIDs] = useState(
    new Set<string>()
  );

  useEffect(() => {
    if (!categoryOption) {
      return;
    }

    const approverRoleIDs = new Set<string>();
    categoryOption.value.approverRoles.forEach((approverRole) =>
      approverRoleIDs.add(approverRole.id)
    );

    const updatedApprovers = new Map<string, ApproverPersonnel>();
    const updatedAdditionalApprovers = new Map<string, ApproverPersonnel>();

    personnelSummaries.forEach((personnel) => {
      const roleID = personnel.role.id;
      const targetBucket = approverRoleIDs.has(roleID)
        ? updatedApprovers
        : updatedAdditionalApprovers;

      if (!targetBucket.has(roleID)) {
        targetBucket.set(roleID, {
          roleName: personnel.role.name,
          personnel: [],
        });
      }

      targetBucket.get(roleID)?.personnel.push(personnel);
    });

    setApprovers(updatedApprovers);
    setAdditionalApprovers(updatedAdditionalApprovers);
    setSelectedApproverIDs(new Set<string>());
  }, [categoryOption, personnelSummaries]);

  const [files] = useState<File[]>([]);

  const [sopDisplayIDError, setSOPDisplayIDError] = useState(false);
  const [sopTitleError, setSopTitleError] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [approvalError, setApprovalError] = useState(false);
  const [labDirectorApprovalError, setLabDirectorApprovalError] =
    useState(false);

  if (!sopsWrite || !sopsRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (
    personnelSummariesIsLoading ||
    sopCategorySummariesIsLoading ||
    !categoryOption
  ) {
    return <LoadingComponent />;
  }

  if (personnelSummariesIsError || sopCategorySummariesIsError) {
    return <ErrorComponent />;
  }

  return (
    <form
      className="mt-3 px-3 space-y-8 divide-y divide-gray-200"
      onSubmit={(e: React.SyntheticEvent) => {
        e.preventDefault();

        setShowErrorBanner(false);

        const target = e.target as typeof e.target & {
          sopDisplayIDSuffix: { value: string };
          sopTitle: { value: string };
        };

        let isValid = true;

        setSOPDisplayIDError(false);
        target.sopDisplayIDSuffix.value =
          target.sopDisplayIDSuffix.value.trim();
        if (target.sopDisplayIDSuffix.value === "") {
          setSOPDisplayIDError(true);
          isValid = false;
        }

        setSopTitleError(false);
        target.sopTitle.value = target.sopTitle.value.trim();
        if (target.sopTitle.value === "") {
          setSopTitleError(true);
          isValid = false;
        }

        setUploadError(false);
        if (files.length !== 1) {
          setUploadError(true);
          isValid = false;
        }

        setApprovalError(false);
        if (approvers.size === 0) {
          setApprovalError(true);
          isValid = false;
        }

        setLabDirectorApprovalError(false);
        if (
          !containsLaboratoryDirector(selectedApproverIDs, personnelSummaries)
        ) {
          setLabDirectorApprovalError(true);
          isValid = false;
        }

        if (!isValid || !sopsWrite) {
          return;
        }

        addSOP(
          categoryOption.value.id,
          target.sopDisplayIDSuffix.value,
          target.sopTitle.value,
          files[0],
          sopMajorVersion,
          sopMinorVersion,
          Array.from(selectedApproverIDs)
        )
          .then((sop) => navigate(`/regulator/sops/${sop.id}/draft-review`))
          .catch((error) => {
            setErrorBannerText(formatErrorMessage(error));
            setShowErrorBanner(true);
          });
      }}
    >
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <ErrorBanner
            showError={showErrorBanner}
            setShowError={setShowErrorBanner}
            errorText={errorBannerText}
          />
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              SOP Information
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Fill in required details to create a new SOP.
            </p>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <CustomSelectMenu
                label={"SOP Category"}
                options={categoryOptions}
                selected={categoryOption}
                setSelected={setCategoryOption}
              />
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="sopDisplayIDSuffix"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                SOP ID
              </label>
              <div className="max-w-lg flex rounded-md shadow-sm">
                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                  {categoryOption.value.initials}
                </span>
                <input
                  type="text"
                  name="sopDisplayIDSuffix"
                  id="sopDisplayIDSuffix"
                  className={classNames(
                    "flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md sm:text-sm border",
                    sopDisplayIDError
                      ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                      : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                  )}
                />
              </div>
              <p
                className="mt-2 text-sm text-red-500"
                hidden={!sopDisplayIDError}
              >
                * SOP ID is required
              </p>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="sopTitle"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                SOP Title
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-1">
                <input
                  type="text"
                  name="sopTitle"
                  id="sopTitle"
                  className={classNames(
                    "flex-1 min-w-0 block w-full px-3 py-2 rounded-md sm:text-sm border",
                    sopTitleError
                      ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                      : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                  )}
                />
                <p
                  className="mt-2 text-sm text-red-500"
                  hidden={!sopTitleError}
                >
                  * SOP Title is required
                </p>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-6 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="sopVersion"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                SOP Version
              </label>
              <div className="max-w-lg flex rounded-md col-span-3 col-start-3">
                <p className="font-medium text-gray-600 px-4 mt-2">{`${sopMajorVersion}.${sopMinorVersion}`}</p>
                <div className="inline-flex w-32">
                  <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm shadow-sm">
                    Major
                  </span>
                  <input
                    type="number"
                    step="1"
                    min="0"
                    name="sopMajorVersion"
                    id="sopMajorVersion"
                    defaultValue={sopMajorVersion}
                    onChange={(e) =>
                      setSOPMajorVersion(parseInt(e.currentTarget.value))
                    }
                    className={classNames(
                      "flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md sm:text-sm border",
                      "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                    )}
                  />
                </div>
                <div className="inline-flex w-36">
                  <span className="ml-4 inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm shadow-sm">
                    Minor
                  </span>
                  <input
                    type="number"
                    step="1"
                    min="0"
                    name="sopMinorVersion"
                    id="sopMinorVersion"
                    defaultValue={sopMinorVersion}
                    onChange={(e) =>
                      setSOPMinorVersion(parseInt(e.currentTarget.value))
                    }
                    className={classNames(
                      "flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md sm:text-sm border",
                      "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                    )}
                  />
                </div>
              </div>
            </div>
            <FileUploadComponent
              label={"SOP File"}
              fileTypes={"PDF"}
              files={files}
            />
            <p
              className="-ml-20 text-center text-sm text-red-500"
              hidden={!uploadError}
            >
              * One file must be uploaded to complete update.
            </p>
            <ApproverSection
              approvalError={approvalError}
              labDirectorApprovalError={labDirectorApprovalError}
              approvers={approvers}
              additionalApprovers={additionalApprovers}
              selectedApproverIDs={selectedApproverIDs}
              setSelectedApproverIDs={setSelectedApproverIDs}
            />
          </div>
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <Link to="/regulator/sops">
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
