import { DocumentAddIcon } from "@heroicons/react/solid";
import { Link, useNavigate } from "react-router-dom";
import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import InformationCircleIcon from "@heroicons/react/outline/InformationCircleIcon";
import * as React from "react";
import { useUserContext } from "../common/BasePageScreen";
import { useSOPCategorySummaries } from "../common/DataFetchers";
import EmptyStateScreen from "../common/EmptyStateScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import { classNames } from "../common/Utils";

const pages = [{ name: "SOPs", path: "/regulator/sops" }];

export default function SOPCategoryList(): JSX.Element {
  const { sopsRead, sopsWrite } = useUserContext();
  const navigate = useNavigate();

  const {
    sopCategorySummaries,
    sopCategorySummariesIsLoading,
    sopCategorySummariesIsError,
  } = useSOPCategorySummaries(sopsRead);

  if (!sopsRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (sopCategorySummariesIsLoading) {
    return <LoadingComponent />;
  }

  if (sopCategorySummariesIsError) {
    return <ErrorComponent />;
  }

  return (
    <div>
      <FullWidthBreadcrumbBar pages={pages} />
      <div className="py-3 text-right">
        <Link
          to="/regulator/sops/add"
          className="px-2"
          hidden={!sopsWrite || sopCategorySummaries.length === 0}
        >
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <DocumentAddIcon
              className="-ml-1 mr-3 h-5 w-5"
              aria-hidden="true"
            />
            Add SOP
          </button>
        </Link>
        <Link to="/regulator/sops/categories/add" hidden={!sopsWrite}>
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <DocumentAddIcon
              className="-ml-1 mr-3 h-5 w-5"
              aria-hidden="true"
            />
            Add SOP Category
          </button>
        </Link>
      </div>
      <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
        SOP Categories
      </h2>
      {sopCategorySummaries.length === 0 ? (
        <div className="mt-2 p-1 bg-white rounded-md shadow-sm">
          <EmptyStateScreen
            item={"SOP category"}
            createLink={"/regulator/sops/categories/add"}
            writeAccess={sopsWrite}
          />
        </div>
      ) : (
        <div className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
          {sopCategorySummaries.map((category) => (
            <li
              key={category.name}
              className="col-span-1 flex shadow-sm rounded-md"
            >
              <div
                className={classNames(
                  "bg-blue-600",
                  "flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md"
                )}
              >
                {category.initials}
              </div>
              <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                <div className="flex-1 px-4 py-2 text-sm truncate">
                  <Link
                    to={`/regulator/sops/active?categoryID=${category.id}`}
                    className="text-gray-900 font-medium hover:text-gray-600"
                  >
                    {category.name}
                  </Link>
                  <p className="text-gray-500">
                    {category.activeSOPCount} Active SOPs
                  </p>
                </div>
                <div className="flex-shrink-0 pr-2">
                  <Link to={`/regulator/sops/categories/${category.id}`}>
                    <button
                      type="button"
                      className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <span className="sr-only">Open options</span>
                      <InformationCircleIcon
                        className="w-5 h-5 text-blue-500"
                        aria-hidden="true"
                      />
                    </button>
                  </Link>
                </div>
              </div>
            </li>
          ))}
        </div>
      )}
    </div>
  );
}
