import ProfileCard from "./ProfileCard";
import {
  CloudUploadIcon,
  CreditCardIcon,
  KeyIcon,
  PencilIcon,
  UserCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import PasswordCard from "./PasswordCard";
import UsersCard from "./UsersCard";
import BillingCard from "./BillingCard";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import BulkSOPCard from "./BulkSOPCard";
import SignatureCard from "./SignatureCard";
import { classNames } from "../common/Utils";
import { useUserContext } from "../common/BasePageScreen";

const adminTabHrefs = ["sops", "users", "billing"];

const tabs = [
  {
    name: "Profile",
    href: "profile",
    icon: UserCircleIcon,
    current: true,
    component: <ProfileCard />,
  },
  {
    name: "Password",
    href: "password",
    icon: KeyIcon,
    current: false,
    component: <PasswordCard />,
  },
  {
    name: "Signature",
    href: "signature",
    icon: PencilIcon,
    current: false,
    component: <SignatureCard />,
  },
  {
    name: "Bulk SOP Import",
    href: "sops",
    icon: CloudUploadIcon,
    current: false,
    component: <BulkSOPCard />,
  },
  {
    name: "Manage Users",
    href: "users",
    icon: UserGroupIcon,
    current: false,
    component: <UsersCard />,
  },
  {
    name: "Plan & Billing",
    href: "billing",
    icon: CreditCardIcon,
    current: false,
    component: <BillingCard />,
  },
];

export default function SettingsPage() {
  const navigate = useNavigate();

  const { user } = useUserContext();

  const adminTabsSet = new Set(adminTabHrefs);

  const [searchParams] = useSearchParams();
  const cardType = searchParams.has("type")
    ? searchParams.get("type")
    : "profile";

  let component: JSX.Element | null = null;

  tabs.map((tab) => {
    if (tab.href === cardType) {
      tab.current = true;
      component = tab.component;
    } else {
      tab.current = false;
    }
  });

  const filteredTabs = tabs.filter(
    (tab) =>
      !adminTabsSet.has(tab.href) ||
      (adminTabsSet.has(tab.href) && user.isAdmin)
  );

  return (
    <>
      <div>
        <div className="bg-white rounded-md shadow">
          <div className="max-w-4xl mx-auto flex flex-col xl:px-0">
            <main className="flex-1">
              <div className="relative max-w-4xl mx-auto md:px-8 xl:px-0">
                <div className="pt-10 pb-16">
                  <div className="px-4 sm:px-6 md:px-0">
                    <h1 className="text-3xl font-extrabold text-gray-900">
                      Settings
                    </h1>
                  </div>
                  <div className="px-4 sm:px-6 md:px-0">
                    <div className="py-6">
                      {/* Tabs */}
                      <div className="xl:hidden">
                        <label htmlFor="selected-tab" className="sr-only">
                          Select a tab
                        </label>
                        <select
                          id="selected-tab"
                          name="selected-tab"
                          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                          defaultValue={
                            filteredTabs.find((tab) => tab.current)?.name
                          }
                          onChange={(e) => {
                            const tabName = e.currentTarget.value;
                            const tabIndex = tabs.findIndex(
                              (tab) => tab.name === tabName
                            );
                            const tab = filteredTabs[tabIndex];
                            navigate(`/regulator/settings?type=${tab.href}`);
                          }}
                        >
                          {filteredTabs.map((tab) => (
                            <option key={tab.name}>{tab.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="hidden xl:block">
                        <div className="border-b border-gray-200">
                          <nav className="-mb-px flex space-x-8">
                            {filteredTabs.map((tab) => (
                              <Link
                                key={tab.name}
                                to={`/regulator/settings?type=${tab.href}`}
                                className={classNames(
                                  tab.current
                                    ? "border-blue-500 text-blue-600"
                                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                  "group flex whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                                )}
                              >
                                <tab.icon
                                  className={classNames(
                                    tab.current
                                      ? "text-blue-500"
                                      : "text-gray-400 group-hover:text-gray-500",
                                    "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                  )}
                                />
                                <span className="truncate">{tab.name}</span>
                              </Link>
                            ))}
                          </nav>
                        </div>
                      </div>
                      {component}
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}
