import { DocumentAddIcon } from "@heroicons/react/solid";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Tabs from "../common/Tabs";
import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import * as React from "react";
import { useUserContext } from "../common/BasePageScreen";
import { useSOPCategory } from "../common/DataFetchers";
import ActiveList from "./ActiveList";
import DraftList from "./DraftList";
import ArchivedList from "./ArchivedList";
import ErrorComponent from "../common/ErrorComponent";
import LoadingComponent from "../common/LoadingComponent";

export default function SOPList(): JSX.Element {
  const { sopsRead, sopsWrite } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const categoryID = searchParams.get("categoryID");

  const { sopCategory, sopCategoryIsLoading, sopCategoryIsError } =
    useSOPCategory(categoryID, sopsRead);

  if (!categoryID) {
    navigate("/regulator/sops/categories");
    return <></>;
  }

  if (!sopsRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (sopCategoryIsLoading) {
    return <LoadingComponent />;
  }

  if (sopCategoryIsError || !sopCategory) {
    return <ErrorComponent />;
  }

  const pages = [
    { name: "SOPs", path: "/regulator/sops" },
    {
      name: `${sopCategory.name}`,
      path: `/regulator/sops/active?categoryID=${categoryID}`,
    },
  ];

  const sopTabs = [
    { name: "Active", href: `/regulator/sops/active?categoryID=${categoryID}` },
    { name: "Draft", href: `/regulator/sops/draft?categoryID=${categoryID}` },
    {
      name: "Archived",
      href: `/regulator/sops/archived?categoryID=${categoryID}`,
    },
  ];

  let component = <ActiveList />;
  if (location.pathname === "/regulator/sops/draft") {
    component = <DraftList />;
  }

  if (location.pathname === "/regulator/sops/archived") {
    component = <ArchivedList />;
  }

  return (
    <div className="flex flex-col">
      <FullWidthBreadcrumbBar pages={pages} />
      <div className="py-3 text-right">
        <Link
          to={`/regulator/sops/add?categoryID=${categoryID}`}
          hidden={!sopsWrite}
        >
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <DocumentAddIcon
              className="-ml-1 mr-3 h-5 w-5"
              aria-hidden="true"
            />
            Add SOP
          </button>
        </Link>
      </div>
      <Tabs tabs={sopTabs} />
      {component}
    </div>
  );
}
