import { BeakerIcon } from "@heroicons/react/solid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Tabs from "../common/Tabs";
import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import * as React from "react";
import { useUserContext } from "../common/BasePageScreen";
import ReagentList from "./ReagentList";
import ReagentTypesList from "./ReagentTypesList";

const reagentPages = [{ name: "Reagents", path: "/regulator/reagents" }];
const typePages = [
  { name: "Reagents", path: "/regulator/reagents" },
  { name: "Reagent Types", path: "/regulator/reagents/types" },
];
const archivedPages = [
  { name: "Reagents", path: "/regulator/reagents" },
  { name: "Archived", path: "/regulator/reagents/archived" },
];

const reagentTabs = [
  { name: "Active", href: "/regulator/reagents" },
  { name: "Types", href: "/regulator/reagents/types" },
  { name: "Archived", href: "/regulator/reagents/archived" },
];

export default function ReagentOrTypeList(): JSX.Element {
  const { reagentsRead, reagentsWrite } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();

  if (!reagentsRead) {
    navigate("/forbidden");
    return <></>;
  }

  let breadcrumbBar = <FullWidthBreadcrumbBar pages={reagentPages} />;

  if (location.pathname === "/regulator/reagents/types") {
    breadcrumbBar = <FullWidthBreadcrumbBar pages={typePages} />;
  }

  if (location.pathname === "/regulator/reagents/archived") {
    breadcrumbBar = <FullWidthBreadcrumbBar pages={archivedPages} />;
  }

  let addButton = { text: "Add Reagent", href: "/regulator/reagents/add" };

  if (location.pathname === "/regulator/reagents/types") {
    addButton = {
      text: "Add Reagent Type",
      href: "/regulator/reagents/types/add",
    };
  }

  let component = <ReagentList />;

  if (location.pathname === "/regulator/reagents/types") {
    component = <ReagentTypesList />;
  }

  return (
    <div className="flex flex-col">
      {breadcrumbBar}
      <div className="py-3 text-right">
        <Link to={addButton.href} hidden={!reagentsWrite}>
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <BeakerIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
            {addButton.text}
          </button>
        </Link>
      </div>
      <Tabs tabs={reagentTabs} />
      {component}
    </div>
  );
}
