import { useNavigate, useParams } from "react-router-dom";
import { getDate, getFullName, getTime } from "../common/Utils";
import * as React from "react";
import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import { useUserContext } from "../common/BasePageScreen";
import { recordAuditPath, useRecord } from "../common/DataFetchers";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import AuditTrailCard from "../common/AuditTrailCard";
import AttachmentRecordElement from "./record_elements/AttachmentRecordElement";
import EquipmentRecordElement from "./record_elements/EquipmentRecordElement";
import SampleRecordElement from "./record_elements/SampleRecordElement";
import ReagentRecordElement from "./record_elements/ReagentRecordElement";
import PersonnelRecordElement from "./record_elements/PersonnelRecordElement";
import SOPRecordElement from "./record_elements/SOPRecordElement";
import WorkflowRunRecordElement from "./record_elements/WorkflowRunRecordElement";
import SectionHeaderRecordElement from "./record_elements/SectionHeaderRecordElement";
import ParagraphRecordElement from "./record_elements/ParagraphRecordElement";
import LinkRecordElement from "./record_elements/LinkRecordElement";
import TextRecordElement from "./record_elements/TextRecordElement";
import { v4 as uuid } from "uuid";

export default function RecordDetailCard() {
  const navigate = useNavigate();
  const { recordsRead } = useUserContext();

  const params = useParams<{ id: string }>();
  const recordId = params.id as string;

  const { record, recordIsLoading, recordIsError } = useRecord(
    recordId,
    recordsRead
  );

  if (recordIsLoading) {
    return <LoadingComponent />;
  }

  if (recordIsError || !record) {
    return <ErrorComponent />;
  }

  if (!recordsRead) {
    navigate("/forbidden");
    return <></>;
  }

  const pages = [
    { name: "Records", path: "/regulator/records" },
    {
      name: `${record.form?.title} : ${getFullName(
        record.authorUser.firstName,
        record.authorUser.lastName
      )} - ${getDate(record.completed)} ${getTime(record.completed)}`,
      path: `/regulator/records/${record.id}`,
    },
  ];

  return (
    <div>
      <FullWidthBreadcrumbBar pages={pages} />
      <div className="mt-3 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {record.form.title}
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            {`Completed: ${getDate(record.completed)} ${getTime(
              record.completed
            )}`}
          </p>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            {record.order.map(function (orderComponent) {
              const category = orderComponent.category;
              const categoryIndex = orderComponent.categoryIndex;
              if (category === "Attachment") {
                const value = record?.attachmentSections[categoryIndex];
                return (
                  <div key={uuid()}>
                    <AttachmentRecordElement value={value} />
                  </div>
                );
              }
              if (category === "Sample") {
                const value = record?.sampleSections[categoryIndex];
                return (
                  <div key={uuid()}>
                    <SampleRecordElement value={value} />
                  </div>
                );
              }
              if (category === "Equipment") {
                const value = record?.equipmentSections[categoryIndex];
                return (
                  <div key={uuid()}>
                    <EquipmentRecordElement value={value} />
                  </div>
                );
              }
              if (category === "Reagent") {
                const value = record?.reagentSections[categoryIndex];
                return (
                  <div key={uuid()}>
                    <ReagentRecordElement value={value} />
                  </div>
                );
              }
              if (category === "Personnel") {
                const value = record?.personnelSections[categoryIndex];
                return (
                  <div key={uuid()}>
                    <PersonnelRecordElement value={value} />
                  </div>
                );
              }
              if (category === "SOP") {
                const value = record?.sopSections[categoryIndex];
                return (
                  <div key={uuid()}>
                    <SOPRecordElement value={value} />
                  </div>
                );
              }

              if (category === "WorkflowRun") {
                const value = record?.workflowRunSections[categoryIndex];
                return (
                  <div key={uuid()}>
                    <WorkflowRunRecordElement value={value} />
                  </div>
                );
              } else {
                const value = record?.textSections[categoryIndex];
                if (value?.elementType === "SectionHeader") {
                  return (
                    <div key={uuid()}>
                      <SectionHeaderRecordElement value={value} />
                    </div>
                  );
                }
                if (value?.elementType === "Paragraph") {
                  return (
                    <div key={uuid()}>
                      <ParagraphRecordElement value={value} />
                    </div>
                  );
                }
                if (value?.elementType === "Link") {
                  return (
                    <div key={uuid()}>
                      <LinkRecordElement value={value} />
                    </div>
                  );
                }
                return (
                  <div key={uuid()}>
                    <TextRecordElement value={value} />
                  </div>
                );
              }
            })}
          </dl>
        </div>
      </div>
      <div className="mt-3">
        <AuditTrailCard
          fieldDisplayMap={new Map()}
          type={`${record.form?.title} : ${getFullName(
            record.authorUser.firstName,
            record.authorUser.lastName
          )} - ${getDate(record.completed)} ${getTime(record.completed)}`}
          fetchPath={recordAuditPath(recordId)}
        />
      </div>
    </div>
  );
}
