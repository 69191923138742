import React, { useEffect, useState } from "react";
import { ArchiveIcon } from "@heroicons/react/solid";
import {
  AttachmentElementType,
  EntitySelectElementType,
  FormCategory,
  FormCategoryPointer,
  FormElementAttachment,
  FormElementEntitySelect,
  FormElementSimpleInput,
  FormElementSimpleSelect,
  FormElementSimpleSelectOption,
  FormElementText,
  SimpleInputElementType,
  SimpleSelectElementType,
  TextElementType,
} from "../common/Entities.d";
import {
  classNames,
  formatErrorMessage,
  formOrderContainsError,
} from "../common/Utils";
import { useNavigate, useParams } from "react-router-dom";
import { useUserContext } from "../common/BasePageScreen";
import { addForm, updateFormArchive, useForm } from "../common/DataFetchers";
import PreviewModal from "./PreviewModal";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import FormArchiveModal from "./FormArchiveModal";
import CancelModal from "./CancelModal";
import TextFormBuilderComponent from "./form_builder_elements/TextFormBuilderComponent";
import SimpleInputFormBuilderComponent from "./form_builder_elements/SimpleInputFormBuilderComponent";
import SimpleSelectFormBuilderComponent from "./form_builder_elements/SimpleSelectFormBuilderComponent";
import EntitySelectFormBuilderComponent from "./form_builder_elements/EntitySelectFormBuilderComponent";
import AttachmentFormBuilderComponent from "./form_builder_elements/AttachmentFormBuilderComponent";
import { v4 as uuid } from "uuid";
import ErrorBanner from "../common/ErrorBanner";

const formElements = [
  { name: "Section Header" },
  { name: "Paragraph" },
  { name: "Text Input" },
  { name: "Text Area" },
  { name: "Checkbox" },
  { name: "Select Menu" },
  { name: "Radio Group" },
  { name: "Date" },
  { name: "Number" },
  { name: "Attachment" },
  { name: "Link" },
  { name: "SOP" },
  { name: "Personnel" },
  { name: "Reagent" },
  { name: "Equipment" },
  { name: "Sample" },
  { name: "Workflow Run" },
  // {name: 'Signature'},
];

export default function EditFormForm() {
  const navigate = useNavigate();
  const { formsRead, formsWrite } = useUserContext();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const params = useParams<{ id: string }>();

  const formID = params.id as string;

  const { form, formIsLoading, formIsError, mutateForm } = useForm(
    formID,
    formsWrite
  );

  const [open, setOpen] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [formTitleError, setFormTitleError] = useState(false);
  const [formVersionError, setFormVersionError] = useState(false);
  const [title, setTitle] = useState("");

  const [order, setOrder] = useState<
    { pointer: FormCategoryPointer; error: boolean }[]
  >([]);
  const [text, setText] = useState<FormElementText[]>([]);
  const [simpleInput, setSimpleInput] = useState<FormElementSimpleInput[]>([]);
  const [simpleSelect, setSimpleSelect] = useState<FormElementSimpleSelect[]>(
    []
  );
  const [entitySelect, setEntitySelect] = useState<FormElementEntitySelect[]>(
    []
  );
  const [attachment, setAttachment] = useState<FormElementAttachment[]>([]);
  const [formMajorVersion, setFormMajorVersion] = useState<number>();
  const [formMinorVersion, setFormMinorVersion] = useState<number>();

  useEffect(() => {
    if (!form) {
      return;
    }
    setTitle(form.title);
    setOrder(
      form.order.map((o) => {
        return { pointer: o, error: false };
      })
    );
    setText(form.texts);
    setSimpleInput(form.simpleInputs);
    setSimpleSelect(form.simpleSelects);
    setEntitySelect(form.entitySelects);
    setAttachment(form.attachments);
    setFormMajorVersion(form.majorVersion);
    setFormMinorVersion(form.minorVersion);
  }, [form]);

  if (!formsWrite || !formsRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (formIsLoading) {
    return <LoadingComponent />;
  }

  if (formIsError || !form) {
    return <ErrorComponent />;
  }

  function clearFormBuilder() {
    setOrder([]);
    setText([]);
    setSimpleInput([]);
    setSimpleSelect([]);
    setEntitySelect([]);
    setAttachment([]);

    setFormTitleError(false);
  }

  function addComponent(e: React.MouseEvent<HTMLButtonElement>) {
    const formElementType = e.currentTarget.id;

    if (
      formElementType === "Section Header" ||
      formElementType === "Paragraph"
    ) {
      const elementType = formElementType.replace(/ /g, "") as TextElementType;
      text.push({
        elementType: elementType,
        text: "",
      });
      setText(Array.from(text));

      order.push({
        pointer: {
          category: "Text" as FormCategory,
          categoryIndex: text.length - 1,
        },
        error: false,
      });
      setOrder(Array.from(order));
    }
    if (
      formElementType === "Text Input" ||
      formElementType === "Text Area" ||
      formElementType === "Number" ||
      formElementType === "Date" ||
      formElementType === "Link"
    ) {
      const elementType = formElementType.replace(
        / /g,
        ""
      ) as SimpleInputElementType;

      simpleInput.push({
        elementType: elementType,
        required: false,
        label: "",
        placeholder: "",
      });
      setSimpleInput(Array.from(simpleInput));

      order.push({
        pointer: {
          category: "SimpleInput" as FormCategory,
          categoryIndex: simpleInput.length - 1,
        },
        error: false,
      });
      setOrder(Array.from(order));
    }
    if (
      formElementType === "Checkbox" ||
      formElementType === "Select Menu" ||
      formElementType === "Radio Group"
    ) {
      const elementType = formElementType.replace(
        / /g,
        ""
      ) as SimpleSelectElementType;
      simpleSelect.push({
        elementType: elementType,
        required: false,
        label: "",
        options: [] as FormElementSimpleSelectOption[],
      });
      setSimpleSelect(Array.from(simpleSelect));

      order.push({
        pointer: {
          category: "SimpleSelect" as FormCategory,
          categoryIndex: simpleSelect.length - 1,
        },
        error: false,
      });
      setOrder(Array.from(order));
    }
    if (
      formElementType === "SOP" ||
      formElementType === "Personnel" ||
      formElementType === "Reagent" ||
      formElementType === "Equipment" ||
      formElementType === "Sample" ||
      formElementType === "Workflow Run"
    ) {
      const elementType = formElementType.replace(
        / /g,
        ""
      ) as EntitySelectElementType;

      entitySelect.push({
        elementType: elementType,
        required: false,
        label: "",
        multiSelect: false,
      });
      setEntitySelect(Array.from(entitySelect));

      order.push({
        pointer: {
          category: "EntitySelect" as FormCategory,
          categoryIndex: entitySelect.length - 1,
        },
        error: false,
      });
      setOrder(Array.from(order));
    }
    if (formElementType === "Attachment") {
      attachment.push({
        elementType: "Attachment" as AttachmentElementType,
        required: false,
        label: "",
      });
      setAttachment(Array.from(attachment));

      order.push({
        pointer: {
          category: "Attachment" as FormCategory,
          categoryIndex: attachment.length - 1,
        },
        error: false,
      });
      setOrder(Array.from(order));
    }
  }

  function reindexOrderArray(
    order: { pointer: FormCategoryPointer; error: boolean }[],
    type: FormCategory,
    index: number
  ) {
    order.map(function (o) {
      if (o.pointer.category === type && o.pointer.categoryIndex > index) {
        o.pointer.categoryIndex = o.pointer.categoryIndex - 1;
      }
    });
  }

  function removeComponent(e: React.MouseEvent<HTMLButtonElement>) {
    const indexString = e.currentTarget.id;
    const index = parseInt(indexString);

    const orderComponent = order[index];
    if (orderComponent.pointer.category === "Text") {
      reindexOrderArray(order, "Text", orderComponent.pointer.categoryIndex);
      text.splice(orderComponent.pointer.categoryIndex, 1);
      setText(Array.from(text));
    }
    if (orderComponent.pointer.category === "SimpleInput") {
      reindexOrderArray(
        order,
        "SimpleInput",
        orderComponent.pointer.categoryIndex
      );
      simpleInput.splice(orderComponent.pointer.categoryIndex, 1);
      setSimpleInput(Array.from(simpleInput));
    }
    if (orderComponent.pointer.category === "SimpleSelect") {
      reindexOrderArray(
        order,
        "SimpleSelect",
        orderComponent.pointer.categoryIndex
      );
      simpleSelect.splice(orderComponent.pointer.categoryIndex, 1);
      setSimpleSelect(Array.from(simpleSelect));
    }
    if (orderComponent.pointer.category === "EntitySelect") {
      reindexOrderArray(
        order,
        "EntitySelect",
        orderComponent.pointer.categoryIndex
      );
      entitySelect.splice(orderComponent.pointer.categoryIndex, 1);
      setEntitySelect(Array.from(entitySelect));
    }
    if (orderComponent.pointer.category === "Attachment") {
      reindexOrderArray(
        order,
        "Attachment",
        orderComponent.pointer.categoryIndex
      );
      attachment.splice(orderComponent.pointer.categoryIndex, 1);
      setAttachment(Array.from(attachment));
    }

    order.splice(index, 1);
    setOrder(Array.from(order));
  }

  function dragStartHandler(
    e: React.DragEvent<HTMLDivElement>,
    data: { pointer: FormCategoryPointer; error: boolean },
    index: number
  ) {
    e.dataTransfer.setData("text", JSON.stringify(data));
    e.dataTransfer.setData("number", index.toString());
  }

  function dropHandler(
    e: React.DragEvent<HTMLDivElement>,
    elementComponent: { pointer: FormCategoryPointer; error: boolean },
    currIndex: number
  ) {
    e.preventDefault();
    const data = e.dataTransfer.getData("text");
    const index = e.dataTransfer.getData("number");

    order[parseInt(index)] = elementComponent;
    order[currIndex] = JSON.parse(data);

    setOrder(Array.from(order));
  }

  function allowDrop(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
  }

  function addSimpleSelectOption(
    e: React.MouseEvent<HTMLButtonElement>,
    element: FormElementSimpleSelect
  ) {
    element.options.push({ value: "", description: "" });
    setSimpleSelect(Array.from(simpleSelect));
  }

  function removeSimpleSelectOption(
    e: React.MouseEvent<HTMLButtonElement>,
    element: FormElementSimpleSelect,
    idx: number
  ) {
    element.options.splice(idx, 1);
    setSimpleSelect(Array.from(simpleSelect));
  }

  function toggleMultiSelect(e: boolean, element: FormElementEntitySelect) {
    element.multiSelect = e;
    setEntitySelect(Array.from(entitySelect));
  }

  function toggleSimpleInputRequired(
    e: boolean,
    element: FormElementSimpleInput
  ) {
    element.required = e;
    setSimpleInput(Array.from(simpleInput));
  }

  function toggleSimpleSelectRequired(
    e: boolean,
    element: FormElementSimpleSelect
  ) {
    element.required = e;
    setSimpleSelect(Array.from(simpleSelect));
  }

  function toggleEntitySelectRequired(
    e: boolean,
    element: FormElementEntitySelect
  ) {
    element.required = e;
    setEntitySelect(Array.from(entitySelect));
  }

  function toggleAttachmentRequired(
    e: boolean,
    element: FormElementAttachment
  ) {
    element.required = e;
    setAttachment(Array.from(attachment));
  }

  function handleForm() {
    setShowErrorBanner(false);

    for (let i = 0; i < order.length; i++) {
      const orderComponent = order[i];

      if (orderComponent.pointer.category === "Text") {
        const element = text[orderComponent.pointer.categoryIndex];
        orderComponent.error = element.text.trim() === "";
      }
      if (orderComponent.pointer.category === "SimpleInput") {
        const element = simpleInput[orderComponent.pointer.categoryIndex];
        orderComponent.error = element.label.trim() === "";
      }
      if (orderComponent.pointer.category === "SimpleSelect") {
        const element = simpleSelect[orderComponent.pointer.categoryIndex];
        orderComponent.error =
          element.label.trim() === "" || element.options.length === 0;
      }
      if (orderComponent.pointer.category === "EntitySelect") {
        const element = entitySelect[orderComponent.pointer.categoryIndex];
        orderComponent.error = element.label.trim() === "";
      }
      if (orderComponent.pointer.category === "Attachment") {
        const element = attachment[orderComponent.pointer.categoryIndex];
        orderComponent.error = element.label.trim() === "";
      }

      setOrder(Array.from(order));
    }

    let isValid = true;

    setFormTitleError(false);
    if (title.trim() === "") {
      isValid = false;
      setFormTitleError(true);
    }

    setFormVersionError(false);
    if (!formMajorVersion || !formMinorVersion) {
      isValid = false;
    }

    const newVersion = formMajorVersion! + formMinorVersion! / 10;
    const currentVersion = form!.majorVersion + form!.minorVersion / 10;

    if (newVersion === currentVersion) {
      setFormVersionError(true);
      isValid = false;
    }

    if (formOrderContainsError(order)) {
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    addForm({
      title: title,
      majorVersion: formMajorVersion!,
      minorVersion: formMinorVersion!,
      order: order.map((o) => o.pointer),
      texts: text,
      simpleInputs: simpleInput,
      simpleSelects: simpleSelect,
      entitySelects: entitySelect,
      attachments: attachment,
    })
      .then((form) => {
        updateFormArchive(formID).catch((error) => console.log(error));
        navigate(`/regulator/forms/${form.id}`);
      })
      .catch((error) => {
        setErrorBannerText(formatErrorMessage(error));
        setShowErrorBanner(true);
      });
  }

  return (
    <div>
      <ErrorBanner
        showError={showErrorBanner}
        setShowError={setShowErrorBanner}
        errorText={errorBannerText}
      />
      <div className="mt-2 -mb-6">
        <div className="flex justify-end">
          <div hidden={form.status === "Archived"}>
            <button
              type="button"
              onClick={() => setArchiveModalOpen(true)}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <ArchiveIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              Archive
              <FormArchiveModal
                open={archiveModalOpen}
                setOpen={setArchiveModalOpen}
                formID={formID}
                mutateForm={mutateForm}
              />
            </button>
          </div>
        </div>
      </div>
      <div className="mt-3 py-1">
        <label
          htmlFor={"form-title"}
          className="block text-sm font-medium text-gray-900"
        >
          Form Title
        </label>
        <div className="mt-1">
          <input
            type="text"
            name={"form-title"}
            id={"form-title"}
            className={
              formTitleError
                ? "shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-red-300 rounded-md"
                : "shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
            }
            placeholder="Enter form title here"
            defaultValue={form.title}
            onChange={(e) => setTitle(e.currentTarget.value)}
          />
        </div>
        <p className="mt-2 text-sm text-red-500" hidden={!formTitleError}>
          * Form Title is required
        </p>
      </div>
      <div className="sm:grid sm:grid-cols-6 sm:gap-4 sm:items-start sm:pt-5">
        <label
          htmlFor="formVersion"
          className="ml-2 block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
        >
          Form Version
        </label>
        <div className="max-w-lg flex rounded-md col-span-3 col-start-3">
          <p className="font-medium text-gray-600 px-4 mt-2">{`${formMajorVersion}.${formMinorVersion}`}</p>
          <div className="inline-flex w-32">
            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm shadow-sm">
              Major
            </span>
            <input
              type="number"
              step="1"
              min={formMajorVersion}
              name="formMajorVersion"
              id="formMajorVersion"
              defaultValue={formMajorVersion}
              onChange={(e) =>
                setFormMajorVersion(parseInt(e.currentTarget.value))
              }
              className={classNames(
                "flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md sm:text-sm border",
                "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
              )}
            />
          </div>
          <div className="inline-flex w-36">
            <span className="ml-4 inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm shadow-sm">
              Minor
            </span>
            <input
              type="number"
              step="1"
              min={formMinorVersion}
              name="formMinorVersion"
              id="formMinorVersion"
              defaultValue={formMinorVersion}
              onChange={(e) =>
                setFormMinorVersion(parseInt(e.currentTarget.value))
              }
              className={classNames(
                "flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md sm:text-sm border",
                "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
              )}
            />
          </div>
        </div>
        <p
          className="-mt-2 text-sm text-red-500 col-start-3 col-span-4"
          hidden={!formVersionError}
        >
          * Form version must not be equal to the previous version.
        </p>
      </div>

      <div className="mt-3 grid grid-cols-5 gap-2 bg-white rounded-md shadow-md">
        <div
          className="m-2 bg-white rounded-md border border-blue-600"
          aria-label="Sidebar"
        >
          {formElements.map((item) => (
            <button
              key={item.name}
              id={item.name}
              onClick={addComponent}
              className="w-full flex items-center px-3 py-2 text-sm font-medium rounded-md text-blue-600 hover:bg-blue-50 hover:text-blue-900"
            >
              <span className="truncate">{item.name}</span>
            </button>
          ))}
        </div>

        <div className="relative m-2 col-span-4 rounded-md border bg-gray-50">
          <p
            className="mt-40 text-gray-900 font-medium text-center"
            hidden={order.length !== 0}
          >
            No form components selected
          </p>
          <div>
            {order.map((orderComponent, orderComponentIndex) => {
              if (orderComponent.pointer.category === "Text") {
                const element = text[orderComponent.pointer.categoryIndex];

                return (
                  <div key={uuid()}>
                    <TextFormBuilderComponent
                      orderComponent={orderComponent}
                      orderComponentIndex={orderComponentIndex}
                      allowDrop={allowDrop}
                      dropHandler={dropHandler}
                      dragStartHandler={dragStartHandler}
                      removeComponent={removeComponent}
                      element={element}
                    />
                  </div>
                );
              }
              if (orderComponent.pointer.category === "SimpleInput") {
                const element =
                  simpleInput[orderComponent.pointer.categoryIndex];
                return (
                  <div key={uuid()}>
                    <SimpleInputFormBuilderComponent
                      orderComponent={orderComponent}
                      orderComponentIndex={orderComponentIndex}
                      allowDrop={allowDrop}
                      dropHandler={dropHandler}
                      dragStartHandler={dragStartHandler}
                      removeComponent={removeComponent}
                      element={element}
                      toggleSimpleInputRequired={toggleSimpleInputRequired}
                    />
                  </div>
                );
              }
              if (orderComponent.pointer.category === "SimpleSelect") {
                const element =
                  simpleSelect[orderComponent.pointer.categoryIndex];
                return (
                  <div key={uuid()}>
                    <SimpleSelectFormBuilderComponent
                      orderComponent={orderComponent}
                      orderComponentIndex={orderComponentIndex}
                      allowDrop={allowDrop}
                      dropHandler={dropHandler}
                      dragStartHandler={dragStartHandler}
                      removeComponent={removeComponent}
                      element={element}
                      toggleSimpleSelectRequired={toggleSimpleSelectRequired}
                      addSimpleSelectOption={addSimpleSelectOption}
                      removeSimpleSelectOption={removeSimpleSelectOption}
                    />
                  </div>
                );
              }
              if (orderComponent.pointer.category === "EntitySelect") {
                const element =
                  entitySelect[orderComponent.pointer.categoryIndex];
                return (
                  <div key={uuid()}>
                    <EntitySelectFormBuilderComponent
                      orderComponent={orderComponent}
                      orderComponentIndex={orderComponentIndex}
                      allowDrop={allowDrop}
                      dropHandler={dropHandler}
                      dragStartHandler={dragStartHandler}
                      removeComponent={removeComponent}
                      element={element}
                      toggleEntitySelectRequired={toggleEntitySelectRequired}
                      toggleMultiSelect={toggleMultiSelect}
                    />
                  </div>
                );
              }
              if (orderComponent.pointer.category === "Attachment") {
                const element =
                  attachment[orderComponent.pointer.categoryIndex];

                return (
                  <div key={uuid()}>
                    <AttachmentFormBuilderComponent
                      orderComponent={orderComponent}
                      orderComponentIndex={orderComponentIndex}
                      allowDrop={allowDrop}
                      dropHandler={dropHandler}
                      dragStartHandler={dragStartHandler}
                      removeComponent={removeComponent}
                      element={element}
                      toggleAttachmentRequired={toggleAttachmentRequired}
                    />
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
      <PreviewModal
        open={open}
        setOpen={setOpen}
        title={form.title}
        order={order}
        text={text}
        simpleInput={simpleInput}
        simpleSelect={simpleSelect}
        entitySelect={entitySelect}
        attachment={attachment}
      />
      <div className="mt-2 flex justify-end">
        <button
          type="button"
          onClick={() => setCancelModalOpen(true)}
          hidden={order.length === 0}
          className="mx-1 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Cancel
          <CancelModal open={cancelModalOpen} setOpen={setCancelModalOpen} />
        </button>
        <button
          type="button"
          onClick={() => setOpen(true)}
          hidden={order.length === 0}
          className="mx-1 bg-white py-2 px-4 border border-blue-600 rounded-md shadow-sm text-sm font-medium text-blue-600 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Preview
        </button>
        <button
          type="button"
          onClick={() => handleForm()}
          hidden={order.length === 0}
          className="mx-1 bg-blue-600 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Save
        </button>
      </div>
    </div>
  );
}
