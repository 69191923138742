import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import CheckoutForm from "./CheckoutForm";
import {
  Appearance,
  loadStripe,
  Stripe,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  useCurrentRegistration,
  useRegistrationPublishableKey,
} from "../common/DataFetchers";
import { Link, useNavigate } from "react-router-dom";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import { getFormattedAmount } from "../common/Utils";

export default function ReviewAndCheckoutSignUp() {
  const navigate = useNavigate();

  const [clientSecret, setClientSecret] = useState<string>();
  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);

  const {
    currentRegistration,
    currentRegistrationIsLoading,
    currentRegistrationIsError,
  } = useCurrentRegistration();
  const { publishableKey } = useRegistrationPublishableKey();

  useEffect(() => {
    if (!publishableKey) {
      return;
    }
    setStripePromise(loadStripe(publishableKey));
  }, [publishableKey]);

  useEffect(() => {
    if (!currentRegistration?.registration) {
      return;
    }

    if (currentRegistration.registration.subscription?.total === 0) {
      navigate("/signup/confirmation");
      return;
    }

    setClientSecret(
      currentRegistration.registration?.subscription?.clientSecret
    );
  }, [currentRegistration]);

  if (currentRegistrationIsLoading || !clientSecret) {
    return <LoadingComponent />;
  }

  if (currentRegistrationIsError || !currentRegistration) {
    return <ErrorComponent />;
  }

  const appearance: Appearance = {
    theme: "stripe",
  };

  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  };

  return (
    <div className="flex h-screen bg-gradient-to-b from-blue-300 to-blue-600">
      <div className="max-w-7xl m-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="relative rounded-md bg-white shadow-xl">
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div
              id="prices"
              className="px-36 flex flex-wrap justify-center col-span-2"
            >
              <div id="payment-form" className="flex justify-center">
                <div className="w-full inline-block p-4 rounded-md">
                  <div className="text-center text-pasha font-bold text-2xl mt-4 mb-6">
                    Review and Checkout
                  </div>
                  <p className="text-sm text-gray-600">
                    By clicking, "Subscribe" you are agreeing to the{" "}
                    <Link
                      to="/terms-of-service"
                      target="_blank"
                      rel="noreferrer"
                      className="font-medium text-blue-600"
                    >
                      Terms of Service
                    </Link>{" "}
                    outlined by Decoto Labs, LLC.
                  </p>
                  <div className="mt-2 font-bold text-xl mb-2">
                    Enter your card details. <br />
                    Your subscription will start now.
                  </div>
                  <p className="text-gray-700 text-base mb-2">
                    → Subscription: <br />
                    <span id="description">
                      {currentRegistration.registration?.subscription?.items.map(
                        (item) => {
                          return (
                            <>
                              {item.description}:{" "}
                              {getFormattedAmount(item.amount)}
                              <br />
                            </>
                          );
                        }
                      )}
                    </span>
                  </p>
                  <p className="text-gray-700 text-base mb-2 mt-2">
                    → Total Due Now:{" "}
                    <span id="total-due-now">
                      {getFormattedAmount(
                        currentRegistration!.registration!.subscription!.total
                      )}
                    </span>
                  </p>
                  <div className="w-full">
                    <div id="payment-form">
                      <div className="flex flex-wrap -mx-3 mb-3">
                        <div className="w-full px-3 mb-0">
                          <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-first-name"
                          >
                            Payment
                          </label>
                          <div
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded-md py-3 px-2 leading-tight focus:outline-none focus:bg-white"
                            id="card-element"
                          >
                            <Elements stripe={stripePromise} options={options}>
                              <CheckoutForm />
                            </Elements>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white px-4 py-3 flex items-center justify-between rounded-b-md border-t border-gray-200 sm:px-6">
            <div className="-mt-4 w-0 flex-1 flex">
              <button
                type="button"
                onClick={() => navigate("/signup/plan")}
                className="pt-4 pr-1 inline-flex items-center text-sm font-medium text-blue-500 hover:text-blue-700"
              >
                <ArrowNarrowLeftIcon
                  className="mr-3 h-5 w-5 text-blue-400"
                  aria-hidden="true"
                />
                Previous
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
