import { Link } from "react-router-dom";

export default function NotFoundFeedbackPage() {
  return (
    <>
      <div className="min-h-full pt-16 pb-12 flex flex-col bg-white">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 flex justify-center">
            <Link to="/home" className="inline-flex">
              <span className="sr-only">Regulator</span>
              <img
                className="h-12 w-auto"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/decoto-labs_logo-full_color-b.svg"
                }
                alt=""
              />
            </Link>
          </div>
          <div className="py-16">
            <div className="text-center">
              <p className="text-sm font-semibold text-blue-600 uppercase tracking-wide">
                404 error
              </p>
              <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                Not Found.
              </h1>
              <p className="mt-2 text-base text-gray-500">
                Please check the URL in the address bar and try again.
              </p>
              <div className="mt-6">
                <div>
                  <Link
                    to="/regulator/home"
                    className="text-base font-medium text-blue-600 hover:text-blue-500"
                  >
                    Go back to Regulator Home
                    <span aria-hidden="true"> &rarr;</span>
                  </Link>
                </div>
                <div>
                  <Link
                    to="/"
                    className="text-base font-medium text-blue-600 hover:text-blue-500"
                  >
                    Go back to Decoto Labs Home
                    <span aria-hidden="true"> &rarr;</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
