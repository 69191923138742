import { RadioGroup } from "@headlessui/react";
import { classNames } from "./Utils";

interface RadioProps {
  options: { display: string; value: any; description: string }[];
  selected: { display: string; value: any; description: string };
  setSelected: (selected: {
    display: string;
    value: any;
    description: string;
  }) => void;
}

export default function CustomRadioGroup(props: RadioProps) {
  return (
    <RadioGroup value={props.selected} onChange={props.setSelected}>
      <RadioGroup.Label className="sr-only">Privacy setting</RadioGroup.Label>
      <div className="bg-white rounded-md -space-y-px">
        {props.options.map((option, optionIdx) => (
          <RadioGroup.Option
            key={option.display}
            value={option}
            className={({ checked }) =>
              classNames(
                optionIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                optionIdx === props.options.length - 1
                  ? "rounded-bl-md rounded-br-md"
                  : "",
                props.selected.display === option.display
                  ? "bg-blue-50 border-blue-200 z-0"
                  : "border-gray-200",
                "relative border p-4 flex cursor-pointer focus:outline-none"
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    props.selected.display === option.display
                      ? "bg-blue-600 border-transparent"
                      : "bg-white border-gray-300",
                    active ? "ring-2 ring-offset-2 ring-blue-500" : "",
                    "h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center"
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <div className="ml-3 flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className={classNames(
                      props.selected.display === option.display
                        ? "text-blue-900"
                        : "text-gray-900",
                      "block text-sm font-medium"
                    )}
                  >
                    {option.display}
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as="span"
                    className={classNames(
                      props.selected.display === option.display
                        ? "text-blue-700"
                        : "text-gray-500",
                      "block text-sm"
                    )}
                  >
                    {option.description}
                  </RadioGroup.Description>
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
