export const REGULATOR_BOT = "RegulatorBot";

export const taskTypeToDisplayMap = new Map<string, string>([
  ["SOPDraftReview", "SOP Draft Approval"],
  ["ProcedureManualReview", "Procedure Manual Review"],
  ["EquipmentCalibration", "Equipment Calibration Due"],
  ["ReagentExpiration", "Upcoming Reagent Expiration"],
  ["CompetencyExpiration", "Upcoming Competency Expiration"],
  ["CompetencyReview", "Competency Review"],
]);

export const priceInfo = [
  {
    plan: "full",
    frequency: "monthly",
    priceID: process.env.REACT_APP_STRIPE_FULL_MONTHLY as string,
    amount: "150",
    currency: "USD",
  },
  {
    plan: "full",
    frequency: "annually",
    priceID: process.env.REACT_APP_STRIPE_FULL_ANNUAL as string,
    amount: "1500",
    currency: "USD",
  },
];
