import React, { useEffect, useState } from "react";
import FileUploadComponent from "../common/FileUploadComponent";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  containsLaboratoryDirector,
  formatErrorMessage,
} from "../common/Utils";
import CustomRadioGroup from "../common/CustomRadioGroup";
import {
  addSOP,
  usePersonnelSummaries,
  useSOP,
  useSOPCategory,
} from "../common/DataFetchers";
import { useUserContext } from "../common/BasePageScreen";
import ApproverSection, { ApproverPersonnel } from "./ApproverSection";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import ErrorBanner from "../common/ErrorBanner";

const options = [
  {
    display: "Minor Version Change",
    value: "minor",
    description:
      "Version changes that do not represent a change in " +
      "the underlying methods or procedures.",
  },
  {
    display: "Major Version Change",
    value: "major",
    description:
      "Version changes that involve changes in methodology " +
      "that could influence the resulting data values.",
  },
];

export default function UpdateSOPForm() {
  const { sopsRead, sopsWrite } = useUserContext();
  const navigate = useNavigate();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const params = useParams<{ id: string }>();
  const sopID = params.id;

  const { sop, sopIsLoading, sopIsError } = useSOP(sopID, sopsRead);
  const { personnelSummaries } = usePersonnelSummaries(sopsWrite);
  const { sopCategory } = useSOPCategory(sop ? sop.categoryID : null, sopsRead);

  const [approvers, setApprovers] = useState(
    new Map<string, ApproverPersonnel>()
  );
  const [additionalApprovers, setAdditionalApprovers] = useState(
    new Map<string, ApproverPersonnel>()
  );
  const [selectedApproverIDs, setSelectedApproverIDs] = useState(
    new Set<string>()
  );

  useEffect(() => {
    if (!sopCategory) {
      return;
    }

    const approverRoleIDs = new Set<string>();
    sopCategory.approverRoles.forEach((approverRole) =>
      approverRoleIDs.add(approverRole.id)
    );

    const updatedApprovers = new Map<string, ApproverPersonnel>();
    const updatedAdditionalApprovers = new Map<string, ApproverPersonnel>();

    personnelSummaries.forEach((personnel) => {
      const roleID = personnel.role.id;
      const targetBucket = approverRoleIDs.has(roleID)
        ? updatedApprovers
        : updatedAdditionalApprovers;

      if (!targetBucket.has(roleID)) {
        targetBucket.set(roleID, {
          roleName: personnel.role.name,
          personnel: [],
        });
      }

      targetBucket.get(roleID)?.personnel.push(personnel);
    });

    setApprovers(updatedApprovers);
    setAdditionalApprovers(updatedAdditionalApprovers);
  }, [sopCategory, personnelSummaries]);

  const [versionChange, setVersionChange] = useState(options[0]);

  const [files] = useState([]);
  const [uploadError, setUploadError] = useState(false);
  const [approvalError, setApprovalError] = useState(false);
  const [labDirectorApprovalError, setLabDirectorApprovalError] =
    useState(false);

  if (!sopsWrite || !sopsRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (sopIsLoading) {
    return <LoadingComponent />;
  }

  if (!sopCategory || !sop || sopIsError) {
    return <ErrorComponent />;
  }

  if (sop.state === "Draft") {
    navigate(`/regulator/sops/${sop.id}/draft-edit`);
    return <></>;
  }

  const majorVersion =
    versionChange.value === "major" ? sop.majorVersion + 1 : sop.majorVersion;
  const minorVersion =
    versionChange.value === "minor" ? sop.minorVersion + 1 : sop.minorVersion;

  return (
    <form
      className="mt-3 px-3 space-y-8 divide-y divide-gray-200"
      onSubmit={(e) => {
        e.preventDefault();

        setShowErrorBanner(false);

        let isValid = true;

        setUploadError(false);
        if (files.length !== 1) {
          setUploadError(true);
          isValid = false;
        }

        setApprovalError(false);
        if (approvers.size === 0) {
          setApprovalError(true);
          isValid = false;
        }

        setLabDirectorApprovalError(false);
        if (
          !containsLaboratoryDirector(selectedApproverIDs, personnelSummaries)
        ) {
          setLabDirectorApprovalError(true);
          isValid = false;
        }

        if (!isValid || !sopsWrite) {
          return;
        }

        addSOP(
          sop.categoryID,
          sop.displayID.slice(3),
          sop.title,
          files[0],
          majorVersion,
          minorVersion,
          Array.from(selectedApproverIDs)
        )
          .then((addedSOP) =>
            navigate(`/regulator/sops/${addedSOP.id}/draft-review`)
          )
          .catch((error) => {
            setErrorBannerText(formatErrorMessage(error));
            setShowErrorBanner(true);
          });
      }}
    >
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <ErrorBanner
            showError={showErrorBanner}
            setShowError={setShowErrorBanner}
            errorText={errorBannerText}
          />
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              SOP Information
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Fill in required details to update this SOP.
            </p>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="sopCategory"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                SOP Category
              </label>
              <p className="mt-2 font-medium text-sm text-gray-900">
                {sopCategory.name}
              </p>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="sopId"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                SOP ID
              </label>
              <p className="mt-2 font-medium text-sm text-gray-900">
                {sop.displayID}
              </p>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="sopTitle"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                SOP Title
              </label>
              <p className="mt-2 font-medium text-sm text-gray-900">
                {sop.title}
              </p>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="sopVersion"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                SOP Version
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <p className="mt-2 font-medium text-sm text-gray-900 pb-3">
                  {`${majorVersion}.${minorVersion}`}
                </p>
                <CustomRadioGroup
                  options={options}
                  selected={versionChange}
                  setSelected={setVersionChange}
                />
              </div>
            </div>

            <FileUploadComponent
              label={"SOP File"}
              fileTypes={"PDF"}
              files={files}
            />
            <p
              className="-ml-20 text-center text-sm text-red-500"
              hidden={!uploadError}
            >
              * One file must be uploaded to complete update.
            </p>
            <ApproverSection
              approvalError={approvalError}
              labDirectorApprovalError={labDirectorApprovalError}
              approvers={approvers}
              additionalApprovers={additionalApprovers}
              selectedApproverIDs={selectedApproverIDs}
              setSelectedApproverIDs={setSelectedApproverIDs}
            />
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <Link to={`/regulator/sops/${sop.id}`}>
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
