import React, { useEffect, useState } from "react";
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import { classNames, formatErrorMessage } from "../common/Utils";
import { Switch } from "@headlessui/react";
import {
  updateRegistrationSubscription,
  useCurrentRegistration,
} from "../common/DataFetchers";
import { priceInfo } from "../common/Constants.d";
import ErrorBanner from "../common/ErrorBanner";

export default function SelectPlanSignUp() {
  const navigate = useNavigate();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const { currentRegistration } = useCurrentRegistration();

  const [annualBilling, setAnnualBilling] = useState(false);

  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    if (
      !currentRegistration ||
      !currentRegistration.registration?.subscription
    ) {
      return;
    }

    const infoIndex = priceInfo.findIndex(
      (info) =>
        info.priceID === currentRegistration.registration?.subscription?.priceID
    );
    const info = priceInfo[infoIndex];
    setAnnualBilling(info.frequency === "annually");
    setQuantity(currentRegistration.registration.subscription.quantity);
  }, [currentRegistration]);

  function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault();

    setShowErrorBanner(false);

    const priceID = annualBilling ? priceInfo[1].priceID : priceInfo[0].priceID;

    updateRegistrationSubscription({
      priceID: priceID,
      quantity: quantity,
    })
      .then(() => navigate("/signup/review"))
      .catch((error) => {
        setErrorBannerText(formatErrorMessage(error));
        setShowErrorBanner(true);
      });
  }

  return (
    <div className="flex h-screen bg-gradient-to-b from-blue-300 to-blue-600">
      <div className="max-w-7xl m-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <form onSubmit={handleSubmit}>
          <div className="relative rounded-md bg-white shadow-xl">
            <div className="grid grid-cols-1 lg:grid-cols-2">
              <div
                id="prices"
                className="px-36 flex flex-wrap justify-center col-span-2"
              >
                <div className="mt-2">
                  <ErrorBanner
                    showError={showErrorBanner}
                    setShowError={setShowErrorBanner}
                    errorText={errorBannerText}
                  />
                </div>
                <div id="prices-form" className="w-full mx-auto md:mb-8">
                  <div className="text-center text-pasha font-bold text-2xl mt-4 mb-6">
                    Set Number of Users
                    <Switch.Group as="div" className="items-center">
                      <Switch
                        checked={annualBilling}
                        onChange={setAnnualBilling}
                        className={classNames(
                          annualBilling ? "bg-blue-600" : "bg-gray-200",
                          "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            annualBilling ? "translate-x-5" : "translate-x-0",
                            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                          )}
                        />
                      </Switch>
                      <Switch.Label as="span" className="ml-3">
                        <span className="text-sm font-medium text-gray-900">
                          Annual billing{" "}
                        </span>
                        <span className="text-sm text-gray-500">
                          (Get 2 months free)
                        </span>
                      </Switch.Label>
                    </Switch.Group>
                  </div>

                  <div className="flex justify-between mb-8">
                    <div
                      id="full"
                      className="w-full rounded border-2 border-blue-600 overflow-hidden rounded-md p-2"
                    >
                      <div className="px-2 py-2 text-center">
                        <div className="text-gray-500 text-xl mb-2 font-medium">
                          Regulator Full Plan
                        </div>
                        <p className="text-pasha text-2xl font-extrabold">
                          $
                          {annualBilling
                            ? priceInfo[1].amount
                            : priceInfo[0].amount}
                          .00
                        </p>
                        <div className="flex-wrap">
                          <div className="leading-5 text-gray-500 text-xs font-medium mt-1">
                            Billed {annualBilling ? " annually" : " monthly"}
                          </div>
                          <div className="leading-5 text-gray-500 text-xs font-medium mt-1">
                            $
                            {annualBilling
                              ? priceInfo[1].amount
                              : priceInfo[0].amount}{" "}
                            per seat
                          </div>
                          <div className="mb-2"></div>
                          <div className="flex justify-center mt-12">
                            <button
                              type="button"
                              onClick={() =>
                                setQuantity(Math.max(quantity - 1, 1))
                              }
                              className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                              -
                            </button>
                            <input
                              type="number"
                              className="shadow-sm mx-2 focus:ring-blue-500 focus:border-blue-500 block w-16 sm:text-sm border-gray-300 rounded-md"
                              id="quantity-input-starter"
                              min="1"
                              value={quantity}
                              onChange={(e) =>
                                setQuantity(
                                  Math.max(parseInt(e.currentTarget.value), 1)
                                )
                              }
                            />
                            <button
                              type="button"
                              onClick={() => setQuantity(quantity + 1)}
                              className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white px-4 py-3 flex items-center justify-between rounded-b-md border-t border-gray-200 sm:px-6">
              <div className="-mt-4 w-0 flex-1 flex">
                <button
                  type="button"
                  onClick={() => navigate("/signup/admin")}
                  className="pt-4 pr-1 inline-flex items-center text-sm font-medium text-blue-500 hover:text-blue-700"
                >
                  <ArrowNarrowLeftIcon
                    className="mr-3 h-5 w-5 text-blue-400"
                    aria-hidden="true"
                  />
                  Previous
                </button>
              </div>
              <div className="-mt-4 w-0 flex-1 flex justify-end">
                <button
                  type="submit"
                  className="pt-4 pl-1 inline-flex items-center text-sm font-medium text-blue-500 hover:text-blue-700"
                >
                  Next
                  <ArrowNarrowRightIcon
                    className="ml-3 h-5 w-5 text-blue-400"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
