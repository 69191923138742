import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CursorClickIcon } from "@heroicons/react/solid";
import { addUserInvitation } from "../common/DataFetchers";
import { DocumentDuplicateIcon } from "@heroicons/react/outline";
import ErrorBanner from "../common/ErrorBanner";
import { formatErrorMessage } from "../common/Utils";

interface GenerateInviteLinkModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  userID: string;
}

function assembleLink(inviteId: string) {
  return `${window.location.origin}/invite?id=${inviteId}`;
}

export default function GenerateInviteLinkModal(
  props: GenerateInviteLinkModalProps
) {
  const generateLinkRef = useRef(null);

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const [link, setLink] = useState("");
  const [copied, setCopied] = useState(false);

  const [showTimer, setShowTimer] = useState(false);

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (!showTimer) {
      return;
    }

    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  async function copyToClipboard(link: string) {
    navigator.clipboard
      .writeText(link)
      .then(() => setCopied(true))
      .catch((err) => {
        setErrorBannerText(formatErrorMessage(err));
        setShowErrorBanner(true);
      });
  }

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={generateLinkRef}
        onClose={(e) => {
          setLink("");
          setShowErrorBanner(false);
          props.setOpen(e);
          setShowTimer(false);
        }}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <ErrorBanner
                showError={showErrorBanner}
                setShowError={setShowErrorBanner}
                errorText={errorBannerText}
              />
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                  <CursorClickIcon
                    className="h-6 w-6 text-blue-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Generate Invitation Link
                  </Dialog.Title>
                  <div hidden={!showTimer}>
                    Link Expires:
                    <span className="ml-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                      {minutes}:{seconds < 10 ? `0${seconds}` : `${seconds}`}
                    </span>
                  </div>
                  <div className="mt-2">
                    {link === "" ? (
                      <p className="text-sm text-gray-500">
                        Click <b>Generate Link</b> below to create an invitation
                        link you can copy and distribute to the prospective
                        user.
                      </p>
                    ) : (
                      <p className="flex text-sm text-gray-700">
                        {link}
                        <button
                          type="button"
                          onClick={() => copyToClipboard(link)}
                        >
                          <DocumentDuplicateIcon
                            className="h-6 w-6 text-blue-600"
                            aria-hidden="true"
                          />
                        </button>
                        <span className="mt-2 px-1 text-sm text-blue-600">
                          {copied ? "Copied!" : "Copy"}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm"
                  onClick={() => {
                    addUserInvitation({ userID: props.userID })
                      .then((result) => {
                        const linkText = assembleLink(result.id);
                        setLink(linkText);
                        setMinutes(30);
                        setSeconds(0);
                        setShowTimer(true);
                      })
                      .catch((error) => {
                        setErrorBannerText(formatErrorMessage(error));
                        setShowErrorBanner(true);
                      });
                  }}
                  ref={generateLinkRef}
                >
                  Generate Link
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => {
                    setLink("");
                    setShowErrorBanner(false);
                    props.setOpen(false);
                    setShowTimer(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
