import { XIcon } from "@heroicons/react/solid";
import React from "react";
import { FormCategoryPointer, FormElementText } from "../../common/Entities.d";

export default function TextFormBuilderComponent(props: {
  orderComponent: { error: boolean; pointer: FormCategoryPointer };
  orderComponentIndex: number;
  allowDrop: (e: React.DragEvent<HTMLDivElement>) => void;
  dropHandler: (
    e: React.DragEvent<HTMLDivElement>,
    orderComponent: { error: boolean; pointer: FormCategoryPointer },
    orderComponentIndex: number
  ) => void;
  dragStartHandler: (
    e: React.DragEvent<HTMLDivElement>,
    orderComponent: { error: boolean; pointer: FormCategoryPointer },
    orderComponentIndex: number
  ) => void;
  removeComponent: (e: React.MouseEvent<HTMLButtonElement>) => void;
  element: FormElementText;
}) {
  return (
    <div
      className="p-3 mt-3 bg-blue-50 border rounded-md shadow-md"
      id={props.orderComponent.pointer.category}
      onDragOver={props.allowDrop}
      onDrop={(e) =>
        props.dropHandler(e, props.orderComponent, props.orderComponentIndex)
      }
      onDragStart={(e) =>
        props.dragStartHandler(
          e,
          props.orderComponent,
          props.orderComponentIndex
        )
      }
      draggable
    >
      <div className="text-right">
        <button
          id={props.orderComponentIndex.toString()}
          type="button"
          onClick={props.removeComponent}
          className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          <XIcon className="h-4 w-4" aria-hidden="true" />
        </button>
      </div>
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        {props.element.elementType}
      </h3>
      <div className="py-1">
        <label
          htmlFor={`text-${props.orderComponentIndex.toString()}`}
          className="block text-sm font-medium text-gray-700"
        >
          Text
        </label>
        <div className="mt-1">
          <input
            type="text"
            name={`text-${props.orderComponentIndex.toString()}`}
            id={`text-${props.orderComponentIndex.toString()}`}
            className={
              props.orderComponent.error
                ? "shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-red-300 rounded-md"
                : "shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
            }
            placeholder={"Enter text here"}
            key={props.element.text}
            defaultValue={props.element.text}
            onChange={(e) => (props.element.text = e.currentTarget.value)}
          />
          <p
            className="mt-2 text-sm text-red-500"
            hidden={!props.orderComponent.error}
          >
            * Text is required
          </p>
        </div>
      </div>
    </div>
  );
}
