import { classNames, formatErrorMessage } from "../common/Utils";
import React, { useState } from "react";
import { logout, updateUserPassword } from "../common/DataFetchers";
import { useUserContext } from "../common/BasePageScreen";
import { useNavigate } from "react-router-dom";
import ErrorBanner from "../common/ErrorBanner";
import SuccessBanner from "../common/SuccessBanner";

export default function PasswordCard() {
  const navigate = useNavigate();
  const { user } = useUserContext();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      <ErrorBanner
        showError={showErrorBanner}
        setShowError={setShowErrorBanner}
        errorText={errorBannerText}
      />
      <section aria-labelledby="payment-details-heading">
        <form
          onSubmit={(e: React.SyntheticEvent) => {
            e.preventDefault();

            setShowErrorBanner(false);

            const target = e.target as typeof e.target & {
              "current-password": { value: string };
              "new-password": { value: string };
              "confirm-password": { value: string };
            };

            const currentPassword = target["current-password"].value.trim();
            const newPassword = target["new-password"].value.trim();
            const confirmPassword = target["confirm-password"].value.trim();

            let isValid = true;

            setNewPasswordError(false);
            if (
              newPassword === "" ||
              newPassword.length < 8 ||
              newPassword.length > 64
            ) {
              setNewPasswordError(true);
              isValid = false;
            }

            setConfirmPasswordError(false);
            if (newPassword !== confirmPassword) {
              setConfirmPasswordError(true);
              isValid = false;
            }

            if (!isValid) {
              return;
            }

            updateUserPassword(user.id, {
              currentPassword: currentPassword,
              //newPassword: newPassword,
              newPassword: "",
            })
              .then(() => logout().then(() => navigate("/regulator/login")))
              .catch((error) => {
                setErrorBannerText(formatErrorMessage(error));
                setShowErrorBanner(true);
              });
          }}
        >
          <div className="mt-2 sm:rounded-md sm:border sm:border-gray-200 sm:overflow-hidden">
            <div className="bg-white py-6 px-4 sm:p-6">
              <div>
                <h2
                  id="payment-details-heading"
                  className="text-lg leading-6 font-medium text-gray-900"
                >
                  Password
                </h2>
                <p className="mt-1 text-sm text-gray-500">
                  Change your password. If you do not remember your current
                  password, reach out to your system administrator to receive a
                  password reset link. Note: you will be logged out upon
                  password change.
                </p>
              </div>

              <div className="mt-6 grid grid-cols-4 gap-6">
                <div className="col-start-2 col-span-2">
                  <label
                    htmlFor="current-password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Current Password
                  </label>
                  <input
                    type="password"
                    name="current-password"
                    id="current-password"
                    className={classNames(
                      "mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3",
                      "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                    )}
                  />
                </div>

                <div className="col-start-2 col-span-2">
                  <label
                    htmlFor="new-password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    New Password
                  </label>
                  <input
                    type="password"
                    name="new-password"
                    id="new-password"
                    className={classNames(
                      "mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3",
                      newPasswordError
                        ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                        : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                    )}
                  />
                  <p
                    className="mt-2 text-sm text-red-500"
                    hidden={!newPasswordError}
                  >
                    * New Password length must be 8-64 characters.
                  </p>
                </div>

                <div className="col-start-2 col-span-2">
                  <label
                    htmlFor="confirm-password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    name="confirm-password"
                    id="confirm-password"
                    className={classNames(
                      "mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3",
                      "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                    )}
                  />
                  <p
                    className="mt-2 text-sm text-red-500"
                    hidden={!confirmPasswordError}
                  >
                    * Passwords do not match.
                  </p>
                </div>
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button
                type="submit"
                className="bg-blue-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}
