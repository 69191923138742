import { useEffect, useState } from "react";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { useRegistrationPublishableKey } from "./DataFetchers";
import { Elements } from "@stripe/react-stripe-js";

interface StripeElementWrapperProps {
  component: JSX.Element;
}

export default function StripeElementWrapper(props: StripeElementWrapperProps) {
  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);

  const { publishableKey } = useRegistrationPublishableKey();

  useEffect(() => {
    if (!publishableKey) {
      return;
    }
    setStripePromise(loadStripe(publishableKey));
  }, [publishableKey]);

  return <Elements stripe={stripePromise}>{props.component}</Elements>;
}
