import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ClipboardCheckIcon,
  DotsCircleHorizontalIcon,
  MailIcon,
  PaperClipIcon,
  PencilAltIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import { useUserContext } from "../common/BasePageScreen";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  competencyAttachmentDownloadLink,
  competencyAuditPath,
  updateCompetencyReview,
  useCompetency,
  useCompetencyApprovals,
} from "../common/DataFetchers";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import { getDate, getFullName, getTime } from "../common/Utils";
import * as React from "react";
import { DocumentTextIcon } from "@heroicons/react/outline";
import AuditTrailCard from "../common/AuditTrailCard";
import { useState } from "react";
import CompetencyDiscardModal from "./CompetencyDiscardModal";
import CompetencyApprovalModal from "./CompetencyApprovalModal";

const fieldDisplayMap = new Map([
  ["records", "Records"],
  ["links", "Links"],
  ["workflowRuns", "Workflow Runs"],
  ["attachments", "Attachments"],
]);

export default function CompetencyDetailCard() {
  const { user, competenciesRead, competenciesWrite } = useUserContext();
  const navigate = useNavigate();

  const params = useParams<{ id: string }>();
  const competencyID = params.id as string;

  const {
    competency,
    competencyIsLoading,
    competencyIsError,
    mutateCompetency,
  } = useCompetency(competencyID, competenciesRead);

  const { approvals, mutateApprovals } = useCompetencyApprovals(
    competency?.id,
    competenciesRead
  );

  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  const [approvalModalOpen, setApprovalModalOpen] = useState(false);
  const [hideSignatures, setHideSignatures] = useState(true);

  if (!competenciesRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (competencyIsLoading) {
    return <LoadingComponent />;
  }

  if (competencyIsError || !competency) {
    return <ErrorComponent />;
  }

  const pages = [
    { name: "Training & Competency", path: "/regulator/competency" },
    {
      name: `${competency.type.name} - ${getFullName(
        competency.personnel.firstName,
        competency.personnel.lastName
      )}`,
      path: `/regulator/competency/${competencyID}`,
    },
  ];

  const canSendForReview = competency.status === "Pending" && competenciesWrite;
  const canDiscard = competenciesWrite && competency.state === "Pending";
  const canEdit = competenciesWrite && competency.state === "Pending";

  return (
    <div>
      <FullWidthBreadcrumbBar pages={pages} />
      <div className="py-3 pt-5">
        <div className="flex justify-end">
          {canSendForReview && (
            <button
              type="button"
              onClick={() =>
                mutateCompetency(updateCompetencyReview(competency?.id), false)
                  .then(() => mutateApprovals())
                  .catch((error) => console.log(error))
              }
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <MailIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              Send for Review
            </button>
          )}
          {canEdit && (
            <Link
              to={`/regulator/competency/${competencyID}/edit`}
              hidden={!competenciesWrite}
            >
              <button
                type="button"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <PencilAltIcon
                  className="-ml-1 mr-3 h-5 w-5"
                  aria-hidden="true"
                />
                Edit
              </button>
            </Link>
          )}
          {canDiscard && (
            <button
              type="button"
              onClick={() => setDiscardModalOpen(true)}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <TrashIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              Discard
              <CompetencyDiscardModal
                open={discardModalOpen}
                setOpen={setDiscardModalOpen}
                competency={competency}
              />
            </button>
          )}
        </div>
      </div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Competency Information
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Details and records associated with competency
          </p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Competency Type
              </dt>
              <Link
                to={`/regulator/competency/type/${competency.type.id}`}
                target="_blank"
                rel="noreferrer"
              >
                <dd className="mt-1 text-sm text-blue-600 font-medium sm:mt-0 sm:col-span-2">
                  {competency.type.name}
                </dd>
              </Link>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Personnel</dt>
              <Link
                to={`/regulator/personnel/${competency.personnel.id}`}
                target="_blank"
                rel="noreferrer"
              >
                <dd className="mt-1 text-sm text-blue-600 font-medium sm:mt-0 sm:col-span-2">
                  {getFullName(
                    competency.personnel.firstName,
                    competency.personnel.lastName
                  )}
                </dd>
              </Link>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Version</dt>
              <dd className="mt-1 text-sm text-blue-600 font-medium sm:mt-0 sm:col-span-2">
                {competency.version === 0 ? (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                    Initial
                  </span>
                ) : competency.version === 1 ? (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                    6-Month
                  </span>
                ) : (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                    Annual
                  </span>
                )}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Records</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <ul
                  role="list"
                  className="border border-gray-200 rounded-md divide-y divide-gray-200"
                >
                  {competency.records.length === 0 ? (
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm italic">
                      None
                    </li>
                  ) : (
                    competency.records.map((record) => (
                      <li
                        key={record.id}
                        className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                      >
                        <div className="w-0 flex-1 flex items-center">
                          <DocumentTextIcon
                            className="flex-shrink-0 h-5 w-5 text-blue-600"
                            aria-hidden="true"
                          />
                          <span className="ml-2 flex-1 w-0 truncate">{`${
                            record.form.title
                          } - ${getFullName(
                            record.authorUser.firstName,
                            record.authorUser.lastName
                          )} ${getDate(record.completed)} ${getTime(
                            record.completed
                          )}
                        
                        `}</span>
                        </div>
                        <div className="ml-4 flex-shrink-0">
                          <Link
                            to={`/regulator/records/${record.id}`}
                            target="_blank"
                            rel="noreferrer"
                            className="font-medium text-blue-600 hover:text-blue-500"
                          >
                            View
                          </Link>
                        </div>
                      </li>
                    ))
                  )}
                </ul>
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Workflow Runs
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <ul
                  role="list"
                  className="border border-gray-200 rounded-md divide-y divide-gray-200"
                >
                  {competency.workflowRuns.length === 0 ? (
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm italic">
                      None
                    </li>
                  ) : (
                    competency.workflowRuns.map((workflowRun) => (
                      <li
                        key={workflowRun.id}
                        className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                      >
                        <div className="w-0 flex-1 flex items-center">
                          <ClipboardCheckIcon
                            className="flex-shrink-0 h-5 w-5 text-blue-600"
                            aria-hidden="true"
                          />
                          <span className="ml-2 flex-1 w-0 truncate">
                            {workflowRun.displayID}
                          </span>
                        </div>
                        <div className="ml-4 flex-shrink-0">
                          <Link
                            to={`/regulator/workflows/history/${workflowRun.id}`}
                            target="_blank"
                            rel="noreferrer"
                            className="font-medium text-blue-600 hover:text-blue-500"
                          >
                            View
                          </Link>
                        </div>
                      </li>
                    ))
                  )}
                </ul>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Links</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <ul
                  role="list"
                  className="border border-gray-200 rounded-md divide-y divide-gray-200"
                >
                  {competency.links.length === 0 ? (
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm italic">
                      None
                    </li>
                  ) : (
                    competency.links.map((link) => (
                      <li
                        key={link.id}
                        className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                      >
                        <div className="w-0 flex-1 flex items-center">
                          <a
                            href={link.url}
                            target="_blank"
                            rel="noreferrer"
                            className="ml-2 flex-1 w-0 truncate text-blue-600"
                          >
                            {link.alias.trim() === "" ? link.url : link.alias}
                          </a>
                        </div>
                      </li>
                    ))
                  )}
                </ul>
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Attachments</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <ul
                  role="list"
                  className="border border-gray-200 rounded-md divide-y divide-gray-200"
                >
                  {competency.attachments.length === 0 ? (
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm italic">
                      None
                    </li>
                  ) : (
                    competency.attachments.map((attachment) => (
                      <li
                        key={attachment.id}
                        className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                      >
                        <div className="w-0 flex-1 flex items-center">
                          <PaperClipIcon
                            className="flex-shrink-0 h-5 w-5 text-blue-600"
                            aria-hidden="true"
                          />
                          <span className="ml-2 flex-1 w-0 truncate">
                            {attachment.fileName}
                          </span>
                        </div>
                        <div className="ml-4 flex-shrink-0">
                          <a
                            download={attachment.fileName}
                            href={competencyAttachmentDownloadLink(
                              attachment.id
                            )}
                            className="font-medium text-blue-600 hover:text-blue-500"
                          >
                            Download
                          </a>
                        </div>
                      </li>
                    ))
                  )}
                </ul>
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div
        className="mt-4 bg-white rounded-md shadow-md"
        hidden={competency.approved !== null}
      >
        <ul role="list" className="divide-y divide-gray-200">
          {approvals.map((approval) => (
            <li key={approval.id}>
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="min-w-0 flex-1 flex items-center">
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div>
                      <Link
                        to={`/regulator/personnel/${approval.personnel.id}`}
                      >
                        <p className="text-sm font-medium text-blue-600 truncate">
                          {`${approval.personnel.firstName} ${approval.personnel.lastName}`.trim()}
                        </p>
                      </Link>
                      <Link
                        to={`/regulator/personnel/roles/${approval.personnel.role.id}`}
                      >
                        <p className="mt-2 flex items-center text-sm text-gray-500">
                          <span className="truncate">
                            {approval.personnel.role.name}
                          </span>
                        </p>
                      </Link>
                    </div>
                    <div className="hidden md:block">
                      <div>
                        {approval.reviewSent ? (
                          <p className="text-sm text-gray-900">
                            Sent for review on{" "}
                            <time dateTime={"2021-10-29"}>
                              {getDate(approval.created)}
                            </time>
                          </p>
                        ) : (
                          <p className="text-sm text-gray-900">
                            Review not yet sent
                          </p>
                        )}

                        <p className="mt-2 flex items-center text-sm text-gray-500">
                          {!approval.reviewSent ? (
                            <DotsCircleHorizontalIcon
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-blue-400"
                              aria-hidden="true"
                            />
                          ) : null}
                          {approval.reviewSent ? "In Review" : "Pending"}
                        </p>
                        {competency.status === "In Review" &&
                        approval.personnel.userID === user.id ? (
                          <div className="flex justify-end -mt-10">
                            <button
                              type="button"
                              onClick={() => {
                                setApprovalModalOpen(true);
                              }}
                              className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                            >
                              Approve
                            </button>
                            <CompetencyApprovalModal
                              open={approvalModalOpen}
                              setOpen={setApprovalModalOpen}
                              competencyID={competency.id}
                              competencyTypeID={competency.type.id}
                              mutateCompetency={mutateCompetency}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div
        className="bg-white mt-3 px-4 rounded-md shadow-sm"
        hidden={competency.approved === null}
      >
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Signatures
          </h3>
          <div className="flex justify-end">
            <button
              type="button"
              className="-mt-8 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={() => setHideSignatures(!hideSignatures)}
            >
              {hideSignatures ? (
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
              ) : (
                <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
        <div className="flow-root">
          {approvals.length === 0 ? (
            <ul className="-mb-4 px-6" hidden={hideSignatures}>
              <li>
                <div className="relative pb-8">
                  <p className="text-sm italic">None</p>
                </div>
              </li>
            </ul>
          ) : (
            <ul
              role="list"
              className="divide-y divide-gray-200"
              hidden={hideSignatures}
            >
              {approvals.map((approval) => (
                <li key={approval.id}>
                  <div className="flex items-center px-4 py-4 sm:px-6">
                    <div className="min-w-0 flex-1 flex items-center">
                      <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <div className="pl-6">
                          <Link
                            to={`/regulator/personnel/${approval.personnel.id}`}
                          >
                            <p className="text-sm font-medium text-blue-600 truncate">
                              {`${approval.personnel.firstName} ${approval.personnel.lastName}`.trim()}
                            </p>
                          </Link>
                          <Link
                            to={`/regulator/personnel/roles/${approval.personnel.role.id}`}
                          >
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              <span className="truncate">
                                {approval.personnel.role.name}
                              </span>
                            </p>
                          </Link>
                          <p className="mt-2 flex items-center text-sm text-gray-500">
                            {approval.status === "Pending" ? (
                              <DotsCircleHorizontalIcon
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-blue-400"
                                aria-hidden="true"
                              />
                            ) : approval.status === "Approved" ? (
                              <CheckCircleIcon
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                aria-hidden="true"
                              />
                            ) : (
                              <XCircleIcon
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
                                aria-hidden="true"
                              />
                            )}
                            {approval.status}
                          </p>
                        </div>
                        <div className="hidden md:block">
                          <div className="pt-5">
                            <img
                              className="-mt-5 border rounded-md bg-gray-50 flex-shrink-0 w-4/5 min-h-full"
                              src={approval.encodedSignature}
                              alt={"signature"}
                            />
                            <p className="mt-2 flex justify-center text-sm text-gray-500 w-4/5">
                              {getDate(
                                approval.approved
                                  ? approval.approved
                                  : undefined
                              )}{" "}
                              {getTime(
                                approval.approved
                                  ? approval.approved
                                  : undefined
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <AuditTrailCard
        fieldDisplayMap={fieldDisplayMap}
        type={`${competency.type.name} - ${getFullName(
          competency.personnel.firstName,
          competency.personnel.lastName
        )}`}
        fetchPath={competencyAuditPath(competencyID)}
      />
    </div>
  );
}
