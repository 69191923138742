import { useUserContext } from "../common/BasePageScreen";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useCompetencyType } from "../common/DataFetchers";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import Tabs from "../common/Tabs";
import * as React from "react";
import { AcademicCapIcon } from "@heroicons/react/outline";
import ActiveList from "./ActiveList";
import PendingList from "./PendingList";
import InactiveList from "./InactiveList";

export default function CompetencyList() {
  const { competenciesRead, competenciesWrite } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const typeID = searchParams.get("typeID");

  const { competencyType, competencyTypeIsLoading, competencyTypeIsError } =
    useCompetencyType(typeID!, competenciesRead);

  if (!typeID) {
    navigate("/regulator/competency");
    return <></>;
  }

  if (!competenciesRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (competencyTypeIsLoading) {
    return <LoadingComponent />;
  }

  if (competencyTypeIsError || !competencyType) {
    return <ErrorComponent />;
  }

  const pages = [
    { name: "Training & Comptency", path: "/regulator/competency" },
    {
      name: `${competencyType.name}`,
      path: `/regulator/competency/active?typeID=${typeID}`,
    },
  ];

  const competencyTabs = [
    { name: "Active", href: `/regulator/competency/active?typeID=${typeID}` },
    { name: "Pending", href: `/regulator/competency/pending?typeID=${typeID}` },
    {
      name: "Inactive",
      href: `/regulator/competency/inactive?typeID=${typeID}`,
    },
  ];

  let component = <ActiveList />;
  if (location.pathname === "/regulator/competency/inactive") {
    component = <InactiveList />;
  }
  if (location.pathname === "/regulator/competency/pending") {
    component = <PendingList />;
  }

  return (
    <div className="flex flex-col">
      <FullWidthBreadcrumbBar pages={pages} />
      <div className="py-3 text-right">
        <Link
          to={`/regulator/competency/add?typeID=${typeID}`}
          hidden={!competenciesWrite}
        >
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <AcademicCapIcon
              className="-ml-1 mr-3 h-5 w-5"
              aria-hidden="true"
            />
            Add Competency
          </button>
        </Link>
      </div>
      <Tabs tabs={competencyTabs} />
      {component}
    </div>
  );
}
