import {
  PencilAltIcon,
  ShieldCheckIcon,
  UserRemoveIcon,
} from "@heroicons/react/solid";
import { Link, useNavigate, useParams } from "react-router-dom";
import { deleteUser, useCurrentLab, useUser } from "../common/DataFetchers";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import React, { useState } from "react";
import { classNames, getFullName } from "../common/Utils";
import {
  AcademicCapIcon,
  BeakerIcon,
  ClipboardCheckIcon,
  CogIcon,
  CursorClickIcon,
  DocumentDuplicateIcon,
  DocumentTextIcon,
  FingerPrintIcon,
  KeyIcon,
  TemplateIcon,
  UsersIcon,
  ViewGridAddIcon,
} from "@heroicons/react/outline";
import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import { useUserContext } from "../common/BasePageScreen";
import GenerateInviteLinkModal from "./GenerateInviteLinkModal";
import ResetPasswordModal from "./ResetPasswordModal";
import DeleteUserModal from "./DeleteUserModal";

const statusToClassnameMap = new Map([
  [
    "Active",
    "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 w-min",
  ],
  [
    "Inactive",
    "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800 w-min",
  ],
  [
    "Pending",
    "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800 w-min",
  ],
]);

export default function UserDetailCard() {
  const navigate = useNavigate();

  const params = useParams<{ id: string }>();
  const userID = params.id as string;

  const [showGenerateInviteModal, setShowGenerateInviteModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);

  const { user: currentUser } = useUserContext();
  const { user, userIsLoading, userIsError } = useUser(userID);

  if (userIsLoading) {
    return <LoadingComponent />;
  }

  if (userIsError || !user) {
    return <ErrorComponent />;
  }

  const pages = [
    { name: "Settings", path: "/regulator/settings?type=users" },
    {
      name: `${user.firstName} ${user.lastName}`,
      path: `/regulator/settings/user/${user.id}`,
    },
  ];
  return (
    <div>
      <FullWidthBreadcrumbBar pages={pages} />
      <div className="py-3 px-5">
        <div className="flex text-right justify-end">
          <Link
            to={`/regulator/settings/user/${user.id}/edit`}
            hidden={
              user.status === "Pending" ||
              (user.id === currentUser.id && currentUser.isAdmin)
            }
          >
            <button
              type="button"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <PencilAltIcon
                className="-ml-1 mr-3 h-5 w-5"
                aria-hidden="true"
              />
              Edit
            </button>
          </Link>
          {user.status === "Pending" ? (
            <>
              <button
                type="button"
                onClick={() => setShowGenerateInviteModal(true)}
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <CursorClickIcon
                  className="-ml-1 mr-3 h-5 w-5"
                  aria-hidden="true"
                />
                Generate Invite Link
                <GenerateInviteLinkModal
                  open={showGenerateInviteModal}
                  setOpen={setShowGenerateInviteModal}
                  userID={user.id}
                />
              </button>
              <button
                type="button"
                onClick={() => setShowDeleteUserModal(true)}
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                <UserRemoveIcon
                  className="-ml-1 mr-3 h-5 w-5"
                  aria-hidden="true"
                />
                Delete User
                <DeleteUserModal
                  open={showDeleteUserModal}
                  setOpen={setShowDeleteUserModal}
                  userID={user.id}
                />
              </button>
            </>
          ) : null}
          {user.status === "Active" ? (
            <button
              type="button"
              onClick={() => setShowResetPasswordModal(true)}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <KeyIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              Reset Password
              <ResetPasswordModal
                open={showResetPasswordModal}
                setOpen={setShowResetPasswordModal}
                userID={user.id}
              />
            </button>
          ) : null}
        </div>
      </div>
      <div className="mt-2 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            <span className="flex items-center">
              {getFullName(user.firstName, user.lastName)}
              <ShieldCheckIcon
                className={classNames(
                  user.isAdmin ? "" : "hidden",
                  "ml-1 flex-shrink-0 h-5 w-5 text-blue-600"
                )}
              />
            </span>
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            User details and permissions.
          </p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Status</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className={statusToClassnameMap.get(user.status)}>
                  {user.status}
                </span>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Permissions</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <TemplateIcon
                        className="flex-shrink-0 h-5 w-5 text-blue-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 flex-1 w-0 truncate font-medium">
                        Workflow Templates
                      </span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                        {user.workflowTemplates === "n"
                          ? "None"
                          : user.workflowTemplates === "rw"
                          ? "Read/Write"
                          : "Read"}
                      </span>
                    </div>
                  </li>
                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <ClipboardCheckIcon
                        className="flex-shrink-0 h-5 w-5 text-blue-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 flex-1 w-0 truncate font-medium">
                        Workflow History
                      </span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                        {user.workflowHistory === "n"
                          ? "None"
                          : user.workflowHistory === "rw"
                          ? "Read/Write"
                          : "Read"}
                      </span>
                    </div>
                  </li>
                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <FingerPrintIcon
                        className="flex-shrink-0 h-5 w-5 text-blue-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 flex-1 w-0 truncate font-medium">
                        Samples
                      </span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                        {user.samples === "n"
                          ? "None"
                          : user.samples === "rw"
                          ? "Read/Write"
                          : "Read"}
                      </span>
                    </div>
                  </li>
                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <BeakerIcon
                        className="flex-shrink-0 h-5 w-5 text-blue-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 flex-1 w-0 truncate font-medium">
                        Reagents
                      </span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                        {user.reagents === "n"
                          ? "None"
                          : user.reagents === "rw"
                          ? "Read/Write"
                          : "Read"}
                      </span>
                    </div>
                  </li>
                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <CogIcon
                        className="flex-shrink-0 h-5 w-5 text-blue-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 flex-1 w-0 truncate  font-medium">
                        Equipment
                      </span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                        {user.equipment === "n"
                          ? "None"
                          : user.equipment === "rw"
                          ? "Read/Write"
                          : "Read"}
                      </span>
                    </div>
                  </li>
                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <UsersIcon
                        className="flex-shrink-0 h-5 w-5 text-blue-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 flex-1 w-0 truncate font-medium">
                        Personnel
                      </span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                        {user.personnel === "n"
                          ? "None"
                          : user.personnel === "rw"
                          ? "Read/Write"
                          : "Read"}
                      </span>
                    </div>
                  </li>
                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <AcademicCapIcon
                        className="flex-shrink-0 h-5 w-5 text-blue-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 flex-1 w-0 truncate font-medium">
                        Training & Competency
                      </span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                        {user.competencies === "n"
                          ? "None"
                          : user.competencies === "rw"
                          ? "Read/Write"
                          : "Read"}
                      </span>
                    </div>
                  </li>
                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <DocumentDuplicateIcon
                        className="flex-shrink-0 h-5 w-5 text-blue-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 flex-1 w-0 truncate font-medium">
                        SOPs
                      </span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                        {user.sops === "n"
                          ? "None"
                          : user.sops === "rw"
                          ? "Read/Write"
                          : "Read"}
                      </span>
                    </div>
                  </li>
                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <ViewGridAddIcon
                        className="flex-shrink-0 h-5 w-5 text-blue-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 flex-1 w-0 truncate font-medium">
                        Forms
                      </span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                        {user.forms === "n"
                          ? "None"
                          : user.forms === "rw"
                          ? "Read/Write"
                          : "Read"}
                      </span>
                    </div>
                  </li>
                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <DocumentTextIcon
                        className="flex-shrink-0 h-5 w-5 text-blue-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 flex-1 w-0 truncate font-medium">
                        Records
                      </span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                        {user.records === "n"
                          ? "None"
                          : user.records === "rw"
                          ? "Read/Write"
                          : "Read"}
                      </span>
                    </div>
                  </li>
                </ul>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}
