import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";
import { applySubdomain, useCurrentRegistration } from "../common/DataFetchers";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";

enum PaymentIntentStatus {
  SUCCEEDED = "succeeded",
  PROCESSING = "processing",
  REQUIRES_PAYMENT_METHOD = "requires_payment_method",
  ERROR = "error",
}

export default function PaymentConfirmationSignUp() {
  const navigate = useNavigate();
  const stripe = useStripe();
  const [searchParams] = useSearchParams();

  const {
    currentRegistration,
    currentRegistrationIsLoading,
    currentRegistrationIsError,
  } = useCurrentRegistration();

  const [message, setMessage] = useState("Loading...");
  const [status, setStatus] = useState<PaymentIntentStatus>();

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = searchParams.get("payment_intent_client_secret");

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case "succeeded":
          setStatus(PaymentIntentStatus.SUCCEEDED);
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setStatus(PaymentIntentStatus.PROCESSING);
          setMessage("Your payment is processing...");
          break;
        case "requires_payment_method":
          setStatus(PaymentIntentStatus.REQUIRES_PAYMENT_METHOD);
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setStatus(PaymentIntentStatus.ERROR);
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  useEffect(() => {
    if (!currentRegistration) {
      return;
    }

    if (currentRegistration.registration?.subscription?.total === 0) {
      setStatus(PaymentIntentStatus.SUCCEEDED);
      setMessage("Your trial started successfully!");
    }
  }, [currentRegistration]);

  if (currentRegistrationIsLoading) {
    return <LoadingComponent />;
  }

  if (currentRegistrationIsError || !currentRegistration) {
    return <ErrorComponent />;
  }

  return (
    <div className="flex h-screen bg-gradient-to-b from-blue-300 to-blue-600">
      <div className="max-w-7xl m-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
          <div className="text-center">
            <img
              className="h-24 w-24 m-auto"
              src={`${process.env.PUBLIC_URL}/dl-regulator_logo-img_color.svg`}
            />
          </div>
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-center text-lg leading-6 font-medium text-gray-900 mb-4">
              {message}
            </h3>
            <p
              className="text-center"
              hidden={status !== PaymentIntentStatus.SUCCEEDED}
            >
              {" "}
              We are creating your laboratory now! Your laboratory instance
              should be available immediately but can take up to 5 minutes in
              rare cases.
              <br /> <br />
            </p>
            <p className="text-center mb-3">
              Please contact support at{" "}
              <a
                className="text-blue-600 font-medium"
                href="mailto: support@decotolabs.com"
              >
                support@decotolabs.com
              </a>{" "}
              <br />
              if you encounter any problems.
            </p>
            <div className="mt-3text-sm">
              {status === PaymentIntentStatus.SUCCEEDED ? (
                <button
                  onClick={() => {
                    // const subdomain =
                    //   currentRegistration.registration?.lab?.subdomain;
                    // window.location.assign(
                    //   `${applySubdomain(subdomain)}/regulator`
                    // );
                    navigate("/regulator/login");
                  }}
                  className="mt-1 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
                >
                  Go to Regulator
                </button>
              ) : null}
              {status === PaymentIntentStatus.ERROR ||
              status === PaymentIntentStatus.REQUIRES_PAYMENT_METHOD ? (
                <button
                  onClick={() => navigate("/signup/review")}
                  className="mt-1 inline-flex justify-center w-full rounded-md border border-blue-600 shadow-sm px-4 py-2 bg-white text-base font-medium text-blue-600 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
                >
                  Return to Checkout
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
