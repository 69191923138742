import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { classNames, formatErrorMessage } from "../common/Utils";
import SOPSelectMenu from "../common/SOPSelectMenu";
import {
  addWorkflowTemplate,
  useCompetencyTypeSummaries,
  useEquipmentTypeSummaries,
  useReagentTypeSummaries,
  useSampleTypeSummaries,
} from "../common/DataFetchers";
import { useUserContext } from "../common/BasePageScreen";
import CustomSelectMenu from "../common/CustomSelectMenu";
import { SOPSummary } from "../common/Entities.d";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import ErrorBanner from "../common/ErrorBanner";

export default function AddWorkflowTemplateForm() {
  const navigate = useNavigate();
  const { workflowTemplatesRead, workflowTemplatesWrite } = useUserContext();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const [templateMajorVersion, setTemplateMajorVersion] = useState(1);
  const [templateMinorVersion, setTemplateMinorVersion] = useState(0);

  const [nameError, setNameError] = useState(false);
  const [sopError, setSopError] = useState(false);
  const [competencyError, setCompetencyError] = useState(false);
  const [equipmentErorr, setEquipmentError] = useState(false);
  const [reagentsError, setReagentsError] = useState(false);
  const [samplesError, setSamplesError] = useState(false);

  const [workflowReagents] = useState(new Set<string>());
  const [workflowEquipment] = useState(new Set<string>());
  const [workflowSamples] = useState(new Set<string>());

  const [sop, setSop] =
    useState<{ id: string; value: SOPSummary | null; display: string }>();
  const [competencyType, setCompetencyType] =
    useState<{ id: string; value: any; display: string }>();

  const { competencyTypeSummaries } = useCompetencyTypeSummaries(
    workflowTemplatesWrite
  );
  const [competencyTypeOptions, setCompetencyTypeOptions] = useState<
    { id: string; value: any; display: string }[]
  >([]);

  useEffect(() => {
    const updatedCompetencyTypeOptions: {
      id: string;
      value: any;
      display: string;
    }[] = [];
    competencyTypeSummaries.forEach((type) => {
      updatedCompetencyTypeOptions.push({
        id: type.id,
        value: type,
        display: type.name,
      });
    });
    setCompetencyTypeOptions(updatedCompetencyTypeOptions);
    setCompetencyType(updatedCompetencyTypeOptions[0]);
  }, [competencyTypeSummaries]);

  const { reagentTypeSummaries } = useReagentTypeSummaries(
    workflowTemplatesWrite
  );
  const { equipmentTypeSummaries } = useEquipmentTypeSummaries(
    workflowTemplatesWrite
  );
  const { sampleTypeSummaries } = useSampleTypeSummaries(
    workflowTemplatesWrite
  );

  if (!workflowTemplatesWrite || !workflowTemplatesRead) {
    navigate("/forbidden");
    return <></>;
  }

  return (
    <form
      className="space-y-8 p-3 mt-3"
      onSubmit={(e: React.SyntheticEvent) => {
        e.preventDefault();

        setShowErrorBanner(false);

        const target = e.target as typeof e.target & {
          workflow_name: { value: string };
        };

        target.workflow_name.value = target.workflow_name.value.trim();
        const name = target.workflow_name.value;

        let isValid = true;

        setNameError(false);
        if (name === "") {
          setNameError(true);
          isValid = false;
        }

        setSopError(false);
        if (!sop) {
          setSopError(true);
          isValid = false;
        }

        setCompetencyError(false);
        if (!competencyType) {
          setCompetencyError(true);
          isValid = false;
        }

        setEquipmentError(false);
        if (workflowEquipment.size === 0) {
          setEquipmentError(true);
          isValid = false;
        }

        setReagentsError(false);
        if (workflowReagents.size === 0) {
          setReagentsError(true);
          isValid = false;
        }

        setSamplesError(false);
        if (workflowSamples.size === 0) {
          setSamplesError(true);
          isValid = false;
        }

        if (!isValid) {
          return;
        }

        if (workflowTemplatesWrite) {
          addWorkflowTemplate({
            name: name,
            sopID: sop!.id,
            competencyTypeID: competencyType!.id,
            majorVersion: templateMajorVersion,
            minorVersion: templateMinorVersion,
            equipmentTypeIDs: Array.from(workflowEquipment),
            reagentTypeIDs: Array.from(workflowReagents),
            sampleTypeIDs: Array.from(workflowSamples),
          })
            .then((template) =>
              navigate(`/regulator/workflows/templates/${template.id}`)
            )
            .catch((error) => {
              setErrorBannerText(formatErrorMessage(error));
              setShowErrorBanner(true);
            });
        }
      }}
    >
      <div className="px-3 space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div>
          <ErrorBanner
            showError={showErrorBanner}
            setShowError={setShowErrorBanner}
            errorText={errorBannerText}
          />
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Add Workflow Template
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Fill in the information in the form below to create a new workflow
              template.
            </p>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="workflow_name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Workflow Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  name="workflow_name"
                  id="workflow_name"
                  className={classNames(
                    "max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm rounded-md",
                    nameError
                      ? "focus:ring-red-500 focus:border-red-500 border-red-300"
                      : "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                  )}
                />
                <p className="mt-2 text-sm text-red-500" hidden={!nameError}>
                  * Workflow Name is required
                </p>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-6 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="sopVersion"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Workflow Template Version
              </label>
              <div className="max-w-lg flex rounded-md col-span-3 col-start-3">
                <p className="font-medium text-gray-600 px-4 mt-2">{`${templateMajorVersion}.${templateMinorVersion}`}</p>
                <div className="inline-flex w-32">
                  <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm shadow-sm">
                    Major
                  </span>
                  <input
                    type="number"
                    step="1"
                    min="0"
                    name="sopMajorVersion"
                    id="sopMajorVersion"
                    defaultValue={templateMajorVersion}
                    onChange={(e) =>
                      setTemplateMajorVersion(parseInt(e.currentTarget.value))
                    }
                    className={classNames(
                      "flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md sm:text-sm border",
                      "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                    )}
                  />
                </div>
                <div className="inline-flex w-36">
                  <span className="ml-4 inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm shadow-sm">
                    Minor
                  </span>
                  <input
                    type="number"
                    step="1"
                    min="0"
                    name="sopMinorVersion"
                    id="sopMinorVersion"
                    defaultValue={templateMinorVersion}
                    onChange={(e) =>
                      setTemplateMinorVersion(parseInt(e.currentTarget.value))
                    }
                    className={classNames(
                      "flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md sm:text-sm border",
                      "focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <SOPSelectMenu
                label={"SOP"}
                selected={sop}
                setSelected={setSop}
              />
              <p
                className="text-sm text-red-500 sm:col-start-2"
                hidden={!sopError}
              >
                * SOP is required.
              </p>
            </div>
          </div>
        </div>
        <div className="divide-y divide-gray-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Competency
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Specify the competency type that corresponds to the personnel that
              are permitted to perform this workflow.
            </p>
          </div>
          <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
            <div className="pt-6 sm:pt-5">
              <div role="group" aria-labelledby="competency-selection">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                  <div>
                    <div
                      className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                      id="competency-selection"
                    >
                      Competency Type
                      <span hidden={competencyTypeSummaries.length !== 0}>
                        <ExclamationCircleIcon className="ml-1 text-red-500 inline-flex h-5 w-5" />
                      </span>
                    </div>
                  </div>

                  {competencyTypeSummaries.length === 0 ? (
                    <div className="mt-4 sm:mt-0 sm:col-span-2">
                      <div className="max-w-lg space-y-4">
                        <p className="mt-2 text-sm text-gray-500">
                          Please{" "}
                          <Link
                            to="/regulator/competency/type/add"
                            className="font-medium text-blue-500"
                            target="_blank"
                            rel="noreferrer"
                          >
                            create
                          </Link>{" "}
                          competency type before creating a workflow template.
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-4 sm:mt-0">
                      <div className="max-w-lg space-y-4">
                        <CustomSelectMenu
                          label={""}
                          options={competencyTypeOptions}
                          selected={competencyType}
                          setSelected={setCompetencyType}
                        />
                      </div>
                    </div>
                  )}
                  <p
                    className="text-sm text-red-500 sm:col-start-2"
                    hidden={!competencyError}
                  >
                    * Competency Type is required.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="divide-y divide-gray-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Equipment
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Specify the equipment types that are used for this workflow.
            </p>
          </div>
          <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
            <div className="pt-6 sm:pt-5">
              <div role="group" aria-labelledby="equipment-selection">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                  <div>
                    <div
                      className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                      id="equipment-selection"
                    >
                      Equipment Types
                      <span hidden={equipmentTypeSummaries.length !== 0}>
                        <ExclamationCircleIcon className="ml-1 text-red-500 inline-flex h-5 w-5" />
                      </span>
                    </div>
                  </div>
                  <div className="mt-4 sm:mt-0 sm:col-span-2">
                    <div className="max-w-lg space-y-4">
                      {equipmentTypeSummaries.length === 0 ? (
                        <p className="mt-2 text-sm text-gray-500">
                          Please{" "}
                          <Link
                            to="/regulator/equipment/types/add"
                            className="font-medium text-blue-500"
                            target="_blank"
                            rel="noreferrer"
                          >
                            create
                          </Link>{" "}
                          equipment types before creating a workflow template.
                        </p>
                      ) : (
                        equipmentTypeSummaries.map((equipmentType) => (
                          <div>
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  id={equipmentType.id}
                                  name={equipmentType.id}
                                  type="checkbox"
                                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                                  onChange={(e) =>
                                    e.currentTarget.checked
                                      ? workflowEquipment.add(
                                          e.currentTarget.id
                                        )
                                      : workflowEquipment.delete(
                                          e.currentTarget.id
                                        )
                                  }
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor={equipmentType.id}
                                  className="font-medium text-gray-700"
                                >
                                  {equipmentType.name}
                                </label>
                              </div>
                            </div>
                          </div>
                        ))
                      )}

                      <p
                        className="text-sm text-red-500"
                        hidden={!equipmentErorr}
                      >
                        *At least one equipment type must be selected.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="divide-y divide-gray-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Reagents
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Specify the reagent types that are used for this workflow.
            </p>
          </div>
          <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
            <div className="pt-6 sm:pt-5">
              <div role="group" aria-labelledby="reagent-selection">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                  <div>
                    <div
                      className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                      id="reagent-selection"
                    >
                      Reagent Types
                      <span hidden={reagentTypeSummaries.length !== 0}>
                        <ExclamationCircleIcon className="ml-1 text-red-500 inline-flex h-5 w-5" />
                      </span>
                    </div>
                  </div>
                  <div className="mt-4 sm:mt-0 sm:col-span-2">
                    <div className="max-w-lg space-y-4">
                      {reagentTypeSummaries.length === 0 ? (
                        <p className="mt-2 text-sm text-gray-500">
                          Please{" "}
                          <Link
                            to="/regulator/reagents/types/add"
                            className="font-medium text-blue-500"
                            target="_blank"
                            rel="noreferrer"
                          >
                            create
                          </Link>{" "}
                          reagent types before creating a workflow template.
                        </p>
                      ) : (
                        reagentTypeSummaries.map((reagentType) => (
                          <div>
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  id={reagentType.id}
                                  name={reagentType.id}
                                  type="checkbox"
                                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                                  onChange={(e) =>
                                    e.currentTarget.checked
                                      ? workflowReagents.add(e.currentTarget.id)
                                      : workflowReagents.delete(
                                          e.currentTarget.id
                                        )
                                  }
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor={reagentType.id}
                                  className="font-medium text-gray-700"
                                >
                                  {reagentType.name}
                                </label>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                      <p
                        className="text-sm text-red-500"
                        hidden={!reagentsError}
                      >
                        *At least one reagent type must be selected.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="divide-y divide-gray-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Samples
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Specify the sample types that are used for this workflow.
            </p>
          </div>
          <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
            <div className="pt-6 sm:pt-5">
              <div role="group" aria-labelledby="reagent-selection">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                  <div>
                    <div
                      className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                      id="reagent-selection"
                    >
                      Sample Types
                      <span hidden={sampleTypeSummaries.length !== 0}>
                        <ExclamationCircleIcon className="ml-1 text-red-500 inline-flex h-5 w-5" />
                      </span>
                    </div>
                  </div>
                  <div className="mt-4 sm:mt-0 sm:col-span-2">
                    <div className="max-w-lg space-y-4">
                      {sampleTypeSummaries.length === 0 ? (
                        <p className="mt-2 text-sm text-gray-500">
                          Please{" "}
                          <Link
                            to="/regulator/samples/types/add"
                            className="font-medium text-blue-500"
                            target="_blank"
                            rel="noreferrer"
                          >
                            create
                          </Link>{" "}
                          sample types before creating a workflow template.
                        </p>
                      ) : (
                        sampleTypeSummaries.map((sampleType) => (
                          <div>
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5">
                                <input
                                  id={sampleType.id}
                                  name={sampleType.id}
                                  type="checkbox"
                                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                                  onChange={(e) =>
                                    e.currentTarget.checked
                                      ? workflowSamples.add(e.currentTarget.id)
                                      : workflowSamples.delete(
                                          e.currentTarget.id
                                        )
                                  }
                                />
                              </div>
                              <div className="ml-3 text-sm">
                                <label
                                  htmlFor={sampleType.id}
                                  className="font-medium text-gray-700"
                                >
                                  {sampleType.name}
                                </label>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                      <p
                        className="text-sm text-red-500"
                        hidden={!samplesError}
                      >
                        *At least one sample type must be selected.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <Link to="/regulator/workflows/templates">
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
