import { FormElementEntitySelect } from "../../common/Entities.d";
import React, { useEffect, useState } from "react";
import CustomSelectMenu from "../../common/CustomSelectMenu";
import { getFullName } from "../../common/Utils";
import {
  useEquipmentSummaries,
  usePersonnelSummaries,
  useReagentSummaries,
  useSampleSummaries,
  useSOPSummaries,
  useWorkflowRunSummaries,
} from "../../common/DataFetchers";
import { useUserContext } from "../../common/BasePageScreen";

export default function InternalEntityInput(props: {
  internalEntityInput: FormElementEntitySelect;
  error: boolean;
  recordPointer: { recordValueArray: string; recordValueIndex: number };
  textValues: Array<string[]>;
  setTextValues: (textValues: Array<string[]>) => void;
}) {
  const { recordsWrite } = useUserContext();
  const options: { id: string; value: any; display: string }[] = [];

  const { sopSummaries } = useSOPSummaries("Active", "", recordsWrite);
  const { personnelSummaries } = usePersonnelSummaries(recordsWrite);
  const { reagentSummaries } = useReagentSummaries(recordsWrite);
  const { equipmentSummaries } = useEquipmentSummaries(recordsWrite);
  const { sampleSummaries } = useSampleSummaries(recordsWrite);
  const { workflowRunSummaries } = useWorkflowRunSummaries(recordsWrite);

  function handleCheck(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.id;
    if (e.target.checked) {
      props.textValues[props.recordPointer.recordValueIndex].push(value);
    } else {
      const index = props.textValues[
        props.recordPointer.recordValueIndex
      ].findIndex((val: string) => val === value);
      if (index !== -1) {
        props.textValues[props.recordPointer.recordValueIndex].splice(index, 1);
      }
    }
    props.setTextValues(props.textValues);
  }

  if (props.internalEntityInput.elementType === "SOP") {
    sopSummaries.map((sop, sopIdx) =>
      options.push({
        id: sopIdx.toString(),
        value: sop.id,
        display: `${sop.displayID} - ${sop.title}`,
      })
    );
  }

  if (props.internalEntityInput.elementType === "Personnel") {
    personnelSummaries.map((person, personIdx) =>
      options.push({
        id: personIdx.toString(),
        value: person.id,
        display: getFullName(person.firstName, person.lastName),
      })
    );
  }

  if (props.internalEntityInput.elementType == "Reagent") {
    reagentSummaries.map((reagent, reagentIdx) =>
      options.push({
        id: reagentIdx.toString(),
        value: reagent.id,
        display: `${reagent.displayID} - ${reagent.type.name}`,
      })
    );
  }

  if (props.internalEntityInput.elementType === "Equipment") {
    equipmentSummaries.map((equipment, equipmentIdx) =>
      options.push({
        id: equipmentIdx.toString(),
        value: equipment.id,
        display: `${equipment.displayID} - ${equipment.type.name}`,
      })
    );
  }

  if (props.internalEntityInput.elementType === "Sample") {
    sampleSummaries.map((sample, sampleIdx) =>
      options.push({
        id: sampleIdx.toString(),
        value: sample.id,
        display: sample.displayID,
      })
    );
  }

  if (props.internalEntityInput.elementType === "WorkflowRun") {
    workflowRunSummaries.map((run, runIdx) =>
      options.push({
        id: runIdx.toString(),
        value: run.id,
        display: run.displayID,
      })
    );
  }

  const label = props.internalEntityInput.label;
  const [selected, setSelected] = useState(options[0]);

  useEffect(() => {
    if (!options) {
      return;
    }
    if (!selected) {
      setSelected(options[0]);
    }
  }, [options]);

  useEffect(() => {
    if (!selected) {
      return;
    }
    if (!props.internalEntityInput.multiSelect) {
      props.textValues[props.recordPointer.recordValueIndex] = [selected.value];
      props.setTextValues(props.textValues);
    }
  }, [selected]);

  return (
    <div>
      {!options || options.length === 0 ? (
        <div>
          <label className="block text-sm font-medium text-gray-700">
            {props.internalEntityInput.label}
          </label>
          <p className="italic text-gray-700 text-sm">None</p>
        </div>
      ) : props.internalEntityInput.multiSelect ? (
        <div>
          <label className="block text-sm font-medium text-gray-700">
            {props.internalEntityInput.label}
          </label>
          {options.map((option) => (
            <div className="relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  id={option.value}
                  aria-describedby="comments-description"
                  name={option.display}
                  type="checkbox"
                  onChange={(e) => handleCheck(e)}
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor={option.display}
                  className="font-medium text-gray-700"
                >
                  {option.display}
                </label>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <CustomSelectMenu
            label={label}
            options={options}
            selected={selected}
            setSelected={setSelected}
          />
        </div>
      )}
      <p className="mt-2 text-sm text-red-500" hidden={!props.error}>
        * {props.internalEntityInput.label} is required.
      </p>
    </div>
  );
}
