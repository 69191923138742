import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useReducer, useState } from "react";
import CustomSelectMenu from "../common/CustomSelectMenu";
import { classNames, formatErrorMessage, getDate } from "../common/Utils";
import {
  updatePersonnel,
  usePersonnel,
  usePersonnelRoleSummaries,
} from "../common/DataFetchers";
import {
  PersonnelAttachmentDetails,
  PersonnelAttachmentType,
} from "../common/Entities.d";
import { PaperClipIcon } from "@heroicons/react/outline";
import { ArchiveIcon } from "@heroicons/react/solid";
import { useUserContext } from "../common/BasePageScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import PersonnelRecordUploadComponent from "../common/PersonnelRecordUploadComponent";
import ErrorBanner from "../common/ErrorBanner";

const attachmentBadgeMap = new Map([
  ["diploma", { display: "Diploma", color: "blue" }],
  ["license", { display: "License", color: "purple" }],
  ["resume", { display: "Resume", color: "gray" }],
  ["cv", { display: "CV", color: "yellow" }],
  ["continuingEducation", { display: "Continuing Education", color: "green" }],
]);

export default function EditPersonnelForm(): JSX.Element {
  const { personnelRead, personnelWrite } = useUserContext();
  const navigate = useNavigate();

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorBannerText, setErrorBannerText] = useState("");

  const { roleSummaries } = usePersonnelRoleSummaries(personnelRead);

  const params = useParams<{ id: string }>();
  const personnelID = params.id as string;

  const { personnel, personnelIsLoading, personnelIsError, mutatePersonnel } =
    usePersonnel(personnelID, personnelRead);

  const [roleError, setRoleError] = useState(false);

  const [roleOptions] = useState<{ id: string; value: any; display: string }[]>(
    []
  );
  const [role, setRole] =
    useState<{ id: string; value: any; display: string }>();
  useEffect(() => {
    roleSummaries.forEach((role, roleIdx) => {
      const roleOption = {
        id: roleIdx.toString(),
        value: role,
        display: role.name,
      };
      const idx = roleOptions.findIndex((option) => option.value === role);
      if (idx < 0) {
        roleOptions.push(roleOption);
      }
    });
    setRole(
      roleOptions[
        roleOptions.findIndex((r) => r.display === personnel?.role.name)
      ]
    );
  }, [roleSummaries, personnel]);

  const initialAttachmentsState = {
    active: Array<PersonnelAttachmentDetails>(),
    archived: Array<PersonnelAttachmentDetails>(),
  };

  type AttachmentAction = {
    type: "archive" | "active" | "initialize";
    id: string;
  };

  function reducer(
    state: typeof initialAttachmentsState,
    action: AttachmentAction
  ) {
    const updatedState = {
      active: Array.from(state.active),
      archived: Array.from(state.archived),
    };

    let srcAttachments: PersonnelAttachmentDetails[];
    let dstAttachments: PersonnelAttachmentDetails[];
    switch (action.type) {
      case "active":
        srcAttachments = updatedState.archived;
        dstAttachments = updatedState.active;
        break;
      case "archive":
        srcAttachments = updatedState.active;
        dstAttachments = updatedState.archived;
        break;
      case "initialize":
        return {
          active:
            personnel?.attachments
              .filter((attachment) => !attachment.archived)
              .map((attachment) => Object.assign({}, attachment)) || [],
          archived:
            personnel?.attachments
              .filter((attachment) => attachment.archived)
              .map((attachment) => Object.assign({}, attachment)) || [],
        };
    }

    const idx = srcAttachments.findIndex(
      (attachment) => attachment.id === action.id
    );
    if (idx < 0) {
      return state;
    }

    const attachment = srcAttachments.splice(idx, 1)[0];
    dstAttachments.push(attachment);

    return updatedState;
  }

  const [attachmentsState, dispatch] = useReducer(
    reducer,
    initialAttachmentsState
  );

  useEffect(() => {
    if (!personnel) {
      return;
    }

    dispatch({ type: "initialize", id: "" });
  }, [personnel]);

  const [addedAttachments] = useState<
    { file: File; type: PersonnelAttachmentType }[]
  >([]);

  if (!personnelWrite || !personnelRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (personnelIsLoading) {
    return <LoadingComponent />;
  }

  if (personnelIsError || !personnel) {
    return <ErrorComponent />;
  }

  const currentAttachmentSection = (title: string) => (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        Current {title}
      </label>
      <div className="col-span-2">
        <p className="ml-1 text-sm">Active</p>
        {attachmentsState.active.length === 0 ? (
          <ul className="bg-white border border-gray-200 rounded-md divide-y divide-gray-200">
            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm italic">
              None
            </li>
          </ul>
        ) : (
          <div>
            {attachmentsState.active.map((attachment) => (
              <ul className="bg-white border border-gray-200 rounded-md divide-y divide-gray-200">
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div className="w-0 flex-1 flex items-center">
                    <PaperClipIcon
                      className="flex-shrink-0 h-5 w-5 text-blue-400"
                      aria-hidden="true"
                    />
                    <span className="ml-2 flex-1 w-0 truncate">
                      {attachment.fileName}
                      <span
                        className={classNames(
                          `bg-${
                            attachmentBadgeMap.get(attachment.type)!.color
                          }-100`,
                          `text-${
                            attachmentBadgeMap.get(attachment.type)!.color
                          }-800`,
                          "ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium"
                        )}
                      >
                        {attachmentBadgeMap.get(attachment.type)!.display}
                      </span>
                    </span>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <button
                      type="button"
                      onClick={() =>
                        dispatch({ type: "archive", id: attachment.id })
                      }
                      className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <ArchiveIcon
                        className="-ml-0.5 mr-2 h-4 w-4"
                        aria-hidden="true"
                      />
                      Archive
                    </button>
                  </div>
                </li>
              </ul>
            ))}
          </div>
        )}
      </div>
      <div className="col-start-2 col-span-2">
        <p className="ml-1 text-sm">Archived</p>
        {attachmentsState.archived.length === 0 ? (
          <ul className="bg-white border border-gray-200 rounded-md divide-y divide-gray-200">
            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm italic">
              None
            </li>
          </ul>
        ) : (
          <div>
            {attachmentsState.archived.map((attachment) => (
              <ul className="bg-white border border-gray-200 rounded-md divide-y divide-gray-200">
                <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div className="w-0 flex-1 flex items-center">
                    <ArchiveIcon
                      className="flex-shrink-0 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-2 flex-1 w-0 truncate italic text-gray-500">
                      {attachment.fileName}
                      <span
                        className={classNames(
                          `bg-${
                            attachmentBadgeMap.get(attachment.type)!.color
                          }-100`,
                          `text-${
                            attachmentBadgeMap.get(attachment.type)!.color
                          }-800`,
                          "ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium"
                        )}
                      >
                        {attachmentBadgeMap.get(attachment.type)!.display}
                      </span>
                    </span>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <button
                      type="button"
                      onClick={() =>
                        dispatch({ type: "active", id: attachment.id })
                      }
                      className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <ArchiveIcon
                        className="-ml-0.5 mr-2 h-4 w-4"
                        aria-hidden="true"
                      />
                      Unarchive
                    </button>
                  </div>
                </li>
              </ul>
            ))}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <form
      className="py-3 px-3 space-y-8 divide-y divide-gray-200"
      onSubmit={(e: React.SyntheticEvent) => {
        e.preventDefault();

        setShowErrorBanner(false);

        let isValid = true;

        setRoleError(false);
        if (!role) {
          setRoleError(true);
          isValid = false;
        }

        if (!isValid || !role || !personnelWrite) {
          return;
        }

        updatePersonnel(
          personnelID,
          role.value.id,
          addedAttachments,
          attachmentsState.active.map((attachment) => attachment.id),
          attachmentsState.archived.map((attachment) => attachment.id)
        )
          .then((personnel) => {
            mutatePersonnel(personnel, false);
            navigate(`/regulator/personnel/${personnelID}`);
          })
          .catch((error) => {
            setErrorBannerText(formatErrorMessage(error));
            setShowErrorBanner(true);
          });
      }}
    >
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          <ErrorBanner
            showError={showErrorBanner}
            setShowError={setShowErrorBanner}
            errorText={errorBannerText}
          />
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Edit Personnel
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Fill in required details to edit existing personnel.
            </p>
          </div>
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="firstName"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                First Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <p className="mt-2 font-medium text-sm text-gray-900">
                    {personnel.firstName}
                  </p>
                </div>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="lastName"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Last Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <p className="mt-2 font-medium text-sm text-gray-900">
                    {personnel.lastName}
                  </p>
                </div>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Hire Date
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <p className="mt-2 font-medium text-sm text-gray-900">
                  {getDate(personnel.hired)}
                </p>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <CustomSelectMenu
                label="Role"
                options={roleOptions}
                selected={role}
                setSelected={setRole}
              />
              <p className="mt-2 text-sm text-red-500" hidden={!roleError}>
                * Role is required
              </p>
            </div>
            {currentAttachmentSection("Attachments")}
            <PersonnelRecordUploadComponent
              label={"Attachments"}
              files={addedAttachments}
              fileTypes={"PDF, PNG, JPG"}
            />
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <Link to={`/regulator/personnel/${personnelID}`}>
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
