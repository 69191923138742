import { Link, useNavigate, useParams } from "react-router-dom";
import { PencilAltIcon, UserIcon } from "@heroicons/react/solid";
import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import { sopCategoryAuditPath, useSOPCategory } from "../common/DataFetchers";
import * as React from "react";
import { useUserContext } from "../common/BasePageScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";
import AuditTrailCard from "../common/AuditTrailCard";

const fieldDisplayMap = new Map([
  ["initials", "Category Initials"],
  ["name", "Category Name"],
  ["approverRoles", "Approvers"],
]);

export default function SOPCategoryDetailCard(): JSX.Element {
  const { sopsRead, sopsWrite } = useUserContext();
  const navigate = useNavigate();

  const params = useParams<{ id: string }>();
  const categoryID = params.id as string;

  const { sopCategory, sopCategoryIsLoading, sopCategoryIsError } =
    useSOPCategory(categoryID, sopsRead);

  if (!sopsRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (sopCategoryIsLoading) {
    return <LoadingComponent />;
  }

  if (sopCategoryIsError || !sopCategory) {
    return <ErrorComponent />;
  }

  const pages = [
    { name: "SOPs", path: "/regulator/sops" },
    {
      name: `SOP Category - ${sopCategory.name}`,
      path: `/regulator/sops/categories/${sopCategory.id}`,
    },
  ];

  return (
    <div>
      <FullWidthBreadcrumbBar pages={pages} />
      <div className="py-3 pt-5">
        <div className="flex justify-end">
          <Link
            to={`/regulator/sops/categories/${categoryID}/edit`}
            hidden={!sopsWrite}
          >
            <button
              type="button"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <PencilAltIcon
                className="-ml-1 mr-3 h-5 w-5"
                aria-hidden="true"
              />
              Edit
            </button>
          </Link>
        </div>
      </div>
      <div className="bg-white mt-3 shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            SOP Category Details
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Details regarding SOP category and associated information
          </p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Category Name
              </dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium sm:mt-0 sm:col-span-2">
                {sopCategory.name}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Category Initials
              </dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium sm:mt-0 sm:col-span-2">
                {sopCategory.initials}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Approvers</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {sopCategory.approverRoles.length === 0 ? (
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm italic">
                      None
                    </li>
                  </ul>
                ) : (
                  <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                    {sopCategory.approverRoles.map((approverRole) => (
                      <li
                        key={approverRole.id}
                        className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                      >
                        <div className="w-0 flex-1 flex items-center">
                          <UserIcon
                            className="flex-shrink-0 h-5 w-5 text-blue-400"
                            aria-hidden="true"
                          />
                          <span className="ml-2 flex-1 w-0 truncate">
                            {approverRole.name}
                          </span>
                        </div>
                        <div className="ml-4 flex-shrink-0">
                          <Link
                            to={`/regulator/personnel/roles/${approverRole.id}`}
                            className="font-medium text-blue-600 hover:text-blue-500"
                          >
                            View
                          </Link>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <AuditTrailCard
        fieldDisplayMap={fieldDisplayMap}
        type={"SOP Category"}
        fetchPath={sopCategoryAuditPath(categoryID)}
      />
    </div>
  );
}
