import React, { useState } from "react";
import { getDate } from "./Utils";
import { AnnotationIcon } from "@heroicons/react/outline";
import { useUserContext } from "./BasePageScreen";
import { Link, useParams } from "react-router-dom";
import {
  addSOPDraftComment,
  deleteSOPDraftComment,
  useSOPDraftComments,
} from "./DataFetchers";
import { XCircleIcon } from "@heroicons/react/solid";

const errorClassname =
  "shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border border-red-500 rounded-md";
const normalClassname =
  "shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border border-gray-300 rounded-md";

export default function CommentsSection() {
  const { user, sopsRead, sopsWrite } = useUserContext();

  const params = useParams<{ id: string }>();
  const sopID = params.id;

  const { comments } = useSOPDraftComments(sopID, sopsRead);

  const [showError, setShowError] = useState(false);
  const [currentClassname, setCurrentClassname] = useState(normalClassname);

  if (!sopID) {
    return <></>;
  }

  return (
    <div>
      <div className="mt-2 bg-white shadow overflow-hidden sm:rounded-md">
        <h3 className="ml-9 mt-3 text-lg leading-6 font-medium text-gray-900">
          Comments
        </h3>
        {comments.length === 0 ? (
          <button
            type="button"
            className="relative block w-full rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <AnnotationIcon className="mx-auto h-12 w-12 text-gray-400" />
            <span className="mt-2 block text-sm font-medium text-gray-900">
              No comments
            </span>
          </button>
        ) : (
          <ul role="list" className="divide-y divide-gray-200">
            {comments.map((comment) => (
              <li key={comment.id}>
                <div className="flex items-center px-4 py-4 sm:px-6">
                  <div className="min-w-0 flex-1 flex items-center">
                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                      <div>
                        {!comment.personnel ? (
                          <p className="text-sm font-medium text-gray-900 truncate">
                            {`${comment.user.firstName} ${comment.user.lastName}`.trim()}
                          </p>
                        ) : (
                          <div>
                            <Link
                              to={`/regulator/personnel/${comment.personnel.id}`}
                            >
                              <p className="text-sm font-medium text-blue-600 truncate">
                                {`${comment.user.firstName} ${comment.user.lastName}`.trim()}
                              </p>
                            </Link>
                            <Link
                              to={`/regulator/personnel/roles/${comment.personnel.role.id}`}
                            >
                              <p className="mt-2 flex items-center text-sm text-gray-500">
                                <span className="truncate">
                                  {comment.personnel.role.name}
                                </span>
                              </p>
                            </Link>
                          </div>
                        )}
                      </div>
                      <div className="hidden md:block">
                        <div>
                          <p className="text-sm text-gray-900">
                            {comment.text}
                          </p>
                          <p className="mt-2 flex items-center text-sm text-gray-500">
                            <time dateTime={"2021-10-29"}>
                              {getDate(comment.created)}
                            </time>
                          </p>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="rounded-full"
                      hidden={comment.user.id !== user.id}
                      onClick={() => {
                        deleteSOPDraftComment({
                          id: comment.id,
                          sopID: sopID,
                        }).catch((error) => console.log(error));
                      }}
                    >
                      <XCircleIcon className="h-7 w-7 text-red-500" />
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>

      {sopsWrite && (
        <form
          className="mt-1.5 p-3"
          onSubmit={(e: React.SyntheticEvent) => {
            e.preventDefault();
            const target = e.target as typeof e.target & {
              comment: { value: string };
            };

            setShowError(false);
            setCurrentClassname(normalClassname);
            if (target.comment.value.trim() === "") {
              setShowError(true);
              setCurrentClassname(errorClassname);
              return;
            }

            addSOPDraftComment({
              sopID: sopID,
              text: target.comment.value,
            }).catch((error) => console.log(error));
            target.comment.value = "";
          }}
        >
          <div className="sm:col-span-6">
            <label
              htmlFor="comment"
              className="block text-sm font-medium text-gray-700"
            >
              Comment
            </label>
            <div className="mt-1">
              <textarea
                id="comment"
                name="comment"
                rows={3}
                className={currentClassname}
                defaultValue={""}
              />
            </div>
            <p className="mt-2 text-sm text-gray-500" hidden={showError}>
              Add a comment regarding the document approval
            </p>
            <p className="mt-2 text-sm text-red-500" hidden={!showError}>
              * Comment must not be empty
            </p>
          </div>
          <div>
            <div className="flex justify-end">
              <button
                type="submit"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
