import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Pagination from "../common/Pagination";
import EmptyStateScreen from "../common/EmptyStateScreen";
import { classNames, formatDateInputValue, getDate } from "../common/Utils";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import CustomSelectMenu from "../common/CustomSelectMenu";
import { EquipmentSummary } from "../common/Entities.d";
import {
  useEquipmentSummaries,
  useEquipmentTypeSummaries,
} from "../common/DataFetchers";
import { useUserContext } from "../common/BasePageScreen";
import LoadingComponent from "../common/LoadingComponent";
import ErrorComponent from "../common/ErrorComponent";

const statusToClassnameMap = new Map([
  [
    "Quarantined",
    "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800",
  ],
  [
    "In Use",
    "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800",
  ],
  [
    "Archived",
    "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800",
  ],
]);

const statusOptions = [
  { id: "0", value: "Select", display: "Select" },
  { id: "1", value: "Quarantined", display: "Quarantined" },
  { id: "2", value: "In Use", display: "In Use" },
];

export default function EquipmentList(): JSX.Element {
  const { equipmentRead, equipmentWrite } = useUserContext();
  const navigate = useNavigate();

  const isArchived = location.pathname === "/regulator/equipment/archived";

  const {
    equipmentTypeSummaries,
    equipmentTypeSummariesIsLoading,
    equipmentTypeSummariesIsError,
  } = useEquipmentTypeSummaries(equipmentRead);

  const {
    equipmentSummaries,
    equipmentSummariesIsLoading,
    equipmentSummariesIsError,
  } = useEquipmentSummaries(equipmentRead);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [items, setItems] = useState<EquipmentSummary[]>([]);

  const [showFilterBox, setShowFilterBox] = useState(false);

  useEffect(() => {
    if (equipmentSummaries.length === 0) {
      return;
    }
    isArchived
      ? setItems(equipmentSummaries.filter((e) => e.status === "Archived"))
      : setItems(equipmentSummaries.filter((e) => e.status !== "Archived"));
  }, [equipmentSummaries]);

  const [typeOptions, setTypeOptions] = useState<
    { id: string; value: any; display: string }[]
  >([{ id: "0", value: "Select", display: "Select" }]);
  const [filterType, setFilterType] = useState(typeOptions[0]);

  const [filterStatus, setFilterStatus] = useState(statusOptions[0]);

  useEffect(() => {
    if (equipmentTypeSummaries.length === 0) {
      return;
    }
    const updatedOptions: { id: string; value: any; display: string }[] = [
      { id: "0", value: "Select", display: "Select" },
    ];
    equipmentTypeSummaries.forEach((equipmentType) =>
      updatedOptions.push({
        id: equipmentType.id,
        value: equipmentType,
        display: equipmentType.name,
      })
    );
    setTypeOptions(updatedOptions);
  }, [equipmentTypeSummaries]);

  if (!equipmentRead) {
    navigate("/forbidden");
    return <></>;
  }

  if (equipmentSummariesIsLoading || equipmentTypeSummariesIsLoading) {
    return <LoadingComponent />;
  }

  if (equipmentSummariesIsError || equipmentTypeSummariesIsError) {
    return <ErrorComponent />;
  }

  const pageEndIndex = currentPage * itemsPerPage;
  const pageStartIndex = pageEndIndex - itemsPerPage;
  const pageEquipmentSummaries: EquipmentSummary[] = items
    ? items.slice(pageStartIndex, pageEndIndex)
    : equipmentSummaries
        .filter((e) =>
          isArchived ? e.status === "Archived" : e.status !== "Archived"
        )
        .slice(pageStartIndex, pageEndIndex);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const tableEquipmentRows = pageEquipmentSummaries.map((equipment, index) => (
    <tr
      key={equipment.id}
      className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
    >
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        {equipment.displayID}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        {equipment.type.name}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {equipment.manufacturer}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {getDate(equipment.lastCalibration)}
      </td>
      <td className={"px-6 py-4 whitespace-nowrap"} hidden={isArchived}>
        <span className={statusToClassnameMap.get(equipment.status)}>
          {equipment.status}
        </span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <Link
          to={`/regulator/equipment/${equipment.id}`}
          className="text-blue-600 hover:text-blue-900"
        >
          View
        </Link>
      </td>
    </tr>
  ));

  function clearFilter() {
    (document.getElementById("equipmentIdFilter") as HTMLInputElement).value =
      "";
    (document.getElementById("manufacturerFilter") as HTMLInputElement).value =
      "";
    (document.getElementById("startDateFilter") as HTMLInputElement).value = "";
    (document.getElementById("endDateFilter") as HTMLInputElement).value = "";
    setFilterType(typeOptions[0]);
    setFilterStatus(statusOptions[0]);
    setItems(
      Array.from(
        equipmentSummaries.filter((e) =>
          isArchived ? e.status === "Archived" : e.status !== "Archived"
        )
      )
    );
  }

  function handleFilter(e: React.SyntheticEvent) {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      equipmentIdFilter: { value: string };
      manufacturerFilter: { value: string };
      startDateFilter: { value: string };
      endDateFilter: { value: string };
    };

    let newItems = equipmentSummaries.filter((e) =>
      isArchived ? e.status === "Archived" : e.status !== "Archived"
    );

    const equipmentIdFilter = target.equipmentIdFilter.value.trim();
    if (equipmentIdFilter !== "") {
      newItems = newItems.filter((e) => e.displayID === equipmentIdFilter);
    }
    const typeFilter = filterType.value;
    if (typeFilter !== "Select") {
      newItems = newItems.filter((e) => e.type.id === typeFilter.id);
    }
    const manufacturerFilter = target.manufacturerFilter.value.trim();
    if (manufacturerFilter !== "") {
      newItems = newItems.filter((e) => e.manufacturer === manufacturerFilter);
    }
    const startDateFilter = target.startDateFilter.value.trim();
    if (startDateFilter !== "") {
      newItems = newItems.filter(
        (e) =>
          new Date(e.lastCalibration) >=
          new Date(formatDateInputValue(startDateFilter))
      );
    }
    const endDateFilter = target.endDateFilter.value.trim();
    if (endDateFilter !== "") {
      newItems = newItems.filter(
        (e) =>
          new Date(e.lastCalibration) <=
          new Date(formatDateInputValue(endDateFilter))
      );
    }
    const statusFilter = filterStatus.value;
    if (statusFilter !== "Select") {
      newItems = newItems.filter((e) => e.status === statusFilter);
    }

    setItems(Array.from(newItems));
  }

  return (
    <div className="flex flex-col">
      <div className="bg-white p-3 border border-gray-200 rounded-lg shadow-sm">
        <h3 className="ml-1 text-md leading-6 font-medium text-gray-900">
          Filter
        </h3>
        <div className="-mt-6 text-right">
          <button onClick={() => setShowFilterBox(!showFilterBox)}>
            {showFilterBox ? (
              <ChevronUpIcon
                className="h-6 w-6 text-gray-500"
                aria-hidden="true"
              />
            ) : (
              <ChevronDownIcon
                className="h-6 w-6 text-gray-500"
                aria-hidden="true"
              />
            )}
          </button>
        </div>
        <div hidden={!showFilterBox}>
          <form
            className={classNames(
              isArchived ? "grid-cols-4" : "grid-cols-5",
              "grid gap-4"
            )}
            onSubmit={(e) => handleFilter(e)}
          >
            <div className="ml-1">
              <label
                htmlFor="equipmentIdFilter"
                className="mt-2.5 block text-sm font-medium text-gray-700"
              >
                Equipment ID
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="equipmentIdFilter"
                  id="equipmentIdFilter"
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="ml-1">
              <CustomSelectMenu
                label={"Equipment Type"}
                options={typeOptions}
                selected={filterType}
                setSelected={setFilterType}
              />
            </div>
            <div className="ml-1">
              <label
                htmlFor="manufacturerFilter"
                className="mt-2.5 block text-sm font-medium text-gray-700"
              >
                Manufacturer
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="manufacturerFilter"
                  id="manufacturerFilter"
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="ml-1" hidden={isArchived}>
              <CustomSelectMenu
                label={"Status"}
                options={statusOptions}
                selected={filterStatus}
                setSelected={setFilterStatus}
              />
            </div>
            <div className="ml-1">
              <label
                htmlFor="startDateFilter"
                className="mt-2.5 block text-sm font-medium text-gray-700 mb-1"
              >
                Last Calibration
              </label>
              <input
                type="date"
                name="startDateFilter"
                id="startDateFilter"
                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
              <p className="text-center">to</p>
              <input
                type="date"
                name="endDateFilter"
                id="endDateFilter"
                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>

            <div
              className={classNames(
                isArchived ? "col-end-5" : "col-end-6",
                "text-center col-span-1"
              )}
            >
              <button
                type="button"
                onClick={clearFilter}
                className="m-1 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Clear
              </button>
              <button
                type="submit"
                className="m-1 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Apply
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            {equipmentSummaries.length === 0 ? (
              <EmptyStateScreen
                item={"equipment"}
                createLink={"/regulator/equipment/add"}
                writeAccess={equipmentWrite}
              />
            ) : (
              <div>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Equipment Id
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Equipment Type
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase ReagentTypeList.tsx"
                      >
                        Manufacturer
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase ReagentTypeList.tsx"
                      >
                        Last Calibration
                      </th>
                      <th
                        scope="col"
                        hidden={isArchived}
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase ReagentTypeList.tsx"
                      >
                        Status
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">View</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{tableEquipmentRows}</tbody>
                </table>
                <Pagination
                  itemsPerPage={itemsPerPage}
                  totalItems={
                    items
                      ? items.length
                      : equipmentSummaries.filter((e) =>
                          isArchived
                            ? e.status === "Archived"
                            : e.status !== "Archived"
                        ).length
                  }
                  currentPage={currentPage}
                  paginate={paginate}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
