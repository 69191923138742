import { FormElementSimpleInput } from "../../common/Entities.d";

export default function NumberInput(props: {
  simpleFormInput: FormElementSimpleInput;
  error: boolean;
  recordPointer: { recordValueArray: string; recordValueIndex: number };
  textValues: Array<string[]>;
  setTextValues: (textValues: Array<string[]>) => void;
}) {
  return (
    <div>
      <label
        htmlFor={props.simpleFormInput.label}
        className="block text-sm font-medium text-gray-700"
      >
        {props.simpleFormInput.label}
      </label>
      <div className="mt-1">
        <input
          type="number"
          name={props.simpleFormInput.label}
          id={props.simpleFormInput.label}
          className={
            props.error
              ? "shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border-red-300 rounded-md"
              : "shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
          }
          placeholder={props.simpleFormInput.placeholder}
          defaultValue={props.textValues[props.recordPointer.recordValueIndex]}
          onChange={(e) => {
            props.textValues[props.recordPointer.recordValueIndex] = [
              e.currentTarget.value,
            ];
            props.setTextValues(props.textValues);
          }}
        />
        <p className="mt-2 text-sm text-red-500" hidden={!props.error}>
          * {props.simpleFormInput.label} is required.
        </p>
      </div>
    </div>
  );
}
