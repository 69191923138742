import { CogIcon } from "@heroicons/react/outline";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Tabs from "../common/Tabs";
import FullWidthBreadcrumbBar from "../common/FullWidthBreadcrumbBar";
import * as React from "react";
import { useUserContext } from "../common/BasePageScreen";
import EquipmentList from "./EquipmentList";
import EquipmentTypesList from "./EquipmentTypesList";

const equipmentPages = [{ name: "Equipment", path: "/regulator/equipment" }];
const typePages = [
  { name: "Equipment", path: "/regulator/equipment" },
  { name: "Equipment Types", path: "/regulator/equipment/types" },
];
const archivedPages = [
  { name: "Equipment", path: "/regulator/equipment" },
  { name: "Archived", path: "/regulator/equipment/archived" },
];

const equipmentTabs = [
  { name: "Active", href: "/regulator/equipment" },
  { name: "Types", href: "/regulator/equipment/types" },
  { name: "Archived", href: "/regulator/equipment/archived" },
];

export default function EquipmentOrTypeList(): JSX.Element {
  const { equipmentRead, equipmentWrite } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();

  if (!equipmentRead) {
    navigate("/forbidden");
    return <></>;
  }

  let breadcrumbBar = <FullWidthBreadcrumbBar pages={equipmentPages} />;

  if (location.pathname === "/regulator/equipment/types") {
    breadcrumbBar = <FullWidthBreadcrumbBar pages={typePages} />;
  }

  if (location.pathname === "/regulator/equipment/archived") {
    breadcrumbBar = <FullWidthBreadcrumbBar pages={archivedPages} />;
  }

  let addButton = { text: "Add Equipment", href: "/regulator/equipment/add" };

  if (location.pathname === "/regulator/equipment/types") {
    addButton = {
      text: "Add Equipment Type",
      href: "/regulator/equipment/types/add",
    };
  }

  let component = <EquipmentList />;

  if (location.pathname === "/regulator/equipment/types") {
    component = <EquipmentTypesList />;
  }

  return (
    <div className="flex flex-col">
      {breadcrumbBar}
      <div className="py-3 text-right">
        <Link to={addButton.href} hidden={!equipmentWrite}>
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <CogIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
            {addButton.text}
          </button>
        </Link>
      </div>
      <Tabs tabs={equipmentTabs} />
      {component}
    </div>
  );
}
